import React, { useCallback, useEffect, useState } from 'react';

import { Drawer, Button, Radio, Space, message } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import Text from 'antd/lib/typography/Text';
import { useMutation } from '@apollo/client';
import { Container, Content, Footer } from './styles';
import { IMerchantDTO } from '../../dtos/MerchantDTO';
import { dateFormat } from '../../utils/constants';
import Mutation from '../../graphql/Mutation';

interface ConsolidateModalProps {
  merchants: IMerchantDTO[];
  onCancel(): void;
}

const ConsolidateModal: React.FC<ConsolidateModalProps> = ({ merchants, onCancel }) => {
  const [selected, setSelected] = useState<string>();
  const [consolidate, { loading, data }] = useMutation(Mutation.consolidateMerchants.mutation);
  useEffect(() => {
    if (data && merchants.length > 0) {
      onCancel();
    }
  }, [data, merchants.length, onCancel]);

  const handleSubmit = useCallback(() => {
    if (!selected) {
      message.error('Please select the merchant to consolidate');
      return;
    }
    const filteredMerchants = merchants.filter((m) => m.id !== selected);
    consolidate({
      variables: {
        id: selected,
        ids: filteredMerchants.map((m) => m.id),
      },
    });
  }, [consolidate, merchants, selected]);

  return (
    <Drawer
      onClose={onCancel}
      headerStyle={{
        background: '#f5f5f5',
        borderBottomColor: '#d9d9d9',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      }}
      footerStyle={{
        background: '#f5f5f5',
        borderTopColor: '#d9d9d9',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
      }}
      footer={
        <Footer>
          <Button key="close" onClick={onCancel}>
            Close
          </Button>
          <Button loading={loading} key="submit" type="primary" onClick={handleSubmit}>
            OK
          </Button>
        </Footer>
      }
      width={560}
      destroyOnClose
      title="Consolidate Merchants"
      visible={merchants.length > 0}
    >
      <Container>
        <Text>Please select the primary Merchant which you wish to consolidate the others into:</Text>
        <Content>
          <Radio.Group onChange={(val) => setSelected(val.target.value)}>
            <Space direction="vertical">
              {merchants.map((a) => (
                <Radio value={a.id} key={a.id}>
                  {a.name} -- {dateFormat(a.createdAt)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Content>
        <Text type="danger">
          <WarningOutlined />
          Warning! This operation is destructive and cannot be undone!
        </Text>
      </Container>
    </Drawer>
  );
};

export default ConsolidateModal;
