import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { IQueryObject } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

const ArticleTypeField: React.FC<IGenericSelectFieldProps> = (params) => {
  const { loading, data } = useQuery<IQueryObject<any>>(Query.articleTypes.query);

  const iTypes = useMemo(() => {
    if (data) {
      return data.object.map((o) => ({
        label: o,
        value: o,
      }));
    }
    return [];
  }, [data]);

  return <SelectField name="Article Type" loading={loading} data={iTypes} {...params} />;
};

export default ArticleTypeField;
