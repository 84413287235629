/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import WebsiteForm from '../../components/Forms/WebsitesForm';
import ListLayout from '../../components/ListLayout';
import { ROUTES } from '../../utils/constants';

const WebsitesPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const publisherId = useMemo(() => {
    const paths = location.pathname.split('/');
    if (paths.length < 3) {
      return undefined;
    }
    return paths[2];
  }, [location.pathname]);

  useEffect(() => {
    if (!publisherId || publisherId === null) {
      message.info('Please select the publisher');
      history.push(ROUTES.publishers);
    }
  }, [history, publisherId]);

  const handleIndexQuery = useMemo(() => {
    return {
      name: 'publisher',
      variables: {
        id: publisherId as string,
      },
      callback: (data: any) => data.publisher.websites,
    };
  }, [publisherId]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'URL',
        key: 'url',
        dataIndex: 'url',
      },
      {
        title: 'Website ID',
        key: 'websiteId',
        dataIndex: 'websiteId',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'active',
        render: (active: boolean) => (active ? 'Active' : 'Inactive'),
        width: 80,
      },
    ];
  }, []);

  const handleLoadVariables = useCallback(
    (values, selected = undefined) => {
      const { websiteId, ...rest } = values;
      return {
        data: rest,
        publisherId,
        websiteId: selected?.websiteId || websiteId,
      };
    },
    [publisherId],
  );

  const handleLoadRemove = useCallback(
    (selected: string[]) => {
      return {
        publisherId,
        websiteIds: selected,
      };
    },
    [publisherId],
  );

  return (
    <ListLayout
      name="Website"
      columns={columns}
      paginated={['name']}
      hideDelete
      handleIndexQuery={handleIndexQuery}
      entityForm={WebsiteForm}
      modalSize="large"
      onBeforeCreate={handleLoadVariables}
      onBeforeUpdate={handleLoadVariables}
      onBeforeRemove={handleLoadRemove}
      schema={{
        id: 'websiteId',
      }}
    />
  );
};

export default WebsitesPage;
