import React, { useEffect, useState } from 'react';
import { Input, Tag, Tooltip } from 'antd';

import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

import Text from 'antd/lib/typography/Text';
import { TagsContent, TagInput } from './styles';

interface TagListProps {
  id?: string;
  value?: string[];
  onChange?(value: string[]): void;
  buttonLabel?: string;
  placeholder?: string;
  tagColor?: string;
  unique?: boolean;
  readOnly?: boolean;
  validate?(text: string): string | undefined;
}

const TagList: React.FC<TagListProps> = ({
  value = [],
  onChange = () => undefined,
  validate,
  placeholder,
  buttonLabel = 'Add Tag',
  unique = true,
  readOnly,
  tagColor,
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputRef, setInputRef] = useState<Input>();
  const [error, setError] = useState<string>();

  const handleClose = (removedTag: string) => {
    const newValue = value.filter((f) => f !== removedTag);
    onChange(newValue);
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue.length > 0) {
      const errorMessage = validate && validate(inputValue);
      if (errorMessage) {
        setError(errorMessage);
        return;
      }
      if (unique && (value || []).indexOf(inputValue) > -1) {
        setError('Must be unique');
        return;
      }
      onChange([...(value || []), inputValue]);
    }
    setError(undefined);
    setInputVisible(false);
    setInputValue('');
  };

  useEffect(() => {
    if (inputVisible && inputRef) {
      inputRef.focus();
    }
  }, [inputRef, inputVisible]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  return (
    <div
      style={{
        alignContent: 'center',
        display: 'flex',
      }}
    >
      {readOnly && (value || []).length === 0 && <Text type="secondary">None</Text>}
      <TagsContent>
        {!readOnly && inputVisible && (
          <TagInput
            ref={(input) => setInputRef(input || undefined)}
            type="text"
            size="small"
            className="tag-input"
            placeholder={placeholder}
            error={!!error}
            prefix={
              <Tooltip title={error}>
                <CloseCircleOutlined />
              </Tooltip>
            }
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!readOnly && !inputVisible && (
          <Tag className="site-tag-plus" onClick={() => setInputVisible(true)}>
            <PlusOutlined /> {buttonLabel}
          </Tag>
        )}
        <div>
          {(value || []).map((tag) => (
            <Tag key={tag} closable={!readOnly} color={tagColor} onClose={() => handleClose(tag)}>
              <span>{tag}</span>
            </Tag>
          ))}
        </div>
      </TagsContent>
    </div>
  );
};

export default TagList;
