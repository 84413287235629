import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { IQueryObject } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';
import { IPlacementTypeDTO } from '../../../dtos/PlacementDTO';

const PlacementTypeField: React.FC<IGenericSelectFieldProps> = (params) => {
  const { loading, data } = useQuery<IQueryObject<IPlacementTypeDTO>>(Query.placementTypes.query);

  const placements = useMemo(() => {
    if (data) {
      return data.object;
    }
    return [];
  }, [data]);

  return <SelectField name="Placement" loading={loading} data={placements} {...params} />;
};

export default PlacementTypeField;
