import styled from 'styled-components';
import { Anchor as AntAnchor } from 'antd';

export const Anchor = styled(AntAnchor)`
  background: #ffffff;
  border-top: 1px solid #e0e0e0 !important;
  .ant-anchor {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: auto;
    color: inherit;
  }
  .ant-anchor-ink {
    display: none;
  }
  .ant-anchor-link {
    padding: 0.5rem 1.5rem;
  }

  .ant-anchor-link-active {
    border-bottom: 1px solid #1890ff;
    color: #1890ff;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 81px;
`;

export const Title = styled.h2`
  font-size: 32px;
  margin: 0 0 35px 0;
  font-weight: 600px;
  color: '#373E46';
`;

export const Table = styled.div`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  td,
  th {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 4px 6px;
  }
  tr:last-child {
    td:last-child,
    th:last-child {
      border-right: 0;
    }
    td {
      border-bottom: 0;
    }
  }
`;
