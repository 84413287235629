import React from 'react';
import { Col, Divider, Row, Collapse } from 'antd';
import { formatDistanceToNow } from 'date-fns';
import {
    ChangeLogRow,
    ChangeLogCol,
    ChangeLogByLineRow,
    ChangeLogByLineCol,
    ChangeLogDateCol,
} from './styles';

interface ChangeLogProps {
  changeLog: string[];
}

const { Panel } = Collapse;

const NO_SHOW_KEYS = ['id', 'dtProductId', '_id'];

const ChangeLog: React.FC<ChangeLogProps> = ({ changeLog }) => {
  const renderField = (key: string, value: string, isDifferent: boolean) => {
    return (
      <ChangeLogRow>
        <Col span={4} style={{ fontWeight: 'bold' }}>{key}:</Col>
        <ChangeLogCol
          span={20}
          isDifferent={isDifferent}
        >
          {value}
        </ChangeLogCol>
      </ChangeLogRow>
    )
  }
    
  return (
    <>
      {
        changeLog?.map((change, changeLogIndex) => {
          try {
            let nextRecord: {[key: string]: any} = {};
            const record = JSON.parse(change);

            if (changeLogIndex < changeLog.length - 1) {
              nextRecord = JSON.parse(changeLog[changeLogIndex + 1])?.data || {};
            }

            const items = Object.keys(record.data).map((key, keyIndex) => {
              if (NO_SHOW_KEYS.indexOf(key) > -1) {
                return null;
              }

              if (!Array.isArray(record.data[key])) {
                const isDifferent = record.data[key] !== nextRecord?.[key];
                if (isDifferent) {
                  return renderField(key, record.data[key], isDifferent); 
                }
 
                return null;
              } else {
                const itemArray = record.data[key];
                let nestedDiffFound = false;
                const subItems = itemArray.map((item: {[key: string]: string}, nestedIndex: number) => {
                  const results = Object.keys(item).map((subKey) => {
                    const subItemIsDifferent = record?.data?.[key]?.[nestedIndex]?.[subKey] !== nextRecord?.[key]?.[nestedIndex]?.[subKey];
                    if (subItemIsDifferent) {
                        nestedDiffFound = subItemIsDifferent;
                        return renderField(subKey, record?.data?.[key]?.[nestedIndex]?.[subKey], subItemIsDifferent);
                    }

                    return null;
                  });
  
                  return results;
                });

                if (nestedDiffFound) {
                  return (
                    <Collapse accordion>
                      <Panel header={key} key={`${changeLogIndex}${keyIndex}`}>
                        { subItems }
                      </Panel>
                    </Collapse>
                  );
                }

                return null;
              }
            });

            return (
              <div>
                <ChangeLogByLineRow>
                  <ChangeLogByLineCol span={12}>
                    <span style={{ fontWeight: 'bold' }}>Changed By:{' '}</span>
                    {' '}{record?.user?.firstName}{' '}{record?.user?.lastName}
                  </ChangeLogByLineCol>
                  <ChangeLogDateCol span={12}>
                    {formatDistanceToNow(new Date(record?.modified_at), { addSuffix: true })}
                  </ChangeLogDateCol>
                </ChangeLogByLineRow>
                { items }
                <Divider />
              </div>
            )
          } catch (_) {
            return null;
          }
        })
      }
    </>
  )
}

export default ChangeLog;
