import styled, { css } from 'styled-components';
import { Row, Button } from 'antd';

interface ContainerProps {
  isHover?: boolean;
}

export const CriteriaLabel = styled.label`
  width: 137px;
  text-align: end;
  padding-right: 10px;
`;

export const Container = styled(Row)<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 3px 0;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: transparent;
  ${(props) =>
    props.isHover &&
    css`
      border-color: #ff000040;
      background: #ff000010;
    `}
`;

export const ButtonRemove = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: red;
  }
`;
