/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface IVariables {
  variables: any;
}

export const asyncUseMutation = async (mutation: any, variables?: IVariables): Promise<any> => {
  return new Promise((resolve, reject) => {
    mutation(variables)
      .then((r: any) => resolve(r.data))
      .catch((err: Error) => reject(err));
  });
};
