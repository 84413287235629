/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';

import { NotificationOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import CampaignForm from '../../components/Forms/CampaignForm';
import ListLayout from '../../components/ListLayout';
import { ICampaignDTO } from '../../dtos/CampaignDTO';
import { dateFormat } from '../../utils/constants';

import { RecordBoldLabel, RecordLabel, TableRecord } from './styles';
import Mutation from '../../graphql/Mutation';
import { asyncUseMutation } from '../../services/GraphQLService';

interface IScopeType {
  categories: any;
  brands: any;
  products: any;
  [key: string]: any;
}

const CampaignsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('active');
  const [duplicate] = useMutation(Mutation.duplicateCampaignRules.mutation);
  const [archive] = useMutation(Mutation.archiveCampaignRules.mutation);
  const [unarchive] = useMutation(Mutation.unarchiveCampaignRules.mutation);

  const customActions = [
    {
      title: 'Duplicate',
      disableWithNoSelections: true,
      onClick: async (_filters: any, selectedKeys: string[]) => {
        await asyncUseMutation(duplicate, {
          variables: { ids: selectedKeys },
        });
      },
    },
    {
      title: selectedTab === 'active' ? 'Archive' : 'Unarchive',
      disableWithNoSelections: true,
      confirm:
        selectedTab === 'active'
          ? 'Do you want to archive selected items?'
          : 'Do you want to unarchive selected items?',
      onClick: async (_filters: any, selectedKeys: string[]) => {
        if (selectedTab === 'active') {
          await asyncUseMutation(archive, {
            variables: { ids: selectedKeys },
          });
        } else {
          await asyncUseMutation(unarchive, {
            variables: { ids: selectedKeys },
          });
        }
      },
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        title: 'Summary',
        key: 'summary',
        render: (v: any, campaign: ICampaignDTO) => (
          <TableRecord>
            <RecordBoldLabel>{campaign.name}</RecordBoldLabel>
            <RecordLabel>
              {campaign.merchants.map((m) => `${m.name} (${m.affiliateNetwork?.name})`).join(', ')}
            </RecordLabel>
          </TableRecord>
        ),
      },
      {
        title: 'Term',
        key: 'term',
        width: 220,
        render: (v: any, campaign: ICampaignDTO) => (
          <TableRecord>
            <RecordLabel>from: {dateFormat(campaign.startDate)}</RecordLabel>
            <RecordLabel>thru: {dateFormat(campaign.endDate)}</RecordLabel>
          </TableRecord>
        ),
      },
    ];
  }, []);

  return (
    <ListLayout
      name={{
        name: 'Campaign',
        entityName: 'CampaignRule',
      }}
      modalSize="medium"
      columns={columns}
      entityForm={CampaignForm}
      actions={customActions}
      onChangeTab={(val) => setSelectedTab(val)}
      tabs={[
        {
          id: 'active',
          title: (
            <>
              <NotificationOutlined /> Active
            </>
          ),
          condition: (record) => record.archived !== true,
        },
        {
          id: 'archived',
          title: (
            <>
              <LockOutlined /> Archived
            </>
          ),
          condition: (record) => record.archived === true,
        },
      ]}
      schema={{
        parse: (data) => {
          const parsed = {
            name: data.name,
            startDate: data.rangeDate[0].getTime(),
            endDate: data.rangeDate[1].getTime(),
            merchantIds: data.merchants?.map((m: any) => m.value),
            scopes: data.scopes?.map(
              ({
                __typename,
                categories = [],
                articleTypes = [],
                articles = [],
                brands = [],
                products = [],
                articleCategories = [],
                placementTypes = [],
                sites = [],
                customTargeting = [],
                placements = [],
                exclusive,
                ...rest
              }: IScopeType) => ({
                ...rest,
                exclusive: !!exclusive,
                articleTypes: articleTypes?.map((a: any) => a.value),
                articles,
                articleCategories,
                brandIds: brands.map((b: any) => b.value),
                categoryIds: categories.map((c: any) => c.value),
                placementIds: placements?.map((p: any) => p.value),
                placementTypes: placementTypes.map((pt: any) => pt.value),
                priority: rest.priority ? parseFloat(rest.priority.value) : null,
                productIds: products.map((p: any) => p.value),
                sites: sites.map((st: any) => st.value),
                customTargeting: customTargeting?.map((ct: any) => ({
                  field: ct.name,
                  value: ct.value,
                })),
              }),
            ),
          };
          return parsed;
        },
      }}
    />
  );
};

export default CampaignsPage;
