import styled from 'styled-components';
import { Typography } from 'antd';

const { Text, Link } = Typography;

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  margin-left: 12px;
  min-width: 200px;
  > div {
    font-size: 12px;
    &:first-child {
      font-size: 14px;
    }
  }
`;

export const PopoverItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 430px;
`;

export const ItemLabel = styled(Text)``;

export const ItemValue = styled(Text)``;

export const ItemLink = styled(Link)``;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  > div {
    /* height: 100%; */
    > img {
      max-height: 60px;
    }
  }
`;
