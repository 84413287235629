import React from 'react';
import { Image } from 'antd';

import { dateFormat } from '../../utils/constants';
import AffiliateNetworkForm from '../../components/Forms/AffiliateNetworkForm';
import ListLayout from '../../components/ListLayout';
import { ILogoAffiliateDTO } from '../../dtos/MerchantDTO';

const AffiliateNetworksPage: React.FC = () => {
  const columns = [
    {
      title: 'Logo',
      key: 'logo',
      dataIndex: 'logo',
      render: (value?: ILogoAffiliateDTO) => <Image preview={false} src={value?.url} />,
      width: 80,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Commission Rate',
      key: 'commissionRate',
      dataIndex: 'commissionRate',
    },
    {
      title: 'Created',
      key: 'createdAt',
      render: (value: number) => value && dateFormat(value, 'LLL'),
      width: 200,
    },
  ];

  return (
    <ListLayout
      paginated={false}
      name="AffiliateNetwork"
      columns={columns}
      entityForm={AffiliateNetworkForm}
    />
  );
};

export default AffiliateNetworksPage;
