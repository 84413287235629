import styled from 'styled-components';
import { Image } from 'antd';

interface WidgetStartProps {
  half?: boolean;
}

export const Container = styled.div`
  width: 310px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const InputHolder = styled.div`
  margin: 7px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InputLabel = styled.label`
  width: 100px;
  font-size: 16px;
`;

export const InputText = styled.input`
  padding: 7px 10px;
  font-size: 14px;
  border-width: 1px;
  line-height: 1;
  border-radius: 4px;
  flex: 1;
  width: 170px;
`;

export const InputButton = styled.button`
  padding: 7px 10px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 14px;
  margin-left: 100px;
  transition: all 0.3s ease;
  cursor: pointer;
`;

export const Widget = styled.div`
  margin: 20px 0 0 0;
  margin-top: 10px;
  width: 300px;
  font-size: 16px;
  transition: all 0.3s ease;
  :hover {
    box-shadow: 0 0 3px 1px hsla(0, 0%, 55%, 0.3);
  }
`;

export const WidgetLink = styled.a`
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    text-decoration: underline;
  }
`;

export const WidgetImage = styled(Image)`
  display: block;
  height: 200px;
  background: no-repeat center;
  background-size: contain;
  margin: 10px 0;
`;

export const WidgetContent = styled.div`
  display: flex;
  padding: 21px 13px 16px 13px;
  text-align: center;
  flex-direction: column;
`;

export const WidgetRating = styled.div`
  padding: 10px 0;
  color: #a0a0a0;
  width: 100%;
  border-bottom: 1px solid #bababa;
  border-top: 1px solid #bababa;
  display: flex;
  justify-content: center;
`;

export const WidgetRatingTitle = styled.span`
  padding: 1px 0 0 0;
`;

export const WidgetHeadlineText = styled.span`
  font-size: 21px;
  font-weight: 700;
  color: #000000;
`;
export const WidgetTitleText = styled.span`
  font-weight: 400;
  color: #c0382f;
  margin: 20px 0 0 0;
`;

export const WidgetDescriptionText = styled.span`
  line-height: 24px;
  font-weight: 400;
  margin: 20px 0 0 0;
  color: #6f6f6f;
`;

export const WidgetButtons = styled.div``;

export const WidgetButton = styled.a`
  display: block;
  padding: 12px 0;
  margin: 10px 8px;
  text-align: center;
  width: 100%;
  background-color: #c8433b;
  color: #ffffff;
  transition: background-color 0.3s ease;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  font-weight: 600;
  text-transform: uppercase;
  :hover {
    background-color: #aa3730;
  }
`;

export const WidgetRatingStar = styled.span`
  margin: 0 0 0 10px;
  font-size: 0;
`;

export const WidgetStar = styled.span<WidgetStartProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.half ? 11.2 : 20)}px;
  height: 20px;
  color: red;
  margin: 0 0 0 5px;
  &:first-child {
    margin: 0;
  }
  ::before,
  ::after {
    content: '';
    width: 100%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAxNCIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE0Ij48cGF0aCBkPSJNMTUgNS4xSDkuM0w3LjUgMCA1LjcgNS4xSDBsNC40IDMuNkwyLjMgMTRsNS4yLTMuMiA1LjIgMy4yLTIuMS01LjN6Ii8+PC9zdmc+');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    opacity: 0.32;
  }
  ::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAxNCIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE0Ij48cGF0aCBkPSJNMTUgNS4xSDkuM0w3LjUgMCA1LjcgNS4xSDBsNC40IDMuNkwyLjMgMTRsNS4yLTMuMiA1LjIgMy4yLTIuMS01LjN6Ii8+PC9zdmc+');
    background-repeat: no-repeat;
  }
`;

// .widget__rating-stars {
//   margin: 0 0 0 10px;
//   font-size: 0;
// }

// .widget__rating-star {
//   display: inline-block;
//   position: relative;
//   width: 20px;
//   height: 20px;
//   color: red;
//

// }

// .widget__rating-star:first-child {
//   margin: 0;
// }

// .widget__rating-star.-half::after {
//   width: 56%;
// }

// .widget__rating-star::before,
// .widget__rating-star::after {
//   content: "";
//   width: 100%;
//   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAxNCIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE0Ij48cGF0aCBkPSJNMTUgNS4xSDkuM0w3LjUgMCA1LjcgNS4xSDBsNC40IDMuNkwyLjMgMTRsNS4yLTMuMiA1LjIgMy4yLTIuMS01LjN6Ii8+PC9zdmc+");
//   background-repeat: no-repeat;
//   background-size: cover;
//   height: 100%;
//   position: absolute;
//   opacity: .32;
// }

// .widget__rating-star::before {
//   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAxNCIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE0Ij48cGF0aCBkPSJNMTUgNS4xSDkuM0w3LjUgMCA1LjcgNS4xSDBsNC40IDMuNkwyLjMgMTRsNS4yLTMuMiA1LjIgMy4yLTIuMS01LjN6Ii8+PC9zdmc+");
//   background-repeat: no-repeat;
// }

// .widget__headline {
//   font-size: 21px;
//   font-weight: 700;
//   color: #000000;
// }

// .widget__title {
//   font-weight: 400;
//   color: #c0382f;
//   margin: 20px 0 0 0;
// }

// .widget__description {
//   line-height: 24px;
//   font-weight: 400;
//   margin: 20px 0 0 0;
//   color: #6f6f6f;
// }

// .widget__offer-buttons {
//   margin: 16px 0 0 0;
// }

// a.widget__offer-button {
//   display: block;
//   padding: 12px 0;
//   margin: 0 0 8px 0;
//   text-align: center;
//   width: 100%;
//   background-color: #c8433b;
//   color: #ffffff;
//   transition: background-color .3s ease;
//   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .5);
//   font-weight: 600;
// }

// .widget__offer-button:hover {
//   background-color: #aa3730;
// }
