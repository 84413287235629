import React from 'react';
import { Drawer } from 'antd';

import { Footer } from './styles';

export type IModalSize = 'small' | 'large' | 'medium';

interface FormModalProps {
  onCancel(): void;
  visible?: boolean;
  title: string;
  footer?: React.ReactNode;
  size?: IModalSize;
}

const FormModal: React.FC<FormModalProps> = ({
  title,
  visible,
  onCancel,
  footer,
  size = 'small',
  children,
}) => {
  return (
    <Drawer
      title={title}
      visible={visible}
      closable
      onClose={onCancel}
      destroyOnClose
      headerStyle={{
        background: '#f5f5f5',
        borderBottomColor: '#d9d9d9',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      }}
      footerStyle={{
        background: '#f5f5f5',
        borderTopColor: '#d9d9d9',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
      }}
      footer={<Footer>{footer}</Footer>}
      width={size === 'large' ? 750 : size === 'medium' ? 610 : 540}
    >
      {children}
    </Drawer>
  );
};

export default FormModal;
