import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #ffffff;
    color: #595959;
    text-align: left;
    margin: 0 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
  }

  body, html {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  body, input, button {
    font-size: 15px;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  fieldset {
    border: 0;
    margin: 1rem 0;
    padding: 20px 20px 0 0;
    border: 1px solid #e8e8e8;
    min-width: auto;
  }

  legend {
    border: 0;
    padding: 0 1rem;
    white-space: normal;
    font-size: 14px;
    width: auto;
    margin: 0 1rem;
    color: #6d6d6d;
  }

  table, label, p, input {
    color: #595959;
    text-align: left;
    font-family:  "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                  "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: 400;
  }
`;
