import React, { useCallback, useEffect, useRef } from 'react';

import { Input } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

interface SearchProps {
  value?: string;
  width?: number;
  style?: React.CSSProperties;
  placeholder?: string;
  onChange?(value: string): void;
}

const Search: React.FC<SearchProps> = ({
  value = '',
  placeholder = 'Search...',
  width = 190,
  style,
  onChange,
}: SearchProps) => {
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleOnChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange],
  );

  return (
    <Input
      placeholder={placeholder}
      value={value}
      ref={inputRef}
      onChange={handleOnChange}
      style={{
        width,
        borderRadius: 4,
        ...style,
      }}
      suffix={<SearchOutlined style={{ color: '#8c8c8c' }} />}
    />
  );
};

export default Search;
