/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { BaseSyntheticEvent, MouseEventHandler, useCallback, useMemo, useState } from 'react';

import { Image, Button, List } from 'antd';
import { CloseCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';

import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import MerchantForm from '../../components/Forms/MerchantForm';
import {
  IAffiliateNetworkDTO,
  IMerchantDTO,
  IMerchantPerformanceDTO,
  IMerchantImpressionsPerPostDTO,
} from '../../dtos/MerchantDTO';
import { IProductImageDTO } from '../../dtos/ProductDTO';
import { dateFormat } from '../../utils/constants';
import ListLayout from '../../components/ListLayout';
import AffiliateNetworkField from '../../components/Fields/AffiliateNetworkField';

import { StatusTag, ImpressionFooter } from './styles';
import ConsolidateModal from '../../components/ConsolidateModal';
import CCDrawer from '../../components/CCDrawer';
import Query from '../../graphql/Query';

interface QueryEventByPostsType {
  object: IMerchantImpressionsPerPostDTO[];
}

const MerchantsPage: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const affiliateNetworkId = params.get('affiliateNetworkId') || '';
  const [consolidate, setConsolidate] = useState<IMerchantDTO[]>([]);
  const [selectedSOV, setSelectedSOV] = useState<IMerchantDTO>();
  const [fetchEventsByPosts, { loading: loadingEventsByPosts, data: eventsByPosts }] =
    useLazyQuery<QueryEventByPostsType>(Query.impressionsPerPostByMerchant.query);

  const handleSelectSOV = (event: BaseSyntheticEvent, merchant: IMerchantDTO) => {
    event.stopPropagation();
    setSelectedSOV(merchant);
    fetchEventsByPosts({
      variables: {
        id: merchant.id,
      },
    });
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Status',
        key: 'disabled',
        dataIndex: 'disabled',
        render: (value: boolean) =>
          value ? (
            <StatusTag color="volcano">
              <CloseCircleTwoTone title="Disabled" twoToneColor="#F55836" />
              Disabled
            </StatusTag>
          ) : (
            <StatusTag color="green">
              <CloseCircleTwoTone title="Disabled" twoToneColor="#52c41a" />
              Active
            </StatusTag>
          ),
        width: 80,
      },
      {
        title: 'Logo',
        key: 'logo',
        dataIndex: 'logo',
        render: (value?: IProductImageDTO) => <Image preview={false} src={value?.url} />,
        width: 80,
      },
      {
        title: 'Name',
        key: 'NAME',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Display Name',
        key: 'displayName',
        dataIndex: 'displayName',
      },
      {
        title: 'Affiliate Network',
        key: 'affiliateNetwork',
        dataIndex: 'affiliateNetwork',
        render: (value?: IAffiliateNetworkDTO) => value?.name,
      },
      {
        title: 'Network ID',
        key: 'externalId',
        dataIndex: 'externalId',
      },
      {
        title: 'Website',
        key: 'website',
        dataIndex: 'website',
      },
      {
        title: 'Created',
        key: 'DATE',
        dataIndex: 'createdAt',
        render: (value?: number) => value && dateFormat(value),
        sorter: true,
        width: 200,
      },
      {
        title: 'Total Offers',
        key: 'offerTotals',
        render: (value?: number) => value || 0,
        width: 70,
      },
      {
        title: 'SOV (yesterday)',
        key: 'SOV',
        dataIndex: 'performance',
        render: (
          performance: IMerchantPerformanceDTO,
          merchant: IMerchantDTO
        ) => {
          if (performance?.sov) {
            return (
              <Button
                type="primary"
                size="small"
                disabled={performance.sov === 0}
                onClick={(evt) => handleSelectSOV(evt, merchant)}
              >
                {(performance.sov * 100).toFixed(3)}%
              </Button>
            );
          } else {
            return <></>;
          }
        },
        width: 70,
      },
    ];
  }, []);

  const filters = useMemo(() => {
    return [
      {
        name: 'affiliateNetworkId',
        label: 'Affiliate Network',
        component: AffiliateNetworkField,
        defaultValue: affiliateNetworkId,
        value: { value: affiliateNetworkId },
        updateUrlParams: true,
      },
    ];
  }, [affiliateNetworkId]);

  const handleConsolidate = useCallback(async (entities: IMerchantDTO[]) => {
    setConsolidate(entities);
  }, []);

  const listLayout = useMemo(() => {
    return (
      <ListLayout
        name="Merchant"
        columns={columns}
        modalSize="medium"
        tableSize="large"
        // actions={[
        //   {
        //     title: 'Consolidate',
        //     disableWithNoSelections: true,
        //     onClick: (filter, selected, entities) => handleConsolidate(entities),
        //   },
        // ]}
        entityForm={MerchantForm}
        hideDelete
        handleIndexQuery={{
          extraVariables: {
            includeOfferTotals: true,
          },
        }}
        filters={filters}
        schema={{
          parse: (data) => {
            const { affiliateNetwork, logo, externalId, ...rest } = data;
            return {
              ...rest,
              affiliateNetworkId: affiliateNetwork ? affiliateNetwork.value : undefined,
            };
          },
        }}
      />
    );
  }, [columns, filters]);

  return (
    <>
      <CCDrawer
        onClose={() => setSelectedSOV(undefined)}
        title={`${selectedSOV?.displayName || selectedSOV?.name} - Impressions per Post`}
        visible={!!selectedSOV}
        footerMessage={
          <ImpressionFooter>
            <InfoCircleOutlined />
            <span>CC Server-Side impression. AMP numbers may be underreported.</span>
          </ImpressionFooter>
        }
      >
        <List
          dataSource={eventsByPosts?.object}
          loading={loadingEventsByPosts}
          renderItem={(item: IMerchantImpressionsPerPostDTO, idx) => (
            <List.Item key={idx}>
              <List.Item.Meta
                title={
                  <a target="_blank" href={item.permalink} rel="noreferrer">
                    {item.postId}
                  </a>
                }
                description={item.title}
              />
              <div>{item.events}</div>
            </List.Item>
          )}
        />
      </CCDrawer>
      <ConsolidateModal merchants={consolidate} onCancel={() => setConsolidate([])} />
      {listLayout}
    </>
  );
};

export default MerchantsPage;
