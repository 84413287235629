import React, { useEffect, useMemo } from 'react';

import { Form, Input } from 'antd';

import { useLazyQuery } from '@apollo/client';
import Password from 'antd/lib/input/Password';

import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import RolesField from '../../Fields/RolesField';
import { IUserDTO } from '../../../dtos/UserDTO';
import { MIN_PASSWORD_LENGTH } from '../../../utils/constants';

const UserForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const [loadUser, { data }] = useLazyQuery(Query.user.query);

  const user = useMemo(() => {
    if (!entity || !data) {
      return undefined;
    }
    return data.user as IUserDTO;
  }, [entity, data]);

  useEffect(() => {
    if (!user || !form) {
      return;
    }
    const iUser = { ...user } as any;
    iUser.role = {
      label: user.roleName,
      value: user.role,
    };
    form.setFieldsValue(iUser);
  }, [form, user]);

  useEffect(() => {
    if (entity) {
      loadUser({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadUser]);

  return (
    <Form form={form} autoComplete="off" labelAlign="right" labelCol={{ span: 5 }} {...props}>
      <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
        <Input data-lpignore="true" autoComplete="off" placeholder="e.g. John" />
      </Form.Item>

      <Form.Item name="lastName" label="Last Name">
        <Input data-lpignore="true" autoComplete="off" placeholder="e.g. Doe" />
      </Form.Item>

      <Form.Item name="phone" label="Phone">
        <Input data-lpignore="true" autoComplete="off" placeholder="e.g. +1 (917) 239 29 84" />
      </Form.Item>

      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input data-lpignore="true" autoComplete="off" placeholder="e.g. example@example.com" />
      </Form.Item>

      {/* This is a temporary client-side validation
        TODO: refactor all this create user form */}
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: !entity, min: MIN_PASSWORD_LENGTH }]}
        data-recording-sensitive
      >
        <Password data-lpignore="true" placeholder="Password" autoComplete="off" data-recording-sensitive />
      </Form.Item>

      <Form.Item name="role" label="Role" rules={[{ required: true }]}>
        <RolesField />
      </Form.Item>
    </Form>
  );
};

export default UserForm;
