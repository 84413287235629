import React, { useMemo } from 'react';
import { Layout, Spin, Statistic } from 'antd';

import { useQuery } from '@apollo/client';

import {
  Chart as ChartJS,
  ChartOptions,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import 'chartjs-adapter-date-fns';

import { Line as LineChart } from 'react-chartjs-2';

import { Container, Anchor } from './styles';

import Query from '../../graphql/Query';

import { IDashboardStatsDTO, DailyAggregateStatistics } from '../../dtos/StatsDTO';
import { IQueryStats } from '../../graphql/Interface';

// eslint-disable-next-line prettier/prettier
ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend,
  Filler,
);

const chartOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      type: 'time' as const,
      time: {
        unit: 'day',
        tooltipFormat: 'M/d h:mm aaa',
        displayFormats: {
          day: 'M/d',
        },
      },
    },
  },
};

const stackedChartOptions: ChartOptions<'line'> = {
  ...chartOptions,
  scales: {
    ...chartOptions.scales,
    y: { stacked: true },
  },
};

const addChartTitle = (options: any, title: string): ChartOptions<'line'> => {
  const plugins = {
    ...options.plugins,
    title: {
      display: true,
      align: 'start',
      text: title,
    },
  };
  return {
    ...options,
    plugins,
  };
};

const Colors = {
  purple: {
    borderColor: 'rgb(153, 0, 204)',
    backgroundColor: 'rgba(153, 0, 204, 0.5)',
  },
  orange: {
    borderColor: 'rgb(255, 102, 0)',
    backgroundColor: 'rgba(255, 102, 0, 0.5)',
  },
  lightOrange: {
    borderColor: 'rgba(255, 102, 0, 0.5)',
    backgroundColor: 'rgba(255, 102, 0, 0.25)',
  },
  green: {
    borderColor: 'rgb(51, 204, 51)',
    backgroundColor: 'rgba(51, 204, 51, 0.5)',
  },
  blue: {
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  red: {
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  },
  gray: {
    borderColor: 'rgb(179, 179, 179)',
    backgroundColor: 'rgba(179, 179, 179, 0.5)',
  },
};

type SectionProps = {
  id: string;
  title: string;
};

const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({ id, title, children }) => {
  return (
    <>
      {title?.length > 0 && (
        <span id={id} style={{ padding: '2rem 0 0 0', visibility: 'hidden' }}>
          {title}
        </span>
      )}
      <Container style={{ padding: '0 0 4rem 0' }} data-recording-gdpr-safe>
        {children}
      </Container>
    </>
  );
};

const TopRowTotals: React.FC<{ stats: IDashboardStatsDTO }> = ({ stats }) => (
  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
    <Statistic title="Total Products" value={stats.totals.products} />
    <Statistic title="Total Brands" value={stats.totals.brands} />
    <Statistic title="Total Categories" value={stats.totals.categories} />
    <Statistic title="Total Merchants" value={stats.totals.merchants} />
  </div>
);

const ProductOffersStackedChart: React.FC<{
  title: string;
  data: DailyAggregateStatistics[];
}> = ({ title, data }) => {
  const options = addChartTitle(stackedChartOptions, title);

  const chartData = {
    labels: data.map((r) => r.createdAt),
    datasets: [
      {
        label: 'No Offers',
        data: data.map((d) => d.productsWithStaleOffers),
        fill: 'origin',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.red,
      },
      {
        label: 'Single Offer',
        data: data.map((d) => d.productsWithSingleOfferV2),
        fill: '-1',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.orange,
      },
      {
        label: 'Multiple Offers',
        data: data.map((d) => d.productsWithMultipleOffers),
        fill: '-1',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.blue,
      },
    ],
  };
  return <LineChart data={chartData} options={options} height={90} data-recording-gdpr-safe />;
};

const AverageActiveOffersChart: React.FC<{ title: string; data: DailyAggregateStatistics[] }> = ({ title, data }) => {
  const options = addChartTitle(chartOptions, title);
  const chartData = {
    labels: data.map((r) => r.createdAt),
    datasets: [
      {
        label: 'Average Active Offers Per Product',
        data: data.map((d) => d.averageActiveOffers),
        fill: true,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };
  return <LineChart data={chartData} options={options} height={90} data-recording-gdpr-safe />;
};

const ProductIdChart: React.FC<{
  title: string;
  data: DailyAggregateStatistics[];
}> = ({ title, data }) => {

  const options = addChartTitle(stackedChartOptions, title);

  const chartData = {
    labels: data.map((r) => r.createdAt),
    datasets: [
      {
        label: 'Products with no ASIN and no UPC',
        data: data.map((d) => d.productsWithNoAsinAndNoUpc),
        fill: 'origin',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.red,
      },
      {
        label: 'Products with UPC but no ASIN',
        data: data.map((d) => d.productsWithUpcAndNoAsin),
        fill: '-1',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.purple,
      },
      {
        label: 'Products with ASIN but no UPC',
        data: data.map((d) => d.productsWithAsinAndNoUpc),
        fill: '-1',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.orange,
      },
      {
        label: 'Products with both ASIN and UPC',
        data: data.map((d) => d.productsWithAsinAndUpc),
        fill: '-1',
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.blue,
      },
    ],
  };

  return <LineChart data={chartData} options={options} height={90} data-recording-gdpr-safe />;
};

const PercentageOfLinksWithNoProductChart: React.FC<{
  title: string;
  data: DailyAggregateStatistics[];
}> = ({ title, data }) => {
  const options = addChartTitle(chartOptions, title);
  const chartData = {
    labels: data.map((r) => r.createdAt),
    datasets: [
      {
        label: '% Links with no Products',
        data: data.map((f) => {
          const clickedLinks = f.clickedLinks ?? 0;
          const linksWithNoProducts = f.linksWithNoProducts ?? 0;
          // eslint-disable-next-line prettier/prettier
          return clickedLinks > 0
            ? ((100 * linksWithNoProducts) / clickedLinks).toFixed(1)
            : null;
        }),
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.red,
      },
      {
        label: '% Clicks with no Products (7 day rolling avg)',
        data: data.map((f) => {
          const totalClicks = f.totalClicks ?? 0;
          const clicksWithNoProducts = totalClicks - (f.clicksWithProducts ?? 0);
          // eslint-disable-next-line prettier/prettier
          return totalClicks > 0
            ? ((100 * clicksWithNoProducts) / totalClicks).toFixed(1)
            : null;
        }),
        pointRadius: 4,
        pointHoverRadius: 6,
        ...Colors.blue,
      },
    ],
  };

  return <LineChart data={chartData} options={options} height={90} data-recording-gdpr-safe />;
};

const secondsToDays = (sec: number) => parseFloat((sec / 60 / 60 / 24.0).toFixed(2));

const ProductLinkAgeChart: React.FC<{
  title: string;
  data: DailyAggregateStatistics[];
}> = ({ title, data }) => {
  const baseOptions = addChartTitle(chartOptions, title);

  const plugins = {
    ...baseOptions.plugins,
    tooltip: {
      callbacks: {
        label: (context: any) => {
          return `${context.dataset.label}: ${context.formattedValue || ''} days`;
        },
      },
    },
  };

  const options = {
    ...addChartTitle(chartOptions, title),
    plugins,
  };

  const chartData = {
    labels: data.map((r) => r.createdAt),
    datasets: [
      {
        label: 'Overall Product Link Age',
        data: data.map((f) => f.averageLinkAgeRolling1DayOverall && secondsToDays(f.averageLinkAgeRolling1DayOverall)),
        pointRadius: 4,
        pointHoverRadius: 6,
        yAxisID: 'y',
        ...Colors.green,
      },
      {
        label: 'Non-manual Product Link Age',
        data: data.map((f) =>
          f.averageLinkAgeRolling1DayNonmanual && secondsToDays(f.averageLinkAgeRolling1DayNonmanual)
        ),
        pointRadius: 4,
        pointHoverRadius: 6,
        yAxisID: 'y',
        ...Colors.blue,
      },
    ],
  };

  return <LineChart data={chartData} options={options} height={90} data-recording-gdpr-safe />;
};

const DashboardPage: React.FC = () => {
  const { data, loading } = useQuery<IQueryStats>(Query.stats.query);
  const stats = data?.stats;

  const dailyAggregateStatistics = useMemo(
    () => [...(data?.stats?.dailyAggregateStatistics || [])].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
    [data],
  );

  return (
    <Layout>
      {loading && <Spin />}
      {stats != null && (
        <>
          <Layout.Content>
            <Container style={{ padding: '1rem' }}>
              <TopRowTotals stats={stats} />
            </Container>
            <Anchor style={{ padding: '1rem' }}>
              <Anchor.Link title="Links with no Product" href="#percentage-of-links-with-no-product" />
              <Anchor.Link title="Offer Trends" href="#product-offer-trends-stacked" />
              <Anchor.Link title="Product Link Age" href="#product-link-age" />
              <Anchor.Link title="UPC/ASIN Trends" href="#product-id-trends" />
              <Anchor.Link title="Average Active Offers" href="#average-active-offers" />
            </Anchor>
            {!!stats.dailyAggregateStatistics && (
              <>
                <Section id="percentage-of-links-with-no-product" title="Percentage of Links with no Product">
                  <PercentageOfLinksWithNoProductChart
                    title="Percentage of Links with no Product"
                    data={dailyAggregateStatistics}
                  />
                </Section>
                <Section id="product-offer-trends-stacked" title="Offer Trends">
                  <ProductOffersStackedChart title="Offer Trends" data={dailyAggregateStatistics} />
                </Section>
                <Section id="product-link-age" title="Offer Trends">
                  <ProductLinkAgeChart
                    title="Product Link Age (rolling 1 day average)"
                    data={dailyAggregateStatistics}
                  />
                </Section>
                <Section id="product-id-trends" title="UPC/ASIN Trends">
                  <ProductIdChart title="UPC/ASIN Trends" data={dailyAggregateStatistics} />
                </Section>
                <Section id="average-active-offers" title="Average Active Offers per Product">
                  <AverageActiveOffersChart title="Average Active Offers per Product" data={dailyAggregateStatistics} />
                </Section>
              </>
            )}
          </Layout.Content>
        </>
      )}
    </Layout>
  );
};

export default DashboardPage;
