import styled from 'styled-components';
import { Divider } from 'antd';

interface FooterFormProps {
  debug: boolean;
}

export const Container = styled.div`
  width: 570px;
  padding: 12px 0;
`;

export const FooterForm = styled.div<FooterFormProps>`
  display: ${(props) => (props.debug ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

export const TableFormTitle = styled(Divider)`
  span {
    opacity: 0.8;
    font-size: 12px;
    font-weight: 400;
    svg {
      font-size: 12px;
      margin-left: 6px;
    }
  }
`;
