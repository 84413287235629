import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Table, Pagination } from 'antd';

import { useLazyQuery } from '@apollo/client';
import { ColumnType } from 'antd/lib/table';
import CCDrawer from '../../CCDrawer';
import {
  IInventoryFilters,
  IPageInfo,
  IInventoryTransactionData,
  IInventoryTransactionVars,
} from '../../../graphql/Interface';
import { ITransactionDTO } from '../../../dtos/ArticleInventoryDTO';
import Query from '../../../graphql/Query';
import { NumberColumn, PaginationContent } from './styles';
import { SmallText } from '../../../pages/InventoryPage/styles';
import { currencyFormat, dateFormat } from '../../../utils/constants';

interface TransactionsModalProps {
  totalCount: number;
  visible?: boolean;
  onCancel(): void;
  filters: IInventoryFilters;
  groupingName?: string;
  maxLocalPerPage: number;
}

const TransactionsModal: React.FC<TransactionsModalProps> = ({
  onCancel,
  totalCount,
  filters,
  visible,
  groupingName,
  maxLocalPerPage,
}) => {
  const [nodes, setNodes] = useState<ITransactionDTO[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState<'ASC' | 'DESC'>('DESC');
  const [order, setOrder] = useState('DATE');
  const [loadTransactions, { data, loading }] = useLazyQuery<
    IInventoryTransactionData,
    IInventoryTransactionVars
  >(Query.inventoryTransactions.query, {
    fetchPolicy: 'no-cache',
  });

  const columns = useMemo(
    () =>
      [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'DATE',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.date - b.date,
                  multiple: 1,
                },
          render: (date: number) => <SmallText type="secondary">{dateFormat(date, 'yyyy-MM-dd')}</SmallText>,
        },
        {
          title: 'Click ID',
          dataIndex: 'clickId',
          key: 'CLICK_ID',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => (a.clickId || '').localeCompare(b.clickId || ''),
                  multiple: 1,
                },
          render: (clickId: number) => <SmallText type="secondary">{clickId}</SmallText>,
        },
        {
          title: 'Merchant',
          dataIndex: 'merchant',
          key: 'MERCHANT',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => (a.merchant || '').localeCompare(b.merchant || ''),
                  multiple: 1,
                },
          render: (merchant: number) => <SmallText type="secondary">{merchant}</SmallText>,
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'QUANTITY',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.quantity - b.quantity,
                  multiple: 1,
                },
          render: (quantity: number) => (
            <NumberColumn>
              <SmallText type="secondary">{quantity}</SmallText>
            </NumberColumn>
          ),
        },
        {
          title: 'Commissions',
          dataIndex: 'commissions',
          key: 'COMMISSIONS',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.commissions - b.commissions,
                  multiple: 1,
                },
          render: (commissions: number) => (
            <NumberColumn>
              <SmallText type="secondary">{currencyFormat(commissions)}</SmallText>
            </NumberColumn>
          ),
        },
      ] as ColumnType<ITransactionDTO>[],
    [maxLocalPerPage, totalCount],
  );

  const handleColumnChange = useCallback(
    (_, filter, { order: iOrder, columnKey }) => {
      if (totalCount > maxLocalPerPage) {
        const newDirection = iOrder === 'ascend' ? 'ASC' : 'DESC';
        const newOrder = columnKey || 'DATE';
        setDirection(newDirection);
        setOrder(newOrder);
      }
    },
    [maxLocalPerPage, totalCount],
  );

  useEffect(() => {
    if (visible) {
      loadTransactions({
        variables: {
          order,
          direction,
          offset: (page - 1) * maxLocalPerPage,
          limit: maxLocalPerPage,
          filters,
        },
      });
    }
  }, [direction, filters, loadTransactions, maxLocalPerPage, order, page, visible]);

  useEffect(() => {
    if (data) {
      setPageInfo(data.inventoryTransactions.pageInfo);
      setNodes(data.inventoryTransactions.edges.map((a) => a.node));
    }
  }, [data]);

  const handlePaginationChange = useCallback((iPage, _iPageSize) => {
    setPage(iPage);
  }, []);

  const handleCleanModal = useCallback(() => {
    setNodes([]);
    setPageInfo(undefined);
    setOrder('DATE');
    setDirection('DESC');
    setPage(1);
  }, []);

  return (
    <CCDrawer
      onOpen={handleCleanModal}
      onClose={onCancel}
      zIndex={199}
      width={900}
      title={`Transactions (by ${groupingName})`}
      visible={visible}
    >
      <>
        <Table
          columns={columns}
          size="small"
          pagination={false}
          loading={loading}
          dataSource={nodes}
          onChange={handleColumnChange}
          showSorterTooltip={false}
          style={{
            marginTop: 10,
          }}
          rowKey={() => Math.random().toString(36).substr(2, 5)}
        />
        {pageInfo && pageInfo.pageCount > 1 && (
          <PaginationContent>
            <Pagination
              current={page}
              onChange={handlePaginationChange}
              pageSize={100}
              showSizeChanger={false}
              total={pageInfo.total}
            />
          </PaginationContent>
        )}
      </>
    </CCDrawer>
  );
};

export default TransactionsModal;
