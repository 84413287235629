import styled from 'styled-components';

interface FilterContainerProps {
  visible: boolean;
}

interface ContentProps {
  size?: string;
}

export const Container = styled.h3`
  margin: 0 auto;
  .hidden-checkboxes {
    .ant-checkbox-wrapper {
      display: none;
    }
  }
  .row-summary {
    background: rgba(0, 0, 0, 0.03);
    color: rgba(0, 0, 0, 0.3);
  }
`;

export const Header = styled.div`
  margin: 0 81px 20px 81px;
  padding: 23px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .controls button {
    margin: 0 0 0 8px;
  }
`;

export const PaginationContent = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToggleFilters = styled.div`
  font-size: 12px;
  margin: 0 0 0 15px;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const FiltersContainer = styled.div<FilterContainerProps>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  > div {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Content = styled.div<ContentProps>`
  margin: ${(props) => (props.size === 'large' ? '0 0' : '0 81px')};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 0;
  > button {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
