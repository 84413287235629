/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { pluralize } from 'inflected';
import Fragment from '../graphql/Fragment';

interface ICRUDMutation {
  [key: string]: {
    mutation: DocumentNode;
  };
}

export const createCUDMutations = (modelName: string): ICRUDMutation => {
  const createMutation = `create${modelName}`;
  const updateMutation = `update${modelName}`;
  const deleteMutation = `delete${pluralize(modelName)}`;
  const inputType = `Input${modelName}`;
  const fragmentName = `${modelName}Fragment`;
  // @ts-ignore
  const fragment = Fragment[fragmentName];

  return {
    [createMutation]: {
      mutation: gql`
        mutation                  ($data: ${inputType}!) {
          ${createMutation}(data: $data) {
          id
        }
        }
      `,
    },
    [updateMutation]: {
      mutation: gql`
        mutation                  ($id: ID!, $data: ${inputType}!) {
          object:${updateMutation}(id: $id, data: $data) {
          ...${fragmentName}
        }
        }
      ${fragment}`,
    },
    [deleteMutation]: {
      mutation: gql`
        mutation                  ($ids: [ID!]!) {
          ${deleteMutation}(ids: $ids)
        }
      `,
    },
  };
};
