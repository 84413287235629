import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Image, Modal } from 'antd';
import {
  CloudDownloadOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { DocumentNode } from 'graphql';

import { useLazyQuery, useMutation } from '@apollo/client';
import config from '../../../config';
import loadingGif from '../../../assets/uploading.gif';

import Mutation from '../../../graphql/Mutation';
import StorageService from '../../../services/StorageService';
import Query from '../../../graphql/Query';

import { AlertContent, Container, ExportContainer, ExportContent } from './styles';
import { IExportData } from '../../../graphql/Interface';

interface ExportModalProps {
  visible?: boolean;
  variables?: any;
  onCancel(): void;
  fileName: string;
  title?: string;
  onPrepare?: DocumentNode;
  onCheckStatus?: DocumentNode;
}

const ExportModal: React.FC<ExportModalProps> = ({
  visible,
  onCancel,
  title = 'Export Data',
  variables,
  fileName,
  onPrepare = Mutation.export.mutation,
  onCheckStatus = Query.export.query,
}) => {
  const [report, setReport] = useState<IExportData>();

  const [checkReport] = useLazyQuery(onCheckStatus, {
    onCompleted: (result) => {
      const key = Object.keys(result)[0];
      const checkedReport = result[key] as IExportData;
      setReport(checkedReport);
      if (checkedReport.status !== 'FINISHED') {
        setTimeout(() => {
          checkReport({
            variables: {
              token: checkedReport.token,
            },
          });
        }, 3000);
      }
    },
  });

  const [onPrepareExport] = useMutation(onPrepare, {
    onCompleted: (data) => {
      const key = Object.keys(data)[0];
      const checkedReport = data[key] as IExportData;
      setReport(checkedReport);
      checkReport({
        variables: {
          token: checkedReport.token,
        },
      });
    },
  });

  const handleDownload = useCallback(async () => {
    if (report) {
      const token = StorageService.getItem('token');
      await fetch(report.reportUrl, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((href) => {
          Object.assign(document.createElement('a'), {
            href,
            download: fileName,
          }).click();
          onCancel();
        });
    }
  }, [fileName, onCancel, report]);

  const handlePrepareExport = useCallback(() => {
    onPrepareExport({
      variables,
    });
  }, [variables, onPrepareExport]);

  const alertPanel = useMemo(() => {
    switch (report?.status || '') {
      case 'IN_PROGRESS':
        return {
          icon: <Image preview={false} width={80} height={80} src={loadingGif} />,
          content: (
            <AlertContent
              message="Exporting data"
              description="Please wait till export finished..."
              type="info"
            />
          ),
        };
      case 'FORBIDDEN':
        return {
          icon: <WarningOutlined style={{ fontSize: 37 }} />,
          content: (
            <AlertContent
              message="Exporting data"
              description="We are currently processing another report, please try to export data in 1min..."
              type="info"
            />
          ),
        };
      case 'FINISHED':
        return {
          icon: <CheckCircleOutlined style={{ fontSize: 37 }} />,
          content: (
            <AlertContent
              message="File ready for download"
              description={
                <Button type="default" onClick={handleDownload} icon={<DownloadOutlined />}>
                  Click here to download
                </Button>
              }
              type="info"
            />
          ),
        };
      case 'IDLE':
        return {
          icon: <Image preview={false} width={80} height={80} src={loadingGif} />,
          content: (
            <AlertContent
              message="Preparing file"
              description="Please Wait, server is preparing file for download..."
              type="info"
            />
          ),
        };
      default:
        return {
          icon: <CloudDownloadOutlined style={{ fontSize: 37 }} />,
          content: (
            <AlertContent
              message={<Button onClick={handlePrepareExport}>Start the export process</Button>}
              description="Please click above to proceed with the export process"
              type="info"
            />
          ),
        };
    }
  }, [handleDownload, handlePrepareExport, report?.status]);

  useEffect(() => {
    if (visible) {
      setReport(undefined);
    }
  }, [visible]);

  return (
    <Modal
      onCancel={onCancel}
      cancelText="Close"
      okButtonProps={{
        hidden: true,
      }}
      destroyOnClose
      title={title}
      visible={visible}
    >
      <Container>
        <ExportContainer>{alertPanel.icon}</ExportContainer>
        <ExportContent>{alertPanel.content}</ExportContent>
      </Container>
    </Modal>
  );
};

export default ExportModal;
