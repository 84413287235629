import React, { useEffect, useMemo } from 'react';

import { Button, Form, Input, Spin } from 'antd';

import { useLazyQuery } from '@apollo/client';

import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import { ICampaignDTO } from '../../../dtos/CampaignDTO';
import CampaignScopeForm from '../CampaignScopeForm';

import DatePicker from '../../DatePicker';

import MerchantField from '../../Fields/MerchantField';
import { IPlacementDTO } from '../../../dtos/PlacementDTO';

const { RangePicker } = DatePicker;

const CampaignForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const [loadCampaign, { data, loading }] = useLazyQuery(Query.campaignRule.query);

  const campaign = useMemo(() => {
    if (!entity || !data) {
      return {
        scopes: [{}],
      };
    }
    const { campaignRule } = data;
    const { scopes = [] } = campaignRule;
    const object = {
      ...campaignRule,
      scopes: scopes.map((s: any) => ({
        ...s,
        brands: s.brands.map((b: any) => ({
          label: b.name,
          value: b.id,
        })),
        categories: s.categories.map((c: any) => ({
          label: c.name,
          value: c.id,
        })),
        products: s.products.map((c: any) => ({
          label: c.name,
          value: c.id,
        })),
        articleTypes: s.articleTypes.map((ac: string) => ({
          label: ac,
          value: ac,
        })),
        placements: s.placements.map((pl: IPlacementDTO) => ({
          label: pl.name,
          value: pl.placementId,
        })),
        customTargeting: s.customTargeting?.map((ct: any) => ({
          ...ct,
          name: ct.field,
        })),
      })),
      merchants: campaignRule.merchants.map((m: any) => ({
        label: m.name,
        value: m.id,
      })),
      rangeDate: [new Date(campaignRule.startDate), new Date(campaignRule.endDate)],
    } as ICampaignDTO;
    return object;
  }, [entity, data]);

  useEffect(() => {
    if (!campaign || !form) {
      return;
    }
    form.setFieldsValue(campaign);
  }, [form, campaign]);

  useEffect(() => {
    if (entity) {
      loadCampaign({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadCampaign]);

  return (
    <Form className="App-Campaign" form={form} labelAlign="right" labelCol={{ span: 5 }} {...props}>
      {loading ? (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin />
        </span>
      ) : (
        <>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Campaign name" />
          </Form.Item>

          <Form.Item
            name="rangeDate"
            label="Range Date"
            rules={[{ required: true, message: 'Please enter the range date.' }]}
          >
            <RangePicker
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Form.Item name="merchants" label="Merchants" rules={[{ required: true }]}>
            <MerchantField multiple />
          </Form.Item>

          <Form.List name="scopes">
            {(fields, { add, remove }) => (
              <>
                {fields.map((formListFieldData, idx) => (
                  <CampaignScopeForm
                    fieldKey={formListFieldData.fieldKey}
                    name={formListFieldData.name}
                    index={idx}
                    onRemove={() => remove(formListFieldData.name)}
                    key={`scope_${idx}`}
                  />
                ))}
                <Form.Item>
                  <Button type="ghost" style={{ float: 'right', marginTop: 10 }} onClick={() => add()}>
                    Add Rule
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      )}
    </Form>
  );
};

export default CampaignForm;
