import React, { useEffect, useMemo } from 'react';

import { Form, Input } from 'antd';

import { useLazyQuery } from '@apollo/client';

import ImageUploadField from '../../Fields/ImageUploadField';

import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import { IAffiliateNetworkDTO } from '../../../dtos/MerchantDTO';
import { IMAGE_TYPES } from '../../../utils/constants';

const AffiliateNetworkForm: React.FC<IEntityForm> = ({ form, entity, ...props }) => {
  const [loadAffiliateNetwork, { data }] = useLazyQuery(Query.affiliateNetwork.query);

  const affiliateNetwork = useMemo(() => {
    if (!entity || !data) {
      return undefined;
    }
    return data.affiliateNetwork as IAffiliateNetworkDTO;
  }, [entity, data]);

  useEffect(() => {
    if (!affiliateNetwork || !form) {
      return;
    }
    form.setFieldsValue(affiliateNetwork);
  }, [form, affiliateNetwork]);

  useEffect(() => {
    if (entity) {
      loadAffiliateNetwork({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadAffiliateNetwork]);

  return (
    <Form form={form} labelAlign="right" labelCol={{ span: 6 }} {...props}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="e.g. Apple Network" autoComplete="off" />
      </Form.Item>

      <Form.Item name="commissionRate" label="Commission Rate">
        <Input placeholder="e.g. 5.5%" />
      </Form.Item>

      <Form.Item name="logo" label="Logo">
        <ImageUploadField accept={IMAGE_TYPES} max={5} imageUrl={affiliateNetwork?.logo?.url} />
      </Form.Item>
    </Form>
  );
};

export default AffiliateNetworkForm;
