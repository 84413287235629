import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Upload, message, Spin, Button } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { Container, Content, ContentText, ContentTextBold, ImportButton } from './styles';
import { ROUTES } from '../../utils/constants';
import Mutation from '../../graphql/Mutation';
import Query from '../../graphql/Query';
import { IImportDTO, IImportItemDTO } from '../../graphql/Interface';
import { sleep } from '../../services/utils';

const { Dragger } = Upload;

const ImportPage: React.FC = () => {
  const history = useHistory();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState<IImportItemDTO>();
  const [file, setFile] = useState<File>();

  const [upload, { data: dataUploaded }] = useMutation(Mutation.uploadFile.mutation);
  const [importResult, { data: dataImported }] = useMutation(Mutation.import.mutation);
  const [loadImport, { data, refetch }] = useLazyQuery<IImportDTO>(Query.import.query);

  const handleCheckImport = useCallback(async () => {
    await sleep(1000);
    if (refetch) {
      refetch().then((r) => {
        const { import: newImport } = r.data;
        if (newImport.status !== 'FINISHED') {
          handleCheckImport();
          return;
        }
        setUploaded(newImport);
      });
    }
  }, [refetch]);

  useEffect(() => {
    if (data && !loading && !uploaded) {
      setLoading(true);
      handleCheckImport();
    }
  }, [data, handleCheckImport, loading, uploaded]);

  useEffect(() => {
    if (dataImported && !loading && !uploaded) {
      const { import: id } = dataImported;
      loadImport({
        variables: {
          id,
        },
      });
    }
  }, [dataImported, loadImport, loading, uploaded]);

  useEffect(() => {
    if (dataUploaded && !loading && !uploaded) {
      const { id: fileId } = dataUploaded.uploadFile;
      importResult({
        variables: {
          fileId,
        },
      });
    }
  }, [dataUploaded, importResult, loading, uploaded]);

  const handleFileSelected = useCallback(
    (iFile: File) => {
      if (!uploading) {
        setUploading(true);
        const variables = {
          variables: {
            field: 'file',
            file: iFile,
          },
        };
        upload(variables);
      }
    },
    [upload, uploading],
  );

  useEffect(() => {
    if (file) {
      handleFileSelected(file);
    }
  }, [file, handleFileSelected]);

  const beforeUpload = useCallback((iFile: File) => {
    if (iFile.type !== 'text/csv') {
      message.error(`Must be a .CSV file`);
      return false;
    }
    setFile(iFile);
    return false;
  }, []);

  const redirect = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const uploadContent = useMemo(() => {
    if (uploaded) {
      return (
        <Content>
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined
              color="gray"
              style={{
                fontSize: 76,
              }}
            />
          </p>
          <ContentText>
            You’ve imported <ContentTextBold>{uploaded.total.toLocaleString()}</ContentTextBold> products
          </ContentText>
          <br />
          <ContentText>
            Updated <ContentTextBold>{uploaded.updated.toLocaleString()}</ContentTextBold> products
          </ContentText>
          <br />
          <ContentText>
            Created <ContentTextBold>{uploaded.created.toLocaleString()}</ContentTextBold> products
          </ContentText>
          <div style={{ marginTop: 20 }}>
            <Button type="primary" onClick={() => redirect(ROUTES.products)}>
              Go to Products
            </Button>
          </div>
          <div style={{ marginTop: 25 }}>
            <ImportButton type="link" href={ROUTES.import}>
              Import another file
            </ImportButton>
          </div>
        </Content>
      );
    }
    if (uploading) {
      return (
        <>
          <Spin size="large" />
          {dataImported && (
            <ContentText
              style={{
                marginTop: 20,
              }}
            >
              Import is being started
            </ContentText>
          )}
        </>
      );
    }
    return (
      <>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined
            style={{
              fontSize: 76,
            }}
          />
        </p>
        <p className="ant-upload-text">
          <Text>
            Drag and Drop your file here or <Text style={{ color: '#44a6ff' }}>browse</Text>
          </Text>
        </p>
        <p className="ant-upload-hint">Uploading CSV files formats are supported</p>
      </>
    );
  }, [dataImported, redirect, uploaded, uploading]);

  return (
    <div>
      <Dragger
        name="file"
        multiple={false}
        disabled={!!uploaded || uploading}
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        <Container>{uploadContent}</Container>
      </Dragger>
    </div>
  );
};

export default ImportPage;
