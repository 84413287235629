import React, { useCallback, useMemo } from 'react';
import CategoryForm from '../../components/Forms/CategoryForm';
import ListLayout from '../../components/ListLayout';

const CategoriesPage: React.FC = () => {
  const filters = useMemo(() => {
    return [
      {
        name: 'type',
        value: 'PRODUCT',
      },
    ];
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
    ];
  }, []);

  const handleLoadVariables = useCallback((values: any, selected = undefined) => {
    return {
      id: selected?.id,
      data: {
        ...values,
        type: 'PRODUCT',
      },
    };
  }, []);

  const handleIndexQuery = useMemo(
    () => ({
      name: 'categoriesCollection',
    }),
    [],
  );

  return (
    <ListLayout
      name="Category"
      columns={columns}
      filters={filters}
      onBeforeUpdate={handleLoadVariables}
      onBeforeCreate={handleLoadVariables}
      handleIndexQuery={handleIndexQuery}
      entityForm={CategoryForm}
    />
  );
};

export default CategoriesPage;
