import styled from 'styled-components';
import { Divider } from 'antd';

interface FooterFormProps {
  debug: boolean;
}

export const Container = styled.div`
  width: 570px;
  padding: 12px 0;
`;

export const FooterForm = styled.div<FooterFormProps>`
  display: ${(props) => (props.debug ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

export const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
  strong {
    font-weight: 600;
  }
  aside {
    color: red;
  }
`;

export const ProductItemContent = styled.div`
  display: flex;
  flex-direction: row;
  span {
    margin-right: 2px;
  }
  aside {
    margin-right: 2px;
    &:first-child {
      color: blue;
    }
  }
`;

export const ProductDivider = styled(Divider)`
  * {
    font-size: 11px;
    opacity: 0.8;
  }
`;
