import React from 'react';
import { Input, Card, Space } from 'antd';
import { Container } from './styles';
import { DATE_FORMAT } from '../../../utils/constants';
import { IMerchantCommissionRateDTO } from '../../../dtos/MerchantDTO';

import DatePicker from '../../DatePicker';

interface MerchantCommissionRateFormProps extends IMerchantCommissionRateDTO {
  index: number;
}

const MerchantCommissionRateForm: React.FC<MerchantCommissionRateFormProps> = ({
  index,
  actionName,
  pricingModel,
  type,
  fixedRate,
  percentageRate,
  createdAt,
}) => {

  const cardTitle = actionName ?? `Action ${index}`;
  const minRate = type === 'Fixed' ? fixedRate?.min : percentageRate?.min;
  const maxRate = type === 'Fixed' ? fixedRate?.max : percentageRate?.max;
  return (
    <Container
      index={index}
      bodyStyle={{
        width: '100%',
        paddingLeft: 0,
        paddingBottom: 0,
      }}
    >
      <Card title={cardTitle} bordered={false}>
        <Space direction="vertical">
          <Input addonBefore="Commission Rate Type" value={type} />
          <Input addonBefore="Pricing model" value={pricingModel} />
          <Input addonBefore="Min Rate" value={minRate} />
          <Input addonBefore="Max Rate" value={maxRate} />
          <DatePicker
            defaultValue={createdAt}
            format={DATE_FORMAT.SHORT_DATETIME}
            disabled
          />
        </Space>
      </Card>

    </Container>
  );
};

export default MerchantCommissionRateForm;
