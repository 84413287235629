import React from 'react';

import Header from '../Header';
import Footer from '../Footer';

import { Layout, LayoutHeader, LayoutContent, LayoutFooter } from './styles';

const SignedLayout: React.FC = ({ children }) => (
  <Layout>
    <LayoutHeader>
      <Header />
    </LayoutHeader>
    <LayoutContent>{children}</LayoutContent>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default SignedLayout;
