import React, { useCallback, useState } from 'react';

import { Form, Input, Button, Divider, Typography, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Mutation from '../../graphql/Mutation';
import { useAuth } from '../../hooks/auth';
import { ROUTES } from '../../utils/constants';

const { Text } = Typography;

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { handleError } = useAuth();

  const [sendForgotPasswordEmailMutation] = useMutation(Mutation.sendForgotPasswordEmail.mutation);
  const handleForgotPassword = useCallback(
    async ({ email }): Promise<void> => {
      try {
        setLoading(true);
        await sendForgotPasswordEmailMutation({
          variables: {
            email,
          },
        });
        notification.success({
          message: `If user exists, a password reset email will be sent to this email address.`,
        });
        history.push(ROUTES.login);
      } catch (err) {
        setLoading(false);
        handleError(err as Error, 'Resetting password error');
      } finally {
        setLoading(false);
      }
    },
    [history, sendForgotPasswordEmailMutation, handleError],
  );

  return (
    <>
      <Text type="secondary">Reset your password</Text>
      <br />
      <Text type="secondary">Enter your email below to reset your password:</Text>
      <Form
        form={form}
        style={{ marginTop: 15 }}
        labelCol={{ span: 6 }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleForgotPassword}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
        >
          <Input placeholder="name@domain.com" data-recording-sensitive />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{
              width: '100%',
            }}
          >
            Send Email
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Link style={{ marginLeft: 4 }} to={ROUTES.login} href={ROUTES.login}>
          Back to Login
        </Link>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
