import styled from 'styled-components';

interface OptionContentProps {
  large?: boolean;
}

export const OptionContent = styled.div<OptionContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  min-height: ${(props) => (props.large ? 50 : 0)}50;
`;

export const OptionSubContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const OptionName = styled.label`
  z-index: 0;
  pointer-events: none;
`;

export const OptionAux = styled.label`
  opacity: 0.7;
  z-index: 0;
  pointer-events: none;
`;
