/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import smartlookclient from 'smartlook-client';
import { IUserDTO } from '../../../dtos/UserDTO';

function initialize(_appId: string): void {
  smartlookclient.init(_appId);
  smartlookclient.record({ numbers: true, emails: true, forms: true });
}

function identify(user: IUserDTO): void {
  smartlookclient.identify(user.id, { email: user.email, name: `${user.firstName} ${user.lastName}` });
}

function trackEvent(eventName: string, data?: any): void {
  smartlookclient.track(eventName, data);
}

function handleError(error: string): void {
  smartlookclient.error(error);
}

export default { initialize, trackEvent, identify, handleError };
