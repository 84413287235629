/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';

import { Form, Button, Col, Divider, Row, Tabs, Input, Tag, Space, notification, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useApolloClient, useLazyQuery, ApolloClient } from '@apollo/client';

import Query from '../../../graphql/Query';
import {
  IOfferProductInfoDTO,
  IProductDTO,
  IRefreshedOfferDTO,
  IInputProduct,
  IInputOfferProductInfo,
  IInputMerchantProductInfo,
  ILandingPageProductInfoDTO,
} from '../../../dtos/ProductDTO';

import { RefreshLink, ProductIDLabel, StyledTab, BadgeTab } from './styles';
import Mutation from '../../../graphql/Mutation';
import { useLazyProductQuery, useRefreshOffersQuery } from '../../../graphql/Hooks';
import {
  IMAGE_TYPES,
  CategoryType,
  cleanUrlQueryParams,
} from '../../../utils/constants';

import CCDrawer from '../../CCDrawer';
import ImportProductModal from '../../Product/ImportProductModal';
import BrandsField from '../../Fields/BrandsField';
import CategoryField from '../../Fields/CategoryField';
import ImageUploadField from '../../Fields/ImageUploadField';
import OfferForm from '../../Forms/OfferForm';
import ProductMerchantForm from '../../Forms/ProductMerchantForm';
import ChangeLog from './changelog';

import LandingPageForm, {
  LandingPageProductFormData,
  DEFAULT_LANDING_PAGE_STATUS
} from '../../Forms/LandingPageForm';

interface ProductModalProps {
  onCancel(): void;
  onSuccess?(): void;
  productId?: string;
  visible?: boolean;
}

export interface IProductFormImage {
  id?: string;
  url: string | null;
}

export interface IProductFormBrand {
  value: string | null;
  label: string;
}

export interface IProductFormCategory {
  value: string | null;
  label: string;
}

export interface IFixedRate {
  max: number;
  min: number;
  currency: string;
}

export interface IPercentageRate {
  max: number;
  min: number;
}

export interface IMerchantCommissionRate {
  actionName: string | null;
  pricingModel: string;
  type: string;
  fixedRate: IFixedRate | null;
  percentageRate: IPercentageRate | null;
  createdAt: any;
}

export interface IMerchantPerformance {
  clicks: number;
  commissions: number;
  revenue: number;
  conversionRate: number;
  revenuePerClick: number;
  updatedAt: any;
}

export interface IProductFormMerchant {
  value?: string;
  label: string;
  commissionRate?: [IMerchantCommissionRate];
  performance: IMerchantPerformance;
}

export interface IProductFormMerchantInfo {
  merchant: IProductFormMerchant | null;
  sku: string | null;
}

export interface IProductFormOffer {
  id: string;
  price: number | null;
  originalPrice: number | null;
  url: string | null;
  default: boolean | null;
  created: string;
  rangeDate: (Date | null)[];
  manual: boolean | null;
  outOfStock: boolean | null;
  buttonText: string | null;
  merchant: IProductFormMerchant | null;
  createdDate: number | null;
  existing: boolean;
}

namespace IProductFormLandingPage {
  export function fromLandingPageProductInfoDto(m: ILandingPageProductInfoDTO): LandingPageProductFormData {
    return {
      price: m.price,
      url: cleanUrlQueryParams(m.url),
      status: { label: m.statusName || '', value: m.status },
      createdDate: m.createdDate,
    };
  }
}

export interface IProductFormData {
  id: string | null;
  name: string | null;
  price: number | null;
  image: IProductFormImage | File | null;
  brand: IProductFormBrand | null;
  headline: string | null;
  description: string | null;
  model: string | null;
  upc: string | null;
  gtin: string | null;
  make: string | null;
  asin: string | null;
  dtProductId: string | null;
  category: IProductFormCategory | null;
  offersInfo: IProductFormOffer[] | null;
  landingPagesInfo: LandingPageProductFormData[];
  merchantsInfo: IProductFormMerchantInfo[] | null;
  rating: number | null;
  original: IProductFormData | null;
}

namespace IProductFormOffer {
  export function fromOfferProductInfoDto(m: IOfferProductInfoDTO): IProductFormOffer {
    return {
      id: m.id,
      price: m.price || null,
      originalPrice: m.originalPrice || null,
      url: m.url || null,
      default: m.default || null,
      created: m.created,
      rangeDate: [m.startDate ? new Date(m.startDate) : null, m.endDate ? new Date(m.endDate) : null],
      manual: m.manual || null,
      outOfStock: m.outOfStock || null,
      buttonText: m.buttonText || null,
      merchant: m.merchant
        ? {
            value: m.merchant.id,
            label: m.merchant.name,
            commissionRate: m.merchant.commissionRate,
            performance: m.merchant.performance,
          }
        : null,
      createdDate: m.createdDate,
      existing: true,
    };
  }

  export function fromRefreshedOfferDto(m: IRefreshedOfferDTO): IProductFormOffer {
    return {
      id: '',
      price: m.price || null,
      originalPrice: m.originalPrice || null,
      url: m.url || null,
      default: m.default || null,
      created: '',
      rangeDate: [null, null],
      manual: null,
      outOfStock: null,
      buttonText: null,
      merchant: m.merchant
        ? {
            value: m.merchant.id,
            label: m.merchant.name,
            commissionRate: m.merchant.commissionRate,
            performance: m.merchant.performance,
          }
        : null,
      createdDate: m.createdDate,
      existing: true,
    };
  }
}

namespace IProductFormData {
  export function fromProductDTO(product: IProductDTO): IProductFormData {
    const brand = product?.brand;
    const category = product?.category;

    return {
      id: product.id,
      name: product.name || null,
      price: product.price || null,
      image: product.image
        ? {
            id: product.image.id,
            url: product.image.url || null,
          }
        : null,
      brand: brand
        ? {
            label: brand.name,
            value: brand.id ?? '-1',
          }
        : null,
      headline: product.headline || null,
      description: product.description || null,
      model: product.model || null,
      upc: product.upc || null,
      gtin: product.gtin || null,
      make: product.make || null,
      asin: product.asin || null,
      dtProductId: product.dtProductId || null,
      category: category
        ? {
            label: category.name,
            value: category.id ?? '-1',
          }
        : null,
      offersInfo: (product?.offersInfo || []).map((m) => IProductFormOffer.fromOfferProductInfoDto(m)),
      landingPagesInfo: (product?.landingPagesInfo || []).map((m) => IProductFormLandingPage.fromLandingPageProductInfoDto(m)),
      merchantsInfo: (product?.merchantsInfo || []).map((mi) => ({
        merchant: mi.merchant?.id
          ? {
              label: mi.merchant.name,
              value: mi.merchant.id,
              commissionRate: mi.merchant.commissionRate,
              performance: mi.merchant.performance,
            }
          : null,
        sku: mi.sku,
      })),
      rating: product.rating || null,
      original: null,
    };
  }
}

async function saveProduct(apolloClient: ApolloClient<any>, entityId: string | undefined, formData: IProductFormData) {
  let brandId: string | null;
  if (formData.brand?.value === '-1') {
    const res = await apolloClient.mutate({
      ...Mutation.createBrand,
      variables: {
        data: {
          name: formData.brand.label,
        },
      },
    });

    console.log('creating brand!', res);

    brandId = res.data.createBrand.id;
  } else {
    brandId = formData.brand?.value ?? null;
  }

  let categoryId: string | null;
  if (formData.category?.value === '-1') {
    const res = await apolloClient.mutate({
      ...Mutation.createCategory,
      variables: {
        data: {
          name: formData.category.label,
          type: CategoryType.PRODUCT,
        },
      },
    });

    console.log('creating category!', res);

    categoryId = res.data.createCategory.id;
  } else {
    categoryId = formData.category?.value ?? null;
  }

  let uploadedImageId: string | undefined;
  if (formData.image instanceof File) {
    const res = await apolloClient.mutate({
      ...Mutation.uploadFile,
      variables: {
        data: {
          name: formData.image.name,
          image: formData.image,
        },
      },
    });
    uploadedImageId = res.data?.uploadFile?.id;
  } else {
    uploadedImageId = formData.image?.id;
  }

  const variablesData: IInputProduct = {
    name: formData.name as string, // form validation should ensure this is not null
    headline: formData.headline,
    description: formData.description,
    model: formData.model,
    make: formData.make,
    upc: formData.upc,
    gtin: formData.gtin,
    asin: formData.asin,
    dtProductId: formData.dtProductId,
    rating: formData.rating,
    imageId: uploadedImageId || undefined,
    brandId,
    categoryId,
    offersInfo:
      formData.offersInfo
        ?.map(
          (item): IInputOfferProductInfo => ({
            merchantId: item.merchant?.value as string, // form validation should ensure this is not null
            price: item.price,
            originalPrice: item.originalPrice,
            url: item.url,
            buttonText: item.buttonText,
            manual: item.manual,
            outOfStock: item.outOfStock,
            startDate: item?.rangeDate?.[0]?.getTime() || null,
            endDate: item?.rangeDate?.[1]?.getTime() || null,
            createdDate: item.existing ? item.createdDate : Date.now(),
          }),
        )
        ?.filter((item) => item.merchantId) || [],
    landingPagesInfo:
      formData.landingPagesInfo?.map((item) => {
        return {
          price: item.price || null,
          url: cleanUrlQueryParams(item.url),
          status: item.status ? item.status.value : DEFAULT_LANDING_PAGE_STATUS,
          createdDate: item.existing ? item.createdDate : Date.now(),
        };
      }) || [],
    merchantsInfo:
      formData?.merchantsInfo
        ?.filter((item) => (item.merchant?.value?.length ?? 0) > 0)
        ?.map((item): IInputMerchantProductInfo => {
          return {
            merchantId: item.merchant?.value as string,
            sku: item.sku,
          };
        }) || [],
  };

  const variables = {
    id: entityId || undefined,
    data: variablesData,
  };

  const res = !entityId
    ? await apolloClient.mutate({ ...Mutation.createProduct, variables })
    : await apolloClient.mutate({ ...Mutation.updateProduct, variables });

  return res.data;
}

const ProductModal: React.FC<ProductModalProps> = ({ visible, productId, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const apolloClient = useApolloClient();
  const [data, setData] = useState<any>();

  const [loadProduct, { loading }] = useLazyProductQuery({
    onCompleted: (dt: any) => setData(dt),
  });
  const [refreshOffers, refreshingOffers] = useRefreshOffersQuery();
  const [discoveredUpcNotification, setDiscoveredUpcNotification] = useState<string>();
  const [activeTab, setActiveTab] = useState(1);
  const [importModalVisible, showImportModal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [saving, setSaving] = useState(false);
  const [changeLog, setChangeLog] = useState<string[]>();

  const isEdit = !!productId;

  const setFieldsValue = useCallback(
    (patch: Partial<IProductFormData>) => {
      form.setFieldsValue(patch);
      setLastUpdated(Date.now());
    },
    [form, setLastUpdated],
  );

  const resetForm = useCallback(() => {
    form.resetFields();
    // these fields have to be cleared manually. ant just doesn't want to clear field lists for some reason.
    form.setFieldsValue({
      offersInfo: [],
      landingPagesInfo: [],
      merchantsInfo: [],
      original: null,
    });
    setActiveTab(1);
  }, [form]);

  const [loadChangeLog] = useLazyQuery<{ productVersions: { data: string[] }}>(Query.productVersions.query, {
    onCompleted: (changeLogData) => {
      setChangeLog(changeLogData?.productVersions?.data);
    },
  });

  // load product data
  useEffect(() => {
    if (productId) {
      loadProduct({
        variables: {
          id: productId,
        },
      });
      loadChangeLog({variables: { productId }});
    } else {
      resetForm();
    }
  }, [productId, loadProduct, resetForm]);

  // initialize form
  useEffect(() => {
    const product = data?.product;
    const formData = product ? IProductFormData.fromProductDTO(product) : undefined;

    console.log('loaded formData', formData);
    console.log('empty formData', form.getFieldsValue(true));

    resetForm();

    if (formData) {
      setFieldsValue({
        ...formData,
        original: formData,
      });
    } else {
      setLastUpdated(Date.now());
    }
  }, [data, form, resetForm, setFieldsValue]);

  const handleRefreshOffers = useCallback(async () => {
    if (!productId) {
      return;
    }

    const formData: IProductFormData = form.getFieldsValue(true);
    const merchantsInfoOriginal = formData.merchantsInfo ?? [];
    const merchantsInfo = merchantsInfoOriginal
      .filter((m) => (m.merchant?.value?.length ?? 0) > 0)
      .map(
        (m): IInputMerchantProductInfo => ({
          merchantId: m.merchant?.value as string,
          sku: m.sku,
        }),
      );

    const res = await refreshOffers({
      productId,
      asin: formData.asin ?? undefined,
      upc: formData.upc ?? undefined,
      merchantsInfo,
    });

    const error = res?.error;
    if (error) {
      notification.error({
        message: error.message,
      });
      return;
    }

    const qdata = res?.data.productOffers;
    const updatedOffers = qdata?.offers?.map(IProductFormOffer.fromRefreshedOfferDto) ?? [];

    if (updatedOffers.length === 0) {
      notification.info({
        message: `There are no new offers for this product`,
      });
      return;
    }

    const discoveredUpc = qdata?.discoveredUpc;
    const manualOrOutOfStockOffers = formData.offersInfo?.filter((item) => item.manual || item.outOfStock) ?? [];

    setFieldsValue({
      ...formData,
      ...(qdata?.image
        ? {
            image: {
              id: qdata?.image?.id || undefined,
              url: qdata?.image?.url,
            },
          }
        : {}),
      ...((discoveredUpc?.length ?? 0) > 0 ? { upc: discoveredUpc } : {}),
      offersInfo: [...manualOrOutOfStockOffers, ...updatedOffers],
      landingPagesInfo: formData.landingPagesInfo ?? [],
    });

    if (discoveredUpc) {
      setDiscoveredUpcNotification(discoveredUpc);
    }
  }, [productId, form, refreshOffers, setFieldsValue]);

  const onSubmit = useCallback(async () => {
    const formData: IProductFormData = form.getFieldsValue(true);

    console.log('[FORM: Product.offersInfo]', productId, form.getFieldValue('offersInfo'));
    console.log('[FORM: Product]', productId, formData);

    try {
      setSaving(true);
      await saveProduct(apolloClient, productId, formData);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setSaving(false);
    }
  }, [form, productId, apolloClient, onSuccess]);

  const handleImport = (product: IProductDTO) => {
    const existing: IProductFormData = form.getFieldsValue(true);
    const updates = IProductFormData.fromProductDTO(product);

    console.log('Existing form data', existing);
    console.log('Importing form data', updates);
    if (!updates.name) {
      notification.info({
        message: 'Product not found',
      });
    }

    const updatedOffers = (updates.offersInfo ?? []).map(
      (o): IProductFormOffer => ({
        ...o,
        existing: true,
        createdDate: o.createdDate ?? Date.now(),
      }),
    );
    const manualOffers = existing.offersInfo?.filter((item) => item.manual) || [];
    const offersInfo = [...manualOffers, ...updatedOffers];

    setFieldsValue({
      name: existing.name || updates.name,
      price: existing.price || updates.price,
      image: existing.image ?? updates.image,
      brand: existing.brand || updates.brand,
      headline: existing.headline || updates.headline,
      description: existing.description || updates.description,
      model: existing.model || updates.model,
      upc: existing.upc || updates.upc,
      gtin: existing.gtin || updates.gtin,
      make: existing.make || updates.make,
      asin: existing.asin || updates.asin,
      dtProductId: existing.dtProductId || updates.dtProductId,
      category: existing.category ?? updates.category,
      offersInfo,
      landingPagesInfo: existing.landingPagesInfo ?? updates.landingPagesInfo,
      merchantsInfo: existing.merchantsInfo ?? updates.merchantsInfo,
      rating: existing.rating || updates.rating,
    });

    showImportModal(false);
  };

  const getLandingPages = () => {
    return form?.getFieldValue('landingPagesInfo') || [];
  };

  const getOffersInfo = () => {
    return form.getFieldValue('offersInfo') || [];
  };

  const handleCancel = useCallback(() => {
    // Prevents to close the modal while apollo is working
    if (loading) {
      return;
    }
    setData(undefined);
    resetForm();
    onCancel();
  }, [loading, onCancel, resetForm]);

  return (
    <CCDrawer
      visible={visible}
      title={productId ? 'Edit Product' : 'New Product'}
      onClose={handleCancel}
      actions={
        <>
          <Button onClick={() => showImportModal(true)}>Import</Button>
          <Button loading={saving} type="primary" onClick={() => form.submit()}>
            {productId ? 'Update' : 'Create'}
          </Button>
        </>
      }
    >
      <ImportProductModal
        id={data?.product?.dtProductId}
        asin={form.getFieldValue('asin')}
        upc={form.getFieldValue('upc')}
        onUpdate={handleImport}
        visible={importModalVisible}
        onCancel={() => showImportModal(false)}
      />
      <Form form={form} onFinish={onSubmit} className="thin-form" labelAlign="right" labelCol={{ span: 5 }} initialValues={{}}>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab.toString()}
          onChange={(index) => setActiveTab(parseInt(index, 10))}
          size="small"
          type="card"
          style={{
            marginTop: -18,
          }}
        >
          <StyledTab.TabPane forceRender tab="General Info" key="1">
            {loading ? (
              <Row>
                <Col span={6}>
                  <Form.Item name="image">
                    <ImageUploadField accept={IMAGE_TYPES} max={5} />
                  </Form.Item>
                </Col>
                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spin />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col span={6}>
                    <Form.Item name="image">
                      <ImageUploadField
                        accept={IMAGE_TYPES}
                        max={5}
                        key={`image-${lastUpdated}`}
                        imageUrl={form.getFieldValue('image')?.url}
                      />
                    </Form.Item>
                    {isEdit && (
                      <div style={{ marginTop: 10 }}>
                        <Button href={`/widget/index.html?productId=${productId}`} target="_blank">
                          Preview Widget
                        </Button>
                      </div>
                    )}
                  </Col>
                  <Col span={18}>
                    {productId && !!data?.product && (
                      <Form.Item name="dtProductId" label="DT Product ID">
                        <ProductIDLabel readOnly value={data.product.dtProductId} />
                      </Form.Item>
                    )}
                    <Form.Item name="brand" label="Brand">
                      <BrandsField showSearch />
                    </Form.Item>
                    <Form.Item name="model" label="Model">
                      <Input placeholder="e.g. SX-800 H" />
                    </Form.Item>
                    <Form.Item name="name" label="Display Name" rules={[{ required: true }]}>
                      <Input autoComplete="false" placeholder="e.g. Samsung SX-800 H" />
                    </Form.Item>
                    <Form.Item name="category" label="Category">
                      <CategoryField />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={18} offset={6}>
                    <Form.Item name="headline" label="Headline">
                      <Input placeholder="e.g. Best SoundBar" />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea rows={2} placeholder="Enter Product Description" />
                    </Form.Item>
                    <Form.Item name="tags" label="Tags">
                      <Input placeholder="e.g. Product of Week" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={18} offset={6}>
                    <Form.Item name="upc" label="UPC">
                      <Input placeholder="e.g. UPC-A 042100005264" />
                    </Form.Item>
                    <Form.Item name="gtin" label="GTIN">
                      <Input placeholder="e.g. 00012345600012" />
                    </Form.Item>
                    <Form.Item name="asin" label="ASIN">
                      <Input placeholder="e.g. B00005N5PF" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Form.List name="merchantsInfo">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((formListFieldData) => (
                        <Space
                          key={`merchant_${formListFieldData.name}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          align="baseline"
                        >
                          <ProductMerchantForm
                            name={formListFieldData.name}
                            fieldKey={formListFieldData.fieldKey}
                            onRemove={() => remove(formListFieldData.name)}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="ghost" style={{ float: 'right' }} onClick={() => add()}>
                          Add Merchant SKU
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
          </StyledTab.TabPane>
          <StyledTab.TabPane
            forceRender
            tab={
              <span>
                {getOffersInfo().length > 0 && <BadgeTab count={getOffersInfo().length} />}
                Offers
              </span>
            }
            key="2"
          >
            {isEdit && (
              <div style={{ height: '2.0rem', textAlign: 'right', marginBottom: 10 }}>
                {discoveredUpcNotification && <Tag color="green">Discovered UPC: {discoveredUpcNotification}</Tag>}
                <RefreshLink type="ghost" onClick={handleRefreshOffers} icon={<SyncOutlined />} loading={refreshingOffers}>
                  {refreshingOffers ? 'Refreshing...' : 'Refresh Offers'}
                </RefreshLink>
              </div>
            )}
            <Form.List name="offersInfo">
              {(fields, { add, remove }) => {
                const offersInfo = getOffersInfo();
                return (
                  <>
                    {fields.map((formListFieldData, idx) => (
                      <OfferForm
                        key={`offer_${idx}`}
                        index={idx}
                        offer={offersInfo[idx]}
                        fieldKey={formListFieldData.fieldKey}
                        name={formListFieldData.name}
                        onRemove={() => {
                          setLastUpdated(Date.now());
                          remove(formListFieldData.name);
                        }}
                      />
                    ))}
                    <Divider />
                    <Form.Item>
                      <Button
                        type="ghost"
                        style={{ float: 'right' }}
                        onClick={() => {
                          setLastUpdated(Date.now());
                          add();
                        }}
                      >
                        Add Offer
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </StyledTab.TabPane>
          <StyledTab.TabPane
            forceRender
            style={{ marginRight: 20 }}
            tab={
              <span>
                {getLandingPages().length > 0 && <BadgeTab count={getLandingPages().length} />}
                Landing Pages
              </span>
            }
            key="3"
          >
            <Form.List name="landingPagesInfo">
              {(fields, { add, remove }) => {
                const landingPagesInfo = getLandingPages();
                return (
                  <>
                    <Form.Item>
                      <Button
                        type="ghost"
                        style={{ float: 'right', marginRight: '2%' }}
                        onClick={() => {
                          setLastUpdated(Date.now());
                          add();
                        }}
                      >
                        Add Landing Page
                      </Button>
                    </Form.Item>
                    <Divider />
                    {fields.map((formListFieldData, idx) => (
                      <LandingPageForm
                        form={form}
                        key={`landing_page_${idx}`}
                        index={idx}
                        landingPage={landingPagesInfo[idx]}
                        fieldKey={formListFieldData.fieldKey}
                        name={formListFieldData.name}
                        onRemove={() => {
                          setLastUpdated(Date.now());
                          remove(formListFieldData.name);
                        }}
                      />
                    ))}
                  </>
                );
              }}
            </Form.List>
          </StyledTab.TabPane>
          <StyledTab.TabPane
            forceRender
            style={{ marginRight: 20 }}
            tab={
              <span>
                Change Log
              </span>
            }
            key="4"
          >
            {
              changeLog && (<ChangeLog changeLog={changeLog} />)
            }
          </StyledTab.TabPane>
        </Tabs>
      </Form>
    </CCDrawer>
  );
};

export default ProductModal;
