import { useMemo, useEffect, useRef } from 'react';

export interface ICustomEvent<EventData> {
  trigger(data: EventData): void;
  useSubscribe: (handler: () => void) => void;
}

export function useCustomEvent<EventData>(eventName: string): ICustomEvent<EventData> {
  const source = useRef(document.createElement('div'));
  return useMemo(
    () => ({
      trigger: (data: EventData) => {
        const event = new CustomEvent('importButton:click', data);
        source.current.dispatchEvent(event);
      },
      useSubscribe: (handler: () => void) => {
        useEffect(() => {
          // eslint-disable-next-line no-console
          console.log('adding event listener', eventName);
          source.current.addEventListener(eventName, handler);
          return () => {
            // eslint-disable-next-line no-console
            console.log('removing event listener', eventName);
            source.current.removeEventListener(eventName, handler);
          };
        });
      },
    }),
    [eventName],
  );
}
