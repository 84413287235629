import React, { useEffect, useMemo, useState } from 'react';

import { Form, Button, Col, Row, Input, InputNumber, Checkbox, Tooltip } from 'antd';

import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';

import { formatDistanceToNow } from 'date-fns';
import { round } from "lodash";

import { Container, URLContent } from './styles';
import { IProductFormOffer } from '../../Modals/ProductModal';
import MerchantField from '../../Fields/MerchantField';
import { dateFormat } from '../../../utils/constants';

import DatePicker from '../../DatePicker';

const labelCol = {
  span: 6,
};

const { RangePicker } = DatePicker;

interface OfferFormProps {
  index: number;
  name: number;
  fieldKey: number;
  offer?: IProductFormOffer;
  onRemove(): void;
}

const OfferForm: React.FC<OfferFormProps> = ({ index, name, fieldKey, offer, onRemove }) => {
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    if (offer?.url) {
      setCurrentUrl(offer.url);
    }
  }, [offer]);

  const urlContent = useMemo(() => {
    if (currentUrl && currentUrl.trim().length > 0) {
      return (
        <URLContent href={currentUrl} target="_blank">
          URL <SelectOutlined />
        </URLContent>
      );
    }
    return 'URL';
  }, [currentUrl]);

  const createdFormatted = useMemo(() => {
    const date = offer?.createdDate || Date.now();
    return dateFormat(date, 'yyyy-MM-dd, h:mma');
  }, [offer]);

  const createdDistanceFormatted = useMemo(() => {
    return offer?.createdDate
      ? formatDistanceToNow(offer.createdDate, {
          addSuffix: true,
        })
      : undefined;
  }, [offer]);

  const yieldProjection = useMemo(() => {
    const performance = offer?.merchant?.performance;
    const commissionRate = offer?.merchant?.commissionRate || [];
    const price = offer?.price || 0;
    const conversionRate = performance?.conversionRate || 0;
    const commissions = performance?.commissions || 0;
    const revenue = performance?.revenue || 0;

    const minCommissionRate = commissionRate
      .filter(commissionRateInfo => commissionRateInfo.type === 'Percentage')
      .filter(commissionRateInfo => !!commissionRateInfo?.percentageRate)
      .sort((a, b) => ((a?.percentageRate?.min || 0) - (b?.percentageRate?.min || 0)))
      .shift()
      ?.percentageRate?.min || 0;

    const effectiveCommissionRate = commissions > 0 && revenue > 0 ? commissions/revenue : 0;

    let yieldProjectionCalculation = 0;
    if ((minCommissionRate || effectiveCommissionRate) && price && conversionRate) {
      const rate = minCommissionRate ? minCommissionRate/100 : effectiveCommissionRate;
      yieldProjectionCalculation = 1000 * conversionRate * price * rate;
    }

    return round(yieldProjectionCalculation, 2);
  }, [offer]);

  return (
    <Container
      index={index}
      bodyStyle={{
        width: '100%',
        paddingLeft: 0,
        paddingBottom: 0,
      }}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Col span={22}>
          <Form.Item
            label="Merchant"
            labelCol={labelCol}
            name={[name, 'merchant']}
            fieldKey={[fieldKey, 'merchant']}
            rules={[{ required: true, message: "'Merchant' is required'" }]}
          >
            <MerchantField active />
          </Form.Item>

          <Form.Item label="Price" labelCol={labelCol} name={[name, 'price']} fieldKey={[fieldKey, 'price']}>
            <InputNumber placeholder="Price" />
          </Form.Item>

          <Form.Item
            label="Original Price"
            labelCol={labelCol}
            name={[name, 'originalPrice']}
            fieldKey={[fieldKey, 'originalPrice']}
          >
            <InputNumber placeholder="Original Price" />
          </Form.Item>
          <Form.Item
            label={urlContent}
            labelCol={labelCol}
            name={[name, 'url']}
            fieldKey={[fieldKey, 'url']}
            rules={[
              { required: true, message: "'URL' is required'" },
              { type: 'url', message: 'Invalid URL' },
            ]}
          >
            <Input onChange={(val) => setCurrentUrl(val.target.value)} placeholder="URL" />
          </Form.Item>

          <Form.Item
            label="CTA"
            labelCol={labelCol}
            name={[name, 'buttonText']}
            fieldKey={[fieldKey, 'buttonText']}
          >
            <Input placeholder="Custom call to action" />
          </Form.Item>

          <Form.Item label="Created" extra={createdDistanceFormatted} labelCol={labelCol}>
            <Input value={createdFormatted} readOnly />
          </Form.Item>
          <Form.Item
            label="Range Date"
            labelCol={labelCol}
            name={[name, 'rangeDate']}
            initialValue={offer?.rangeDate}
            fieldKey={[fieldKey, 'rangeDate']}
          >
            <RangePicker
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item label="Yield projection"  labelCol={labelCol}>
            <Input value={yieldProjection} readOnly />
          </Form.Item>
          <Form.Item
            label="Manual"
            labelCol={labelCol}
            name={[name, 'manual']}
            fieldKey={[fieldKey, 'manual']}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Out of stock"
            labelCol={labelCol}
            name={[name, 'outOfStock']}
            fieldKey={[fieldKey, 'outOfStock']}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Col>
          <Tooltip title="Remove offer">
            <Button type="primary" danger shape="circle" onClick={onRemove} icon={<DeleteOutlined />} />
          </Tooltip>
        </Col>
      </Row>
    </Container>
  );
};

export default OfferForm;
