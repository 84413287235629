import config from '../../config';

import ConsoleLoggerAnalyticsAdapter from './Implentations/ConsoleLoggerAnalyticsAdapter';
import SmartlookAnalyticsAdapter from './Implentations/SmartlookAnalyticsAdapter';

const analyticsService =
  config.smartlookAppId?.length > 0 || process.env.NODE_ENV === 'production'
    ? SmartlookAnalyticsAdapter
    : ConsoleLoggerAnalyticsAdapter;

export default analyticsService;
