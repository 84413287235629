import React, { useCallback, useEffect } from 'react';

import { Redirect, Route as ReactDOMRoute, RouteProps as ReactRouteProps } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { useLayout } from '../hooks/layout';
import { PermissionType } from '../services/SecurityService';
import { ROUTES } from '../utils/constants';

interface RouteProps extends ReactRouteProps {
  component: React.ComponentType;
  permit?: PermissionType;
  noLayout?: boolean;
}

const Route: React.FC<RouteProps> = ({ permit, noLayout: myNolayout, component: Component, ...rest }) => {
  const { user, isPermitted } = useAuth();
  const { useNoLayout } = useLayout();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useNoLayout(myNolayout);
  }, [myNolayout, useNoLayout]);

  const isRoutePermitted = useCallback(() => {
    return !!permit === !!user && (!permit || isPermitted(permit));
  }, [isPermitted, permit, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) =>
        isRoutePermitted() ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: user ? ROUTES.base : ROUTES.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default Route;
