/* eslint-disable prefer-promise-reject-errors */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router';
import { Container, FooterForm, TableFormTitle } from './styles';
import ImageUploadField from '../../components/Fields/ImageUploadField';
import BrandsField from '../../components/Fields/BrandsField';
import CategoryField from '../../components/Fields/CategoryField';
import { IMAGE_TYPES } from '../../utils/constants';
import Mutation from '../../graphql/Mutation';
import { asyncUseMutation } from '../../services/GraphQLService';
import { IDataRegisterProduct, InputRegisterProduct } from '../../dtos/ProductDTO';

interface ISelect {
  value: string;
  label: any;
}

interface RegisterProductPageForm {
  asin: string;
  brand: ISelect;
  category: ISelect;
  image: any;
  model: string;
  name: string;
  price: string;
  sku: string;
  upc: string;
  url: string;
  buttonText: string;
}

const RegisterProductPage: React.FC = () => {
  const location = useLocation();
  const debug = new URLSearchParams(location.search).get('debug') === 'true';
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState(false);
  const [create, { loading }] = useMutation<IDataRegisterProduct, InputRegisterProduct>(
    Mutation.registerProduct.mutation,
  );
  const [upload] = useMutation(Mutation.uploadFile.mutation);

  const handleUpperSubmit = useCallback(
    (event) => {
      const { action } = event.data;
      if (action === 'registerProduct') {
        form.submit();
      }
    },
    [form],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!debug) {
      window.addEventListener('message', handleUpperSubmit);
      return () => {
        window.removeEventListener('message', handleUpperSubmit);
      };
    }
  }, [debug, handleUpperSubmit]);

  const asyncUpload = useCallback(
    async (fieldName: string, file: File) => {
      const variables = {
        operation: 'uploadFile',
        field: fieldName,
        [fieldName]: file,
      };
      setUploading(true);
      try {
        const uploaded = await asyncUseMutation(upload, { variables });
        return uploaded.uploadFile;
      } finally {
        setUploading(false);
      }
    },
    [upload],
  );

  const uploadImage = useCallback(
    async (file: File) => {
      return asyncUpload('image', file);
    },
    [asyncUpload],
  );

  const handleSubmit = useCallback(
    async (val: RegisterProductPageForm) => {
      let imageId;
      if (val.image) {
        const imageSaved = await uploadImage(val.image);
        imageId = imageSaved.id;
      }

      const data = {
        url: val.url,
        name: val.name,
        model: val.model,
        upc: val.upc,
        gtin: val.sku,
        asin: val.asin,
        buttonText: val.buttonText,
        imageId,
        brandId: val.brand?.value,
        categoryId: val.category?.value,
      };
      const created = await create({
        variables: {
          data,
        },
      });
      const productId = created.data?.object.id || null;
      if (!debug) {
        window.parent.postMessage(
          {
            action: 'registerProduct',
            productId,
          },
          '*',
        );
      } else {
        message.info(`The product ${productId} has been created`);
      }
    },
    [create, debug, uploadImage],
  );

  return (
    <Container>
      <Form
        onFinish={handleSubmit}
        className="thin-form"
        form={form}
        labelAlign="right"
        labelCol={{ span: 7 }}
      >
        <Row>
          <Col span={6}>
            <Form.Item
              name="image"
              rules={[
                { required: true, message: 'Image is required' },
              ]}
            >
              <ImageUploadField
                loading={uploading}
                accept={IMAGE_TYPES}
                max={5}
                description='Provide a game poster for the image preview.'
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              name="url"
              label="External URL"
              rules={[
                { required: true, message: 'External URL is required' },
                { type: 'url', message: 'Invalid URL' },
              ]}
            >
              <Input />
            </Form.Item>
            <TableFormTitle orientation="left">
              <span>Product form</span>
            </TableFormTitle>
            <Form.Item
              style={{
                marginTop: 22,
              }}
              rules={[{ required: true, message: 'Name is required' }]}
              name="name"
              label="Product Name"
            >
              <Input autoComplete="false" placeholder="e.g. Samsung SX-800 H" />
            </Form.Item>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                {
                  pattern: new RegExp(/^\d{1,6}(\.\d{1,2})?$/),
                  message: 'Price should be a number. E.g. 1.23',
                },
              ]}
            >
              <Input placeholder="0.00" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18} offset={6}>
            <Form.Item name="category" label="Category">
              <CategoryField />
            </Form.Item>
            <Form.Item name="brand" label="Brand">
              <BrandsField showSearch />
            </Form.Item>
            <Form.Item name="model" label="Model">
              <Input placeholder="e.g. SX-800 H" />
            </Form.Item>
            <Form.Item name="upc" label="UPC" rules={[{ max: 12, min: 1 }]}>
              <Input placeholder="e.g. UPC-A 042100005264" maxLength={12} />
            </Form.Item>
            <Form.Item name="sku" label="SKU" rules={[{ max: 100, min: 1 }]}>
              <Input placeholder="e.g. UGG-BB-PUR-06" />
            </Form.Item>
            <Form.Item name="asin" label="ASIN" rules={[{ len: 10 }]}>
              <Input placeholder="e.g. B00005N5PF" maxLength={10} />
            </Form.Item>
            <Form.Item label="CTA" name="buttonText">
              <Input placeholder="Custom call to action" />
            </Form.Item>
          </Col>
        </Row>
        <FooterForm debug={debug}>
          <Button type="primary" onClick={() => form.submit()} loading={loading || uploading}>
            Create
          </Button>
        </FooterForm>
      </Form>
    </Container>
  );
};

export default RegisterProductPage;
