import React from 'react';
import UserForm from '../../components/Forms/UserForm';
import ListLayout from '../../components/ListLayout';
import { dateFormat } from '../../utils/constants';

const formatLastSeen = (lastSeenUnix: number) => {
  return dateFormat(lastSeenUnix);
};

const UsersPage: React.FC = () => {
  const columns = [
    {
      title: 'First Name',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      key: 'lastName',
    },
    {
      title: 'Role',
      key: 'roleName',
    },
    {
      title: 'Last Active',
      key: 'lastSeen',
      render: formatLastSeen,
    },
    {
      title: 'Email',
      key: 'email',
    },
  ];

  return (
    <ListLayout
      name="User"
      columns={columns}
      entityForm={UserForm}
      schema={{
        parse: (data) => {
          const { role, ...rest } = data;
          return {
            ...rest,
            role: role?.value,
          };
        },
      }}
    />
  );
};

export default UsersPage;
