import { Button, ButtonProps } from 'antd';
import { pluralize } from 'inflected';
import * as React from 'react';
import { Container } from './styles';

interface PerformanceButtonProps extends ButtonProps {
  counter: number;
  name: string;
}

const PerformanceButton: React.FC<PerformanceButtonProps> = ({ counter, name, ...props }) => {
  return (
    <Container>
      <Button
        type="ghost"
        shape="round"
        size="small"
        title={`${counter === 0 ? 'No' : counter} ${counter === 1 ? name : pluralize(name)}`}
        danger={counter === 0}
        disabled={counter === 0}
        style={{
          width: 80,
          fontSize: 12,
        }}
        {...props}
      >
        {counter}
      </Button>
    </Container>
  );
};

export default PerformanceButton;
