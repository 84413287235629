import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import './App.css';

import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './hooks';
import Routes from './routes';

import GlobalStyle from './styles/global';

import client from './services/ApolloService';
import config from './config';
import analyticsService from './services/Analytics/AnalyticsService';

const App: React.FC = () => {
  useEffect(() => {
    analyticsService.initialize(config.smartlookAppId);
  });
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyle />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
