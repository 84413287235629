import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import PublisherForm from '../../components/Forms/PublisherForm';
import { dateFormat, ROUTES } from '../../utils/constants';
import { IPublisherDTO } from '../../dtos/PublisherDTO';
import ListLayout from '../../components/ListLayout';

const PublisherPage: React.FC = () => {
  const [selected, setSelected] = useState<IPublisherDTO>();

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (name: string, publisher: IPublisherDTO) => (
          <Button type="link" onClick={() => setSelected(publisher)}>
            {name}
          </Button>
        ),
      },
      {
        title: 'Publisher ID',
        key: 'publisherId',
        dataIndex: 'publisherId',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'active',
        render: (active: boolean) => (active ? 'Active' : 'Inactive'),
        width: 80,
      },
      {
        title: 'URL',
        key: 'url',
        dataIndex: 'url',
      },
      {
        title: 'Created',
        key: 'createdAt',
        render: (value: number) => value && dateFormat(value),
        width: 200,
      },
      {
        title: 'Websites',
        key: 'websitesCol',
        dataIndex: 'websitesCol',
        render: (_val: any, publisher: IPublisherDTO) => (
          <a href={`${ROUTES.websites}/${publisher.id}`}>View</a>
        ),
      },
    ];
  }, []);

  return (
    <ListLayout
      name="Publisher"
      columns={columns}
      clickable={false}
      selected={selected}
      onUnselect={() => setSelected(undefined)}
      entityForm={PublisherForm}
    />
  );
};

export default PublisherPage;
