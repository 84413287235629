import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { IQueryPagination } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import { IBrandDTO } from '../../../dtos/ProductDTO';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

const BrandField: React.FC<IGenericSelectFieldProps<IBrandDTO>> = (params) => {
  const [iValue, setIvalue] = useState('');
  const [loadBrands, { loading, data }] = useLazyQuery<IQueryPagination<IBrandDTO>>(Query.brands.query);

  const brands = useMemo(() => {
    if (data) {
      return data.object.edges.map((o) => ({
        value: o.node.id,
        label: o.node.name,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    loadBrands({
      variables: {
        filters: {
          query: iValue,
        },
      },
    });
  }, [loadBrands, iValue]);

  return (
    <SelectField
      name="Brand"
      onSearch={(val) => setIvalue(val)}
      loading={loading}
      data={brands}
      {...params}
    />
  );
};

export default BrandField;
