import React, { useMemo } from 'react';
import { Image, Popover, PopoverProps, Typography } from 'antd';
import {
  ImageContainer,
  PopoverContainer,
  PopoverContent,
  PopoverItem,
  ItemLabel,
  ItemLink,
  ItemValue,
} from './styles';
import { IMAGE_NOT_FOUND } from '../../utils/constants';

const { Text } = Typography;

interface InfoPopoverItem {
  value?: string;
  label?: string;
  url?: string;
  render?: React.ReactNode;
}

interface InfoPopoverProps extends PopoverProps {
  image?: string;
  showImageNotFound?: boolean;
  width?: number;
  items?: InfoPopoverItem[];
}

const InfoPopover: React.FC<InfoPopoverProps> = ({
  image,
  showImageNotFound,
  width,
  items = [],
  children,
  ...props
}) => {
  const filteredItems = useMemo(() => {
    return items.filter((i) => !!i.value || !!i.render);
  }, [items]);

  return (
    <Popover
      placement="rightTop"
      {...props}
      content={
        <PopoverContainer>
          {(image || showImageNotFound) && (
            <ImageContainer>
              <Image src={image || IMAGE_NOT_FOUND} preview={false} fallback={IMAGE_NOT_FOUND} />
            </ImageContainer>
          )}
          <PopoverContent>
            {filteredItems.map((item, idx) => (
              <PopoverItem key={idx}>
                {item.label && <ItemLabel>{item.label}</ItemLabel>}
                {item.render ? (
                  item.render
                ) : (
                  <>
                    {item.url && (
                      <ItemLink href={item.url} target="_blank">
                        {item.value}
                      </ItemLink>
                    )}
                    {!item.url && <ItemValue type="secondary">{item.value}</ItemValue>}
                  </>
                )}
              </PopoverItem>
            ))}
          </PopoverContent>
        </PopoverContainer>
      }
    >
      {typeof children === 'string' ? (
        <Text
          ellipsis
          type="secondary"
          title=""
          style={{
            cursor: 'pointer',
            fontSize: 12,
            maxWidth: width,
          }}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </Popover>
  );
};

export default InfoPopover;
