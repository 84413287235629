import React, { useEffect, useMemo, useState } from 'react';

import { DocumentNode } from 'graphql';
import { useLazyQuery } from '@apollo/client';
import { IProductVars, IQueryPagination } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import { IProductDTO } from '../../../dtos/ProductDTO';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';
import { ISelectFieldDTO } from '../../../dtos/FormDTO';

interface IProductSelectOption extends ISelectFieldDTO {
  value: string;
  label: string;
}

interface IProductsFieldProps extends IGenericSelectFieldProps<IProductSelectOption, IProductDTO> {
  categoryId?: string;
  graphqlQuery?: DocumentNode;
}

const ProductsField: React.FC<IProductsFieldProps> = ({ categoryId, graphqlQuery, ...params }) => {
  const [iValue, setIvalue] = useState('');
  const [loadProducts, { loading, data }] = useLazyQuery<IQueryPagination<IProductDTO>, IProductVars>(
    graphqlQuery || Query.products.query,
  );

  const products = useMemo(() => {
    if (data) {
      return data.object.edges.map(({ node }) => ({
        value: node.id,
        label: node.name ?? '',
        itemData: node,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    loadProducts({
      variables: {
        limit: 100,
        order: 'NAME',
        direction: 'ASC',
        filters: {
          query: iValue,
          categoryId,
        },
      },
    });
  }, [loadProducts, iValue, categoryId]);

  return (
    <SelectField
      name="Product"
      onSearch={(val) => setIvalue(val)}
      loading={loading}
      data={products}
      {...params}
    />
  );
};

export default ProductsField;
