import styled from 'styled-components';
import { Button, Input, Tabs, Badge, Row, Col } from 'antd';

interface ChangeLogColProps {
  isDifferent: boolean;
}

export const ProductIDLabel = styled(Input)`
  border-width: 0;
  cursor: default;
  :hover {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent;
  }
  :focus {
    border-width: 0;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent;
  }
`;

export const RefreshLink = styled(Button)`
  text-decoration: underline;
  width: 140px;
  cursor: pointer;
  color: #595959;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
`;

export const StyledTab = styled(Tabs)`
  .ant-tabs-tab {
    background: #1d6dd9 !important;

    margin-left: 20px !important;
  }

  .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    margin-left: 20px !important;
  }
`;

export const BadgeTab = styled(Badge)`
  margin-right: 4px;
  sup {
    background: #007bff;
  }
`;

export const ChangeLogRow = styled(Row)`
  margin-bottom: 12px;
`

export const ChangeLogCol = styled(Col)<ChangeLogColProps>`
  background-color: ${(props) => (props.isDifferent ? '#f6ffed' : 'white')};
  color: ${(props) => (props.isDifferent ? '#389e0d' : 'black')};
  border-color:  ${(props) => (props.isDifferent ? '#b7eb8f' : 'white')};
  padding: 5px;
  border: ${(props) => (props.isDifferent ? '1px solid' : 'none')};
`

export const ChangeLogByLineRow = styled(Row)`
  padding-bottom: 10px;
`

export const ChangeLogByLineCol = styled(Col)`
  text-align: left;
  font-size: 18px;
`

export const ChangeLogDateCol = styled(Col)`
  text-align: right;
`
