/* eslint-disable prefer-promise-reject-errors */
import React, { useCallback, useEffect } from 'react';

import { Form, Input, message, Modal } from 'antd';
import { IImportModalProps } from '../../../dtos/FormDTO';
import { useFetchProductInfoMutation } from '../../../graphql/Hooks';
import { IProductDTO } from '../../../dtos/ProductDTO';

interface IFetchProduct extends IImportModalProps<IProductDTO> {
  id?: string;
  asin?: string;
  upc?: string;
  amazonUrl?: string;
}

const ImportProductModal: React.FC<IFetchProduct> = ({ visible, onCancel, onUpdate, ...props }) => {
  const [form] = Form.useForm<IFetchProduct>();
  const [fetchProduct, fetchingProduct] = useFetchProductInfoMutation();

  const validateUniqueASIN = async (): Promise<void> => {
    const asin = form.getFieldValue('asin');
    const upc = form.getFieldValue('upc');
    const amazonUrl = form.getFieldValue('amazonUrl');
    if (!asin && !upc && !amazonUrl) {
      return Promise.reject('One of these fields should be entered');
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        asin: props.asin,
        upc: props.upc,
      });
    }
  }, [form, props.asin, props.upc, visible]);

  const handleCancel = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [form, onCancel]);

  const handleImport = async () => {
    const res = await fetchProduct({
      id: props.id,
      amazonUrl: form.getFieldValue('amazonUrl'),
      asin: form.getFieldValue('asin'),
      upc: form.getFieldValue('upc'),
    });

    const errors = res?.errors;
    if (errors) {
      message.error(errors.map((e) => e.message).join(',\n'));
      return;
    }

    const product = res?.data?.fetchProductInfo?.product;
    if (product && onUpdate) {
      onUpdate?.(product);
    }
  };

  return (
    <Modal
      title="Import Product Information"
      destroyOnClose
      visible={visible}
      onCancel={handleCancel}
      cancelText="Close"
      okText="Import"
      onOk={() => form.submit()}
      okButtonProps={{
        loading: fetchingProduct,
      }}
    >
      <Form
        form={form}
        onFinish={handleImport}
        labelCol={{
          span: 6,
        }}
      >
        <Form.Item label="Amazon URL" name="amazonUrl" rules={[{ validator: validateUniqueASIN }]}>
          <Input
            placeholder="https://www.amazon.com/All-new-Kindle-Paperwhite-Waterproof-Storage/dp/B07CXG6C9W/"
            disabled={!!props.amazonUrl}
          />
        </Form.Item>
        <Form.Item label="ASIN" name="asin" rules={[{ len: 10, validator: validateUniqueASIN }]}>
          <Input placeholder="B07CXG6C9W" disabled={!!props.asin} />
        </Form.Item>
        <Form.Item label="UPC" name="upc" rules={[{ max: 12, min: 1, validator: validateUniqueASIN }]}>
          <Input placeholder="000123456789" disabled={!!props.upc} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportProductModal;
