import styled from 'styled-components';
import { Collapse, Input, Select, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

interface SelectContainerProps {
  width: number;
}

interface AppTagProps {
  width: number;
}

interface AppInputProps {
  padding?: number;
}

interface SelectedButtonProps {
  width: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: ${(props) => props.width}px;
`;

export const AppSelect = styled(Select)`
  .ant-select-selection-item {
    display: none;
  }
  flex: 1;
`;

export const AppInput = styled(Input)<AppInputProps>`
  cursor: pointer;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  input {
    visibility: hidden;
    width: 0;
  }
  .clean {
    visibility: hidden;
  }
  :hover {
    .clean {
      visibility: visible;
      cursor: pointer;
      svg {
        color: #bebebe;
        :hover {
          color: #8b8b8b;
        }
      }
    }
  }
`;

export const AppTag = styled(Tag)<AppTagProps>`
  margin-right: 4px;
  .ant-typography {
    max-width: ${(props) => props.width}px;
  }
`;

export const BadgeContainer = styled(Text)`
  font-size: 12px;
  cursor: pointer;
`;

export const CleanButton = styled.div``;

export const SelectedList = styled(Collapse.Panel)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  font-size: 12px;
  margin: 0;
  svg {
    font-size: 10px;
    color: #5a5a5a90;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const SelectedButton = styled.div<SelectedButtonProps>`
  width: ${(props) => props.width}px;
  padding: 4px 12px;
  background-color: #e7f6ff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  :hover {
    background-color: #ddf2ff;
  }
`;

export const SelectedText = styled(Text)`
  color: #5a5a5a;
  font-size: 12px;
`;

export const CheckIcon = styled(CheckOutlined)`
  color: #218bff;
`;

export const NoSelectedContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  padding-left: 16px;
  svg {
    font-size: 10px;
    margin-right: 12px;
    color: #5a5a5a90;
  }
`;
