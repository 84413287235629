import { Form, Typography, Select, Row, Button, Tooltip } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterForm = styled(Form)`
  margin: 0 81px 20px 81px;
`;

export const FilterContent = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const SubmitFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: default;
`;

export const FooterSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewItem = styled(Select.Option)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ViewItemLabel = styled.span`
  margin-left: 4px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  div: {
    height: 100%;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled(Text)`
  font-size: 12px;
  margin-right: 8px;
`;

export const DateText = styled.label`
  font-size: 10px;
`;

export const SlotIdContent = styled(Tooltip)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ClicksTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-left: 8px;
  }
`;

export const ClicksText = styled.span`
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const NumberColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PaginationContent = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveLink = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
`;
