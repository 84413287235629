import React from 'react';
import BrandsForm from '../../components/Forms/BrandsForm';
import ListLayout from '../../components/ListLayout';

const BrandsPage: React.FC = () => {
  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
  ];

  return <ListLayout name="Brand" columns={columns} entityForm={BrandsForm} />;
};

export default BrandsPage;
