/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { notification, Spin } from 'antd';
import { useLocation } from 'react-router';
import { IUserDTO } from '../dtos/UserDTO';
import { ILoginWithEmail } from '../graphql/Interface';
import Mutation from '../graphql/Mutation';
import StorageService from '../services/StorageService';
import SecurityService, { PermissionType, RoleType } from '../services/SecurityService';
import Query from '../graphql/Query';
import analyticsService from '../services/Analytics/AnalyticsService';

interface AuthState {
  token: string;
  user: IUserDTO;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUserDTO;
  updateUser(user: IUserDTO): void;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  isPermitted(...permissions: PermissionType[]): boolean;
  handleError(error: Error, title?: string | boolean, description?: string | boolean): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export class AuthError {
  public readonly message: string;

  public readonly code: number;

  constructor(message: string, code = 401) {
    this.message = message;
    this.code = code;
  }
}

export const AuthProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const userSessionTokenParam = new URLSearchParams(location.search).get('userSessionToken');
  const userSessionToken =
    !userSessionTokenParam || userSessionTokenParam === null ? undefined : userSessionTokenParam;
  const [data, setData] = useState<AuthState>(() => {
    const token = StorageService.getItem('token');
    const user = StorageService.getItem('user');

    if (token && user) {
      const parsedUser = JSON.parse(user);
      analyticsService.identify(parsedUser);
      return { token, user: parsedUser };
    }

    return {} as AuthState;
  });
  const [loginMutation] = useMutation<ILoginWithEmail>(Mutation.loginWithEmail.mutation);
  const [recoverUserSessionToken, { data: userSessionData, error: invalidSessionData, loading }] =
    useLazyQuery(Query.recoverSessionFromTransferToken.query);

  useEffect(() => {
    if (!data.user && userSessionData) {
      const { accessToken, user } = userSessionData.object || {};
      if (accessToken) {
        StorageService.setItem('token', accessToken);
        StorageService.setJsonItem('user', user);
        setData({ token: accessToken, user });
      }
      setLoaded(true);
    }
    if (invalidSessionData) {
      setLoaded(true);
    }
  }, [data.user, invalidSessionData, userSessionData]);

  const checkToken = useCallback(
    (token: string) => {
      setLoaded(false);
      if (!data.user && token) {
        recoverUserSessionToken({
          variables: { token },
        });
      }
    },
    [data.user, recoverUserSessionToken],
  );

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const result = await loginMutation({
          variables: {
            email,
            password,
          },
        });
        if (result && result.data) {
          const { accessToken: token, user } = result.data.loginWithEmail;
          StorageService.setItem('token', token);
          StorageService.setJsonItem('user', user);
          setData({ token, user });
          analyticsService.identify(user);
          analyticsService.trackEvent('signIn', user);
        }
      } catch (err) {}
    },
    [loginMutation],
  );

  const isPermitted = useCallback(
    (...permissions: PermissionType[]): boolean => {
      if (data.user && permissions.length > 0) {
        const role = data.user.role as RoleType;
        const permitted = permissions.find((permission) => SecurityService.isPermitted(role, permission));
        return !!permitted;
      }
      if (userSessionToken && invalidSessionData) {
        return false;
      }
      return true;
    },
    [data.user, invalidSessionData, userSessionToken],
  );

  const signOut = useCallback(() => {
    StorageService.removeItem('token');
    StorageService.removeItem('user');
    analyticsService.trackEvent('signOut');
    setData({} as AuthState);
  }, []);

  const handleError = useCallback(
    (err: Error, title?: string | boolean, description?: string | boolean) => {
      const subtitle = description === true ? 'Please try again later' : description || err.message;

      if (err instanceof AuthError) {
        if (err.code === 401) {
          signOut();
        }
      }
      if (title !== false) {
        const message = title || 'Error';
        notification.error({
          key: 'error-handler',
          message,
          description: subtitle,
        });
      }
    },
    [signOut],
  );

  const updateUser = useCallback(
    (user: IUserDTO) => {
      StorageService.setJsonItem('user', user);
      setData({
        token: data.token,
        user,
      });
    },
    [data.token],
  );

  useEffect(() => {
    if (!data.user && userSessionToken !== null && userSessionToken !== undefined) {
      checkToken(userSessionToken);
    }
  }, [checkToken, data.user, userSessionToken]);

  const checkingToken = useMemo(() => {
    if (!userSessionToken || !!data.user) {
      return false;
    }
    return loading || !loaded;
  }, [data.user, loaded, loading, userSessionToken]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        isPermitted,
        handleError,
      }}
    >
      {checkingToken ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 300,
          }}
        >
          <Spin />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}
