import React, { useEffect, useMemo } from 'react';

import { Form, Input, Checkbox } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import { IEntityForm } from '../../../dtos/FormDTO';

import { WebsiteIdText } from './styles';
import { IWebsiteDTO, IPublisherDTO } from '../../../dtos/PublisherDTO';

import Query from '../../../graphql/Query';

const WebsitesForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const location = useLocation();

  const [loadPublisher, { data }] = useLazyQuery(Query.publisher.query);

  const publisherId = useMemo(() => {
    const paths = location.pathname.split('/');
    if (paths.length < 3) {
      return undefined;
    }
    return paths[2];
  }, [location.pathname]);

  const iWebsite = useMemo(() => {
    if (!entity) {
      return undefined;
    }
    return entity as unknown as IWebsiteDTO;
  }, [entity]);

  useEffect(() => {
    if (publisherId) {
      loadPublisher({
        variables: {
          id: publisherId,
        },
      });
    }
  }, [loadPublisher, publisherId]);

  const website = useMemo(() => {
    if (!iWebsite || !data) {
      return undefined;
    }

    const publisher = data.publisher as IPublisherDTO;
    return publisher.websites.find((f) => f.websiteId === iWebsite.websiteId);
  }, [iWebsite, data]);

  useEffect(() => {
    if (!website || !form) {
      return;
    }
    form.setFieldsValue(website);
  }, [form, website]);

  const isEdit = useMemo(() => !!website, [website]);

  return (
    <Form form={form} labelAlign="right" labelCol={{ span: 5 }} {...props}>
      <Form.Item name="publisherId" noStyle hidden>
        <Input type="text" />
      </Form.Item>
      {/* <Form.Item label="Username" noStyle>
        <Input type="text" type="hidden" />
      </Form.Item> */}

      <Form.Item name="websiteId" label="Website ID" rules={[{ required: true }]}>
        {isEdit ? <WebsiteIdText>{website?.websiteId}</WebsiteIdText> : <Input placeholder="Website ID" />}
      </Form.Item>

      <Form.Item name="name" label="Website name" rules={[{ required: true }]}>
        <Input placeholder="e.g. Website" />
      </Form.Item>

      <Form.Item name="url" label="Website URL">
        <Input placeholder="http://www.digitaltrends.com" />
      </Form.Item>

      <Form.Item name="domains" label="Domains" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="active" label="Active" valuePropName="checked" initialValue={false}>
        <Checkbox />
      </Form.Item>

      <fieldset>
        <legend>Rakuten Account Information</legend>
        <Form.Item name={['rakutenInfo', 'siteId']} label="Site ID">
          <Input placeholder="0000000" />
        </Form.Item>
        <Form.Item name={['rakutenInfo', 'affiliateId']} label="Affiliate ID">
          <Input placeholder="The aff ID that appears in links" />
        </Form.Item>
      </fieldset>

      <fieldset>
        <legend>CJ Account Information</legend>
        <Form.Item name={['cjInfo', 'publisherId']} label="Publisher ID">
          <Input placeholder="Publisher ID" />
        </Form.Item>
        <Form.Item name={['cjInfo', 'websiteId']} label="Property ID">
          <Input placeholder="Property ID" />
        </Form.Item>
      </fieldset>

      <fieldset>
        <legend>Connexity Account Information</legend>
        <Form.Item name={['connexityInfo', 'publisherId']} label="Publisher ID">
          <Input placeholder="Publisher ID" />
        </Form.Item>
      </fieldset>

      <fieldset>
        <legend>Narrativ Site Information</legend>
        <Form.Item name={['narrativInfo', 'slugId']} label="Slug ID">
          <Input placeholder="Slug ID" />
        </Form.Item>
      </fieldset>

      <fieldset>
        <legend>Skimlinks Site Information</legend>
        <Form.Item name={['skimlinksInfo', 'siteId']} label="Site ID">
          <Input placeholder="Site ID" />
        </Form.Item>
      </fieldset>
    </Form>
  );
};

export default WebsitesForm;
