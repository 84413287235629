import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Table, Pagination, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useLazyQuery } from '@apollo/client';
import { ColumnType } from 'antd/lib/table';
import { humanize } from 'inflected';
import CCDrawer from '../../CCDrawer';
import {
  IInventoryFilters,
  IPageInfo,
  IInventoryData,
  IInventoryVars,
  InventoryColumnType,
} from '../../../graphql/Interface';
import { IInventoryDTO } from '../../../dtos/ArticleInventoryDTO';
import Search from '../../Search';
import Query from '../../../graphql/Query';
import { FilterContent, PaginationContent } from './styles';

export type ArticleInventoryModalView = 'article' | 'link';

interface InventoryModalProps {
  totalCount: number;
  visible?: boolean;
  onCancel(): void;
  filters: IInventoryFilters;
  name: string;
  groupingName?: string;
  columns: ColumnType<IInventoryDTO>[];
  dbColumns: InventoryColumnType[];
  localFilter(item: IInventoryDTO): string | undefined;
  maxLocalPerPage: number;
  width?: number;
}

const InventoryModal: React.FC<InventoryModalProps> = ({
  onCancel,
  totalCount,
  filters,
  localFilter,
  visible,
  name,
  groupingName,
  columns,
  dbColumns,
  maxLocalPerPage,
  width,
}) => {
  const [localDbColumns, _setLocalDbColumns] = useState(dbColumns);
  const [nodes, setNodes] = useState<IInventoryDTO[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState<'ASC' | 'DESC'>('DESC');
  const [order, setOrder] = useState('CLICKS');
  const [queryString, setQueryString] = useState('');
  const [queryStringSearch, setQueryStringSearch] = useState('');
  const [loadInventoryInfo, { data, loading }] = useLazyQuery<IInventoryData, IInventoryVars>(
    Query.linkInventory.query,
  );

  const handleColumnChange = useCallback(
    (_, filter, { order: iOrder, columnKey }) => {
      if (totalCount > maxLocalPerPage) {
        const newDirection = iOrder === 'ascend' ? 'ASC' : 'DESC';
        const newOrder = columnKey || 'CLICKS';
        setDirection(newDirection);
        setOrder(newOrder);
      }
    },
    [maxLocalPerPage, totalCount],
  );

  useEffect(() => {
    if (visible) {
      loadInventoryInfo({
        variables: {
          order,
          direction,
          offset: (page - 1) * maxLocalPerPage,
          limit: maxLocalPerPage,
          filters: {
            ...filters,
            query: queryStringSearch,
          },
          columns: localDbColumns,
        },
      });
    }
  }, [
    localDbColumns,
    direction,
    filters,
    loadInventoryInfo,
    maxLocalPerPage,
    order,
    page,
    queryStringSearch,
    visible,
  ]);

  useEffect(() => {
    if (data) {
      setPageInfo(data.inventories.pageInfo);
      setNodes(data.inventories.edges.map((a) => a.node));
    }
  }, [data]);

  const handlePaginationChange = useCallback((iPage, _iPageSize) => {
    setPage(iPage);
  }, []);

  const filteredNodes = useMemo(() => {
    if (totalCount > maxLocalPerPage || queryString.trim().length === 0) {
      return nodes;
    }
    const fixedQuery = queryString.replace(/\W/g, '');
    const regex = new RegExp(fixedQuery, 'i');
    const filtered = nodes.filter((link) => {
      const aa = `${localFilter(link) || ''}`.match(regex);
      return !!aa;
    });
    return filtered;
  }, [localFilter, maxLocalPerPage, nodes, queryString, totalCount]);

  const handleCleanModal = useCallback(() => {
    setNodes([]);
    setPageInfo(undefined);
    setQueryString('');
    setQueryStringSearch('');
    setOrder('CLICKS');
    setDirection('DESC');
    setPage(1);
  }, []);

  const titleSufix = useMemo(() => {
    if (!groupingName) {
      return '';
    }
    return `(by ${groupingName})`;
  }, [groupingName]);

  return (
    <CCDrawer
      onOpen={handleCleanModal}
      onClose={onCancel}
      zIndex={199}
      width={width}
      title={`${humanize(name)} Summary ${titleSufix}`}
      visible={visible}
    >
      <>
        <FilterContent>
          <Search placeholder="Filter" value={queryString} onChange={(val: any) => setQueryString(val)} />
          {totalCount > maxLocalPerPage && (
            <Button
              shape="round"
              loading={loading}
              type="ghost"
              icon={<SearchOutlined />}
              onClick={() => setQueryStringSearch(queryString)}
            >
              Filter
            </Button>
          )}
        </FilterContent>
        <Table
          columns={columns}
          size="small"
          pagination={false}
          loading={loading}
          dataSource={filteredNodes}
          onChange={handleColumnChange}
          showSorterTooltip={false}
          style={{
            marginTop: 10,
          }}
          rowKey={() => Math.random().toString(36).substr(2, 5)}
        />
        {pageInfo && pageInfo.pageCount > 1 && (
          <PaginationContent>
            <Pagination
              current={page}
              onChange={handlePaginationChange}
              pageSize={100}
              showSizeChanger={false}
              total={pageInfo.total}
            />
          </PaginationContent>
        )}
      </>
    </CCDrawer>
  );
};

export default InventoryModal;
