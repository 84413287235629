import React from 'react';
import config from '../../../config';

import { Footer as StyledFooter } from './styles';

const Footer: React.FC = () => {
  return <StyledFooter>Build: {config.buildCommit}</StyledFooter>;
};

export default Footer;
