import gql from 'graphql-tag';

const KeyValuePairFragment = gql`
  fragment KeyValuePairFragment on KeyValuePair {
    label
    value
  }
`;

const UserFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    phone
    email
    role
    roleName
    lastSeen
  }
`;

const PerformanceFragment = gql`
  fragment PerformanceFragment on Performance {
    updatedAt
    articles
    clicks
    transactions
    commissions
  }
`;

const MetricsFragment = gql`
  fragment MetricsFragment on ProductMetrics {
    updatedAt
    clicksRolling1Day
    pageviewsRolling1Day
    serversideImpressionsRolling1Day
    clicksRolling1DayZScore
    pageviewsRolling1DayZScore
    ctrRolling1DayZScore
    serversideImpressionsRolling1DayZScore
    clickScore
  }
`;

const CategoryFragment = gql`
  fragment CategoryFragment on Category {
    id
    name
  }
`;
const BrandFragment = gql`
  fragment BrandFragment on Brand {
    id
    name
  }
`;

const MerchantPerformanceFragment = gql`
  fragment MerchantPerformanceFragment on MerchantPerformance {
    clicks
    commissions
    revenue
    conversionRate
    revenuePerClick
    sov
    updatedAt
  }
`;

const MerchantCommissionRateFragment = gql`
  fragment MerchantCommissionRateFragment on CommissionRateInfo {
    actionName
    pricingModel
    type
    fixedRate {
      max
      min
      currency
    }
    percentageRate {
      max
      min
    }
    createdAt
  }
`;

const ShortProductFragment = gql`
  fragment ShortProductFragment on Product {
    id
    createdAt
    name
    price
    asin
    upc
    offerTotals
    landingPageTotals
    image(resized: { width: 100, height: 100, mode: INSET }) {
      url
    }
    brand {
      ...BrandFragment
    }
    category {
      ...CategoryFragment
    }
    performance {
      ...PerformanceFragment
    }
    metrics {
      ...MetricsFragment
    }
    lastOffersUpdatedAt
  }
  ${CategoryFragment}
  ${BrandFragment}
  ${PerformanceFragment}
  ${MetricsFragment}
`;

const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    name
    price
    image(resized: { width: 100, height: 100, mode: INSET }) {
      url
    }
    headline
    description
    model
    tags
    publishedAt
    rating
    make
    upc
    gtin
    asin
    dtProductId
    brand {
      ...BrandFragment
    }
    category {
      ...CategoryFragment
    }
    landingPagesInfo {
      price
      url
      status
      statusName
      createdDate
    }
    offersInfo {
      merchant {
        id
        name
        commissionRate {
          ...MerchantCommissionRateFragment
        }
        performance {
          ...MerchantPerformanceFragment
        }
      }
      price
      originalPrice
      url
      default
      buttonText
      startDate
      endDate
      manual
      outOfStock
      createdDate
    }
    merchantsInfo {
      merchant {
        id
        name
      }
      sku
    }
  }
  ${CategoryFragment}
  ${BrandFragment}
  ${MerchantCommissionRateFragment}
  ${MerchantPerformanceFragment}
`;

const MerchantFragment = gql`
  fragment MerchantFragment on Merchant {
    id
    externalId
    name
    displayName
    website
    createdAt
    commissionRate {
      ...MerchantCommissionRateFragment
    }
    affiliateNetwork {
      id
      name
    }
    logo {
      id
      url
    }
    performance {
      ...MerchantPerformanceFragment
    }
    disabled
    offerTotals
    linkableDomains
    nonlinkableDomains
  }
  ${MerchantCommissionRateFragment}
  ${MerchantPerformanceFragment}
`;

const AffiliateNetworkFragment = gql`
  fragment AffiliateNetworkFragment on AffiliateNetwork {
    id
    name
    commissionRate
    logo {
      id
      url
    }
  }
`;

const PlacementFragment = gql`
  fragment PlacementFragment on Placement {
    id
    createdAt
    updatedAt
    publisherId
    websiteId
    placementId
    name
    archived
  }
`;

const CampaignRuleFragment = gql`
  fragment CampaignRuleFragment on CampaignRule {
    id
    name
    archived
    merchants {
      id
      name
      affiliateNetwork {
        id
        name
      }
    }
    scopes {
      exclusive
      slot
      priority {
        ...KeyValuePairFragment
      }
      categories {
        id
        name
      }
      products {
        id
        name
      }
      brands {
        id
        name
      }
      sites {
        ...KeyValuePairFragment
      }
      placements {
        ...PlacementFragment
      }
      placementTypes {
        ...KeyValuePairFragment
      }
      articleCategories
      articleTypes
      articles
      customTargeting {
        field
        value
      }
    }
    startDate
    endDate
  }
  ${PlacementFragment}
  ${KeyValuePairFragment}
`;

const PublisherWebsiteFragment = gql`
  fragment PublisherWebsiteFragment on PublisherWebsite {
    websiteId
    name
    active
    url
    domains
    rakutenInfo {
      siteId
      affiliateId
    }
    cjInfo {
      websiteId
      publisherId
    }
    connexityInfo {
      publisherId
    }
    narrativInfo {
      slugId
    }
    skimlinksInfo {
      siteId
    }
  }
`;

const PublisherFragment = gql`
  fragment PublisherFragment on Publisher {
    id
    createdAt
    updatedAt
    publisherId
    name
    active
    url
    websites {
      ...PublisherWebsiteFragment
    }
  }
  ${PublisherWebsiteFragment}
`;

export default {
  KeyValuePairFragment,
  CategoryFragment,
  ProductFragment,
  ShortProductFragment,
  UserFragment,
  MerchantFragment,
  BrandFragment,
  CampaignRuleFragment,
  AffiliateNetworkFragment,
  PublisherFragment,
  PublisherWebsiteFragment,
  PlacementFragment,
  MerchantPerformanceFragment,
  MerchantCommissionRateFragment,
};
