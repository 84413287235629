import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Form, Button, Col, Row, Input, Tooltip, InputNumber, FormInstance } from 'antd';
import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';
import { cleanUrlQueryParams, dateFormat } from '../../../utils/constants';
import { LandingPageStatus } from '../../../dtos/ProductDTO';
import LandingPageStatusField from '../../Fields/LandingPageStatusField';
import { Container, URLContent } from './styles';

const labelCol = {
  span: 6,
};

export const DEFAULT_LANDING_PAGE_STATUS: LandingPageStatus = 'UNKNOWN' as const;

export type LandingPageProductFormData = {
  id?: string;
  price?: number;
  url: string;
  status: { label: string; value: LandingPageStatus };
  createdDate: number;
  existing?: boolean;
};

type LandingPageProps = {
  index: number;
  name: number;
  fieldKey: number;
  landingPage?: LandingPageProductFormData;
  onRemove(): void;
  form: FormInstance;
};

const LandingPageForm: React.FC<LandingPageProps> = ({ form, index, name, fieldKey, landingPage, onRemove }) => {
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    if (landingPage?.url) {
      setCurrentUrl(landingPage.url);
    }
  }, [landingPage]);

  const urlContent = useMemo(() => {
    if (currentUrl && currentUrl.trim().length > 0) {
      return (
        <URLContent href={currentUrl} target="_blank">
          URL <SelectOutlined />
        </URLContent>
      );
    }
    return 'URL';
  }, [currentUrl]);

  const handleUrlContent = (event: BaseSyntheticEvent) => {
    const url = event.target.value;
    try {
      const cleannedUrl = cleanUrlQueryParams(url);
      const fields = form.getFieldsValue();
      const { landingPagesInfo } = fields;
      Object.assign(landingPagesInfo[index], { url: cleannedUrl });
      form.setFieldsValue({ landingPagesInfo });
      setCurrentUrl(cleannedUrl);
    } catch (error) {
      setCurrentUrl(url);
    }
  };
  const createdFormatted = (): string => {
    const date = landingPage?.createdDate || Date.now();
    return dateFormat(date, 'yyyy-MM-dd, h:mma');
  };

  return (
    <Container
      index={index}
      bodyStyle={{
        width: '100%',
        paddingLeft: 0,
        paddingBottom: 0,
      }}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Col span={22}>
          <Form.Item
            label={urlContent}
            labelCol={labelCol}
            name={[name, 'url']}
            fieldKey={[fieldKey, 'url']}
            rules={[
              { required: true, message: "'URL' is required'" },
              { type: 'url', message: 'Invalid URL' },
            ]}
          >
            <Input autoComplete="off" onBlur={(val) => handleUrlContent(val)} placeholder="URL" />
          </Form.Item>
          <Form.Item
            label="Price"
            labelCol={labelCol}
            name={[name, 'price']}
            fieldKey={[fieldKey, 'price']}
            rules={[{ type: 'number', message: 'Invalid price' }]}
          >
            <InputNumber placeholder="Price" min="0" />
          </Form.Item>

          <Form.Item label="Created" labelCol={labelCol}>
            <Input value={createdFormatted()} readOnly />
          </Form.Item>
          <Form.Item name={[name, 'status']} fieldKey={[fieldKey, 'status']} labelCol={labelCol} label="Landing Page Status">
            <LandingPageStatusField />
          </Form.Item>
        </Col>
        <Col>
          <Tooltip title="Remove landing page">
            <Button type="primary" danger shape="circle" onClick={onRemove} icon={<DeleteOutlined />} />
          </Tooltip>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageForm;
