import React, { useCallback, useState } from 'react';

import { Divider, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Title, LoginButton } from './styles';

import { useAuth } from '../../hooks/auth';
import { ROUTES } from '../../utils/constants';

interface ILoginFormData {
  password: string;
  email: string;
}

const iconStyle = {
  color: '#bfbfbf',
};

const LoginPage: React.FC = () => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async ({ email, password }: ILoginFormData): Promise<void> => {
      try {
        setLoading(true);
        await signIn({
          email,
          password,
        });
      } finally {
        setLoading(false);
      }
    },
    [signIn],
  );

  return (
    <>
      <Form form={form} onFinish={(values) => handleSubmit(values as ILoginFormData)}>
        <Title>Welcome to Digital Trends DB</Title>
        <Form.Item name="email" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input prefix={<UserOutlined style={iconStyle} />} placeholder="Username" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input
            prefix={<LockOutlined style={iconStyle} />}
            type="password"
            placeholder="Password"
            data-recording-sensitive
          />
        </Form.Item>
        <LoginButton loading={loading} type="primary" htmlType="submit">
          Log in
        </LoginButton>
      </Form>
      <Divider />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Link style={{ marginLeft: 4 }} to={ROUTES.forgotPassword} href={ROUTES.forgotPassword}>
          Forgot your password?
        </Link>
      </div>
    </>
  );
};

export default LoginPage;
