import React from 'react';

import { Drawer, Button } from 'antd';

import { Footer } from './styles';

interface CCDrawerProps {
  width?: number;
  visible?: boolean;
  title: string;
  zIndex?: number;
  footerMessage?: React.ReactElement | string;
  onClose(): void;
  onOpen?(): void;
  actions?: React.ReactElement;
}

const CCDrawer: React.FC<CCDrawerProps> = ({
  onClose,
  onOpen,
  actions,
  visible,
  title,
  width = 800,
  zIndex,
  children,
  footerMessage,
}) => {
  return (
    <Drawer
      zIndex={zIndex}
      onClose={onClose}
      afterVisibleChange={(v) => v && onOpen && onOpen()}
      headerStyle={{
        background: '#f5f5f5',
        borderBottomColor: '#d9d9d9',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      }}
      footerStyle={{
        background: '#f5f5f5',
        borderTopColor: '#d9d9d9',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
      }}
      footer={
        <Footer>
          {footerMessage && (
            <span
              style={{
                position: 'absolute',
                left: 0,
              }}
            >
              {footerMessage}
            </span>
          )}
          <Button key="close" onClick={onClose}>
            Close
          </Button>
          {actions}
        </Footer>
      }
      width={width}
      destroyOnClose
      title={title}
      visible={visible}
    >
      {children}
    </Drawer>
  );
};

export default CCDrawer;
