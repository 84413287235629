import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentText = styled.label`
  line-height: 0;
`;

export const ContentTextBold = styled.span`
  color: #000000;
  font-weight: bold;
`;

export const ImportButton = styled(Button)`
  text-decoration: none;
  color: #333333;
  :hover {
    text-decoration: underline;
  }
`;
