import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import AntLink from 'antd/lib/typography/Link';
import Text from 'antd/lib/typography/Text';

interface MenuOptionProps {
  icon: React.ReactNode;
  to?: string;
  selected?: boolean;
  onClick?(): void;
}

export const MenuOption: React.FC<MenuOptionProps> = ({ icon, to, selected, onClick, children }) => {
  const content = useMemo(
    () => (
      <>
        <span
          style={{
            marginRight: 6,
            color: selected ? '#4090f7' : '#595959',
          }}
        >
          {icon}
        </span>
        <Text
          type="secondary"
          style={{
            color: selected ? '#4090f7' : undefined,
          }}
        >
          {children}
        </Text>
      </>
    ),
    [selected, icon, children],
  );

  return (
    <>
      {to && <Link to={to}>{content}</Link>}
      {!to && onClick && <AntLink onClick={onClick}>{content}</AntLink>}
      {!to && !onClick && <span>{content}</span>}
    </>
  );
};

export default MenuOption;
