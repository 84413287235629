import React from 'react';

import { Form, Button, Checkbox, Tooltip, Select } from 'antd';

import { DeleteFilled } from '@ant-design/icons';

import { Container, TitleLabel } from './styles';
import { IScopeDTO } from '../../../dtos/CampaignDTO';
import PriorityField from '../../Fields/PriorityField';
import BrandField from '../../Fields/BrandsField';
import CategoryField from '../../Fields/CategoryField';
import ProductsField from '../../Fields/ProductsField';
import TagsField from '../../Fields/TagsField';
import ArticleTypeField from '../../Fields/ArticleTypeField';
import CriteriaForm from '../CriteriaForm';
import PlacementField from '../../Fields/PlacementField';
import PlacementTypeField from '../../Fields/PlacementTypeField';
import WebsiteField from '../../Fields/WebsiteField';

interface CampaignScopeFormProps {
  index: number;
  name: number;
  fieldKey: number;
  scope?: IScopeDTO;
  onRemove(): void;
}

const labelColSpan = {
  span: 8,
};

const labelColSubSpan = {
  span: 7,
};

const CampaignScopeForm: React.FC<CampaignScopeFormProps> = ({ index, name, fieldKey, scope, onRemove }) => {
  return (
    <Container
      className="thin-cell"
      title={<TitleLabel>Rule {index + 1}.</TitleLabel>}
      extra={
        <Tooltip title={index === 0 ? 'Cannot remove all scopes' : ''}>
          <Button
            type="link"
            danger
            disabled={index === 0}
            onClick={onRemove}
            icon={
              <DeleteFilled
                style={{
                  color: 'red',
                  opacity: index === 0 ? 0.4 : 1,
                }}
              />
            }
          />
        </Tooltip>
      }
      index={index}
      headStyle={{
        width: '100%',
        background: '#cccccc20',
      }}
      bodyStyle={{
        width: '100%',
        paddingLeft: 0,
        paddingBottom: 0,
      }}
    >
      <>
        <Form.Item
          label="Exclusive"
          labelCol={labelColSpan}
          name={[name, 'exclusive']}
          fieldKey={[fieldKey, 'exclusive']}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          labelCol={labelColSpan}
          label="Slot"
          name={[name, 'slot']}
          fieldKey={[fieldKey, 'slot']}
          rules={[{ required: true, message: "'Slot' is required'" }]}
        >
          <Select style={{ width: '50%' }}>
            {[...Array(10)].map((n, idx) => (
              <Select.Option value={idx}>{idx}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          labelCol={labelColSpan}
          label="Priority"
          name={[name, 'priority']}
          fieldKey={[fieldKey, 'priority']}
          rules={[{ required: true, message: "'Priority' is required" }]}
        >
          <PriorityField />
        </Form.Item>
        <fieldset
          style={{
            marginLeft: 20,
            padding: 20,
          }}
        >
          <legend>Product Criteria</legend>
          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'brands']}
            fieldKey={[fieldKey, 'brands']}
            label="Brands"
          >
            <BrandField multiple />
          </Form.Item>
          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'categories']}
            fieldKey={[fieldKey, 'categories']}
            label="Categories"
          >
            <CategoryField multiple />
          </Form.Item>
          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'products']}
            fieldKey={[fieldKey, 'products']}
            label="Products"
          >
            <ProductsField multiple />
          </Form.Item>
        </fieldset>
        <fieldset
          style={{
            marginLeft: 20,
            padding: 20,
          }}
        >
          <legend>Content Criteria</legend>
          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'sites']}
            fieldKey={[fieldKey, 'sites']}
            label="Web Sites"
          >
            <WebsiteField multiple placeholder="Leave blank for all" />
          </Form.Item>

          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'articles']}
            fieldKey={[fieldKey, 'articles']}
            label="Article IDs"
          >
            <TagsField tags={scope?.articles} placeholder="Enter post IDs" />
          </Form.Item>

          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'articleCategories']}
            fieldKey={[fieldKey, 'articleCategories']}
            label="Article Categories"
          >
            <Select mode="tags" placeholder="Enter categories" />
          </Form.Item>
          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'articleTypes']}
            fieldKey={[fieldKey, 'articleTypes']}
            label="Article Types"
          >
            <ArticleTypeField multiple placeholder="Leave blank for all" />
          </Form.Item>

          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'placements']}
            fieldKey={[fieldKey, 'placements']}
            label="Placement IDs"
          >
            <PlacementField multiple placeholder="Leave blank for all" />
          </Form.Item>

          <Form.Item
            labelCol={labelColSubSpan}
            name={[name, 'placementTypes']}
            fieldKey={[fieldKey, 'placementTypes']}
            label="Placement Types"
          >
            <PlacementTypeField multiple placeholder="Leave blank for all" />
          </Form.Item>

          <Form.List name={[name, 'customTargeting']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((formListFieldData, idx) => (
                  <CriteriaForm
                    fieldKey={formListFieldData.fieldKey}
                    name={formListFieldData.name}
                    index={idx}
                    onRemove={() => remove(formListFieldData.name)}
                    key={`scope_ct_${idx}`}
                  />
                ))}
                <Form.Item>
                  <Button type="ghost" style={{ float: 'right', marginTop: 10 }} onClick={() => add()}>
                    Add Custom Target Criteria
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </fieldset>
      </>
    </Container>
  );
};

export default CampaignScopeForm;
