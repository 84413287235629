import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { IQueryPagination } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import { IMerchantDTO } from '../../../dtos/MerchantDTO';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

interface MerchantFieldProps extends IGenericSelectFieldProps<IMerchantDTO> {
  active?: boolean;
  filterSkimlinks?: boolean;
  disabled?: boolean;
}

const MerchantField: React.FC<MerchantFieldProps> = ({ active, ...params }) => {
  const [sValue, setsValue] = useState('');

  const [loadMerchants, { loading, data }] = useLazyQuery<IQueryPagination<IMerchantDTO>>(
    Query.merchantOptions.query,
  );

  const merchants = useMemo(() => {
    if (data) {
      const allMerchants = data.object.edges.map((o) => ({
        value: o.node.id,
        label: o.node.name,
        aux: o.node.affiliateNetwork?.name,
        disabled: o.node.disabled,
      }));

      if (params?.filterSkimlinks) {
        const filteredMerchant = allMerchants.filter((merchant) => merchant.aux !== 'Skimlinks');

        return filteredMerchant.length > 0 ? filteredMerchant : allMerchants;
      }

      return allMerchants;
    }
    return [];
  }, [data]);

  useEffect(() => {
    const activeFilter = active ? { disabled: false } : undefined;
    loadMerchants({
      variables: {
        filters: {
          query: sValue,
          ...activeFilter,
        },
      },
    });
  }, [active, loadMerchants, sValue]);

  return (
    <SelectField
      name="Merchant"
      onSearch={(val) => setsValue(val || '')}
      loading={loading}
      data={merchants}
      {...params}
    />
  );
};

export default MerchantField;
