import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { IQueryObject } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';
import { IAffiliateNetworkDTO } from '../../../dtos/MerchantDTO';

const AffiliateNetworkField: React.FC<IGenericSelectFieldProps<IAffiliateNetworkDTO>> = (params) => {
  const [value, setValue] = useState('');
  const [loadAffiliates, { loading, data }] = useLazyQuery<IQueryObject<IAffiliateNetworkDTO>>(
    Query.affiliateNetworks.query,
  );

  const affiliates = useMemo(() => {
    if (data) {
      return data.object.map((o) => ({
        value: o.id,
        label: o.name,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    loadAffiliates({
      variables: {
        filters: {
          query: value,
        },
      },
    });
  }, [loadAffiliates, value]);

  return (
    <SelectField
      name="Affiliate Network"
      onSearch={(val) => setValue(val)}
      loading={loading}
      data={affiliates}
      {...params}
    />
  );
};

export default AffiliateNetworkField;
