import styled from 'styled-components';

import { Image } from 'antd';

export const Logo = styled(Image)`
  float: left;
  width: 120px;
  margin-top: 6px;
  margin-right: 20px;
  height: auto;
  width: 120px;
`;
