import React, { memo, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

const LandingPageStatusField: React.FC<IGenericSelectFieldProps> = (params) => {
  const { loading, data } = useQuery(Query.landingPageStatus.query);

  const iLandingPageStatus = useMemo(() => {
    if (data) {
      const dataObject = data.object;
      return dataObject;
    }
    return [];
  }, [data]);

  return (
    <SelectField
      showSearch={false}
      name="Landing Page Status"
      loading={loading}
      data={iLandingPageStatus}
      {...params}
    />
  );
};

export default memo(LandingPageStatusField);
