import styled from 'styled-components';

export const Footer = styled.footer`
  flex: 0 0 auto;
  background: none;
  position: fixed;
  width: 100%;
  z-index: 2;
  bottom: 0;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.65);
  text-align: right;
  padding 0em 1em;
`;
