import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CloseCircleFilled, MinusCircleOutlined } from '@ant-design/icons';

import { LabeledValue, RefSelectProps, SelectProps } from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
import { Collapse, Divider } from 'antd';
import { useLazyQuery } from '@apollo/client';
import {
  Container,
  SelectContainer,
  AppInput,
  AppSelect,
  AppTag,
  BadgeContainer,
  CleanButton,
  SelectedButton,
  SelectedList,
  CheckIcon,
  SelectedText,
  NoSelectedContainer,
} from './styles';
import Query from '../../../graphql/Query';
import { IProductVars, IQueryPagination } from '../../../graphql/Interface';
import { IProductDTO } from '../../../dtos/ProductDTO';

interface ProductsListFieldProps extends SelectProps<any> {
  width: number;
  categoryId?: string;
  initialValues?: LabeledValue[];
  onChange?(values: LabeledValue[] | undefined): void;
}

const ProductsListField: React.FC<ProductsListFieldProps> = ({
  width,
  categoryId,
  onChange,
  onChange: _,
  value,
  ...selectProps
}) => {
  const [query, setQuery] = useState('');
  const selectRef = useRef<RefSelectProps>(null);
  const [focus, setFocus] = useState(false);
  const [selected, setSelected] = useState<LabeledValue[]>([]);

  const [loadProducts, { loading, data }] = useLazyQuery<IQueryPagination<IProductDTO>, IProductVars>(
    Query.products.query,
  );

  useEffect(() => {
    loadProducts({
      variables: {
        limit: 60,
        order: 'NAME',
        direction: 'ASC',
        filters: {
          query,
          categoryId,
        },
      },
    });
  }, [loadProducts, query, categoryId]);

  const products = useMemo(() => {
    if (data) {
      return data.object.edges.map((o) => ({
        value: o.node.id,
        label: o.node.name ?? '',
      }));
    }
    return [];
  }, [data]);

  const onLocalChange = useCallback(
    (option) => {
      if (onChange) {
        onChange(option);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (value && value.length > 0) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    if (focus) {
      selectRef.current?.focus();
    }
  }, [focus]);

  const handleSelect = useCallback(
    ({ value: iValue }) => {
      const selectedOption = products.find((d) => d.value === iValue);
      setSelected((previous) => [...previous, selectedOption as LabeledValue]);
    },
    [products],
  );

  const handleDeselect = useCallback(
    ({ value: iValue }) => {
      const currencSelection = selected.filter((p) => p.value !== iValue);
      onLocalChange(currencSelection);
      setSelected(currencSelection);
    },
    [selected, onLocalChange],
  );

  const handleSearch = useCallback((a) => {
    setQuery(a);
  }, []);

  const availableOptions = useMemo(() => {
    return products.filter((opt) => selected.findIndex((s) => s.value === opt.value) === -1);
  }, [products, selected]);

  return (
    <Container
      style={{
        width,
      }}
    >
      {focus ? (
        <AppSelect
          loading={loading}
          ref={selectRef}
          allowClear
          onBlur={() => setFocus(false)}
          mode="multiple"
          showSearch
          filterOption={() => true}
          onSearch={handleSearch}
          onChange={onLocalChange}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          defaultValue={selected}
          labelInValue
          value={selected}
          showAction={['focus']}
          dropdownStyle={{
            minWidth: width + width / 2,
          }}
          dropdownRender={(items) => (
            <div onMouseDown={(a: any) => a.preventDefault()}>
              {selected.length > 0 ? (
                <Collapse ghost>
                  <SelectedList
                    header={<Text type="secondary">Selected products: {selected.length}</Text>}
                    key="1"
                  >
                    {selected.map((s) => (
                      <SelectedButton
                        width={width + width / 2}
                        key={s.value}
                        title={s.label as string}
                        onClick={() => handleDeselect(s)}
                      >
                        <SelectedText ellipsis>{s.label || s.value}</SelectedText>
                        <CheckIcon />
                      </SelectedButton>
                    ))}
                  </SelectedList>
                </Collapse>
              ) : (
                <NoSelectedContainer>
                  <MinusCircleOutlined />
                  <Text style={{ fontSize: 12 }} type="secondary">
                    No selected products
                  </Text>
                </NoSelectedContainer>
              )}
              <Divider
                style={{
                  marginTop: 4,
                  marginBottom: 4,
                }}
              />
              {items}
            </div>
          )}
          {...selectProps}
        >
          {availableOptions.map((opt) => (
            <AppSelect.Option style={{ fontSize: 12 }} key={`opt_${opt.value}`} value={opt.value}>
              {opt.label}
            </AppSelect.Option>
          ))}
        </AppSelect>
      ) : (
        <AppInput
          padding={selected.length > 0 && !focus ? 5 : 10}
          prefix={
            selected.length > 0 ? (
              <SelectContainer width={width - 33} onClick={() => setFocus(true)}>
                <AppTag color="blue" width={width - ((selected.length > 1 ? 20 : 0) + 47)}>
                  <Text ellipsis title={selected[0].label as string}>
                    {selected[0].label || selected[0].value}
                  </Text>
                </AppTag>
                {selected.length > 1 && (
                  <BadgeContainer type="secondary">+{selected.length - 1}</BadgeContainer>
                )}
              </SelectContainer>
            ) : undefined
          }
          readOnly
          placeholder={
            selected.length > 0 ? undefined : selectProps.placeholder?.toString() || 'Select Products'
          }
          suffix={
            !focus && selected.length > 0 ? (
              <CleanButton
                className="clean"
                onClick={() => {
                  setSelected([]);
                  onLocalChange([]);
                }}
              >
                <CloseCircleFilled style={{ fontSize: 12 }} />
              </CleanButton>
            ) : undefined
          }
          onClick={() => setFocus(true)}
        />
      )}
    </Container>
  );
};

export default ProductsListField;
