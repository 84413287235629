import { Form, Row } from 'antd';
import styled, { css } from 'styled-components';

interface FilterContentProps {
  hide?: boolean;
}

interface ContainerProps {
  margin?: boolean;
}

export const Container = styled(Form)<ContainerProps>`
  display: flex;
  flex-direction: column;
  background: black;
  margin: 0;
  ${(props) =>
    props.margin &&
    css`
      margin: 0 0 18px 0;
    `}
`;

export const SubmitFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: default;
`;

export const FilterContent = styled(Row)<FilterContentProps>`
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px;
  > * {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const FooterSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
