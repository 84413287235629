import styled from 'styled-components';
import { Input } from 'antd';

interface TagInputProps {
  error?: boolean;
}

export const TagsContent = styled.div`
  > span {
    cursor: pointer;
  }
  > div {
    margin-top: 6px;
    display: table-caption;
  }
`;

export const TagInput = styled(Input)<TagInputProps>`
  border-color: ${(props) => (props.error ? 'red' : undefined)};
  svg {
    color: red;
    display: ${(props) => (props.error ? 'block' : 'none')};
  }
`;
