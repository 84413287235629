/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { createCUDMutations } from '../helpers/GraphQLHelper';
import Fragment from './Fragment';

type MutationType = {
  mutation: DocumentNode;
};

interface IMutation {
  createProduct: MutationType;
  updateProduct: MutationType;
  deleteProducts: MutationType;
  createPublisher: MutationType;
  updatePublisher: MutationType;
  deletePublishers: MutationType;
  createCategory: MutationType;
  updateCategory: MutationType;
  deleteCategories: MutationType;
  createMerchant: MutationType;
  updateMerchant: MutationType;
  deleteMerchants: MutationType;
  createAffiliateNetwork: MutationType;
  updateAffiliateNetwork: MutationType;
  deleteAffiliateNetworks: MutationType;
  createUser: MutationType;
  updateUser: MutationType;
  deleteUsers: MutationType;
  createBrand: MutationType;
  updateBrand: MutationType;
  deleteBrands: MutationType;
  createCampaignRule: MutationType;
  updateCampaignRule: MutationType;
  deleteCampaignRules: MutationType;
  uploadFile: MutationType;
  duplicateCampaignRules: MutationType;
  archiveCampaignRules: MutationType;
  consolidateMerchants: MutationType;
  unarchiveCampaignRules: MutationType;
  loginWithEmail: MutationType;
  import: MutationType;
  fetchProductInfo: MutationType;
  updateProductOffers: MutationType;
  createWebsite: MutationType;
  updateWebsite: MutationType;
  deleteWebsite: MutationType;
  export: MutationType;
  getMutation(name: string): DocumentNode;
}

const { createProduct, updateProduct, deleteProducts } = createCUDMutations('Product');
const { createBrand, updateBrand, deleteBrands } = createCUDMutations('Brand');
const { createCategory, updateCategory, deleteCategories } = createCUDMutations('Category');

const Mutation = {
  createProduct,
  updateProduct,
  deleteProducts,
  ...createCUDMutations('Publisher'),
  createCategory,
  updateCategory,
  deleteCategories,
  ...createCUDMutations('Merchant'),
  ...createCUDMutations('AffiliateNetwork'),
  createBrand,
  updateBrand,
  deleteBrands,
  ...createCUDMutations('User'),
  ...createCUDMutations('CampaignRule'),
  getMutation: (name: string): DocumentNode => (Mutation as any)[name].mutation,
  duplicateCampaignRules: {
    mutation: gql`
      mutation duplicateCampaignRules($ids: [ID!]!) {
        duplicateCampaignRules(ids: $ids)
      }
    `,
  },
  archiveCampaignRules: {
    mutation: gql`
      mutation archiveCampaignRules($ids: [ID!]!) {
        archiveCampaignRules(ids: $ids)
      }
    `,
  },
  consolidateMerchants: {
    mutation: gql`
      mutation consolidateMerchants($ids: [ID!], $id: ID!) {
        consolidateMerchants(ids: $ids, id: $id)
      }
    `,
  },
  unarchiveCampaignRules: {
    mutation: gql`
      mutation unarchiveCampaignRules($ids: [ID!]!) {
        unarchiveCampaignRules(ids: $ids)
      }
    `,
  },
  loginWithEmail: {
    mutation: gql`
      mutation loginWithEmail($email: String!, $password: String!) {
        loginWithEmail(email: $email, password: $password) {
          user {
            ...UserFragment
          }
          accessToken
          expiresAt
        }
      }
      ${Fragment.UserFragment}
    `,
  },
  sendForgotPasswordEmail: {
    mutation: gql`
      mutation sendForgotPasswordEmail($email: String) {
        sendForgotPasswordEmail(email: $email)
      }
    `,
  },
  resetPassword: {
    mutation: gql`
      mutation resetPassword($data: InputResetPassword) {
        resetPassword(data: $data)
      }
    `,
  },
  uploadFile: {
    mutation: gql`
      mutation uploadFile($name: String) {
        uploadFile(field: $name) {
          id
          url
          name
          size
        }
      }
    `,
  },
  uploadImage: {
    mutation: gql`
      mutation uploadImage($name: String) {
        object: uploadImage(field: $name) {
          id
          url
          name
          size
        }
      }
    `,
  },
  import: {
    mutation: gql`
      mutation import($fileId: ID!) {
        import(fileId: $fileId)
      }
    `,
  },

  fetchProductInfo: {
    mutation: gql`
      mutation fetchProductInfo(
        $id: ID
        $asin: String
        $amazonUrl: String
        $upc: String
        $skus: [InputMerchantProductInfo!]
      ) {
        fetchProductInfo(id: $id, asin: $asin, amazonUrl: $amazonUrl, upc: $upc, skus: $skus) {
          product {
            ...ProductFragment
            image(resized: { width: 100, height: 100, mode: INSET }) {
              id
              url
            }
          }
          error {
            namespace
            name
            message
          }
        }
      }
      ${Fragment.ProductFragment}
    `,
  },

  updateProductOffers: {
    mutation: gql`
      mutation updateProductOffers($id: ID!) {
        updateProductOffers(id: $id) {
          id
          offersInfo {
            merchant {
              id
            }
            url
            price
            originalPrice
            default
            buttonText
          }
        }
      }
    `,
  },
  registerProduct: {
    mutation: gql`
      mutation ($data: InputRegisterProduct!) {
        object: registerProduct(data: $data) {
          ...ProductFragment
        }
      }
      ${Fragment.ProductFragment}
    `,
  },
  createWebsite: {
    mutation: gql`
      mutation createWebsite($publisherId: ID!, $websiteId: String!, $data: InputPublisherWebsite) {
        createWebsite(publisherId: $publisherId, websiteId: $websiteId, data: $data) {
          ...PublisherFragment
        }
      }
      ${Fragment.PublisherFragment}
    `,
  },
  updateWebsite: {
    mutation: gql`
      mutation updateWebsite($publisherId: ID!, $websiteId: String!, $data: InputPublisherWebsite) {
        updateWebsite(publisherId: $publisherId, websiteId: $websiteId, data: $data) {
          ...PublisherFragment
        }
      }
      ${Fragment.PublisherFragment}
    `,
  },
  deleteWebsites: {
    mutation: gql`
      mutation deleteWebsites($publisherId: ID!, $websiteIds: [String!]!) {
        deleteWebsites(publisherId: $publisherId, websiteIds: $websiteIds)
      }
    `,
  },
  export: {
    mutation: gql`
      mutation export($filters: ProductFilters!) {
        export(filters: $filters) {
          id
          token
          status
          reportUrl
        }
      }
    `,
  },
  exportProducts: {
    mutation: gql`
      mutation exportProducts(
        $filters: ProductFilters
        $order: ProductOrderField
        $direction: OrderDirection
      ) {
        exportProducts(filters: $filters, order: $order, direction: $direction) {
          id
          status
          reportUrl
          token
        }
      }
    `,
  },
  exportInventories: {
    mutation: gql`
      mutation inventoriesExport(
        $filters: InventoryFilters
        $columns: [InventoryColumns]
        $order: InventorySortField
        $direction: OrderDirection
      ) {
        inventoriesExport(filters: $filters, columns: $columns, order: $order, direction: $direction) {
          id
          status
          reportUrl
          token
        }
      }
    `,
  },
  generateDeepLink: {
    mutation: gql`
      mutation ($merchantId: ID!, $url: String!, $noCache: Boolean, $synchronous: Boolean, $site: String) {
        object: generateDeepLink(
          merchantId: $merchantId
          url: $url
          noCache: $noCache
          synchronous: $synchronous
          site: $site
        ) {
          url
          error {
            namespace
            name
            message
          }
        }
      }
    `,
  },
  generateAffiliateLinks: {
    mutation: gql`
      mutation generateAffiliateLinks($url: String!) {
        generateAffiliateLinks(url: $url) {
          landingPage
          url
          links {
            url
            merchant {
              id
              name
              affiliateNetwork {
                id
                name
              }
              performance {
                clicks
                commissions
                revenue
                conversionRate
              }
            }
          }
        }
      }
    `,
  },
};

export default Mutation;
