import React, { useCallback, useMemo, useState } from 'react';

import { Button, Tag } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { SlotIdContent } from './styles';
import { IInventoryDTO } from '../../../dtos/ArticleInventoryDTO';
import { IInventoryFilters } from '../../../graphql/Interface';
import { IMerchantDTO } from '../../../dtos/MerchantDTO';
import InventoryModal from '../InventoryModal';
import { IProductDTO } from '../../../dtos/ProductDTO';
import {
  LinkButtonContainer,
  LinkContainer,
  NumberColumn,
  SmallText,
} from '../../../pages/InventoryPage/styles';
import LinksModal from '../LinksModal';
import InfoPopover from '../../InfoPopover';
import { currencyFormat } from '../../../utils/constants';
import TransactionsModal from '../TransactionsModal';

interface SlotsModalProps {
  totalCount: number;
  visible?: boolean;
  onCancel(): void;
  filters: IInventoryFilters;
  groupingName?: string;
  maxLocalPerPage: number;
}

const SlotsModal: React.FC<SlotsModalProps> = ({ maxLocalPerPage, totalCount, filters, ...props }) => {
  const [selectedSlot, setSelectedSlot] = useState<IInventoryDTO>();
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState<IInventoryDTO>();

  const getMerchantName = useCallback((merchant?: IMerchantDTO) => {
    return merchant?.displayName || merchant?.name || 'N/A';
  }, []);

  const handleSelectArticles = useCallback((inventory: IInventoryDTO, counter: number) => {
    setTransactionsCount(counter);
    setSelectedArticle(inventory);
  }, []);

  const columns = useMemo(() => {
    const arrayColumns: ColumnsType<IInventoryDTO> = [];
    if (props.groupingName !== 'page') {
      arrayColumns.push({
        key: 'POST_TITLE',
        title: 'Article',
        dataIndex: 'title',
        ellipsis: true,
        width: 130,
        sorter:
          totalCount > maxLocalPerPage
            ? true
            : {
                compare: (a, b) => (a.title || '').localeCompare(b.title || ''),
                multiple: 1,
              },
        render: (title: string, inventory: IInventoryDTO) => (
          <InfoPopover
            key={inventory.postId}
            showImageNotFound
            image={inventory.image}
            items={[
              {
                url: inventory.permalink,
                value: inventory.title,
              },
              {
                value: inventory.category,
              },
            ]}
          >
            {title}
          </InfoPopover>
        ),
      });
    }
    arrayColumns.push({
      title: 'Slot ID',
      dataIndex: 'slotId',
      key: 'SLOT_ID',
      width: 100,
      sorter: {
        compare: (a, b) => a.slotId - b.slotId,
        multiple: 1,
      },
      render: (slotId: number, inventory: IInventoryDTO) => (
        <SlotIdContent>
          <Tag
            color="blue"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setSelectedSlot(inventory)}
          >
            {slotId}
          </Tag>
        </SlotIdContent>
      ),
    });
    if (props.groupingName !== 'merchant') {
      arrayColumns.push({
        title: 'Merchant',
        dataIndex: 'merchant',
        width: 150,
        sorter:
          totalCount > maxLocalPerPage
            ? true
            : {
                compare: (a, b) => (a.merchant?.name || '').localeCompare(b.merchant?.name || ''),
                multiple: 1,
              },
        render: (merchant: IMerchantDTO) => (
          <InfoPopover
            image={merchant?.logo?.url}
            showImageNotFound
            width={150}
            items={[
              {
                value: getMerchantName(merchant),
              },
              {
                value: merchant?.id,
              },
              {
                value: merchant?.affiliateNetwork?.name || undefined,
              },
              {
                value: merchant?.website || undefined,
                url: merchant?.website || undefined,
              },
              {
                render: merchant?.disabled === true ? <Tag color="volcano">Disabled</Tag> : undefined,
              },
            ]}
          >
            {getMerchantName(merchant)}
          </InfoPopover>
        ),
      });
    }
    if (props.groupingName !== 'product') {
      arrayColumns.push({
        title: 'Product',
        dataIndex: 'product',
        key: 'PRODUCT_NAME',
        width: 180,
        ellipsis: true,
        sorter:
          totalCount > maxLocalPerPage
            ? true
            : {
                compare: (a, b) => (a.product.name || '').localeCompare(b.product.name || ''),
                multiple: 1,
              },
        render: (product: IProductDTO) => (
          <>
            {product?.name ? (
              <InfoPopover
                key={product.id}
                showImageNotFound
                width={180}
                image={product?.image?.url}
                items={[
                  {
                    value: product.name,
                  },
                  {
                    value: product.id,
                  },
                  {
                    value: product.categoryName,
                  },
                ]}
              >
                {product.name}
              </InfoPopover>
            ) : (
              <LinkContainer>
                <SmallText type="secondary">N/A</SmallText>
                <SmallText type="secondary">{product?.id}</SmallText>
              </LinkContainer>
            )}
          </>
        ),
      });
    }
    arrayColumns.push({
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'CLICKS',
      width: 100,
      showSorterTooltip: false,
      sorter:
        totalCount > maxLocalPerPage
          ? true
          : {
              compare: (a, b) => a.clicks - b.clicks,
              multiple: 1,
            },
      render: (clicks: number) => (
        <NumberColumn>
          <SmallText type="secondary">{clicks}</SmallText>
        </NumberColumn>
      ),
    });
    arrayColumns.push({
      title: 'Transactions',
      key: 'TRANSACTIONS',
      dataIndex: 'quantity',
      width: 105,
      sorter:
        totalCount > maxLocalPerPage
          ? true
          : {
              compare: (a, b) => a.quantity - b.quantity,
            },
      defaultSortOrder: 'descend',
      render: (transactions: number, inventory: IInventoryDTO) => (
        <LinkButtonContainer>
          <Button
            type="ghost"
            shape="round"
            size="small"
            title={`${transactions === 0 ? 'No' : transactions} transaction${transactions !== 1 && 's'}`}
            danger={transactions === 0}
            disabled={transactions === 0}
            onClick={() => handleSelectArticles(inventory, transactions)}
            style={{
              width: 80,
              fontSize: 12,
            }}
          >
            {transactions}
          </Button>
        </LinkButtonContainer>
      ),
    });
    arrayColumns.push({
      title: 'Commissions',
      key: 'COMMISSIONS',
      dataIndex: 'commissions',
      width: 95,
      sorter:
        totalCount > maxLocalPerPage
          ? true
          : {
              compare: (a, b) => a.revenue - b.revenue,
            },
      render: (commissions: number) => (
        <NumberColumn>
          <SmallText type="secondary">{currencyFormat(commissions)}</SmallText>
        </NumberColumn>
      ),
    });
    return arrayColumns;
  }, [getMerchantName, handleSelectArticles, maxLocalPerPage, props.groupingName, totalCount]);

  return (
    <>
      <LinksModal
        visible={!!selectedSlot}
        filters={{
          ...filters,
          slotId: selectedSlot?.slotId,
          websiteId: selectedSlot?.siteCode,
          postId: selectedSlot?.postId,
        }}
        onCancel={() => setSelectedSlot(undefined)}
      />
      <TransactionsModal
        totalCount={transactionsCount}
        onCancel={() => setSelectedArticle(undefined)}
        filters={{
          ...filters,
          slotId: selectedArticle?.slotId,
          websiteId: selectedArticle?.siteCode,
          postId: selectedArticle?.postId,
        }}
        visible={!!selectedArticle}
        maxLocalPerPage={maxLocalPerPage}
        groupingName="Slot"
      />
      <InventoryModal
        filters={filters}
        dbColumns={['ARTICLE', 'MERCHANT', 'PRODUCT', 'SLOT_ID']}
        columns={columns}
        {...props}
        name="Slots"
        width={1000}
        totalCount={totalCount}
        maxLocalPerPage={maxLocalPerPage}
        localFilter={(iInventory) => iInventory.product.name}
      />
    </>
  );
};

export default SlotsModal;
