import React, { useCallback } from 'react';

import { Checkbox } from 'antd';

interface CheckFieldProps {
  name?: string;
  disabled?: boolean;
  defaultValue?: any;
  onChange?(val: any): void;
  onFilterChange?(val: any): void;
}

const CheckField: React.FC<CheckFieldProps> = ({
  name,
  defaultValue,
  disabled,
  onChange,
  onFilterChange,
}) => {
  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.checked);
      }
      if (onFilterChange) {
        onFilterChange(e.target.checked);
      }
    },
    [onChange, onFilterChange],
  );

  return (
    <Checkbox
      disabled={disabled}
      onChange={handleChange}
      defaultChecked={defaultValue}
    >
      {name}
    </Checkbox>
  );
};

export default CheckField;
