import React, { useEffect, useMemo } from 'react';

import { Image, Typography, Tag, Divider, Spin } from 'antd';

import { useLazyQuery } from '@apollo/client';
import {
  LoadingContainer,
  Content,
  Header,
  HeaderItem,
  ProductContainer,
  Section,
  ImageContainer,
  SectionContent,
} from './styles';
import CCDrawer from '../../CCDrawer';
import { currencyFormat, dateFormat, IMAGE_NOT_FOUND, percentFormat } from '../../../utils/constants';
import Query from '../../../graphql/Query';
import { IInventoryData, IInventoryFilters, IInventoryVars } from '../../../graphql/Interface';

const { Text, Link } = Typography;

interface LinksModalProps {
  filters: IInventoryFilters;
  onCancel(): void;
  visible?: boolean;
}

const LinksModal: React.FC<LinksModalProps> = ({ onCancel, filters, visible }) => {
  const [loadLinkInfo, { data, loading }] = useLazyQuery<IInventoryData, IInventoryVars>(
    Query.linkInventory.query,
  );

  useEffect(() => {
    if (visible) {
      loadLinkInfo({
        variables: {
          filters,
          columns: ['ARTICLE', 'PRODUCT', 'MERCHANT', 'SLOT_ID', 'SITE', 'PLACEMENT_TYPE'],
          limit: 1,
        },
      });
    }
  }, [filters, loadLinkInfo, visible]);

  const linkFetched = useMemo(() => {
    const nodes = data?.inventories.edges.map((a) => a.node);
    return nodes && nodes[0];
  }, [data]);

  return (
    <CCDrawer onClose={onCancel} zIndex={199} title="Link Details" visible={visible}>
      {loading && (
        <LoadingContainer>
          <Spin />
        </LoadingContainer>
      )}
      {!loading && linkFetched && (
        <Content>
          <Header>
            <HeaderItem>
              <Text type="secondary">Post ID</Text>
              <Text>{linkFetched.postId}</Text>
            </HeaderItem>
            <HeaderItem>
              <Text type="secondary">Slot</Text>
              <Tag color="blue" style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
                {linkFetched.slotId}
              </Tag>
            </HeaderItem>
            <HeaderItem>
              <Text type="secondary">Website</Text>
              <Text>
                {linkFetched.site} ({linkFetched.siteCode})
              </Text>
            </HeaderItem>
            <HeaderItem>
              <Text type="secondary">Placement</Text>
              <Text>{linkFetched.placement}</Text>
            </HeaderItem>
          </Header>
          <Header
            style={{
              marginTop: 6,
            }}
          >
            <HeaderItem>
              <Text type="secondary">Clicks</Text>
              <Tag color="blue" style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
                {linkFetched.clicks}
              </Tag>
            </HeaderItem>
            <HeaderItem>
              <Text type="secondary">Transactions</Text>
              <Tag color="blue" style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
                {linkFetched.quantity}
              </Tag>
            </HeaderItem>
            <HeaderItem>
              <Text type="secondary">Commissions</Text>
              <Tag color="blue" style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
                {currencyFormat(linkFetched.commissions)}
              </Tag>
            </HeaderItem>
            <HeaderItem>
              <Text type="secondary">Conversion Rate</Text>
              <Text>{percentFormat(linkFetched.conversionRate)}</Text>
            </HeaderItem>
          </Header>
          <Divider orientation="left">Article</Divider>
          <Section>
            <ImageContainer>
              <Image src={linkFetched.image || IMAGE_NOT_FOUND} preview={false} fallback={IMAGE_NOT_FOUND} />
            </ImageContainer>
            <SectionContent>
              <Link href={linkFetched.permalink} target="_blank" title={linkFetched.permalink}>
                {linkFetched.title}
              </Link>
              <Text type="secondary" style={{ marginBottom: 6 }}>
                Category: <Text>{linkFetched.category}</Text>
              </Text>
              <Header>
                <HeaderItem
                  style={{
                    width: 250,
                  }}
                >
                  <Text type="secondary">Published At</Text>
                  <Text>{dateFormat(linkFetched.createdAt)}</Text>
                </HeaderItem>
                <HeaderItem>
                  <Text type="secondary">Last Modified</Text>
                  <Text>{dateFormat(linkFetched.modifiedAt)}</Text>
                </HeaderItem>
              </Header>
            </SectionContent>
          </Section>
          <Divider orientation="left">Merchant</Divider>
          <Section>
            <ImageContainer>
              <Image
                src={linkFetched.merchant?.logo?.url || IMAGE_NOT_FOUND}
                preview={false}
                fallback={IMAGE_NOT_FOUND}
              />
            </ImageContainer>
            <SectionContent>
              {linkFetched.merchant ? (
                <>
                  <Text>{linkFetched.merchant.displayName || linkFetched.merchant.name}</Text>
                  <Link href={linkFetched.merchant.website} target="_blank">
                    {linkFetched.merchant.website}
                  </Link>
                  {linkFetched.merchant.affiliateNetwork && (
                    <Text type="secondary">{linkFetched.merchant.affiliateNetwork.name}</Text>
                  )}
                  {linkFetched.merchant.disabled && <Tag color="volcano">Disabled</Tag>}
                </>
              ) : (
                <>
                  <Text>N/A</Text>
                  <Text type="secondary">Not a CC Merchant</Text>
                </>
              )}
            </SectionContent>
          </Section>
          <Divider orientation="left">Product</Divider>
          <Section>
            <ImageContainer>
              <Image
                src={linkFetched.product?.image?.url || IMAGE_NOT_FOUND}
                preview={false}
                fallback={IMAGE_NOT_FOUND}
              />
            </ImageContainer>
            <SectionContent>
              {linkFetched.product?.name ? (
                <ProductContainer>
                  <Text>{linkFetched.product.name}</Text>
                  <Text type="secondary">{linkFetched.product.categoryName}</Text>
                </ProductContainer>
              ) : (
                <ProductContainer>
                  <Text>N/A</Text>
                  <Text type="secondary">{linkFetched.product?.id}</Text>
                  <Text type="secondary">Not a CC Product</Text>
                </ProductContainer>
              )}
            </SectionContent>
          </Section>
        </Content>
      )}
    </CCDrawer>
  );
};

export default LinksModal;
