import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Select, Input, message, Row } from 'antd';
import MarkdownIt from "markdown-it";
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/client';
import CustomMdEditorWrapper from '../../components/Fields/MdEditor'
import { Container, FooterForm, ProductItem, ProductItemContent, ProductDivider } from './styles';
import { IMAGE_TYPES } from '../../utils/constants';
import { asyncUseMutation } from '../../services/GraphQLService';
import ImageUploadField from '../../components/Fields/ImageUploadField';
import MerchantField from '../../components/Fields/MerchantField';
import ProductsField from '../../components/Fields/ProductsField';
import Mutation from '../../graphql/Mutation';
import PlacementField from '../../components/Fields/PlacementField';

const PLACEMENT_TYPES = [
  {
    key: 'deals-compact',
    value: 'Compact',
  },
  {
    key: 'deals-partner',
    value: 'Partner Offer',
  },
];

interface ISelect {
  value: string;
  label: any;
}

interface CreateDealDeeplinkFormProps {
  type: string;
  title?: string;
  merchant: ISelect;
  url: string;
  customKey?: string;
  customValue?: string;
  subcopy?: string;
  badge?: string;
  productDescription?: string;
  promoCodeBlurb?: string;
  product?: ISelect;
  placementId?: ISelect;
  placement?: string;
  image?: any;
}

const CreateDealDeeplinkPage: React.FC = () => {
  const location = useLocation();
  const debug = new URLSearchParams(location.search).get('debug') === 'true';
  const [form] = Form.useForm();
  const [deeplinkError, setDeeplinkError] = useState<string>();
  const [deepLink, setDeeplink] = useState<string>();
  const [selectedMerchantId, setSelectedMerchantId] = useState<string>();
  const [offerUrl, setOfferUrl] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [productName, setProductName] = useState<string>();
  const [placementType, setPlacementType] = useState<string>('deals-compact');
  const [uploading, setUploading] = useState(false);
  const [upload] = useMutation(Mutation.uploadFile.mutation);
  const [create, { loading }] = useMutation(Mutation.generateDeepLink.mutation);

  const handleUpperSubmit = useCallback(
    (event) => {
      const { action } = event.data;
      if (action === 'createDealLink') {
        form.submit();
      }
    },
    [form],
  );

  const asyncUpload = useCallback(
    async (fieldName: string, file: File) => {
      const variables = {
        operation: 'uploadFile',
        field: fieldName,
        [fieldName]: file,
      };
      setUploading(true);
      try {
        const uploaded = await asyncUseMutation(upload, { variables });
        return uploaded.uploadFile;
      } finally {
        setUploading(false);
      }
    },
    [upload],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!debug) {
      window.addEventListener('message', handleUpperSubmit);
      return () => {
        window.removeEventListener('message', handleUpperSubmit);
      };
    }
  }, [debug, handleUpperSubmit]);

  const handleSubmit = useCallback(
    async (val: CreateDealDeeplinkFormProps) => {
      const { url, merchant, product, placementId, image, ...rest } = val;
      let savedImageUrl;
      if (image) {
        const savedImage = await asyncUpload('image', image);
        savedImageUrl = savedImage.url;
      }

      const md = new MarkdownIt();

      if (rest.productDescription) {
        rest.productDescription = md.render(rest.productDescription);
      }

      if (rest.promoCodeBlurb) {
        rest.promoCodeBlurb = md.renderInline(rest.promoCodeBlurb);
      }

      const wpObject = JSON.parse(
        JSON.stringify({
          offerUrl: url,
          deepLink,
          placementId: placementId?.value,
          merchantId: merchant.value,
          productId: product?.value,
          productName,
          imageUrl: savedImageUrl || imageUrl,
          ...rest,
        }),
      );
      const wpMessage = {
        action: 'createDealLink',
        data: wpObject,
      };

      if (!debug) {
        window.parent.postMessage(wpMessage, '*');
      } else {
        // eslint-disable-next-line no-console
        console.log(wpMessage);
        message.info('The link has been created');
      }
    },
    [asyncUpload, debug, deepLink, imageUrl, productName],
  );

  const handleCheckDeeplink = useCallback(
    async (merchantId, url) => {
      await form.validateFields(['merchant', 'url']);
      const createdDeeplink = await create({
        variables: {
          noCache: true,
          synchronous: true,
          merchantId,
          url,
        },
      });
      const { url: iUrl, error } = createdDeeplink.data.object;
      setDeeplink(iUrl);
      setDeeplinkError(error?.message?.split('.')[0]);
    },
    [create, form],
  );

  useEffect(() => {
    setDeeplinkError(undefined);
    if (!!selectedMerchantId && !!offerUrl) {
      handleCheckDeeplink(selectedMerchantId, offerUrl);
    }
  }, [handleCheckDeeplink, offerUrl, selectedMerchantId]);

  return (
    <Container>
      <Form
        onFinish={handleSubmit}
        className="thin-form"
        form={form}
        labelAlign="right"
        labelCol={{ span: 7 }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="type"
              label="Placement Type"
              initialValue="deals-compact"
              rules={[{ required: true, message: 'Placement Type is required' }]}
            >
              <Select
                placeholder="Placement Type"
                style={{
                  width: '100%',
                  height: 32,
                }}
                onSelect={(value) => setPlacementType(String(value))}
              >
                {PLACEMENT_TYPES.map(({ key, value }) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="placementId" label="Placement ID">
              <PlacementField placeholder="Select Placement" />
            </Form.Item>
            <Form.Item
              name="merchant"
              label="Merchant"
              rules={[{ required: true, message: 'Merchant is required' }]}
            >
              <MerchantField
                active
                placeholder="Select Merchant"
                onChange={(item) => setSelectedMerchantId(item?.value)}
              />
            </Form.Item>
            <Form.Item
              name="url"
              label="Offer Url"
              hasFeedback={!!deeplinkError}
              help={deeplinkError}
              validateStatus={deeplinkError ? 'warning' : undefined}
              rules={[
                { required: true, message: 'Offer URL is required' },
                { type: 'url', message: 'Invalid URL' },
              ]}
            >
              <Input onBlur={(event) => setOfferUrl(event.target.value)} />
            </Form.Item>
            <Form.Item label="Custom URL Param">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Form.Item name="customKey" style={{ maxWidth: 110, marginBottom: 0 }}>
                  <Input placeholder="Key" />
                </Form.Item>
                <Form.Item name="customValue" style={{ width: 320, marginLeft: 12, marginBottom: 0 }}>
                  <Input placeholder="value" />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="Override Title"
              name="title"
              rules={[{ required: !form.getFieldValue('product'), message: 'Title is required' }]}
            >
              <Input placeholder="Override Title" />
            </Form.Item>
            {form.getFieldValue('type') === 'deals-partner' && (
              <Form.Item label="Product Description"
                name="productDescription"
              >
                <CustomMdEditorWrapper />
              </Form.Item>
            )}
            {form.getFieldValue('type') === 'deals-partner' && (
              <Form.Item label="Promo Code Blurb"
                         name="promoCodeBlurb"
              >
                <CustomMdEditorWrapper />
              </Form.Item>
            )}
            <Form.Item label="Sub-Copy" name="subcopy">
              <Input placeholder="Sub-Copy" maxLength={85} />
            </Form.Item>
            <Form.Item name="badge" label="Badge">
              <Input placeholder="Badge" maxLength={20} />
            </Form.Item>
            <Row>
              <Col span={6}>
                <Form.Item name="image" style={{ marginTop: 12 }}
                  rules={[{ required: !form.getFieldValue('product') && placementType !== 'deals-bullet',
                    message: 'Image is required' }]}
                >
                  <ImageUploadField
                    imageUrl={imageUrl}
                    resetImage
                    text="Change Image"
                    loading={uploading}
                    accept={IMAGE_TYPES}
                    max={5}
                  />
                </Form.Item>
              </Col>
              <Col span={17} offset={1}>
                <ProductDivider
                  style={{
                    marginBottom: 10,
                    marginTop: 6,
                  }}
                  orientation="left"
                >
                  Product Form
                </ProductDivider>
                <Form.Item name="product">
                  <ProductsField
                    width={402}
                    onChange={(_val, product) => {
                      form.setFieldsValue({
                        image: undefined,
                      });
                      setImageUrl(product?.image?.url || undefined);
                      setProductName(product?.name || '');
                    }}
                    dropdownStyle={{
                      maxWidth: 402,
                      minWidth: 402,
                    }}
                    renderItem={(product) => (
                      <ProductItem>
                        <strong title={product.name}>{product.name}</strong>
                        <span>{product.brand?.name}</span>
                        {product.offersInfo && product.offersInfo.length > 0 ? (
                          <ProductItemContent>
                            <aside>
                              {product.offersInfo.length} Offer{product.offersInfo.length > 1 ? 's' : ''}:
                            </aside>
                            {product.offersInfo.map((offerInfo, idx) => (
                              <span key={idx}>
                                {offerInfo.merchant?.name}: ${offerInfo.price}
                                {product.offersInfo && idx + 1 < product.offersInfo.length && ','}
                              </span>
                            ))}
                          </ProductItemContent>
                        ) : (
                          <aside>No Offers</aside>
                        )}
                      </ProductItem>
                    )}
                  />
                </Form.Item>
                <Form.Item name="price">
                  <Input placeholder="Price" />
                </Form.Item>
                <Form.Item name="originalPrice">
                  <Input placeholder="Original Price" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <FooterForm debug={debug}>
          <Button type="primary" onClick={() => form.submit()} loading={loading}>
            Create
          </Button>
        </FooterForm>
      </Form>
    </Container>
  );
};

export default CreateDealDeeplinkPage;
