import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f3f3f4;

  width: 100vw;
  height: 100vh;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const Content = styled.div`
  width: 390px;
  margin: 0 auto;
  padding: 30px 40px;
  background: #eeeeee;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #bfbfbf;
`;
