import React, { useCallback, useState } from 'react';
import { Form, Card, Button, notification } from 'antd';
import { CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Link from 'antd/lib/typography/Link';

import Search from 'antd/lib/input/Search';
import { useMutation } from '@apollo/client';
import { Header, LinksContent, SummaryPanel, SummaryResult, AffiliateRow, AffiliateRowLabel } from './styles';
import { IGeneratedLinks } from '../../dtos/LinkDTO';
import { copyToClipboard } from '../../services/utils';
import { ROUTES } from '../../utils/constants';
import Mutation from '../../graphql/Mutation';

interface LinkGeneratorRowProps {
  labelWidth?: number;
  label: string;
  value: React.ReactElement | string;
}

const LinkGeneratorPage: React.FC = () => {
  const [form] = Form.useForm();
  const [generatedLinks, setGeneratedLinks] = useState<IGeneratedLinks>();

  const [generateLinks, { loading }] = useMutation(Mutation.generateAffiliateLinks.mutation, {
    onCompleted: (data) => {
      setGeneratedLinks(data.generateAffiliateLinks);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  });

  const handleSearch = useCallback(
    ({ url }) => {
      generateLinks({
        variables: {
          url,
        },
      });
    },
    [generateLinks],
  );

  const LinkGeneratorRow: React.FC<LinkGeneratorRowProps> = ({ label, value, labelWidth = 170 }) => {
    return (
      <AffiliateRow>
        <AffiliateRowLabel width={labelWidth}>{label}</AffiliateRowLabel>
        <span>{value}</span>
      </AffiliateRow>
    );
  };

  return (
    <>
      <Header>
        <Form form={form} onFinish={handleSearch}>
          <Form.Item name="url" rules={[{ required: true }]}>
            <Search
              placeholder="Type the product page URL here"
              allowClear
              enterButton="Generate"
              size="large"
              loading={loading}
              type="url"
              required
              onSearch={() => form.submit()}
            />
          </Form.Item>
        </Form>
      </Header>
      {generatedLinks && (
        <LinksContent>
          <SummaryPanel>
            <LinkGeneratorRow
              labelWidth={110}
              label="Original URL"
              value={
                <Link ellipsis style={{ maxWidth: 800 }} href={generatedLinks.url} target="_blank">
                  {generatedLinks.url}
                </Link>
              }
            />
            <LinkGeneratorRow
              labelWidth={110}
              label="Landing Page"
              value={
                <Link ellipsis style={{ maxWidth: 800 }} href={generatedLinks.landingPage} target="_blank">
                  {generatedLinks.landingPage}
                </Link>
              }
            />
            <br />
            <SummaryResult empty={generatedLinks.links.length === 0}>
              {generatedLinks.links.length === 0 ? (
                <>
                  <CloseCircleOutlined />
                  <Text>No affiliate links for this URL</Text>
                </>
              ) : (
                <>
                  <CheckCircleOutlined />
                  <Text>
                    {generatedLinks.links.length} link{generatedLinks.links.length > 1 ? 's' : ''}:
                  </Text>
                </>
              )}
            </SummaryResult>
          </SummaryPanel>
          {generatedLinks.links.map((gl, idx) => (
            <Card
              key={idx}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                background: '#FAFBFC',
              }}
              style={{
                marginBottom: 16,
              }}
            >
              <LinkGeneratorRow
                label="Affiliate Link"
                value={
                  <span>
                    <Link href={gl.url} ellipsis style={{ maxWidth: 800 }} target="_blank">
                      {gl.url}
                    </Link>
                    <br />
                    <Button
                      onClick={() => {
                        copyToClipboard(gl.url);
                        notification.info({
                          message: 'Affiliate link has been copied to clipboard',
                        });
                      }}
                      style={{ marginBottom: 10 }}
                      type="default"
                      size="small"
                      icon={<CopyOutlined />}
                    >
                      Copy to clipboard
                    </Button>
                  </span>
                }
              />
              <LinkGeneratorRow
                label="Merchant"
                value={
                  <Link
                    href={`${window.location.origin}${ROUTES.merchants}?id=${gl.merchant.id}`}
                    target="_blank"
                  >
                    <strong>{gl.merchant.name} </strong>
                    <small>({gl.merchant.affiliateNetwork?.name})</small>
                  </Link>
                }
              />
            </Card>
          ))}
        </LinksContent>
      )}
    </>
  );
};

export default LinkGeneratorPage;
