import { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function getParam(queryString: string, name: string) {
  const params = new URLSearchParams(queryString);
  return params.get(name);
}

function updateQueryString(queryString: string, name: string, value: string) {
  const params = new URLSearchParams(queryString);
  params.set(name, value);
  return params;
}

export function useQueryStringParam(
  paramName: string,
  initialState?: string,
): [string | undefined, (value: string) => void] {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState(getParam(location.search, paramName) ?? initialState);

  const updateState = useCallback(
    (value) => {
      setState(value);
      const updatedParams = updateQueryString(location.search, paramName, value);
      history.push({ search: updatedParams.toString() });
    },
    [paramName, history, location.search],
  );

  return [state, updateState];
}
