import React, { useEffect, useMemo } from 'react';

import { Checkbox, Form, Input } from 'antd';

import { useLazyQuery } from '@apollo/client';

import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import { IPublisherDTO } from '../../../dtos/PublisherDTO';

import { PublisherIDLabel } from './styles';

const PublisherForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const [loadPublisher, { data }] = useLazyQuery(Query.publisher.query);

  const publisher = useMemo(() => {
    if (!entity || !data) {
      return undefined;
    }
    return data.publisher as IPublisherDTO;
  }, [entity, data]);

  useEffect(() => {
    if (!publisher || !form) {
      return;
    }
    form.setFieldsValue(publisher);
  }, [form, publisher]);

  useEffect(() => {
    if (entity) {
      loadPublisher({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadPublisher]);

  return (
    <Form form={form} labelAlign="right" labelCol={{ span: 5 }} {...props}>
      <Form.Item name="publisherId" label="Publisher ID" rules={[{ required: true }]}>
        {publisher ? (
          <PublisherIDLabel>{publisher.publisherId}</PublisherIDLabel>
        ) : (
          <Input placeholder="Publisher ID" />
        )}
      </Form.Item>

      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="Publisher name" autoComplete="off" />
      </Form.Item>

      <Form.Item name="url" label="URL">
        <Input placeholder="Publisher's Web Site URL" />
      </Form.Item>

      <Form.Item name="active" label="Active" valuePropName="checked" initialValue={false}>
        <Checkbox />
      </Form.Item>
    </Form>
  );
};

export default PublisherForm;
