import React from 'react';

import { AuthProvider } from './auth';
import { LayoutProvider } from './layout';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LayoutProvider>
      <div className="App">{children}</div>
    </LayoutProvider>
  </AuthProvider>
);

export default AppProvider;
