import styled from 'styled-components';

import { Button } from 'antd';

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16;
  margin: 0 0 20px 0;
  text-align: center;
  color: #323440;
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;
