/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IUserDTO } from '../../../dtos/UserDTO';
import logger from '../../LoggerService';

function initialize(): void {
  logger.info('initialize console logger analytics', null);
}

function identify(user: IUserDTO): void {
  logger.info('identify user', { email: user.email });
}

function trackEvent(eventName: string, data?: any): void {
  logger.info(`Tracking event ${eventName}`, data);
}

function handleError(error: string): void {
  logger.error(error, null);
}

export default { initialize, trackEvent, identify, handleError };
