import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { message } from 'antd';
import { IQueryPagination } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';
import { IPublisherDTO } from '../../../dtos/PublisherDTO';

const DT_PUBLISHER_ID = 'dt';

const WebsiteField: React.FC<IGenericSelectFieldProps<any>> = (params) => {
  const { loading, data } = useQuery<IQueryPagination<IPublisherDTO>>(Query.publishers.query);

  const websites = useMemo(() => {
    if (data) {
      const dtPublisher = data.object.edges.find((f) => f.node.publisherId === DT_PUBLISHER_ID);
      if (!dtPublisher) {
        message.error(`No 'dt' publisher found`);
        return [];
      }
      return dtPublisher.node.websites.map((ws) => ({
        value: ws.websiteId,
        label: ws.name,
      }));
    }
    return [];
  }, [data]);

  return <SelectField name="Website" loading={loading} data={websites} {...params} />;
};

export default WebsiteField;
