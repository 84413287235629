import styled from 'styled-components';

interface ActionContentProps {
  visible?: boolean;
  color?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ActionsContainer = styled.div``;

export const ActionsPanel = styled.div`
  left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ActionContent = styled.div<ActionContentProps>`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color || '#969696'}90;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  transform-origin: left;
  transform: scaleX(${(props) => (props.visible ? 1 : 0)});
  :hover {
    background-color: ${(props) => props.color || '#969696'};
  }
  margin-top: 1px;
  &:first-child {
    margin-top: 0px;
  }
`;

export const TitlePopover = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PaginationContent = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
