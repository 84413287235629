import React, { memo, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

const RolesField: React.FC<IGenericSelectFieldProps> = (params) => {
  const { loading, data } = useQuery(Query.roles.query, {
    variables: {
      filters: {},
    },
  });

  const iRoles = useMemo(() => {
    if (data) {
      return data.object;
    }
    return [];
  }, [data]);

  return <SelectField showSearch={false} name="Role" loading={loading} data={iRoles} {...params} />;
};

export default memo(RolesField);
