import styled from 'styled-components';

export const LinkItem = styled.div``;

export const ImageContainer = styled.div`
  width: 60px;
  div: {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const MerchantContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ClicksTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-left: 8px;
  }
`;

export const SlotIdContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
