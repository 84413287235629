/* eslint no-extra-boolean-cast: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Select, Input, message, Row } from 'antd';
import { useLocation } from 'react-router';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import { Container, FooterForm, ProductItem, ProductItemContent } from './styles';
import MerchantField from '../../components/Fields/MerchantField';
import ProductsField from '../../components/Fields/ProductsField';
import { IProductDTO } from '../../dtos/ProductDTO';
import Mutation from '../../graphql/Mutation';
import Query from '../../graphql/Query';

const { TextArea } = Input;

const PLACEMENT_TYPES = [
  {
    key: 'textlink',
    value: 'Text Link',
  },
  {
    key: 'button',
    value: 'Button',
  },
  {
    key: 'widget',
    value: 'Widget',
  },
];

const ALIGNMENT_TYPES = [
  {
    key: 'default',
    value: 'Default',
  },
  {
    key: 'left',
    value: 'Left',
  },
  {
    key: 'right',
    value: 'Right',
  },
];

interface ISelect {
  value: string;
  label: any;
}

interface CreateDeeplinkFormProps {
  placementType: string;
  title?: string;
  merchant: ISelect;
  url: string;
  customKey?: string;
  customValue?: string;
  buttonText?: string;
  linkDescription?: string;
  alignment: string;
  product?: ISelect;
}

const CreateDeeplinkPage: React.FC = () => {
  const location = useLocation();
  const debug = new URLSearchParams(location.search).get('debug') === 'true';
  const [form] = Form.useForm();
  const [placementType, setPlacementType] = useState<string>();
  const [deeplinkError, setDeeplinkError] = useState<string>();
  const [deepLink, setDeeplink] = useState<string>();
  const [selectedMerchantId, setSelectedMerchantId] = useState<string>();
  const [offerUrl, setOfferUrl] = useState<string>();
  const [create, { loading }] = useMutation(Mutation.generateDeepLink.mutation);
  const [loadMerchant, { data: merchantData }] = useLazyQuery(Query.merchant.query);
  const [loadProduct, { data: productData }] = useLazyQuery(Query.product.query);

  const handleUpperSubmit = useCallback(
    (event) => {
      const { action } = event.data;
      if (action === 'createLink') {
        form.submit();
      }
    },
    [form],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!debug) {
      window.addEventListener('message', handleUpperSubmit);
      return () => {
        window.removeEventListener('message', handleUpperSubmit);
      };
    }
  }, [debug, handleUpperSubmit]);

  useEffect(() => {
    if (!merchantData) {
      return;
    }

    const { merchant: { id: merchantId, name: merchantName } } = merchantData;

    form.setFieldsValue({
      merchant: { value: merchantId, label: merchantName }
    });

  }, [merchantData]);

  useEffect(() => {
    if (!productData) {
      return;
    }

    const { product: { id: productId, name: productName } } = productData;

    form.setFieldsValue({
      product: { value: productId, label: productName }
    });
  }, [productData]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const urlParameters = new URLSearchParams(location.search);
    const buttonText = urlParameters.get('buttonText') || '';
    const url = urlParameters.get('url') || '';
    const decodedUrl = decodeURI(url);
    const alignment = urlParameters.get('alignment') || '';
    const placementTypeParameter = urlParameters.get('placementType') || '';
    const customKey = urlParameters.get('customKey') || '';
    const customValue = urlParameters.get('customValue') || '';
    const productId = urlParameters.get('productId') || '';
    const merchantId = urlParameters.get('merchantId') || '';
    const title = urlParameters.get('title') || '';
    const linkDescription = urlParameters.get('linkDescription') || '';

    form.setFieldsValue({
      buttonText,
      url: decodedUrl,
      customKey,
      customValue,
      title,
      linkDescription,
    })

    if (alignment) {
      form.setFieldsValue({
        alignment,
      })
    }

    if (placementTypeParameter) {
      form.setFieldsValue({
        placementType: placementTypeParameter,
      })
    }

    if (productId) {
      loadProduct({
        variables: {
          id: productId,
        }
      });
    }

    if (merchantId) {
      loadMerchant({
        variables: {
          id: merchantId,
        }
      });
    }

    setPlacementType(placementTypeParameter);
  }, []);

  const handleSubmit = useCallback(
    async (val: CreateDeeplinkFormProps) => {
      const { url, merchant, product, buttonText, ...rest } = val;

      const wpObject = JSON.parse(
        JSON.stringify({
          offerUrl: url,
          deepLink,
          merchantId: merchant.value,
          productId: product?.value,
          cta: buttonText,
          ...rest,
        }),
      );

      if (!debug) {
        window.parent.postMessage(
          {
            action: 'createLink',
            data: wpObject,
          },
          '*',
        );
      } else {
        message.info('The link has been created');
      }
    },
    [debug, deepLink],
  );

  const handleCheckDeeplink = useCallback(
    async (merchantId, url) => {
      await form.validateFields();
      const createdDeeplink = await create({
        variables: {
          noCache: true,
          synchronous: true,
          merchantId,
          url,
        },
      });
      const { url: iUrl, error } = createdDeeplink.data.object;
      setDeeplink(iUrl);
      setDeeplinkError(error?.message?.split('.')[0]);
    },
    [create, form],
  );

  useEffect(() => {
    setDeeplinkError(undefined);
    if (!!selectedMerchantId && !!offerUrl) {
      handleCheckDeeplink(selectedMerchantId, offerUrl);
    }
  }, [handleCheckDeeplink, offerUrl, selectedMerchantId]);

  return (
    <Container>
      <Form
        onFinish={handleSubmit}
        className="thin-form"
        form={form}
        labelAlign="right"
        labelCol={{ span: 7 }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="placementType"
              label="Placement Type"
              initialValue="textlink"
              rules={[{ required: true, message: 'Placement Type is required' }]}
            >
              <Select
                placeholder="Placement Type"
                onSelect={(iPlacement: string) => setPlacementType(iPlacement)}
                style={{
                  width: '100%',
                  height: 32,
                }}
              >
                {PLACEMENT_TYPES.map(({ key, value }) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {placementType === 'widget' && (
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Title is required' }]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="merchant"
              label="Merchant"
              rules={[{ required: true, message: 'Merchant is required' }]}
            >
              <MerchantField
                active
                filterSkimlinks
                placeholder="Select Merchant"
                onChange={(item) => setSelectedMerchantId(item?.value)}
              />
            </Form.Item>
            <Form.Item
              name="url"
              label="Offer Url"
              hasFeedback={!!deeplinkError}
              help={deeplinkError}
              validateStatus={deeplinkError ? 'warning' : undefined}
              rules={[
                { required: true, message: 'Offer URL is required' },
                { type: 'url', message: 'Invalid URL' },
              ]}
            >
              <Input onBlur={(event) => setOfferUrl(event.target.value)} />
            </Form.Item>
            <Form.Item label="Custom URL Param">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Form.Item name="customKey" style={{ maxWidth: 110, marginBottom: 0 }}>
                  <Input placeholder="Key" />
                </Form.Item>
                <Form.Item name="customValue" style={{ width: 320, marginLeft: 12, marginBottom: 0 }}>
                  <Input placeholder="value" />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label="CTA" name="buttonText">
              <Input placeholder="Custom call to action" />
            </Form.Item>
            {placementType === 'widget' && (
              <Form.Item
                name="linkDescription"
                label="Link Description"
                rules={[{ required: true, message: 'Link Description is required' }]}
              >
                <TextArea />
              </Form.Item>
            )}
            <Form.Item name="alignment" label="Alignment" initialValue="default">
              <Select
                placeholder="Select Alignment"
                style={{
                  width: '100%',
                  height: 32,
                }}
              >
                {ALIGNMENT_TYPES.map(({ key, value }) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Product" name="product">
              <ProductsField
                width={402}
                graphqlQuery={Query.productsWithOffers.query}
                dropdownStyle={{
                  maxWidth: 402,
                  minWidth: 402,
                }}
                renderItem={(product: IProductDTO) => (
                  <ProductItem>
                    <strong title={product.name}>{product.name}</strong>
                    <span>{product.brand?.name}</span>
                    {product.offersInfo && product.offersInfo.length > 0 ? (
                      <ProductItemContent>
                        <aside>
                          {product.offersInfo.length} Offer{product.offersInfo.length > 1 ? 's' : ''}:
                        </aside>
                        {product.offersInfo.map((offerInfo, idx) => (
                          <span key={idx}>
                            {offerInfo.merchant?.name}: ${offerInfo.price}
                            {product.offersInfo && idx + 1 < product.offersInfo.length && ','}
                          </span>
                        ))}
                      </ProductItemContent>
                    ) : (
                      <aside>No Offers</aside>
                    )}
                  </ProductItem>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <FooterForm debug={debug}>
          <Button type="primary" onClick={() => form.submit()} loading={loading}>
            Create
          </Button>
        </FooterForm>
      </Form>
    </Container>
  );
};

export default CreateDeeplinkPage;
