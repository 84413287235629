import React, { useCallback, useMemo, useState } from 'react';

import { Button, Tag } from 'antd';

import { ColumnsType } from 'antd/lib/table';

import { IInventoryFilters } from '../../../graphql/Interface';
import { IInventoryDTO } from '../../../dtos/ArticleInventoryDTO';
import { SlotIdContent } from './styles';
import InventoryModal from '../InventoryModal';
import InfoPopover from '../../InfoPopover';
import { LinkButtonContainer, NumberColumn, SmallText } from '../../../pages/InventoryPage/styles';
import { currencyFormat } from '../../../utils/constants';
import TransactionsModal from '../TransactionsModal';

export type ArticleInventoryModalView = 'article' | 'link';

interface ArticlesModalProps {
  totalCount: number;
  visible?: boolean;
  onCancel(): void;
  filters: IInventoryFilters;
  view: ArticleInventoryModalView;
  groupingName?: string;
  maxLocalPerPage: number;
}

const ArticlesModal: React.FC<ArticlesModalProps> = ({
  view,
  totalCount,
  maxLocalPerPage,
  filters,
  ...props
}) => {
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState<IInventoryDTO>();

  const handleSelectArticles = useCallback((inventory: IInventoryDTO, counter: number) => {
    setTransactionsCount(counter);
    setSelectedArticle(inventory);
  }, []);

  const columns = useMemo(() => {
    const c: ColumnsType<IInventoryDTO> = [
      {
        key: 'POST_TITLE',
        title: 'Article',
        dataIndex: 'title',
        ellipsis: true,
        width: 110,
        sorter:
          totalCount > maxLocalPerPage
            ? true
            : {
                compare: (a, b) => (a.title || '').localeCompare(b.title || ''),
                multiple: 1,
              },
        render: (title: string, inventory: IInventoryDTO) => (
          <InfoPopover
            key={inventory.postId}
            showImageNotFound
            image={inventory.image}
            items={[
              {
                url: inventory.permalink,
                value: inventory.title,
              },
              {
                value: inventory.category,
              },
            ]}
          >
            {title}
          </InfoPopover>
        ),
      },
    ];
    if (view === 'link') {
      c.push({
        key: 'SLOT_ID',
        title: 'Slot ID',
        dataIndex: 'slotId',
        width: 120,
        showSorterTooltip: false,
        sorter:
          totalCount > maxLocalPerPage
            ? true
            : {
                compare: (a, b) => (a.slotId || 0) - (b.slotId || 0),
              },
        render: (slotId: number) => (
          <SlotIdContent>
            <Tag color="blue">{slotId}</Tag>
          </SlotIdContent>
        ),
      });
    }
    c.push({
      title: 'Clicks',
      key: 'CLICKS',
      dataIndex: 'clicks',
      width: 100,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sorter:
        totalCount > maxLocalPerPage
          ? true
          : {
              compare: (a, b) => a.clicks - b.clicks,
            },
      render: (clicks: number) => (
        <NumberColumn>
          <SmallText type="secondary">{clicks}</SmallText>
        </NumberColumn>
      ),
    });
    c.push({
      title: 'Transactions',
      key: 'TRANSACTIONS',
      dataIndex: 'quantity',
      width: 85,
      sorter:
        totalCount > maxLocalPerPage
          ? true
          : {
              compare: (a, b) => a.quantity - b.quantity,
            },
      defaultSortOrder: 'descend',
      render: (transactions: number, inventory: IInventoryDTO) => (
        <LinkButtonContainer>
          <Button
            type="ghost"
            shape="round"
            size="small"
            title={`${transactions === 0 ? 'No' : transactions} transaction${transactions !== 1 && 's'}`}
            danger={transactions === 0}
            disabled={transactions === 0}
            onClick={() => handleSelectArticles(inventory, transactions)}
            style={{
              width: 80,
              fontSize: 12,
            }}
          >
            {transactions}
          </Button>
        </LinkButtonContainer>
      ),
    });
    c.push({
      title: 'Commissions',
      key: 'COMMISSIONS',
      dataIndex: 'commissions',
      width: 85,
      sorter:
        totalCount > maxLocalPerPage
          ? true
          : {
              compare: (a, b) => a.commissions - b.commissions,
            },
      defaultSortOrder: 'descend',
      render: (commissions: number) => (
        <NumberColumn>
          <SmallText type="secondary">{currencyFormat(commissions)}</SmallText>
        </NumberColumn>
      ),
    });
    return c;
  }, [handleSelectArticles, maxLocalPerPage, totalCount, view]);

  const selectedFilters = useMemo(() => {
    if (view === 'link') {
      return {
        ...filters,
        slotId: selectedArticle?.slotId,
        postId: selectedArticle?.postId,
      };
    }
    return {
      ...filters,
      postId: selectedArticle?.postId,
    };
  }, [filters, selectedArticle?.postId, selectedArticle?.slotId, view]);

  return (
    <>
      <TransactionsModal
        totalCount={transactionsCount}
        onCancel={() => setSelectedArticle(undefined)}
        filters={selectedFilters}
        visible={!!selectedArticle}
        maxLocalPerPage={maxLocalPerPage}
        groupingName="Article"
      />
      <InventoryModal
        dbColumns={view === 'link' ? ['ARTICLE', 'SLOT_ID'] : ['ARTICLE']}
        totalCount={totalCount}
        columns={columns}
        maxLocalPerPage={maxLocalPerPage}
        localFilter={(iInventory) => iInventory.title}
        name="Articles"
        filters={filters}
        {...props}
      />
    </>
  );
};

export default ArticlesModal;
