import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Upload, Image, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

import { Container, ImageSquare, UploadText, ImageLabel } from './styles';

interface Image {
  url: string;
}

interface SelectedFile {
  file: File;
  preview: string;
}

interface ImageUploadFieldProps {
  onChange?(file: File): void;
  imageUrl?: string;
  accept?: string[];
  max?: number;
  loading?: boolean;
  disabled?: boolean;
  resetImage?: boolean;
  text?: string;
  description?: string;
}

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  onChange,
  imageUrl,
  accept,
  max,
  loading,
  disabled,
  resetImage,
  text = 'Upload',
  description = '',
}) => {
  const [selected, setSelected] = useState<SelectedFile>();

  useEffect(() => {
    if (imageUrl && resetImage) {
      setSelected(undefined);
    }
  }, [imageUrl, resetImage]);

  const imageUploadHandler = useCallback(
    ({ file: iFile }) => {
      if (accept && accept.length > 0 && !accept.includes(iFile.type)) {
        message.error(`You can only upload JPG/PNG file!`);
        return;
      }
      const sizeMB = iFile.size / 1000000;
      if (max && sizeMB > max) {
        message.error(`Image must smaller than ${max}MB!`);
        return;
      }
      setSelected({
        file: iFile,
        preview: URL.createObjectURL(iFile),
      });
      if (onChange) {
        onChange(iFile);
      }
    },
    [accept, max, onChange],
  );

  const uploadButton = useMemo(
    () => (
      <ImageSquare>
        {loading ? <LoadingOutlined color="#595959" /> : <PlusOutlined color="#595959" />}
        <UploadText>{loading ? 'Uploading' : text}</UploadText>
      </ImageSquare>
    ),
    [loading, text],
  );

  const imageContent = useMemo(() => {
    if (loading) {
      return <>{uploadButton}</>;
    }
    if (selected) {
      return <Image preview={false} src={selected.preview} />;
    }
    if (imageUrl) {
      return <Image preview={false} src={imageUrl} />;
    }
    return <>{uploadButton}</>;
  }, [imageUrl, loading, selected, uploadButton]);

  return (
    <Container disabled={disabled}>
      {description &&
        <ImageLabel>{description}</ImageLabel>
      }
      <Upload
        disabled={disabled}
        style={{
          opacity: 0.2,
        }}
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        customRequest={imageUploadHandler}
      >
        {imageContent}
      </Upload>
      <ImageLabel>jpg/png, less then 5Mb</ImageLabel>
    </Container>
  );
};

export default ImageUploadField;
