/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import Fragment from './Fragment';

const Query = {
  getQuery: (name: string): DocumentNode =>
    // @ts-ignore
    Query[name].query,
  recoverSessionFromTransferToken: {
    query: gql`
      query ($token: String!) {
        object: recoverSessionFromTransferToken(token: $token) {
          user {
            ...UserFragment
          }
          accessToken
        }
      }
      ${Fragment.UserFragment}
    `,
  },

  me: {
    query: gql`
      query me {
        me {
          ...UserFragment
        }
      }
      ${Fragment.UserFragment}
    `,
  },
  categories: {
    query: gql`
      query categories($filters: CategoryFilters) {
        object: categories(filters: $filters) {
          id
          name
        }
      }
    `,
  },
  categoriesCollection: {
    query: gql`
      query categoriesCollection($filters: CategoryFilters, $limit: Int, $offset: Int) {
        object: categoriesCollection(filters: $filters, limit: $limit, offset: $offset) {
          edges {
            node {
              id
              name
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
    `,
  },
  category: {
    query: gql`
      query category($id: ID!) {
        category(id: $id) {
          id
          name
          aliases
        }
      }
    `,
  },
  productOptions: {
    query: gql`
      query productsOptions($filters: ProductFilters, $offset: Int, $limit: Int, $useSearchIndex: Boolean) {
        object: products(filters: $filters, offset: $offset, limit: $limit, useSearchIndex: $useSearchIndex) {
          edges {
            node {
              id
              name
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
    `,
  },
  products: {
    query: gql`
      query products(
        $filters: ProductFilters
        $offset: Int
        $limit: Int
        $order: ProductOrderField
        $direction: OrderDirection
        $useSearchIndex: Boolean
        $includeOfferTotals: Boolean
        $includeLandingPageTotals: Boolean
      ) {
        object: products(
          filters: $filters
          offset: $offset
          order: $order
          direction: $direction
          limit: $limit
          useSearchIndex: $useSearchIndex
          includeOfferTotals: $includeOfferTotals
          includeLandingPageTotals: $includeLandingPageTotals
        ) {
          edges {
            node {
              ...ShortProductFragment
              offersInfo {
                merchant {
                  id
                  name
                }
                price
              }
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.ShortProductFragment}
    `,
  },
  productsWithOffers: {
    query: gql`
      query products(
        $filters: ProductFilters
        $offset: Int
        $limit: Int
        $order: ProductOrderField
        $direction: OrderDirection
        $useSearchIndex: Boolean
        $includeOfferTotals: Boolean
        $includeLandingPageTotals: Boolean
      ) {
        object: products(
          filters: $filters
          offset: $offset
          order: $order
          direction: $direction
          limit: $limit
          useSearchIndex: $useSearchIndex
          includeOfferTotals: $includeOfferTotals
          includeLandingPageTotals: $includeLandingPageTotals
        ) {
          edges {
            node {
              ...ShortProductFragment
              offersInfo {
                merchant {
                  id
                  name
                }
                price
              }
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.ShortProductFragment}
    `,
  },
  product: {
    query: gql`
      query product($id: ID!) {
        product(id: $id) {
          ...ProductFragment
        }
      }
      ${Fragment.ProductFragment}
    `,
  },
  productVersions: {
    query: gql`
      query productVersions($productId: ID!) {
        productVersions(productId: $productId) {
          data
        }
      }
    `,
  },
  searchProducts: {
    query: gql`
      query searchProducts($keywords: String!, $limit: Int, $offset: Int) {
        object: searchProducts(keywords: $keywords, limit: $limit, offset: $offset) {
          edges {
            node {
              ...ShortProductFragment
              offersInfo {
                merchant {
                  id
                  name
                }
                price
              }
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.ShortProductFragment}
    `,
  },
  findProductsByOfferCount: {
    query: gql`
      query findProductsByOfferCount(
        $limit: Int
        $offset: Int
      ) {
        object: findProductsByOfferCount(
          limit: $limit
          offset: $offset
        ) {
          edges {
            node {
              ...ShortProductFragment
              offersInfo {
                merchant {
                  id
                  name
                }
                price
              }
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.ShortProductFragment}
    `,
  },
  user: {
    query: gql`
      query user($id: ID!) {
        user(id: $id) {
          ...UserFragment
        }
      }
      ${Fragment.UserFragment}
    `,
  },
  users: {
    query: gql`
      query users($filters: SearchFilters, $offset: Int, $limit: Int) {
        object: users(filters: $filters, offset: $offset, limit: $limit) {
          edges {
            node {
              ...UserFragment
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.UserFragment}
    `,
  },
  roles: {
    query: gql`
      query roles {
        object: roles {
          value
          label
        }
      }
    `,
  },
  landingPageStatus: {
    query: gql`
      query landingPageStatus {
        object: landingPageStatus {
          value
          label
        }
      }
    `,
  },
  merchantOptions: {
    query: gql`
      query merchantOptions($filters: MerchantFilters) {
        object: merchants(filters: $filters) {
          edges {
            node {
              id
              name
              disabled
              affiliateNetwork {
                id
                name
              }
            }
          }
        }
      }
    `,
  },
  publishers: {
    query: gql`
      query publishers($offset: Int, $limit: Int) {
        object: publishers(offset: $offset, limit: $limit) {
          edges {
            node {
              ...PublisherFragment
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.PublisherFragment}
    `,
  },
  publisher: {
    query: gql`
      query publisher($id: ID!) {
        publisher(id: $id) {
          ...PublisherFragment
        }
      }
      ${Fragment.PublisherFragment}
    `,
  },
  merchants: {
    query: gql`
      query merchants(
        $filters: MerchantFilters
        $offset: Int
        $limit: Int
        $order: OrderField = NAME
        $direction: OrderDirection = ASC
        $includeOfferTotals: Boolean
      ) {
        object: merchants(
          filters: $filters
          offset: $offset
          limit: $limit
          order: $order
          direction: $direction
          includeOfferTotals: $includeOfferTotals
        ) {
          edges {
            node {
              ...MerchantFragment
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.MerchantFragment}
    `,
  },
  merchant: {
    query: gql`
      query merchant($id: ID!) {
        merchant(id: $id) {
          ...MerchantFragment
          externalId
        }
      }
      ${Fragment.MerchantFragment}
    `,
  },
  impressionsPerPostByMerchant: {
    query: gql`
      query impressionsPerPostByMerchant($id: ID!) {
        object: impressionsPerPostByMerchant(id: $id) {
          postId
          events
          title
          permalink
        }
      }
    `,
  },
  affiliateNetworks: {
    query: gql`
      query affiliateNetworks($filters: SearchFilters) {
        object: affiliateNetworks(filters: $filters) {
          ...AffiliateNetworkFragment
        }
      }
      ${Fragment.AffiliateNetworkFragment}
    `,
  },
  affiliateNetwork: {
    query: gql`
      query affiliateNetwork($id: ID!) {
        affiliateNetwork(id: $id) {
          ...AffiliateNetworkFragment
        }
      }
      ${Fragment.AffiliateNetworkFragment}
    `,
  },
  brands: {
    query: gql`
      query brands($filters: SearchFilters, $offset: Int, $limit: Int) {
        object: brands(filters: $filters, offset: $offset, limit: $limit) {
          edges {
            node {
              ...BrandFragment
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.BrandFragment}
    `,
  },
  brand: {
    query: gql`
      query brand($id: ID!) {
        brand(id: $id) {
          ...BrandFragment
        }
      }
      ${Fragment.BrandFragment}
    `,
  },
  tags: {
    query: gql`
      query tags($query: String) {
        object: tags(query: $query)
      }
    `,
  },
  sites: {
    query: gql`
      query sites {
        object: sites {
          ...KeyValuePairFragment
        }
      }
      ${Fragment.KeyValuePairFragment}
    `,
  },
  placements: {
    query: gql`
      query placements(
        $filters: PlacementSearchFilters
        $limit: Int = 20
        $offset: Int = 0
        $order: OrderField = NAME
        $direction: OrderDirection = ASC
      ) {
        object: placements(
          filters: $filters
          limit: $limit
          offset: $offset
          order: $order
          direction: $direction
        ) {
          edges {
            node {
              ...PlacementFragment
            }
          }
        }
      }
      ${Fragment.PlacementFragment}
    `,
  },
  placementTypes: {
    query: gql`
      query placementTypes {
        object: placementTypes {
          ...KeyValuePairFragment
        }
      }
      ${Fragment.KeyValuePairFragment}
    `,
  },
  articleTypes: {
    query: gql`
      query articleTypes {
        object: articleTypes
      }
    `,
  },
  campaignRulePriorities: {
    query: gql`
      query campaignRulePriorities {
        object: campaignRulePriorities {
          ...KeyValuePairFragment
        }
      }
      ${Fragment.KeyValuePairFragment}
    `,
  },
  campaignRule: {
    query: gql`
      query campaignRule($id: ID!) {
        campaignRule(id: $id) {
          ...CampaignRuleFragment
        }
      }
      ${Fragment.CampaignRuleFragment}
    `,
  },
  campaignRules: {
    query: gql`
      query campaignRules($filters: CampaignRuleFilters, $limit: Int, $offset: Int) {
        object: campaignRules(filters: $filters, limit: $limit, offset: $offset) {
          edges {
            node {
              ...CampaignRuleFragment
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
      ${Fragment.CampaignRuleFragment}
    `,
  },
  import: {
    query: gql`
      query import($id: ID!) {
        import(id: $id) {
          status
          total
          created
          updated
        }
      }
    `,
  },
  currentImport: {
    query: gql`
      query currentImport {
        currentImport {
          id
          status
          total
          created
          updated
        }
      }
    `,
  },
  stats: {
    query: gql`
      query stats {
        stats {
          totals {
            products
            categories
            brands
            merchants
            feedProviders
            productsWithoutASIN
          }
          lastImport {
            createdAt
          }
          dailyAggregateStatistics {
            productsWithNoAsinAndNoUpc
            productsWithUpcAndNoAsin
            productsWithAsinAndNoUpc
            productsWithAsinAndUpc
            totalActiveOffers
            productsWithStaleOffers
            productsWithSingleOffer
            productsWithSingleOfferV2
            productsWithMultipleOffers
            totalProducts
            averageActiveOffers
            totalClicks
            clicksWithProducts
            percentageOfClicksWithProducts
            clickedLinks
            linksWithNoProducts
            averageLinkAgeRolling1DayOverall
            averageLinkAgeRolling1DayNonmanual
            createdAt
          }
        }
      }
    `,
  },
  productOffers: {
    query: gql`
      query productOffers(
        $productId: ID!
        $asin: String
        $upc: String
        $merchantsInfo: [InputMerchantProductInfo]
      ) {
        productOffers(productId: $productId, asin: $asin, upc: $upc, merchantsInfo: $merchantsInfo) {
          image {
            id
            url
          }
          offers {
            merchant {
              id
              name
              commissionRate {
                ...MerchantCommissionRateFragment
              }
              performance {
                ...MerchantPerformanceFragment
              }
            }
            url
            price
            originalPrice
            default
            buttonText
            createdDate
          }
          discoveredUpc
        }
      }
      ${Fragment.MerchantCommissionRateFragment}
      ${Fragment.MerchantPerformanceFragment}
    `,
  },
  export: {
    query: gql`
      query export($id: ID!) {
        export(id: $id) {
          id
          token
          status
          reportUrl
        }
      }
    `,
  },
  widget: {
    query: gql`
      query productWidget($id: ID!, $targeting: InputWidgetTargeting) {
        productWidget(id: $id, targeting: $targeting) {
          product {
            id
            headline
            description
            name
            rating
            image {
              url
            }
          }
          buttons {
            price
            url
            merchant {
              name
            }
          }
        }
      }
    `,
  },
  inventoryTransactions: {
    query: gql`
      query inventoryTransactions(
        $filters: InventoryFilters
        $limit: Int
        $offset: Int
        $order: InventoryTransactionSortField
        $direction: OrderDirection
      ) {
        inventoryTransactions(
          filters: $filters
          limit: $limit
          offset: $offset
          order: $order
          direction: $direction
        ) {
          edges {
            node {
              date
              clickId
              siteCode
              vendor
              merchant
              commissions
              revenue
              quantity
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
    `,
  },
  landingPage: {
    query: gql`
      query landingPage($url: String!) {
        object: fetchLandingPageData(url: $url) {
          landingPageUrl
          imageUrl
          name
          sku
          upc
          asin
          model
          brand
          price
          merchant {
            merchantId
            name
          }
          category
        }
      }
    `,
  },
  linkInventory: {
    query: gql`
      query inventories(
        $filters: InventoryFilters
        $columns: [InventoryColumns]
        $limit: Int
        $offset: Int
        $order: InventorySortField
        $direction: OrderDirection
      ) {
        inventories(
          filters: $filters
          limit: $limit
          columns: $columns
          offset: $offset
          order: $order
          direction: $direction
        ) {
          edges {
            node {
              postId
              createdAt
              modifiedAt
              primaryType
              secondaryType
              placement
              category
              title
              permalink
              image
              site
              siteCode
              links
              articles
              products
              merchants
              clicks
              commissions
              revenue
              quantity
              revenuePerClick
              conversionRate
              slotId
              productCategory {
                id
                name
              }
              product {
                id
                name
                category {
                  id
                  name
                }
                image {
                  url
                }
                brand {
                  id
                  name
                }
              }
              brand {
                id
                name
              }
              merchant {
                id
                name
                displayName
                logo {
                  url
                }
                website
                affiliateNetwork {
                  name
                }
                disabled
              }
              campaign {
                id
                name
                archived
                startDate
                endDate
              }
            }
          }
          pageInfo {
            pageCount
            pageSize
            total
          }
        }
      }
    `,
  },
  checkInventoryExport: {
    query: gql`
      query linkInventory($token: String!) {
        checkInventoriesExport(token: $token) {
          id
          status
          reportUrl
          token
        }
      }
    `,
  },
  recoverUserFromPasswordResetToken: {
    query: gql`
      query recoverUserFromPasswordResetToken($token: String!) {
        recoverUserFromPasswordResetToken(token: $token) {
          ...UserFragment
        }
      }
      ${Fragment.UserFragment}
    `,
  },
  searchProductInNetworkCatalogs: {
    query: gql`
      query ($filter: InputProductNetworkSearchArgs, $limit: Int) {
        object: searchProductInNetworkCatalogs(filter: $filter, limit: $limit) {
          edges {
            node {
              name
              imageUrl
              upc
              gtin
              brand
              description
              networkName
            }
          }
        }
      }
    `,
  },
};

export default Query;
