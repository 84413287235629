import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { Select, Tag } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import { pluralize } from 'inflected';
import { OptionContent, OptionSubContent, OptionName, OptionAux } from './styles';
import { isArray } from '../../../services/utils';
import { ISelectFieldDTO } from '../../../dtos/FormDTO';

const { Option } = Select;

type IDefaultOption = {
  value: string;
  label: string;
  aux?: string;
  disabled?: boolean;
  itemData: any;
};

export interface IGenericSelectFieldProps<T = ISelectFieldDTO, ItemDataType = any> {
  multiple?: boolean;
  onFilterChange?(value: T | T[] | undefined): void;
  onSelect?(value: any): void;
  onChange?(value: any, itemData: ItemDataType | undefined): void;
  value?: any | any[];
  width?: number;
  dropdownStyle?: React.CSSProperties;
  large?: boolean;
  placeholder?: string;
  showSearch?: boolean;
  filterOption?: any;
  notFoundContentText?: string;
  disabled?: boolean;
  renderItem?(item: ItemDataType): React.ReactElement;
}

interface SelectFieldProps extends IGenericSelectFieldProps {
  name: string;
  loading?: boolean;
  data: any[];
  large?: boolean;
  value?: IDefaultOption | IDefaultOption[];
  onSelect?(val: any[]): void;
  onSearch?(val: string): void;
}

const SelectField: React.FC<SelectFieldProps> = ({
  renderItem: customRenderItem,
  dropdownStyle,
  multiple,
  onFilterChange,
  value,
  width,
  large,
  onChange,
  placeholder,
  name,
  data,
  loading,
  onSelect,
  onSearch,
  showSearch = true,
  filterOption,
  notFoundContentText,
  disabled,
}) => {
  const [values, setValues] = useState<any[]>([]);

  const arrayValue = useMemo(() => {
    if (!value) {
      return [];
    }
    return isArray(value) ? (value as any[]) : [value];
  }, [value]);

  useEffect(() => {
    if (arrayValue.length > 0) {
      const filtered = arrayValue.filter((p) => !!p?.value);
      setValues((previous) => {
        return filtered.map((item) => {
          const exist = previous.find(({ value: iValue }) => iValue === item.value);
          return exist || item;
        });
      });
    }
  }, [arrayValue, name]);

  const renderItem = useCallback(
    (option: IDefaultOption) => {
      const { value: iValue, label, aux, disabled: disabledOption , itemData } = option;

      return (
        <Option value={iValue} label={label} key={iValue}>
          {customRenderItem ? (
            customRenderItem(itemData)
          ) : (
            <OptionContent large={large}>
              <OptionName>{label}</OptionName>
              {(aux || disabledOption) && (
                <OptionSubContent>
                  <OptionAux>{aux}</OptionAux>
                  {disabledOption && (
                    <Tag color="volcano" icon={<WarningFilled />}>
                      Disabled
                    </Tag>
                  )}
                </OptionSubContent>
              )}
            </OptionContent>
          )}
        </Option>
      );
    },
    [customRenderItem, large],
  );

  const options = useMemo(() => {
    const mapped = data.map((o) => renderItem(o));
    return mapped;
  }, [data, renderItem]);

  const handleSelect = useCallback(
    ({ value: iValue }) => {
      const selected = data.find((d) => d.value === iValue);
      if (selected) {
        if (multiple) {
          setValues((previous) => [...previous, selected]);
          return;
        }
        setValues([selected]);
      }
    },
    [data, multiple],
  );

  useEffect(() => {
    if (onFilterChange) {
      if (values && values.length > 0) {
        const newValue = values.map((v) => ({
          id: v.value,
          name: v.label,
        }));
        onFilterChange(newValue);
        return;
      }
      onFilterChange(undefined);
    }
  }, [onFilterChange, values]);

  const handleDeselect = useCallback(({ value: iValue }) => {
    setValues((previous) => previous.filter((p) => p.value !== iValue));
  }, []);

  const handleChangeQuery = useCallback(
    (iQuery: string) => {
      if (onSearch) {
        onSearch(iQuery);
      }
    },
    [onSearch],
  );

  useEffect(() => {
    if (onSelect) {
      onSelect(values);
    }
  }, [onSelect, values]);

  const selectFilter = useCallback(
    (text: string) => {
      const regex = new RegExp(text, 'i');
      const index = data.findIndex((d) => d.label?.match(regex));
      return index > -1;
    },
    [data],
  );

  return (
    <Select
      showSearch={showSearch}
      loading={loading}
      mode={multiple ? 'multiple' : undefined}
      onClear={() => setValues([])}
      allowClear={!loading && !multiple}
      filterOption={filterOption || selectFilter}
      value={values}
      disabled={disabled}
      onChange={(selecteds: any) => {
        if (onChange) {
          let selectedItemData;
          if (selecteds) {
            selectedItemData = _.isArray(selecteds)
              ? data.filter((d) => selecteds.map((s) => s.value).includes(d.value)).map((s) => s.itemData)
              : data.find((d) => d.value === selecteds.value)?.itemData;
          }
          onChange(selecteds, selectedItemData);
        }
      }}
      placeholder={placeholder || (multiple ? pluralize(name) : name)}
      onSearch={showSearch ? handleChangeQuery : undefined}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      labelInValue
      dropdownStyle={{
        minWidth: width ? width + width / 2 : undefined,
        ...dropdownStyle,
      }}
      style={{
        width: width || '100%',
        height: multiple ? undefined : 32,
      }}
      notFoundContent={ notFoundContentText || undefined}
    >
      {options}
    </Select>
  );
};

export default SelectField;
