/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import { Form, Select, DatePicker, Button, Input, Card, Tooltip, Tag, Pagination, Image } from 'antd';
import {
  LinkOutlined,
  FileTextOutlined,
  SearchOutlined,
  ShoppingOutlined,
  CloudDownloadOutlined,
  ShopOutlined,
  TrademarkOutlined,
  NotificationOutlined,
  SaveOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { useLazyQuery } from '@apollo/client';
import { humanize } from 'inflected';
import moment from 'moment';
import queryString from 'query-string';
import WebsiteField from '../../components/Fields/WebsiteField';

import { ViewType, IInventoryDTO, IProductCategoryDTO } from '../../dtos/ArticleInventoryDTO';
import {
  Container,
  FilterForm,
  FilterContent,
  SubmitFormContent,
  ViewItem,
  SmallText,
  ViewItemLabel,
  FooterSide,
  DateText,
  SlotIdContent,
  ClicksTitle,
  ClicksText,
  NumberColumn,
  PaginationContent,
  ImageContainer,
  LinkContainer,
  LinkButtonContainer,
  RemoveLink,
} from './styles';
import CategoryField from '../../components/Fields/CategoryField';
import StorageService from '../../services/StorageService';
import MerchantField from '../../components/Fields/MerchantField';
import InfoPopover from '../../components/InfoPopover';
import { currencyFormat, dateFormat, IMAGE_NOT_FOUND, percentFormat } from '../../utils/constants';
import DynamicTable, { DynamicColumnType, IColumnConfig } from '../../components/DynamicTable';
import { IMerchantDTO } from '../../dtos/MerchantDTO';
import Query from '../../graphql/Query';
import {
  IInventoryFilters,
  IInventoryData,
  IInventoryVars,
  InventoryColumnType,
  IPageInfo,
} from '../../graphql/Interface';
import { IBrandDTO, IProductDTO } from '../../dtos/ProductDTO';
import { ICampaignDTO } from '../../dtos/CampaignDTO';
import ArticlesModal from '../../components/LinkInventory/ArticlesModal';
import SlotsModal from '../../components/LinkInventory/SlotsModal';
import ProductModal from '../../components/LinkInventory/ProductModal';
import MerchantModal from '../../components/LinkInventory/MerchantModal';
import TransactionsModal from '../../components/LinkInventory/TransactionsModal';
import LinksModal from '../../components/LinkInventory/LinksModal';
import ExportModal from '../../components/Export/ExportModal';
import Mutation from '../../graphql/Mutation';
import ProductsListField from '../../components/Fields/ProductsListField';

const { RangePicker } = DatePicker;

const MAX_LOCAL_PER_PAGE = 200;

enum PAGE_COLUMNS {
  'MERCHANT_LOGO',
  'PRODUCT_IMAGE',
}

type PageColumnType = keyof typeof PAGE_COLUMNS;
type ColumnsType = InventoryColumnType | PageColumnType;

type ModalType = 'articles' | 'products' | 'links' | 'merchants' | 'transactions' | 'linkDetails';

interface IColumnMapItem {
  column: ColumnsType;
  label: string;
}

const INITIAL_VIEW = 'product';

const COLUMN_MAP: IColumnMapItem[] = [
  {
    column: 'CAMPAIGN',
    label: 'Campaign',
  },
  {
    column: 'ARTICLE',
    label: 'Article',
  },
  {
    column: 'SITE',
    label: 'Site',
  },
  {
    column: 'POST_TYPE',
    label: 'Post Type',
  },
  {
    column: 'POST_CREATED_AT',
    label: 'Published At',
  },
  {
    column: 'POST_MODIFIED_AT',
    label: 'Modified At',
  },
  {
    column: 'SLOT_ID',
    label: 'Slot ID',
  },
  {
    column: 'PLACEMENT_TYPE',
    label: 'Placement',
  },
  {
    column: 'MERCHANT_LOGO',
    label: 'Merchant Logo',
  },
  {
    column: 'MERCHANT',
    label: 'Merchant',
  },
  {
    column: 'PRODUCT_IMAGE',
    label: 'Product Image',
  },
  {
    column: 'PRODUCT',
    label: 'Product',
  },
  {
    column: 'BRAND',
    label: 'Brand',
  },
  {
    column: 'PRODUCT_CATEGORY',
    label: 'Product Category',
  },
  {
    column: 'MERCHANTS',
    label: 'Merchants',
  },

  {
    column: 'PRODUCTS',
    label: 'Products',
  },
  {
    column: 'ARTICLES',
    label: 'Articles',
  },
  {
    column: 'LINKS',
    label: 'Links',
  },
];

const getColumnLabel = (dbName: ColumnsType) => {
  const label = COLUMN_MAP.find((a) => a.column === dbName);
  return label?.label || '';
};

const getColumnDbName = (label: string) => {
  const dbName = COLUMN_MAP.find((a) => a.label === label);
  return dbName?.column;
};

interface ISelect {
  value: string;
}

interface ICustomColumns {
  order: string;
  direction: string;
  columns: IColumnConfig[];
}

interface ISearchForm {
  query?: string;
  websiteId?: ISelect;
  view: ViewType;
  productIds?: ISelect[];
  categoryId?: ISelect;
  merchantId?: ISelect;
  dateRange?: moment.Moment[];
  dateRangeSearch?: moment.Moment[];
}

interface ISelectedView {
  filters: IInventoryFilters;
  name: ModalType;
  count: number;
}

type StaticViewType = {
  [key in ViewType]: ColumnsType[];
};

type IFeatureColumnMap = {
  [key in PageColumnType]: InventoryColumnType[];
};

const featureColumnMap: IFeatureColumnMap = {
  MERCHANT_LOGO: ['MERCHANT'],
  PRODUCT_IMAGE: ['PRODUCT'],
};

const staticView: StaticViewType = {
  custom: [],
  brand: ['BRAND', 'PRODUCTS', 'ARTICLES', 'LINKS'],
  link: ['ARTICLE', 'SITE', 'POST_MODIFIED_AT', 'SLOT_ID', 'MERCHANT', 'PRODUCT', 'PRODUCT_CATEGORY'],
  page: ['ARTICLE', 'SITE', 'POST_TYPE', 'POST_MODIFIED_AT', 'LINKS'],
  product: ['PRODUCT_IMAGE', 'PRODUCT', 'PRODUCT_CATEGORY', 'ARTICLES', 'LINKS'],
  campaign: ['CAMPAIGN', 'MERCHANTS', 'PRODUCTS', 'ARTICLES', 'LINKS'],
  merchant: ['MERCHANT_LOGO', 'MERCHANT', 'PRODUCTS', 'ARTICLES', 'LINKS'],
};

const InventoryPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [loadInventory, { loading, data }] = useLazyQuery<IInventoryData, IInventoryVars>(
    Query.linkInventory.query,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [selectedView, setSelectedView] = useState<ISelectedView>();
  const [showExport, setShowExport] = useState<any>();
  const [inventories, setInventories] = useState<IInventoryDTO[]>();
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const [direction, setDirection] = useState<'ASC' | 'DESC' | undefined>();
  const [order, setOrder] = useState<string>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filters, setFilters] = useState<IInventoryFilters>();
  const [dbColumns, setDbColumns] = useState<InventoryColumnType[]>([]);
  const [columnList, setColumnList] = useState<IColumnConfig[]>([]);

  const [form] = Form.useForm();

  const [preView, setPreView] = useState<ViewType>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [unsavedCustomColumns, setUnsavedCustomColumns] = useState<IColumnConfig[]>();
  const [customColumns, setCustomColumns] = useState<IColumnConfig[]>();

  function disabledDate(current: moment.Moment) {
    const from = moment().add(-60, 'days').endOf('day');
    return current && (current < from || current > moment());
  }

  const getMerchantName = useCallback((merchant?: IMerchantDTO) => {
    return merchant?.displayName || merchant?.name || 'N/A';
  }, []);

  const createCountButton = useCallback((name, counter, onClick) => {
    return (
      <LinkButtonContainer>
        <Button
          type="ghost"
          shape="round"
          size="small"
          title={`${counter === 0 ? 'No' : counter} ${name}${counter !== 1 && 's'}`}
          danger={counter === 0}
          disabled={counter === 0}
          onClick={onClick}
          style={{
            width: 80,
            fontSize: 12,
          }}
        >
          {counter}
        </Button>
      </LinkButtonContainer>
    );
  }, []);

  const loadDbColumns = useCallback(() => {
    if (!preView) {
      return [];
    }
    const selectedView = staticView[preView];
    let myDbColumns = selectedView.filter((sv) => !Object.values(PAGE_COLUMNS).includes(sv));
    if (preView === 'custom') {
      const filtered = (unsavedCustomColumns || customColumns || []).filter(
        (cc) => !cc.disabled && !!cc.visible,
      );
      const cols = filtered.map((a) => getColumnDbName(a.title) as ColumnsType);
      myDbColumns = cols;
    }
    myDbColumns = myDbColumns.reduce((iCols, col) => {
      if (Object.keys(PAGE_COLUMNS).includes(col)) {
        const pageCols = featureColumnMap[col as PageColumnType];
        return [...iCols, ...pageCols];
      }
      return [...iCols, col];
    }, [] as ColumnsType[]);
    myDbColumns = myDbColumns.filter((v, i, a) => a.indexOf(v) === i);
    return myDbColumns as InventoryColumnType[];
  }, [customColumns, preView, unsavedCustomColumns]);

  const handleView = useCallback(
    (name: ModalType, inventory: IInventoryDTO, count: number) => {
      const filters: IInventoryFilters = {};
      if (dbColumns.includes('CAMPAIGN')) {
        filters.campaignId = inventory.campaign?.id;
      }
      if (dbColumns.includes('PRODUCT')) {
        filters.productIds = [inventory.product?.id];
      }
      if (dbColumns.includes('MERCHANT')) {
        filters.merchantId = inventory.merchant?.id;
      }
      if (dbColumns.includes('BRAND')) {
        filters.brandId = inventory.brand?.id;
      }
      if (dbColumns.includes('PRODUCT_CATEGORY')) {
        filters.categoryId = inventory.productCategory?.id;
      }
      if (dbColumns.includes('SITE')) {
        filters.websiteId = inventory.siteCode;
      }
      if (inventory.postId) {
        filters.postId = inventory.postId;
      }
      if (inventory.slotId) {
        filters.slotId = inventory.slotId;
      }
      setSelectedView({
        filters,
        name,
        count,
      });
    },
    [dbColumns],
  );

  const handleViewMerchants = useCallback(
    (inventory: IInventoryDTO, merchants: number) => {
      handleView('merchants', inventory, merchants);
    },
    [handleView],
  );

  const handleViewProducts = useCallback(
    (inventory: IInventoryDTO, products: number) => {
      handleView('products', inventory, products);
    },
    [handleView],
  );

  const handleViewArticles = useCallback(
    (inventory: IInventoryDTO, articles: number) => {
      handleView('articles', inventory, articles);
    },
    [handleView],
  );

  const handleViewLinks = useCallback(
    (inventory: IInventoryDTO, links: number) => {
      handleView('links', inventory, links);
    },
    [handleView],
  );

  const handleViewTransactions = useCallback(
    (inventory: IInventoryDTO, transactions: number) => {
      handleView('transactions', inventory, transactions);
    },
    [handleView],
  );

  const handleViewLinkDetails = useCallback(
    (inventory: IInventoryDTO) => {
      handleView('linkDetails', inventory, 0);
    },
    [handleView],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: getColumnLabel('CAMPAIGN'),
          key: 'CAMPAIGN',
          dataIndex: 'campaign',
          hideable: true,
          width: 150,
          sorter: true,
          render: (campaign: ICampaignDTO) => (
            <SmallText type="secondary">{campaign?.name || 'N/A'}</SmallText>
          ),
        },
        {
          title: getColumnLabel('ARTICLE'),
          key: 'POST_TITLE',
          dataIndex: 'title',
          hideable: true,
          ellipsis: true,
          sorter: true,
          width: 150,
          render: (title: string, inventory: IInventoryDTO) => (
            <InfoPopover
              key={inventory.postId}
              showImageNotFound
              image={inventory.image}
              items={[
                {
                  url: inventory.permalink,
                  value: inventory.title,
                },
                {
                  value: inventory.category,
                },
              ]}
            >
              {title}
            </InfoPopover>
          ),
        },
        {
          title: getColumnLabel('SITE'),
          key: 'SITE_CODE',
          dataIndex: 'siteCode',
          hideable: true,
          width: 60,
          sorter: true,
          render: (siteCode: string, inventory: IInventoryDTO) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title={inventory.site}>
                <Text type="secondary" ellipsis style={{ cursor: 'pointer' }}>
                  {siteCode}
                </Text>
              </Tooltip>
            </div>
          ),
        },
        {
          title: getColumnLabel('POST_TYPE'),
          key: 'POST_TYPE',
          dataIndex: 'primaryType',
          hideable: true,
          width: 105,
          sorter: true,
          render: (primaryType: string, inventory: IInventoryDTO) => (
            <LinkContainer>
              <SmallText ellipsis type="secondary">
                {primaryType || 'N/A'}
              </SmallText>
              <SmallText
                ellipsis
                type="secondary"
                style={{
                  fontStyle: 'italic',
                }}
              >
                {inventory.secondaryType}
              </SmallText>
            </LinkContainer>
          ),
        },
        {
          title: 'Published At',
          key: 'POST_CREATED_AT',
          dataIndex: 'createdAt',
          hideable: {
            title: getColumnLabel('POST_CREATED_AT'),
          },
          width: 110,
          sorter: true,
          render: (createdAt: number) => <DateText>{dateFormat(createdAt, 'yyyy-MM-dd h')}h</DateText>,
        },
        {
          title: 'Last Modified',
          key: 'POST_MODIFIED_AT',
          dataIndex: 'modifiedAt',
          hideable: {
            title: getColumnLabel('POST_MODIFIED_AT'),
          },
          width: 110,
          sorter: true,
          render: (modifiedAt: number) => <DateText>{dateFormat(modifiedAt, 'yyyy-MM-dd   h')}h</DateText>,
        },
        {
          title: getColumnLabel('SLOT_ID'),
          key: 'SLOT_ID',
          dataIndex: 'slotId',
          hideable: true,
          width: 70,
          sorter: true,
          render: (slotId: number, inventory: IInventoryDTO) => (
            <SlotIdContent title="Show details">
              <Tag
                style={{ cursor: 'pointer' }}
                onClick={() => handleViewLinkDetails(inventory)}
                color="blue"
              >
                {slotId}
              </Tag>
            </SlotIdContent>
          ),
        },
        {
          title: getColumnLabel('PLACEMENT_TYPE'),
          key: 'PLACEMENT_TYPE',
          dataIndex: 'placement',
          hideable: true,
          width: 90,
          sorter: true,
          render: (placement: string) => (
            <SmallText ellipsis type="secondary">
              {placement}
            </SmallText>
          ),
        },
        {
          title: '',
          dataIndex: 'merchant',
          width: 40,
          hideable: {
            title: getColumnLabel('MERCHANT_LOGO'),
          },
          render: (merchant: IMerchantDTO) => (
            <ImageContainer>
              <Image src={merchant.logo?.url || IMAGE_NOT_FOUND} preview={false} fallback={IMAGE_NOT_FOUND} />
            </ImageContainer>
          ),
        },
        {
          title: getColumnLabel('MERCHANT'),
          key: 'MERCHANT_NAME',
          dataIndex: 'merchant',
          hideable: true,
          width: 110,
          render: (merchant: IMerchantDTO) => (
            <>
              <InfoPopover
                image={merchant?.logo?.url}
                showImageNotFound
                items={[
                  {
                    value: getMerchantName(merchant),
                  },
                  {
                    value: merchant?.id,
                  },
                  {
                    value: merchant?.affiliateNetwork?.name || undefined,
                  },
                  {
                    value: merchant?.website || undefined,
                    url: merchant?.website || undefined,
                  },
                  {
                    render: merchant?.disabled === true ? <Tag color="volcano">Disabled</Tag> : undefined,
                  },
                ]}
              >
                {getMerchantName(merchant)}
              </InfoPopover>
            </>
          ),
        },
        {
          title: '',
          dataIndex: 'product',
          width: 50,
          ellipsis: true,
          hideable: {
            title: getColumnLabel('PRODUCT_IMAGE'),
          },
          render: (product: IProductDTO) => (
            <ImageContainer>
              <Image
                src={product?.image?.url || IMAGE_NOT_FOUND}
                preview={false}
                fallback={IMAGE_NOT_FOUND}
              />
            </ImageContainer>
          ),
        },
        {
          title: getColumnLabel('PRODUCT'),
          key: 'PRODUCT_NAME',
          dataIndex: 'product',
          hideable: true,
          width: 170,
          sorter: true,
          render: (product: IProductDTO) => (
            <>
              {product?.name ? (
                <InfoPopover
                  key={product.id}
                  showImageNotFound
                  image={product?.image?.url}
                  items={[
                    {
                      value: product.name,
                    },
                    {
                      value: product.id,
                    },
                    {
                      value: product.categoryName,
                    },
                  ]}
                >
                  {product.name}
                </InfoPopover>
              ) : (
                <LinkContainer>
                  <SmallText type="secondary">N/A</SmallText>
                  <SmallText type="secondary">{product?.id}</SmallText>
                </LinkContainer>
              )}
            </>
          ),
        },
        {
          title: 'Category',
          key: 'PRODUCT_CATEGORY',
          dataIndex: 'productCategory',
          hideable: {
            title: getColumnLabel('PRODUCT_CATEGORY'),
          },
          width: 110,
          sorter: true,
          render: (productCategory: IProductCategoryDTO) => (
            <SmallText ellipsis type="secondary">
              {productCategory?.name || 'N/A'}
            </SmallText>
          ),
        },
        {
          title: getColumnLabel('BRAND'),
          key: 'BRAND',
          dataIndex: 'brand',
          hideable: true,
          width: 120,
          sorter: true,
          render: (brand: IBrandDTO) => (
            <SmallText ellipsis type="secondary">
              {brand?.name || 'N/A'}
            </SmallText>
          ),
        },
        {
          title: getColumnLabel('MERCHANTS'),
          key: 'MERCHANTS',
          dataIndex: 'merchants',
          width: 100,
          sorter: true,
          defaultSortOrder: 'descend',
          hideable: true,
          render: (merchants: number, inventory: IInventoryDTO) =>
            createCountButton('merchant', merchants, () => handleViewMerchants(inventory, merchants)),
        },
        {
          title: getColumnLabel('PRODUCTS'),
          key: 'PRODUCTS',
          dataIndex: 'products',
          width: 100,
          sorter: true,
          defaultSortOrder: 'descend',
          hideable: true,
          render: (products: number, inventory: IInventoryDTO) =>
            createCountButton('product', products, () => handleViewProducts(inventory, products)),
        },
        {
          title: getColumnLabel('ARTICLES'),
          key: 'ARTICLES',
          dataIndex: 'articles',
          width: 100,
          sorter: true,
          defaultSortOrder: 'descend',
          hideable: true,
          render: (articles: number, inventory: IInventoryDTO) =>
            createCountButton('article', articles, () => handleViewArticles(inventory, articles)),
        },
        {
          title: getColumnLabel('LINKS'),
          key: 'LINKS',
          dataIndex: 'links',
          width: 100,
          sorter: true,
          defaultSortOrder: 'descend',
          hideable: true,
          render: (links: number, inventory: IInventoryDTO) =>
            createCountButton('link', links, () => handleViewLinks(inventory, links)),
        },
        {
          title: 'Clicks',
          key: 'CLICKS',
          dataIndex: 'clicks',
          width: 85,
          sorter: true,
          hideable: {
            disabled: true,
          },
          render: (clicks: number) => (
            <ClicksTitle>
              <ClicksText>{clicks}</ClicksText>
            </ClicksTitle>
          ),
        },
        {
          title: 'Transactions',
          key: 'TRANSACTIONS',
          dataIndex: 'quantity',
          hideable: {
            disabled: true,
          },
          width: 95,
          sorter: true,
          defaultSortOrder: 'descend',
          render: (transactions: number, inventory: IInventoryDTO) =>
            createCountButton('transaction', transactions, () =>
              handleViewTransactions(inventory, transactions),
            ),
        },
        {
          title: 'Commissions',
          key: 'COMMISSIONS',
          dataIndex: 'commissions',
          hideable: {
            disabled: true,
          },
          width: 95,
          sorter: true,
          defaultSortOrder: 'descend',
          render: (commissions: number) => <NumberColumn>{currencyFormat(commissions)}</NumberColumn>,
        },
        {
          title: 'Revenue',
          key: 'REVENUE',
          dataIndex: 'revenue',
          defaultSortOrder: 'descend',
          hideable: true,
          width: 85,
          sorter: true,
          render: (revenue: number) => <NumberColumn>{currencyFormat(revenue)}</NumberColumn>,
        },
        {
          title: 'CVR',
          key: 'CONVERSION_RATE',
          dataIndex: 'conversionRate',
          defaultSortOrder: 'descend',
          hideable: true,
          width: 85,
          sorter: true,
          render: (conversionRate: number) => <NumberColumn>{percentFormat(conversionRate)}</NumberColumn>,
        },
        {
          title: 'RPC',
          key: 'REVENUE_PER_CLICK',
          dataIndex: 'revenuePerClick',
          defaultSortOrder: 'descend',
          hideable: true,
          width: 85,
          sorter: true,
          render: (revenuePerClick: number) => <NumberColumn>{currencyFormat(revenuePerClick)}</NumberColumn>,
        },
      ] as DynamicColumnType[],
    [
      createCountButton,
      getMerchantName,
      handleViewArticles,
      handleViewLinkDetails,
      handleViewLinks,
      handleViewMerchants,
      handleViewProducts,
      handleViewTransactions,
    ],
  );

  useEffect(() => {
    if (filters) {
      setInventories(undefined);
      setPageInfo(undefined);
      loadInventory({
        variables: {
          filters,
          columns: dbColumns as InventoryColumnType[],
          direction,
          order,
          limit: pageSize,
          offset: (page - 1) * pageSize,
        },
      });
    }
  }, [dbColumns, direction, filters, loadInventory, order, page, pageSize]);

  const handleLoadData = useCallback(
    async ({ query, websiteId, productIds = [], categoryId, merchantId, dateRange }: ISearchForm) => {
      const newDateRange = dateRange && {
        from: dateRange[0].format('YYYY-MM-DD'),
        to: dateRange[1].format('YYYY-MM-DD'),
      };
      const siteId = websiteId?.value === 'dtes' ? 'es' : websiteId?.value;
      setFilters({
        query,
        websiteId: siteId,
        productIds: productIds.length > 0 ? productIds.map((p) => p.value) : undefined,
        categoryId: categoryId?.value,
        merchantId: merchantId?.value,
        dateRange: newDateRange,
      });
      setDbColumns(loadDbColumns());
    },
    [loadDbColumns],
  );

  const columnsConfig = useMemo(() => {
    if (!preView) {
      return [];
    }
    if (preView === 'custom') {
      return unsavedCustomColumns || customColumns;
    }
    const selectedView = staticView[preView];
    const map = columns.map((c, index) => {
      const title = (typeof c.hideable !== 'boolean' && c.hideable?.title) || (c.title as string);
      const disabled = typeof c.hideable !== 'boolean' && c.hideable?.disabled;
      const visible = selectedView.findIndex((sv) => getColumnLabel(sv) === title) > -1 || disabled;
      return {
        title,
        visible,
        disabled,
        index,
      };
    });
    return map;
  }, [columns, customColumns, preView, unsavedCustomColumns]);

  useEffect(() => {
    if (data) {
      if (data?.inventories?.edges) {
        const { edges, pageInfo: iPageInfo } = data.inventories;
        const nodes = edges.map((a) => a.node);
        setInventories(nodes);
        setPageInfo(iPageInfo);
      }
    }
  }, [data]);

  const handleChangeFilters = useCallback(
    (name: string, newFilters: any, updateUrlParams = true) => {
      if (name !== 'dateRangeSearch' && name !== 'columnsSearch') {
        setFilters((previous) => ({ ...previous, [name]: newFilters }));
      }
      const newValue =
        newFilters === false || newFilters === [] || newFilters === '' || newFilters?.length === 0
          ? undefined
          : newFilters;
      if (updateUrlParams) {
        const queryParams = queryString.parse(location.search);
        const cleanedParams = JSON.parse(
          JSON.stringify({
            ...queryParams,
            [name]: newValue,
          }),
        );
        const newSearch = new URLSearchParams(cleanedParams);
        history.push({ search: newSearch.toString() });
      }
    },
    [history, location.search],
  );

  const setViewURL = useCallback(
    (newView: string) => {
      const queryParams = queryString.parse(location.search);
      const cleanedParams = JSON.parse(
        JSON.stringify({
          ...queryParams,
          view: newView,
        }),
      );
      const search = new URLSearchParams(cleanedParams);
      history.push({ search: search.toString() });
    },
    [history, location.search],
  );

  const handleOnLoadColums = useCallback(
    (iView: string, queryParamColumnSearch: string[]) => {
      const selectedView = staticView[iView as ViewType];
      const mappedColumns = columns.map((c, index) => {
        const title = (typeof c.hideable !== 'boolean' && c.hideable?.title) || (c.title as string);
        const disabled = typeof c.hideable !== 'boolean' && c.hideable?.disabled;
        const visible = selectedView.findIndex((sv) => getColumnLabel(sv) === title) > -1 || disabled;
        return {
          title,
          visible,
          disabled,
          index,
        };
      });
      queryParamColumnSearch.forEach((columnTitle: string) => {
        const foundColumn = _.find(
          mappedColumns,
          (mappedColumn: IColumnConfig) => mappedColumn.title === columnTitle,
        );
        if (foundColumn) {
          foundColumn.visible = true;
        }
      });
      setCustomColumns(mappedColumns);
    },
    [columns],
  );
  useEffect(() => {
    if (!preView) {
      let builtSearch: any = {};
      for (const [key, value] of Array.from(params.entries())) {
        if (key !== 'query' && key !== 'id' && key !== 'page' && key !== 'view' && key !== 'websiteId') {
          builtSearch = { ...builtSearch, [key]: value?.length > 0 ? value : undefined };
        }
      }
      let iView = params.get('view');
      const query = params.get('query') || '';
      const site = params.get('websiteId') ? { value: params.get('websiteId') } : undefined;
      const saved = StorageService.getItem(`inventory:custom`);
      const queryParamColumnSearch = params.get('columnsSearch')?.split(',') || [];

      if (saved && !(queryParamColumnSearch.length > 0)) {
        const savedObj = JSON.parse(saved) as ICustomColumns;
        setCustomColumns(savedObj.columns);
        if (!iView) {
          setOrder(savedObj.order);
          setDirection(savedObj.direction === 'ASC' ? 'ASC' : 'DESC');
          iView = 'custom';
        }
      } else if (queryParamColumnSearch.length > 0) {
        if (iView) {
          handleOnLoadColums(iView, queryParamColumnSearch);
        }
      }
      if (!iView) {
        iView = INITIAL_VIEW;
        if (unsavedCustomColumns) {
          iView = 'custom';
        }
      }
      iView =
        iView === 'custom' && !unsavedCustomColumns && !saved && queryParamColumnSearch.length === 0
          ? INITIAL_VIEW
          : iView;
      setPreView(iView as ViewType);
      const momentDates = [];
      if (builtSearch?.dateRangeSearch) {
        const splitByDash = builtSearch?.dateRangeSearch.split('-');
        momentDates.push(moment.unix(parseInt(splitByDash[0], 10)));
        momentDates.push(moment.unix(parseInt(splitByDash[1], 10)));
      }

      const productIdsSearch =
        builtSearch?.productIds?.length > 0
          ? builtSearch.productIds.split(',').map((pId: string) => {
              return {
                value: pId,
              };
            })
          : undefined;
      let setFormData: ISearchForm = {
        view: iView as ViewType,
        query,
        websiteId: site as ISelect,
        merchantId: { value: builtSearch?.merchantId },
        categoryId: { value: builtSearch?.categoryId },
        productIds: productIdsSearch,
      };
      if (momentDates.length > 0) {
        setFormData = { ...setFormData, dateRange: momentDates };
      }
      form.setFieldsValue(setFormData);
    }
  }, [
    columnsConfig,
    customColumns,
    form,
    handleOnLoadColums,
    location.search,
    params,
    preView,
    unsavedCustomColumns,
  ]);

  const handleChangePreview = useCallback(
    (newView: string) => {
      setInventories(undefined);
      setPageInfo(undefined);
      setPreView(newView as ViewType);
      setViewURL(newView);
    },
    [setViewURL],
  );

  const handleChangeCategory = useCallback((selected) => {
    setSelectedCategory(selected?.value);
  }, []);

  const handleExport = useCallback(() => {
    const {
      query,
      websiteId,
      productIds = [],
      categoryId,
      merchantId,
      dateRange,
    } = form.getFieldsValue() as ISearchForm;
    const newDateRange = dateRange && {
      from: dateRange[0].format('YYYY-MM-DD'),
      to: dateRange[1].format('YYYY-MM-DD'),
    };
    const siteId = websiteId?.value === 'dtes' ? 'es' : websiteId?.value;
    const objFilter = {
      query,
      websiteId: siteId,
      productIds: productIds.length > 0 ? productIds.map((p) => p.value) : undefined,
      categoryId: categoryId?.value,
      merchantId: merchantId?.value,
      dateRange: newDateRange,
    };
    setShowExport({
      filters: objFilter,
      columns: loadDbColumns(),
      direction,
      order,
    });
  }, [direction, form, loadDbColumns, order]);

  const handleColumnChange = useCallback((_, filter, { order: iOrder, columnKey }) => {
    setDirection(iOrder === 'ascend' ? 'ASC' : 'DESC');
    setOrder(columnKey);
  }, []);

  const handlePaginationChange = useCallback((iPage, iPageSize) => {
    setPage(iPage);
    setPageSize(iPageSize);
  }, []);

  const handleChangeColumns = useCallback(
    (configs: IColumnConfig[]) => {
      setUnsavedCustomColumns(configs);
      form.setFieldsValue({
        view: 'custom',
      });
      setPreView('custom');
      setViewURL('custom');
      setInventories([]);
      setPageInfo(undefined);
      handleChangeFilters(
        'columnsSearch',
        configs
          .filter((c) => c.visible && !c.disabled)
          .map((c) => c.title)
          .join(','),
        true,
      );
    },
    [form, setViewURL, handleChangeFilters],
  );

  const handleSaveCustomColumns = useCallback(() => {
    const obj = {
      order,
      direction,
      columns: unsavedCustomColumns,
    } as ICustomColumns;
    StorageService.setItem(`inventory:custom`, JSON.stringify(obj));
    setUnsavedCustomColumns(undefined);
    setCustomColumns(obj.columns);
    form.setFieldsValue({
      view: 'custom',
    });
    setPreView('custom');
    setViewURL('custom');
  }, [direction, form, order, setViewURL, unsavedCustomColumns]);

  const handleDeleteCustomColumns = useCallback(() => {
    StorageService.removeItem(`inventory:custom`);
    setCustomColumns(undefined);
    form.setFieldsValue({
      view: INITIAL_VIEW,
    });
    setPreView(INITIAL_VIEW);
    setViewURL(INITIAL_VIEW);
    setInventories([]);
    setPageInfo(undefined);
  }, [form, setViewURL]);

  return (
    <>
      <ExportModal
        onPrepare={Mutation.exportInventories.mutation}
        onCheckStatus={Query.checkInventoryExport.query}
        variables={showExport}
        onCancel={() => setShowExport(undefined)}
        title={`Export ${humanize(preView || '')} view`}
        visible={!!showExport}
        fileName={`inventory_${preView}.xlsx`}
      />
      <ArticlesModal
        totalCount={selectedView?.count || 0}
        onCancel={() => setSelectedView(undefined)}
        view="article"
        filters={{
          ...filters,
          ...selectedView?.filters,
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedView?.name === 'articles'}
        groupingName={preView}
      />
      <ProductModal
        totalCount={selectedView?.count || 0}
        onCancel={() => setSelectedView(undefined)}
        filters={{
          ...filters,
          ...selectedView?.filters,
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedView?.name === 'products'}
        groupingName={preView}
      />
      <SlotsModal
        totalCount={selectedView?.count || 0}
        onCancel={() => setSelectedView(undefined)}
        filters={{
          ...filters,
          ...selectedView?.filters,
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedView?.name === 'links'}
        groupingName={preView}
      />
      <MerchantModal
        totalCount={selectedView?.count || 0}
        onCancel={() => setSelectedView(undefined)}
        filters={{
          ...filters,
          ...selectedView?.filters,
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedView?.name === 'merchants'}
        groupingName={preView}
      />
      <LinksModal
        visible={selectedView?.name === 'linkDetails'}
        filters={{
          ...filters,
          ...selectedView?.filters,
        }}
        onCancel={() => setSelectedView(undefined)}
      />
      <TransactionsModal
        totalCount={selectedView?.count || 0}
        onCancel={() => setSelectedView(undefined)}
        filters={{
          ...filters,
          ...selectedView?.filters,
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedView?.name === 'transactions'}
        groupingName={preView}
      />
      <Container>
        <FilterForm layout="vertical" form={form} onFinish={(val) => handleLoadData(val as ISearchForm)}>
          <Card
            style={{
              borderWidth: 0.7,
              borderStyle: 'solid',
              borderColor: '#d9d9d9',
              paddingTop: 10,
            }}
            bodyStyle={{
              paddingTop: 15,
              paddingBottom: 0,
            }}
            actions={[
              <SubmitFormContent>
                <FooterSide>
                  <SmallText type="secondary">View Mode:</SmallText>
                  <Form.Item name="view" noStyle>
                    <Select style={{ width: 150 }} value={preView} onChange={handleChangePreview}>
                      {(unsavedCustomColumns || customColumns) && (
                        <ViewItem value="custom">
                          <ToolOutlined />
                          <ViewItemLabel>Custom</ViewItemLabel>
                        </ViewItem>
                      )}
                      <ViewItem value="product">
                        <ShoppingOutlined />
                        <ViewItemLabel>Product</ViewItemLabel>
                      </ViewItem>
                      <ViewItem value="brand">
                        <TrademarkOutlined />
                        <ViewItemLabel>Brand</ViewItemLabel>
                      </ViewItem>
                      <ViewItem value="link">
                        <LinkOutlined />
                        <ViewItemLabel>Link</ViewItemLabel>
                      </ViewItem>
                      <ViewItem value="page">
                        <FileTextOutlined />
                        <ViewItemLabel>Page</ViewItemLabel>
                      </ViewItem>
                      <ViewItem value="campaign">
                        <NotificationOutlined />
                        <ViewItemLabel>Campaign</ViewItemLabel>
                      </ViewItem>
                      <ViewItem value="merchant">
                        <ShopOutlined />
                        <ViewItemLabel>Merchant</ViewItemLabel>
                      </ViewItem>
                    </Select>
                  </Form.Item>
                  {customColumns && preView === 'custom' && (
                    <RemoveLink danger type="link" onClick={handleDeleteCustomColumns}>
                      Remove Custom View
                    </RemoveLink>
                  )}
                  <SmallText
                    type="secondary"
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    Date Range:
                  </SmallText>
                  <Form.Item name="dateRange" noStyle initialValue={[moment().add(-7, 'days'), moment()]}>
                    <RangePicker
                      ranges={{
                        '7 days': [moment().add(-7, 'days'), moment()],
                        '14 days': [moment().add(-14, 'days'), moment()],
                        '30 days': [moment().add(-30, 'days'), moment()],
                        '60 days': [moment().add(-60, 'days'), moment()],
                        'Current Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [
                          moment().add(-1, 'month').startOf('month'),
                          moment().add(-1, 'month').endOf('month'),
                        ],
                      }}
                      disabledDate={disabledDate}
                      onChange={(val: any) => {
                        const start = val ? val[0] : undefined;
                        const end = val ? val[1] : undefined;
                        if (start && end) {
                          handleChangeFilters('dateRangeSearch', `${start.unix()}-${end.unix()}`, true);
                        }
                      }}
                    />
                  </Form.Item>
                </FooterSide>
                <FooterSide>
                  <Button
                    shape="round"
                    type="default"
                    icon={<CloudDownloadOutlined />}
                    onClick={handleExport}
                    style={{
                      marginRight: 12,
                    }}
                  >
                    Export
                  </Button>
                  <Button
                    shape="round"
                    loading={loading}
                    type="primary"
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >
                    Apply
                  </Button>
                </FooterSide>
              </SubmitFormContent>,
            ]}
          >
            <FilterContent>
              <Form.Item name="query" label="Article Title">
                <Input
                  placeholder="Type article title"
                  allowClear
                  onChange={(val: any) => {
                    handleChangeFilters('query', val?.target?.value, true);
                  }}
                />
              </Form.Item>
              <Form.Item name="websiteId" label="Website">
                <WebsiteField
                  width={180}
                  showSearch={false}
                  placeholder="Select Website"
                  onChange={(val: any) => {
                    handleChangeFilters('websiteId', val?.value, true);
                  }}
                />
              </Form.Item>
              <Form.Item name="merchantId" label="Merchant">
                <MerchantField
                  width={180}
                  placeholder="Select Merchant"
                  onChange={(val: any) => {
                    handleChangeFilters('merchantId', val?.value, true);
                  }}
                />
              </Form.Item>
              <Form.Item name="categoryId" label="Product Category">
                <CategoryField
                  width={180}
                  onChange={(val: any) => {
                    handleChangeCategory(val);
                    handleChangeFilters('categoryId', val?.value, true);
                  }}
                />
              </Form.Item>
              <Form.Item name="productIds" label="Products">
                <ProductsListField
                  width={180}
                  categoryId={selectedCategory}
                  onChange={(val: any) =>
                    handleChangeFilters(
                      'productIds',
                      val.map((v: any) => v.value),
                      true,
                    )
                  }
                />
              </Form.Item>
            </FilterContent>
          </Card>
        </FilterForm>
        <DynamicTable
          columns={columns}
          size="small"
          tableLayout="fixed"
          showSorterTooltip={false}
          loading={loading}
          dataSource={inventories}
          initialConfig={columnsConfig}
          extraActions={[
            {
              icon: SaveOutlined,
              visible: !!unsavedCustomColumns && preView === 'custom',
              onClick: handleSaveCustomColumns,
              title: 'Save Custom View',
            },
          ]}
          onChangeColumnsConfig={handleChangeColumns}
          rowKey={() => Math.random().toString(36).substr(2, 5)}
          onChange={handleColumnChange}
        />
        {pageInfo && pageInfo.pageCount > 1 && (
          <PaginationContent>
            <Pagination
              current={page}
              onChange={handlePaginationChange}
              pageSize={pageSize}
              pageSizeOptions={['20', '50', '100']}
              total={pageInfo.total}
            />
          </PaginationContent>
        )}
      </Container>
    </>
  );
};

export default InventoryPage;
