import React, { useState } from 'react';

import { Form, Col, Select, Tooltip, AutoComplete } from 'antd';

import { DeleteFilled } from '@ant-design/icons';

import { ButtonRemove, Container, CriteriaLabel } from './styles';

interface CriteriaFormProps {
  index: number;
  name: number;
  fieldKey: number;
  onRemove(): void;
}

const CriteriaForm: React.FC<CriteriaFormProps> = ({ index, name, fieldKey, onRemove }) => {
  const [deleteHover, setDeleteHover] = useState(false);

  return (
    <>
      <Container isHover={deleteHover}>
        <Col
          span={22}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CriteriaLabel>Custom Field {index + 1}: </CriteriaLabel>
          <Form.Item noStyle name={[name, `name`]} fieldKey={[fieldKey, `name`]}>
            <AutoComplete
              style={{
                width: 130,
                alignItems: 'flex-start',
              }}
              placeholder="Name"
              options={[
                {
                  value: 'articles',
                  label: 'Articles',
                },
                {
                  value: 'articleCategories',
                  label: 'Article Category',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            style={{
              marginBottom: 0,
            }}
            name={[name, `value`]}
            fieldKey={[fieldKey, `value`]}
          >
            <Select
              style={{
                width: 150,
                marginLeft: 10,
              }}
              mode="tags"
              placeholder="Value"
            />
          </Form.Item>
        </Col>
        <Col>
          <Tooltip title="Remove criteria">
            <ButtonRemove
              onMouseOver={() => setDeleteHover(true)}
              onMouseOut={() => setDeleteHover(false)}
              type="link"
              shape="circle"
              onClick={onRemove}
            >
              <DeleteFilled />
            </ButtonRemove>
          </Tooltip>
        </Col>
      </Container>
    </>
  );
};

export default CriteriaForm;
