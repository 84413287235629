import styled from 'styled-components';

interface ContainerProps {
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const ImageSquare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UploadText = styled.label`
  opacity: 0.8;
  text-align: center;
  pointer-events: none;
`;

export const ImageLabel = styled.label`
  font-size: 12px !important;
  opacity: 0.8;
  margin-left: 4px;
`;
