import { Card, FormProps, Button } from 'antd';
import React from 'react';
import { SearchOutlined, CloudDownloadOutlined } from '@ant-design/icons';

import { Container, SubmitFormContent, FilterContent, FooterSide } from './styles';

interface IFilterFormProps extends FormProps {
  footer?: React.ReactElement;
  filters: React.ReactElement;
  actions?: React.ReactElement;
  onExport?(): void;
  loading?: boolean;
  margin?: boolean;
}

const FilterForm: React.FC<IFilterFormProps> = ({
  footer,
  filters,
  actions,
  onExport,
  loading,
  margin,
  ...props
}) => {
  return (
    <Container margin={margin} layout="vertical" {...props}>
      <Card
        style={{
          borderWidth: 0.7,
          borderStyle: 'solid',
          borderColor: '#d9d9d9',
          paddingTop: 10,
        }}
        bodyStyle={{
          paddingTop: 15,
          paddingBottom: 0,
        }}
        actions={[
          <SubmitFormContent>
            <FooterSide>{footer}</FooterSide>
            {actions && <FooterSide>{actions}</FooterSide>}
            {!actions && (
              <FooterSide>
                {onExport && (
                  <Button shape="round" type="default" icon={<CloudDownloadOutlined />} onClick={onExport}>
                    Export
                  </Button>
                )}

                <Button
                  shape="round"
                  loading={loading}
                  type="primary"
                  icon={<SearchOutlined />}
                  htmlType="submit"
                >
                  Apply
                </Button>
              </FooterSide>
            )}
          </SubmitFormContent>,
        ]}
      >
        <FilterContent>{filters}</FilterContent>
      </Card>
    </Container>
  );
};

export default FilterForm;
