/* eslint-disable @typescript-eslint/ban-ts-comment */
import { message } from 'antd';

import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { onError } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils/throwServerError';
import { print as printGraphQL } from 'graphql';
import logger from './LoggerService';
import StorageService from './StorageService';
import analyticsService from './Analytics/AnalyticsService';
import browserHistory from './browserHistory';

const httpLink = createUploadLink();

const loggingRequestLink = new ApolloLink((operation: any, forward: any) => {
  logger.info(`Request Payload ${operation?.operationName}`, {
    ...operation,
    query: printGraphQL(operation.query),
  });
  operation.setContext(({ headers = {} }) => {
    const token = StorageService.getItem('token');
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  return forward(operation);
});

const loggingResponseLink = new ApolloLink((operation: any, forward: any) =>
  forward(operation).map((response: any) => {
    logger.success('Response Payload', response.data || '');
    return response;
  }),
);

const errorLink = onError((errorResponse) => {
  const { graphQLErrors, networkError } = errorResponse;
  if (networkError && (networkError as ServerError).statusCode === 401) {
    StorageService.removeItem('token');
    StorageService.removeItem('user');
    browserHistory.go(0);
  }

  (graphQLErrors || []).forEach((err) => {
    analyticsService.handleError(err.message);
    message.error(err.message);
  });
});

const client = new ApolloClient({
  link: from([
    errorLink,
    loggingRequestLink,
    loggingResponseLink,

    // this weird cast is a workaround for type inconsistency between apollo libraries
    // seehttps://github.com/jaydenseric/apollo-upload-client/issues/221
    httpLink as unknown as ApolloLink,
  ]),
  cache: new InMemoryCache(),
  // just say hell no to caching
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
