import { Alert } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ExportContainer = styled.div`
  min-width: 90px !important;
  height: 90;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExportContent = styled.div`
  flex: 1;
`;

export const AlertContent = styled(Alert)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .ant-alert-description {
    font-size: 13px;
    margin-top: 12px;
    color: #6f6f6f;
  }
`;
