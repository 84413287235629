import styled from 'styled-components';
import { Card } from 'antd';

interface ContainerProps {
  index: number;
}
export const Container = styled(Card)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${(props) => (props.index > 0 ? 12 : 0)}px;
`;
