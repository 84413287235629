import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Typography, Card, Descriptions, Image, Tooltip, Button, Modal, Badge } from 'antd';
import { InfoCircleTwoTone, CloudDownloadOutlined } from '@ant-design/icons';

import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import Search from 'antd/lib/input/Search';
import _ from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { IPerformance, IProductMetrics, IProductDTO, IProfuctFilter } from '../../dtos/ProductDTO';
import CategoryField from '../../components/Fields/CategoryField';
import BrandsField from '../../components/Fields/BrandsField';
import MerchantField from '../../components/Fields/MerchantField';
import CheckField from '../../components/Fields/CheckField';
import FilterForm from '../../components/FilterForm';

import ProductModal from '../../components/Modals/ProductModal';

import { ProductSummary, ImageContainer, SmallText, CenteredColumn, FilterContainer } from './styles';

import PerformanceButton from '../../components/LinkInventory/PerformanceButton';
import { currencyFormat, dateFormat } from '../../utils/constants';
import ArticlesModal from '../../components/LinkInventory/ArticlesModal';
import TransactionsModal from '../../components/LinkInventory/TransactionsModal';
import DynamicTable from '../../components/DynamicTable';
import Query from '../../graphql/Query';
import { IPageInfo, IQueryPagination } from '../../graphql/Interface';
import { FilterContent } from '../../components/FilterForm/styles';
import { ToggleFilters } from '../../components/ListLayout/styles';
import Mutation from '../../graphql/Mutation';
import ExportModal from '../../components/Export/ExportModal';

const { Text } = Typography;

const PER_PAGE = 50;
const MAX_LOCAL_PER_PAGE = 200;

type PerformanceType = 'ARTICLES' | 'TRANSACTIONS';

interface ISelectedPerformance {
  type: PerformanceType;
  productId: string;
  count: number;
}

const ProductsPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const id = params.get('id');
  const withoutASIN = params.get('withoutASIN') === 'true';
  const hasManualOffers = params.get('hasManualOffers') === 'true';
  const exactSearch = params.get('exactSearch') === 'true';
  const useSearchIndex = params.get('useSearchIndex') !== 'false';
  const categoryId = params.get('categoryId');
  const brandId = params.get('brandId');
  const merchantIds = params.get('merchantIds')?.split(',') as [string];
  const query = params.get('query') || '';
  const unaffiliated = params.get('unaffiliated') === 'true'

  const [showProductModal, setShowProductModal] = useState(!!id);
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(id || undefined);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [products, setProducts] = useState<IProductDTO[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const [direction, setDirection] = useState<'ASC' | 'DESC'>();
  const [order, setOrder] = useState<string>();
  const [showFilters, setShowFilters] = useState(
    withoutASIN ||
      exactSearch ||
      hasManualOffers ||
      unaffiliated ||
      query !== '' ||
      categoryId != null ||
      brandId != null ||
      merchantIds?.length > 0,
  );
  const [showExport, setShowExport] = useState<any>();

  const [filter, setFilter] = useState<IProfuctFilter>({
    withoutASIN,
    hasManualOffers,
    unaffiliated,
    exactSearch,
    query,
    categoryId: categoryId ?? undefined,
    brandId: brandId ?? undefined,
    merchantIds,
  });

  const [deleteProducts, { loading: deleting }] = useMutation(Mutation.deleteProducts.mutation, {
    onCompleted: () => {
      handleLoadProducts();
    },
  });

  const [loadProducts, { loading }] = useLazyQuery<IQueryPagination<IProductDTO>>(Query.products.query, {
    onCompleted: (data) => {
      setPageInfo(data.object.pageInfo);
      setProducts(data.object.edges.map((p) => p.node));
    },
  });

  const [loadProductsByOffer, { loading: loadingProductsByOffer }] = useLazyQuery<IQueryPagination<IProductDTO>>(Query.findProductsByOfferCount.query, {
    onCompleted: (data) => {
      setPageInfo(data.object.pageInfo);
      setProducts(data.object.edges.map((p) => p.node));
    },
  });

  const [selectedPerformance, setSelectedPerformance] = useState<ISelectedPerformance>();

  const debouncedQueryString = useRef(
    _.debounce((q: string) => {
      handleChangeFilters('query', q);
      setPage(1);
    }, 200),
  );

  const handleSelectPerformance = (e: any, type: PerformanceType, count: number, productId: string) => {
    e.stopPropagation();
    setSelectedPerformance({
      count,
      productId,
      type,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Summary',
        key: 'NAME',
        dataIndex: 'name',
        sorter: true,
        hideable: {
          disabled: true,
        },
        showSorterTooltip: false,
        render: (name: string, product: IProductDTO) => (
          <ProductSummary bordered={false}>
            <Card.Meta
              avatar={
                <ImageContainer>
                  <Image key={`product_img_${product.id}`} preview={false} src={product.image?.url} />
                </ImageContainer>
              }
              title={name}
              description={
                <Descriptions column={3}>
                  <Descriptions.Item label={<Text type="secondary">ASIN</Text>}>
                    <Text type="secondary">{product.asin || '-'}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Brand</Text>}>
                    <Text type="secondary">{product.brand?.name || '-'}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Price</Text>}>
                    <Text type="secondary">{product.price ? currencyFormat(product.price) : '-'}</Text>
                  </Descriptions.Item>

                  <Descriptions.Item label={<Text type="secondary">UPC</Text>}>
                    <Text type="secondary">{product.upc || '-'}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Category</Text>}>
                    <Text type="secondary">{product.category?.name || '-'}</Text>
                  </Descriptions.Item>

                  <Descriptions.Item label={<Text type="secondary">Offers/Landing Pages</Text>}>
                    <Text type="secondary">
                      {product.offerTotals || 0}/{product.landingPageTotals || 0}
                    </Text>
                  </Descriptions.Item>
                </Descriptions>
              }
            />
          </ProductSummary>
        ),
      },
      {
        title: 'Commissions (30day)',
        key: 'COMMISSIONS',
        width: 100,
        dataIndex: 'performance',
        sorter: true,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (performance: IPerformance) => (
          <CenteredColumn>{currencyFormat(performance?.commissions || 0)}</CenteredColumn>
        ),
      },
      {
        title: (
          <>
            <span>Clicks (24hr)</span>
            <Tooltip title="Clicks in the last 24 hours">
              <InfoCircleTwoTone
                style={{
                  marginLeft: 6,
                }}
              />
            </Tooltip>
          </>
        ),
        key: 'CLICKS_ROLLING1DAY',
        width: 100,
        dataIndex: 'metrics',
        hideable: {
          title: 'Clicks (24hr)',
        },
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (metrics: IProductMetrics) => (
          <CenteredColumn width={70}>{metrics?.clicksRolling1Day || 0}</CenteredColumn>
        ),
      },
      {
        title: (
          <>
            <span>Pageviews (24hr)</span>
            <Tooltip title="Pageviews in the last 24 hours">
              <InfoCircleTwoTone
                style={{
                  marginLeft: 6,
                }}
              />
            </Tooltip>
          </>
        ),
        key: 'PAGEVIEWS_ROLLING1DAY',
        width: 100,
        dataIndex: 'metrics',
        hideable: {
          title: 'Pageviews (24hr)',
        },
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (metrics: IProductMetrics) => (
          <CenteredColumn width={70}>{metrics?.pageviewsRolling1Day || 0}</CenteredColumn>
        ),
      },
      {
        title: (
          <>
            <span>Click Score (24hr)</span>
            <Tooltip title="ClickScore">
              <InfoCircleTwoTone
                style={{
                  marginLeft: 6,
                }}
              />
            </Tooltip>
          </>
        ),
        key: 'CLICK_SCORE',
        width: 100,
        dataIndex: 'metrics',
        hideable: {
          title: 'CLICK_SCORE (24hr)',
        },
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (metrics: IProductMetrics) => (
          <CenteredColumn width={100}>{metrics?.clickScore?.toFixed(1) || 0}</CenteredColumn>
        ),
      },
      {
        title: (
          <>
            <span>CTR</span>
            <Tooltip title="CTR (24hrs)">
              <InfoCircleTwoTone
                style={{
                  marginLeft: 6,
                }}
              />
            </Tooltip>
          </>
        ),
        key: 'CTR (24hr)',
        width: 100,
        dataIndex: 'metrics',
        hideable: {
          title: 'CTR (24hr)',
        },
        sorter: false,
        showSorterTooltip: false,
        render: (metrics: IProductMetrics) => {
          let ctr = null;
          if (metrics) {
            ctr =
              metrics.pageviewsRolling1Day > 0
                ? metrics.clicksRolling1Day / metrics.pageviewsRolling1Day
                : metrics.clicksRolling1Day > 0
                ? 1.0
                : 0;
            ctr = Math.min(ctr, 1.0) * 100;
            ctr = `${ctr.toFixed(1)}%`;
          }
          return <CenteredColumn width={70}>{ctr}</CenteredColumn>;
        },
      },
      {
        title: (
          <>
            <span>CC API Requests (24hr)</span>
            <Tooltip title="How many CC api requests the website has made in last 24 hours.">
              <InfoCircleTwoTone
                style={{
                  marginLeft: 6,
                }}
              />
            </Tooltip>
          </>
        ),
        key: 'IMPRESSIONS_ROLLING1DAY',
        width: 100,
        dataIndex: 'metrics',
        hideable: {
          hideByDefault: true,
          title: 'Cloud City Requests (24hr)',
        },
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (metrics: IProductMetrics) => (
          <CenteredColumn width={70}>{metrics?.serversideImpressionsRolling1Day || 0}</CenteredColumn>
        ),
      },
      {
        title: 'Articles',
        key: 'ARTICLES',
        width: 90,
        dataIndex: 'performance',
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (performance: IPerformance, product: IProductDTO) => (
          <PerformanceButton
            name="Article"
            counter={performance?.articles || 0}
            onClick={(e) => handleSelectPerformance(e, 'ARTICLES', performance?.articles || 0, product.id)}
          />
        ),
      },
      {
        title: 'Transactions',
        key: 'TRANSACTIONS',
        width: 110,
        dataIndex: 'performance',
        sorter: true,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (performance: IPerformance, product: IProductDTO) => (
          <PerformanceButton
            name="Transaction"
            size="small"
            counter={performance?.transactions || 0}
            onClick={(e) =>
              handleSelectPerformance(e, 'TRANSACTIONS', performance?.transactions || 0, product.id)
            }
          />
        ),
      },
      {
        title: 'Created',
        key: 'DATE',
        dataIndex: 'createdAt',
        sorter: true,
        sortDirections: ['descend' as const, 'ascend' as const],
        width: 110,
        showSorterTooltip: false,
        render: (createdAt: number) => (
          <SmallText type="secondary">{dateFormat(createdAt, 'yyyy-MM-dd')}</SmallText>
        ),
      },
      {
        title: 'Offers Updated',
        key: 'LAST_OFFERS_UPDATED_AT',
        sorter: true,
        sortDirections: ['descend' as const, 'ascend' as const],
        dataIndex: 'lastOffersUpdatedAt',
        width: 130,
        showSorterTooltip: true,
        render: (lastOffersUpdatedAt: number | null) =>
          lastOffersUpdatedAt && (
            <SmallText type="secondary">
              {formatDistanceToNow(lastOffersUpdatedAt, { addSuffix: true })}
            </SmallText>
          ),
      },
      {
        title: (
          <>
            <span>Clicks</span>
            <Tooltip title="Performance from the last 7 days">
              <InfoCircleTwoTone
                style={{
                  marginLeft: 6,
                }}
              />
            </Tooltip>
          </>
        ),
        key: 'CLICKS',
        width: 100,
        dataIndex: 'performance',
        hideable: {
          title: 'Clicks',
          hideByDefault: true,
        },
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as const, 'ascend' as const],
        render: (performance: IPerformance) => (
          <CenteredColumn width={70}>{performance?.clicks || 0}</CenteredColumn>
        ),
      },
      {
        title: 'Performance update',
        width: 140,
        dataIndex: 'performance',
        hideable: {
          hideByDefault: true,
        },
        render: (performance: IPerformance) => (
          <CenteredColumn>
            <SmallText ellipsis type="secondary">
              {performance ? dateFormat(performance.updatedAt, 'yyyy-MM-dd, h:mma') : '-'}
            </SmallText>
          </CenteredColumn>
        ),
      },
    ];
  }, []);

  const handleLoadProducts = useCallback(() => {
    if (filter.unaffiliated) {
      loadProductsByOffer({
        variables: {
          limit: perPage,
          offset: (page - 1) * perPage,
        },
      });
    }
    else {
      delete filter.unaffiliated;
      loadProducts({
        variables: {
          filters: filter,
          offset: (page - 1) * perPage,
          limit: perPage,
          order,
          direction,
          useSearchIndex,
          includeOfferTotals: true,
          includeLandingPageTotals: true,
        },
      });
    }
  }, [direction, filter, loadProductsByOffer, loadProducts, order, page, perPage, useSearchIndex]);

  useEffect(() => {
    handleLoadProducts();
    setSelectedRows([]);
  }, [handleLoadProducts]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const cleanedParams = JSON.parse(
      JSON.stringify({
        ...queryParams,
        id: selectedProductId,
      }),
    );
    const newSearch = new URLSearchParams(cleanedParams);
    history.push({ search: newSearch.toString() });
  }, [history, location.search, selectedProductId]);

  const handleColumnChange = useCallback((__, _filter, { order: iOrder, columnKey }) => {
    if (!iOrder || !columnKey) {
      setOrder(undefined);
      setDirection(undefined);
      return;
    }
    console.log({ iOrder, columnKey });
    setDirection(iOrder === 'ascend' ? 'ASC' : 'DESC');
    setOrder(columnKey);
  }, []);

  const handleDeleteProducts = useCallback(() => {
    const productNames = products.filter((p) => selectedRows.includes(p.id));
    const sufix = productNames.length === 1 ? productNames[0].name : `${productNames.length} products`;
    Modal.confirm({
      title: `Do you want to delete '${sufix}'?`,
      onOk: async () => {
        deleteProducts({
          variables: {
            ids: selectedRows,
          },
        });
      },
    });
  }, [deleteProducts, products, selectedRows]);

  const handleChangeFilters = (name: string, newFilters: any, updateUrlParams = true) => {
    setFilter((previous) => ({ ...previous, [name]: newFilters }));
    const newValue = newFilters === false || newFilters === [] || newFilters === '' ? undefined : newFilters;
    if (updateUrlParams) {
      const queryParams = queryString.parse(location.search);
      const cleanedParams = JSON.parse(
        JSON.stringify({
          ...queryParams,
          [name]: newValue,
        }),
      );
      const newSearch = new URLSearchParams(cleanedParams);
      history.push({ search: newSearch.toString() });
    }
  };

  return (
    <>
      <ExportModal
        onPrepare={Mutation.exportProducts.mutation}
        onCheckStatus={Query.checkInventoryExport.query}
        variables={showExport}
        onCancel={() => setShowExport(undefined)}
        title="Export Products"
        visible={!!showExport}
        fileName="products.csv"
      />
      <ProductModal
        productId={selectedProductId}
        onCancel={() => {
          setSelectedProductId(undefined);
          setShowProductModal(false);
        }}
        onSuccess={() => {
          setShowProductModal(false);
          handleLoadProducts();
        }}
        visible={showProductModal}
      />
      <ArticlesModal
        totalCount={selectedPerformance?.count || 0}
        onCancel={() => setSelectedPerformance(undefined)}
        view="article"
        filters={{
          productIds: selectedPerformance ? [selectedPerformance.productId] : [],
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedPerformance?.type === 'ARTICLES'}
        groupingName="Product"
      />
      <TransactionsModal
        totalCount={selectedPerformance?.count || 0}
        onCancel={() => setSelectedPerformance(undefined)}
        filters={{
          productIds: selectedPerformance ? [selectedPerformance.productId] : [],
        }}
        maxLocalPerPage={MAX_LOCAL_PER_PAGE}
        visible={selectedPerformance?.type === 'TRANSACTIONS'}
        groupingName="Product"
      />
      <FilterForm
        loading={loading || loadingProductsByOffer}
        actions={
          <>
            <Button
              type="primary"
              onClick={() => {
                setSelectedProductId(undefined);
                setShowProductModal(true);
              }}
            >
              Create Product
            </Button>
            <Button
              type="ghost"
              loading={deleting}
              danger
              disabled={selectedRows.length === 0}
              onClick={handleDeleteProducts}
            >
              Delete Selected
            </Button>
            <Button
              type="default"
              icon={<CloudDownloadOutlined />}
              loading={deleting}
              onClick={() =>
                setShowExport({
                  filters: filter,
                  order,
                  direction,
                })
              }
            >
              Export
            </Button>
          </>
        }
        filters={
          <FilterContainer>
            <FilterContent>
              <Search
                placeholder="Product name"
                allowClear
                defaultValue={query}
                style={{ width: 220 }}
                disabled={filter.unaffiliated}
                onChange={(event) => debouncedQueryString.current(event.target.value)}
              />
              <ToggleFilters onClick={() => setShowFilters((previous) => !previous)}>
                {showFilters ? 'hide filters' : 'show filters'}
              </ToggleFilters>
            </FilterContent>
            <FilterContent hide={!showFilters}>
              <CategoryField
                width={180}
                placeholder="Select Category"
                disabled={filter.unaffiliated}
                value={{ value: filter.categoryId }}
                onChange={(val: any) => handleChangeFilters('categoryId', val?.value, true)}
              />
              <BrandsField
                width={180}
                value={{ value: filter.brandId }}
                placeholder="Select Brand"
                disabled={filter.unaffiliated}
                onChange={(val: any) => handleChangeFilters('brandId', val?.value, true)}
              />
              <MerchantField
                width={200}
                multiple
                placeholder="Select Merchant"
                disabled={filter.unaffiliated}
                value={(filter.merchantIds || []).map((mId) => {
                  return { value: mId };
                })}
                onChange={(val) =>
                  handleChangeFilters(
                    'merchantIds',
                    val.map((v: any) => v.value),
                    true,
                  )
                }
              />
              <CheckField
                name="Manual Offers"
                disabled={filter.unaffiliated}
                defaultValue={filter.hasManualOffers}
                onChange={(val) => handleChangeFilters('hasManualOffers', val)}
              />
              <CheckField
                name="Without ASIN"
                disabled={filter.unaffiliated}
                defaultValue={filter.withoutASIN}
                onChange={(val) => handleChangeFilters('withoutASIN', val)}
              />
              <CheckField
                name="Exact Search"
                disabled={filter.unaffiliated}
                defaultValue={filter.exactSearch}
                onChange={(val) => handleChangeFilters('exactSearch', val)}
              />
              <CheckField
                name="Unaffiliated Products"
                defaultValue={filter.unaffiliated}
                onChange={(val) => handleChangeFilters('unaffiliated', val)}
              />
            </FilterContent>
          </FilterContainer>
        }
      />
      <DynamicTable
        className="clickable"
        rowClassName="tall-cell"
        tableLayout="fixed"
        rowSelection={{
          onChange: (val) => setSelectedRows(val as string[]),
          selectedRowKeys: selectedRows,
        }}
        onRow={(record: IProductDTO) => ({
          onClick: () => {
            setSelectedProductId(record.id);
            setShowProductModal(true);
          },
        })}
        pagination={
          pageInfo && {
            pageSize: perPage,
            current: page,
            showSizeChanger: true,
            onChange: (iPage, iPerPage) => {
              setPage(iPage);
              setPerPage(iPerPage || PER_PAGE);
            },
            total: pageInfo?.total,
          }
        }
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={products}
        loading={loading || loadingProductsByOffer}
        onChange={handleColumnChange}
      />
    </>
  );
};

export default ProductsPage;
