import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, message, Row } from 'antd';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import { IProductDTO, IInputProduct, IOfferProductInfoDTO, ILandingPageProductInfoDTO } from '../../dtos/ProductDTO';

import {
  IFetchProductInfoVars,
  useFetchProductInfoMutation,
  useFetchLandingPageDataQuery,
  IProductNetworkSearchResultDTO,
  useSearchProductInNetworkCatalogsQuery,
} from '../../graphql/Hooks';
import ImageUploadField from '../Fields/ImageUploadField';
import BrandsField from '../Fields/BrandsField';
import CategoryField from '../Fields/CategoryField';
import MerchantField from '../Fields/MerchantField';
import { IMAGE_TYPES } from '../../utils/constants';
import Mutation from '../../graphql/Mutation';
import { asyncUseMutation } from '../../services/GraphQLService';

interface CreateProductFormProps {
  debug?: boolean;
  wpUserName?: string | null;
}

interface ISelect {
  value: string;
  label: any;
}

interface CreateProductFormState {
  asin: string;
  brand: ISelect;
  category: ISelect;
  image: any;
  merchant: ISelect;
  model: string;
  name: string;
  price: string;
  sku: string;
  upc: string;
  affiliateLink: string | undefined;
  url: string | undefined;
  buttonText: string;
}

function useProductSearch() {
  const [searchProductInNetworkCatalogs, searchResultsLoading] = useSearchProductInNetworkCatalogsQuery();
  const [searchResults, setSearchResults] = useState<IProductNetworkSearchResultDTO[] | null>(null);

  const doSearch = async (keyword: string) => {
    const res = await searchProductInNetworkCatalogs({
      filter: { keyword },
      limit: 3,
    });

    setSearchResults(res.data.object.edges.map(({ node }) => node));
  };

  return { searchResults, doSearch, searchResultsLoading };
}

const CreateProductPage: React.FC<CreateProductFormProps> = ({ debug, wpUserName }) => {
  const [form] = Form.useForm();
  const [showOfferUrlSection, setShowOfferUrlSection] = useState(true);
  const [initialFormError, setInitialFormError] = useState<string>();
  const [uploading, setUploading] = useState(false);
  const [create, { loading }] = useMutation(Mutation.getMutation('createProduct'));
  const [upload] = useMutation(Mutation.uploadImage.mutation);
  const [imageId, setImageId] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [fetchProductInfoData, productInfoLoading] = useFetchProductInfoMutation();
  const [fetchLandingPageData, productDataLoading] = useFetchLandingPageDataQuery();
  // CCP-554 TODO: add product search capability
  // const { searchResults, doSearch, searchResultsLoading } = useProductSearch();

  const handleUpperSubmit = useCallback(
    (event) => {
      const { action } = event.data;
      if (action === 'createProductFormSubmit') {
        form.submit();
      }
    },
    [form],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!debug) {
      window.addEventListener('message', handleUpperSubmit);
      return () => {
        window.removeEventListener('message', handleUpperSubmit);
      };
    }
  }, [debug, handleUpperSubmit]);

  const asyncUpload = useCallback(
    async (fieldName: string, { file, url }) => {
      const variables = {
        operation: 'uploadImage',
        field: fieldName,
        [fieldName]: file,
        url,
      };
      setUploading(true);
      try {
        const uploaded = await asyncUseMutation(upload, { variables });
        return uploaded.object;
      } finally {
        setUploading(false);
      }
    },
    [upload],
  );

  const validateUniqueASIN = useCallback(async (): Promise<void> => {
    const asin = form.getFieldValue('asin')?.trim();
    const upc = form.getFieldValue('upc')?.trim();
    const sku = form.getFieldValue('sku')?.trim();
    if (!asin && !upc && !sku) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('UPC/ASIN/SKU one of these fields should be entered');
    }
    return Promise.resolve();
  }, [form]);

  const uploadImage = useCallback(
    async (file: File) => {
      const saved = await asyncUpload('image', { file });
      setImageId(saved.id);
    },
    [asyncUpload],
  );

  const uploadImageByUrl = useCallback(
    async (url: string) => {
      const saved = await asyncUpload('image', { url });
      setImageId(saved.id);
    },
    [asyncUpload],
  );

  const onBackClick = useCallback(() => {
    form.resetFields();
    setImageId('');
    setImageUrl('');
    setShowOfferUrlSection(true);
  }, [form]);

  const uploadImageToCloudCity = useCallback(
    async (url: string) => {
      try {
        await uploadImageByUrl(url);
        setImageUrl(url);
        return url;
      } catch (_) {
        return undefined;
      }
    },
    [uploadImageByUrl],
  );

  const setProductFormGivenOfferUrl = useCallback(
    async (offerUrl: string) => {
      const res = await fetchLandingPageData({ url: offerUrl });
      const landingPageData = res.data.object || {};

      const foundId =
        (landingPageData.asin && landingPageData.asin?.length > 0) || (landingPageData.upc && landingPageData.upc?.length > 0);

      const fetchProductInfoResponse = foundId
        ? await fetchProductInfoData({
          asin: landingPageData.asin || undefined,
          upc: landingPageData.upc || undefined,
        })
        : {};

      const product = fetchProductInfoResponse.data?.fetchProductInfo?.product;

      let image = null;
      if (product?.image?.id) {
        setImageId(product.image?.id);
        setImageUrl(product.image?.url);
        image = product.image.url;
      } else if (landingPageData.imageUrl) {
        image = await uploadImageToCloudCity(landingPageData.imageUrl);
      }

      form.setFieldsValue({
        image,
        name: landingPageData.name || product?.name,
        model: landingPageData.model || product?.model,
        brand: product?.brand
          ? {
            value: product.brand.id,
            label: product.brand.name,
          }
          : undefined,
        category: product?.category
          ? {
            value: product.category.id,
            label: product.category.name,
          }
          : undefined,
        sku: landingPageData.sku,
        upc: landingPageData.upc || product?.upc,
        asin: landingPageData.asin || product?.asin,
        price: landingPageData.price,
        merchant: landingPageData.merchant
          ? {
            value: landingPageData.merchant.merchantId,
            label: landingPageData.merchant.name,
          }
          : undefined,
      });
    },
    [fetchLandingPageData, fetchProductInfoData, form, uploadImageToCloudCity],
  );

  const setProductFormGivenProductId = useCallback(
    async (asin: string | undefined, upc: string | undefined) => {
      const fetchProductInfoResponse =
        asin || upc
          ? await fetchProductInfoData({
            asin,
            upc,
          })
          : {};

      const product = fetchProductInfoResponse.data?.fetchProductInfo?.product;

      if (product?.image) {
        setImageId(product.image?.id);
        setImageUrl(product.image?.url);
      }

      form.setFieldsValue({
        image: product?.image?.url,
        name: product?.name,
        model: product?.model,
        brand: product?.brand
          ? {
            value: product.brand.id,
            label: product.brand.name,
          }
          : undefined,
        category: product?.category
          ? {
            value: product.category.id,
            label: product.category.name,
          }
          : undefined,
        upc: product?.upc,
        asin: product?.asin,
        price: product?.offersInfo?.[0]?.price || product?.offersInfo?.[0]?.originalPrice,
        affiliateLink: product?.offersInfo?.[0]?.url,
        merchant: product?.offersInfo?.[0]
          ? {
            value: product.offersInfo?.[0]?.merchant?.id,
            label: product.offersInfo?.[0]?.merchant?.name,
          }
          : undefined,
      });
    },
    [fetchProductInfoData, form],
  );

  const onNextClick = useCallback(async () => {
    const upc = form.getFieldValue('upc');
    const asin = form.getFieldValue('asin');
    const offerUrl = form.getFieldValue('url');

    try {
      if (offerUrl) {
        await setProductFormGivenOfferUrl(offerUrl);
      } else if (upc || asin) {
        await setProductFormGivenProductId(asin, upc);
      } else {
        setInitialFormError('No Offer URL, UPC or ASIN provided');
        setTimeout(() => {
          setInitialFormError('');
        }, 5000);
      }
    } finally {
      setShowOfferUrlSection(false);
    }
  }, [form, setProductFormGivenOfferUrl, setProductFormGivenProductId]);

  const handleSubmit = useCallback(
    async (val: CreateProductFormState) => {
      const data: IInputProduct = {
        name: val.name?.trim(),
        model: val.model?.trim(),
        upc: val.upc?.trim() || null,
        asin: val.asin?.trim() || null,
        imageId,
        brandId: val.brand?.value,
        categoryId: val.category?.value,
        landingPagesInfo:
          val.url && val.url?.length > 0
            ? [
              {
                price: parseFloat(val.price),
                url: val.url,
                status: 'UNKNOWN' as const,
                createdDate: Date.now(),
              },
            ]
            : [],
        offersInfo: [
          {
            merchantId: val.merchant?.value,
            price: parseFloat(val.price),
            originalPrice: parseFloat(val.price),
            url: val.affiliateLink || val.url || null,
            buttonText: val.buttonText,
            createdDate: Date.now(),
            startDate: Date.now(),
            endDate: moment().add('1', 'M').valueOf(),
            manual: false,
            outOfStock: false,
          },
        ],
        merchantsInfo:
          !!val.merchant?.value && val.sku?.trim()?.length > 0
            ? [
              {
                merchantId: val.merchant.value,
                sku: val.sku.trim(),
              },
            ]
            : [],
        headline: null,
        description: null,
        dtProductId: null,
        make: null,
        gtin: null,
        rating: null,
      };

      const created = await create({
        variables: { data, wpUserName },
      });

      if (!debug) {
        const productId = created.data.createProduct.id || null;
        window.parent.postMessage(
          {
            action: 'createProduct',
            productId,
          },
          '*',
        );
      } else {
        message.info('The product has been saved');
      }
    },
    [create, debug, imageId],
  );

  return (
    <>
      <Form onFinish={handleSubmit} className="thin-form" form={form} labelAlign="left" labelCol={{ span: 7 }}>
        <div style={{ display: showOfferUrlSection ? 'block' : 'none' }}>
          <h4 style={{ marginLeft: '164px' }}>Please enter one of the following</h4>
          {initialFormError && <div style={{ color: 'red', marginLeft: '164px' }}>{initialFormError}</div>}
          <Form.Item name="url" label="Offer Url" rules={[{ type: 'url', message: 'Invalid URL' }]}>
            <Input placeholder="https://www.amazon.com/dp/ASINASIN" />
          </Form.Item>
          <Form.Item name="upc" label="UPC" rules={[{ max: 12, min: 1, validator: validateUniqueASIN }]}>
            <Input placeholder="e.g. UPC-A 042100005264" maxLength={12} />
          </Form.Item>
          <Form.Item name="asin" label="ASIN" rules={[{ len: 10, validator: validateUniqueASIN }]}>
            <Input placeholder="e.g. B00005N5PF" maxLength={10} />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '15px',
            }}
          >
            <Button type="primary" onClick={onNextClick} loading={productDataLoading || productInfoLoading}>
              Next
            </Button>
          </div>
        </div>
        <div style={{ display: showOfferUrlSection ? 'none' : 'block' }}>
          <Row>
            <Col span={6}>
              <Form.Item name="image" rules={[{ required: true, message: 'Image is required' }]}>
                <ImageUploadField
                  loading={uploading}
                  onChange={uploadImage}
                  accept={IMAGE_TYPES}
                  max={5}
                  imageUrl={imageUrl}
                  disabled={false}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item name="brand" label="Brand">
                <BrandsField showSearch />
              </Form.Item>
              <Form.Item name="model" label="Model">
                <Input placeholder="e.g. SX-800 H" />
              </Form.Item>
              <Form.Item name="name" label="Display Name" rules={[{ required: true, message: 'Name is required' }]}>
                <Input autoComplete="false" placeholder="e.g. Samsung SX-800 H" />
              </Form.Item>
              <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Category is required' }]}>
                <CategoryField />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={18} offset={6}>
              <Form.Item name="upc" label="UPC" rules={[{ max: 12, min: 1, validator: validateUniqueASIN }]}>
                <Input placeholder="e.g. UPC-A 042100005264" maxLength={12} />
              </Form.Item>
              <Form.Item name="asin" label="ASIN" rules={[{ len: 10, validator: validateUniqueASIN }]}>
                <Input placeholder="e.g. B00005N5PF" maxLength={10} />
              </Form.Item>
              <Form.Item name="sku" label="SKU" rules={[{ max: 100, min: 1, validator: validateUniqueASIN }]}>
                <Input placeholder="e.g. UGG-BB-PUR-06" />
              </Form.Item>
              {form.getFieldValue('affiliateLink')?.length > 0 ? (
                <Form.Item
                  name="affiliateLink"
                  label="Affiliate Link"
                  rules={[
                    { required: true, message: 'Affiliate Link is required' },
                    { type: 'url', message: 'Invalid URL' },
                  ]}
                >
                  <Input placeholder="Please Enter an affiliate link for the offer URL" />
                </Form.Item>
              ) : (
                <Form.Item
                  name="url"
                  label="Offer Url"
                  rules={[
                    { required: true, message: 'Offer URL is required' },
                    { type: 'url', message: 'Invalid URL' },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item name="merchant" label="Merchant" rules={[{ required: true, message: 'Merchant is required' }]}>
                <MerchantField placeholder="Select Merchant" />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true, message: 'Price is required' },
                  {
                    pattern: /^\d{1,6}(\.\d{1,2})?$/,
                    message: 'Price should be a number. E.g. 1.23',
                  },
                ]}
              >
                <Input placeholder="0.00" />
              </Form.Item>

              <Form.Item label="CTA" name="buttonText">
                <Input placeholder="Custom call to action" />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '15px',
            }}
          >
            <Button style={{ marginRight: '20px' }} type="primary" onClick={onBackClick}>
              Back
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading || uploading}
              style={{ display: debug ? 'block' : 'none' }}
            >
              Create
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateProductPage;
