import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { Menu, Modal } from 'antd';

import {
  AppstoreFilled,
  AuditOutlined,
  TeamOutlined,
  LogoutOutlined,
  NotificationOutlined,
  ApartmentOutlined,
  TrademarkOutlined,
  ImportOutlined,
  TagsOutlined,
  ShoppingOutlined,
  ExclamationCircleOutlined,
  ContainerOutlined,
  ShopOutlined,
  LinkOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';

import { Logo } from './styles';

import { ROUTES } from '../../../utils/constants';
import MenuOption from '../../MenuOption';
import { useAuth } from '../../../hooks/auth';

const SUBMENU = [
  ROUTES.products,
  ROUTES.merchants,
  ROUTES.import,
  ROUTES.productCategories,
  ROUTES.affiliateNetworks,
  ROUTES.brands,
  ROUTES.publishers,
] as string[];

const Header: React.FC = () => {
  const [currentTab, setCurrentTab] = useState('database');
  const { signOut, isPermitted } = useAuth();
  const location = useLocation();

  const handleLogouc = useCallback(() => {
    Modal.confirm({
      title: 'Signing Out?',
      content: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, I am!',
      onOk() {
        signOut();
      },
    });
  }, [signOut]);

  const isPathSelected = useCallback((path: string) => `/${path}` === currentTab, [currentTab]);

  const handleUpdateTab = useCallback((path: string) => {
    if (SUBMENU.includes(path)) {
      setCurrentTab('/database');
    } else {
      setCurrentTab(path);
    }
  }, []);

  useEffect(() => {
    handleUpdateTab(location.pathname);
  }, [handleUpdateTab, location]);

  return (
    <>
      <Link to="/" style={{ float: 'left', paddingTop: '5px' }}>
        <Logo preview={false} src="/dt-logo-with-text.png" />
      </Link>
      <Menu onClick={(e) => handleUpdateTab(e.key)} selectedKeys={[currentTab]} mode="horizontal">
        <Menu.SubMenu
          key="database"
          title={
            <MenuOption icon={<AppstoreFilled />} selected={isPathSelected('database')}>
              Database
            </MenuOption>
          }
        >
          {isPermitted('products') && (
            <Menu.Item key="products">
              <MenuOption to={ROUTES.products} icon={<ShoppingOutlined />} key="products">
                Products
              </MenuOption>
            </Menu.Item>
          )}
          {isPermitted('inventory') && (
            <Menu.Item key="inventory">
              <MenuOption to={ROUTES.inventory} icon={<ContainerOutlined />} selected={isPathSelected('inventory')}>
                Inventory
              </MenuOption>
            </Menu.Item>
          )}
          <Menu.Item key="merchants">
            <MenuOption to={ROUTES.merchants} icon={<ShopOutlined />} key="marchants">
              Merchants
            </MenuOption>
          </Menu.Item>
          <Menu.Item key="import">
            <MenuOption to={ROUTES.import} icon={<ImportOutlined />}>
              Import CSV
            </MenuOption>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="categories">
            <MenuOption to={ROUTES.productCategories} icon={<TagsOutlined />}>
              Categories
            </MenuOption>
          </Menu.Item>
          <Menu.Item key="affiliateNetworks">
            <MenuOption to={ROUTES.affiliateNetworks} icon={<ApartmentOutlined />}>
              Affiliate Networks
            </MenuOption>
          </Menu.Item>
          <Menu.Item key="brands">
            <MenuOption to={ROUTES.brands} icon={<TrademarkOutlined />}>
              Brands
            </MenuOption>
          </Menu.Item>
        </Menu.SubMenu>
        {isPermitted('campaigns') && (
          <Menu.Item key="campaigns">
            <MenuOption to={ROUTES.campaigns} icon={<NotificationOutlined />} selected={isPathSelected('campaigns')}>
              Campaigns
            </MenuOption>
          </Menu.Item>
        )}

        {isPermitted('users') && (
          <Menu.Item key="publishers">
            <MenuOption to={ROUTES.publishers} icon={<AuditOutlined />} selected={isPathSelected('publishers')}>
              Publishers
            </MenuOption>
          </Menu.Item>
        )}

        {isPermitted('linkGenerator') && (
          <Menu.Item key="linkGenerator">
            <MenuOption to={ROUTES.linkGenerator} icon={<LinkOutlined />} selected={isPathSelected('linkGenerator')}>
              Link Generator
            </MenuOption>
          </Menu.Item>
        )}

        {isPermitted('users') && (
          <Menu.Item key="users">
            <MenuOption to={ROUTES.users} icon={<TeamOutlined />} selected={isPathSelected('users')}>
              Users
            </MenuOption>
          </Menu.Item>
        )}

        <Menu.Item key="logout" style={{ float: 'right' }}>
          <MenuOption onClick={handleLogouc} icon={<LogoutOutlined />}>
            Logout
          </MenuOption>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Header;
