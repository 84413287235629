import React, { useEffect, useMemo, useState } from 'react';

import { Checkbox, Divider, Form, Input, Tabs } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

import { useLazyQuery } from '@apollo/client';

import Text from 'antd/lib/typography/Text';
import AffiliateNetworkField from '../../Fields/AffiliateNetworkField';
import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import { IMerchantDTO } from '../../../dtos/MerchantDTO';

import { MerchantIDLabel } from './styles';
import ImageUploadField from '../../Fields/ImageUploadField';
import { parseUrl, IMAGE_TYPES } from '../../../utils/constants';
import MerchantCommissionRateForm from '../MerchantCommissionRateForm';
import TagList from '../../TagList';
import { useAuth } from '../../../hooks/auth';

const { TabPane } = Tabs;

const MerchantForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const { isPermitted } = useAuth();
  const [loadMerchant, { data }] = useLazyQuery(Query.merchant.query);
  const [validDomain, setValidDomain] = useState<boolean>();

  const merchant = useMemo(() => {
    if (!entity || !data) {
      return undefined;
    }
    return data.merchant as IMerchantDTO;
  }, [entity, data]);

  useEffect(() => {
    if (!merchant || !form) {
      return;
    }
    form.setFieldsValue({
      ...merchant,
      affiliateNetwork: merchant.affiliateNetwork
        ? {
            label: merchant.affiliateNetwork.name,
            value: merchant.affiliateNetwork.id,
          }
        : undefined,
    });
  }, [form, merchant]);

  const validatePattern = (pattern: string) => {
    if (pattern.indexOf(' ') > -1) {
      return 'Remove white spaces';
    }
    return undefined;
  };

  const handleValidateURL = (urlEvent: any) => {
    const { value } = urlEvent.target;

    const formatHostname = (hostname: string) => {
      return hostname.toLowerCase().replace('www.', '');
    };

    const validateTypedUrl = (url: string) => {
      if (!url || url.length === 0) {
        return undefined;
      }
      const normalizedUrl = (url.startsWith('http') ? url : `https://${url}`).toLowerCase();
      const linkableDomains = form.getFieldValue('linkableDomains') as string[];
      const nonlinkableDomains = form.getFieldValue('nonlinkableDomains') as string[];

      if (nonlinkableDomains.length === 0 && linkableDomains.length === 0) {
        return true;
      }

      const isLinkable =
        !linkableDomains.length ||
        linkableDomains.map(formatHostname).find((d) => normalizedUrl.indexOf(d) > -1);

      const isNonlinkable = nonlinkableDomains.map(formatHostname).find((d) => normalizedUrl.indexOf(d) > -1);

      return !!isLinkable && !isNonlinkable;
    };
    setValidDomain(validateTypedUrl(value));
  };

  useEffect(() => {
    if (entity) {
      loadMerchant({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadMerchant]);

  const merchantWebsite = useMemo(() => {
    if (merchant?.website) {
      const url = parseUrl(merchant.website);
      return url?.hostname?.replace('www.', '') || undefined;
    }
    return undefined;
  }, [merchant?.website]);

  return (
    <Tabs
      defaultActiveKey="1"
      size="small"
      type="card"
      style={{
        marginTop: -18,
      }}
    >
      <TabPane forceRender tab="General Info" key="1">
        <Form form={form} labelAlign="right" labelCol={{ span: 6 }} {...props}>
          {entity && (
            <Form.Item name="name" label="ID">
              <MerchantIDLabel>{entity.id}</MerchantIDLabel>
            </Form.Item>
          )}
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="e.g. Apple" autoComplete="off" />
          </Form.Item>

          <Form.Item name="displayName" label="Display Name">
            <Input placeholder="e.g. Apple" autoComplete="off" />
          </Form.Item>

          <Form.Item name="website" label="Website">
            <Input placeholder="e.g. http://apple.com" />
          </Form.Item>

          <Form.Item name="externalId" label="External ID">
            <Input readOnly />
          </Form.Item>

          <Form.Item name="affiliateNetwork" label="Affiliate Network">
            <AffiliateNetworkField />
          </Form.Item>

          <Form.Item name="logo" label="Logo">
            <ImageUploadField accept={IMAGE_TYPES} max={5} imageUrl={merchant?.logo?.url} />
          </Form.Item>

          <Form.Item name="disabled" label="Disabled" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Form>
      </TabPane>
      {merchant?.commissionRate?.length && (
        <TabPane forceRender tab="Merchant Commission Rate Info" key="2">
          {merchant?.commissionRate.map((formListFieldData, idx) => (
            <MerchantCommissionRateForm
              key={`commission_rate_${idx}`}
              index={idx}
              actionName={formListFieldData.actionName}
              pricingModel={formListFieldData.pricingModel}
              type={formListFieldData.type}
              fixedRate={formListFieldData.fixedRate}
              percentageRate={formListFieldData.percentageRate}
              createdAt={formListFieldData.createdAt}
            />
          ))}
        </TabPane>
      )}
      <TabPane forceRender tab="Domain Patterns" key="3">
        <Form form={form} labelAlign="right" labelCol={{ span: 6 }} {...props}>
          <Form.Item label="Website">
            <Text type="secondary">{merchantWebsite || 'None'}</Text>
          </Form.Item>
          <Form.Item name="linkableDomains" label="Linkable">
            <TagList
              placeholder="e.g. sony.com"
              buttonLabel="Add Linkable Domain"
              tagColor="blue"
              readOnly={!isPermitted('manageMerchantDomains')}
              validate={validatePattern}
            />
          </Form.Item>
          <Form.Item name="nonlinkableDomains" label="Non Linkable">
            <TagList
              placeholder="e.g. sony.com"
              buttonLabel="Add Non Linkable Domain"
              tagColor="red"
              readOnly={!isPermitted('manageMerchantDomains')}
              validate={validatePattern}
            />
          </Form.Item>
          <Divider />
          <Form.Item label="URL Checker">
            <Input placeholder="e.g. www.sony.com" onChange={handleValidateURL} />
            {validDomain === false && (
              <span
                style={{
                  color: 'red',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Text type="danger">Invalid domain</Text>
                <CloseCircleOutlined
                  style={{
                    marginLeft: 4,
                  }}
                />
              </span>
            )}
            {validDomain === true && (
              <span
                style={{
                  color: 'green',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span>Valid domain</span>
                <CheckCircleOutlined
                  style={{
                    marginLeft: 4,
                  }}
                />
              </span>
            )}
          </Form.Item>
        </Form>
      </TabPane>
    </Tabs>
  );
};

export default MerchantForm;
