import React, { createContext, useCallback, useState, useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router';
import SignedLayout from '../components/Layout/SignedLayout';
import UnsignedLayout from '../components/Layout/UnsignedLayout';
import { useAuth } from './auth';

interface LayoutState {
  noLayout?: boolean;
  newPath: boolean;
}

interface LayoutContextData {
  noLayout?: boolean;
  newPath: boolean;
  useNoLayout(noLayout: boolean | undefined): void;
}

const LayoutContext = createContext<LayoutContextData>({
  newPath: true,
} as LayoutContextData);

export const LayoutProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  const [path, setPath] = useState<string>();

  const [data, setData] = useState<LayoutState>(() => {
    return {} as LayoutState;
  });

  const useNoLayout = useCallback((noLayout: boolean | undefined): void => {
    setData((previous) => ({ ...previous, noLayout }));
  }, []);

  const content = useMemo(() => {
    if (data.noLayout) {
      return <>{children}</>;
    }
    if (user) {
      return <SignedLayout>{children}</SignedLayout>;
    }
    return <UnsignedLayout>{children}</UnsignedLayout>;
  }, [children, data.noLayout, user]);

  useEffect(() => {
    if (location.pathname !== path) {
      setData((previous) => ({ ...previous, newPath: true }));
      setPath(location.pathname);
      return;
    }
    setData((previous) => ({ ...previous, newPath: false }));
  }, [location.pathname, path]);

  const values = useMemo(
    () => ({
      noLayout: data.noLayout,
      newPath: data.newPath,
      useNoLayout,
    }),
    [data.newPath, data.noLayout, useNoLayout],
  );

  return <LayoutContext.Provider value={values}>{content}</LayoutContext.Provider>;
};

export function useLayout(): LayoutContextData {
  const context = useContext(LayoutContext);
  return context;
}
