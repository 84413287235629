import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { WidgetDTO } from '../../dtos/ProductDTO';
import Query from '../../graphql/Query';

import {
  Container,
  InputHolder,
  InputLabel,
  InputButton,
  InputText,
  Widget,
  WidgetImage,
  WidgetLink,
  WidgetContent,
  WidgetRatingTitle,
  WidgetRatingStar,
  WidgetRating,
  WidgetHeadlineText,
  WidgetTitleText,
  WidgetDescriptionText,
  WidgetButton,
  WidgetButtons,
  WidgetStar,
} from './styles';

const WidgetPage: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialProductId = params.get('productId') || '';
  const [searchProductId, setSearchProductId] = useState(initialProductId);
  const [productId, setProductId] = useState<string>(params.get('productId') || '');
  const { data, loading } = useQuery(Query.widget.query, {
    variables: {
      id: productId,
    },
  });
  const [widget, setWidget] = useState<WidgetDTO>();

  useEffect(() => {
    if (data) {
      setWidget(data.productWidget);
    } else {
      setWidget(undefined);
    }
  }, [data]);

  const handleChangeProductID = useCallback((e) => {
    setSearchProductId(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (searchProductId.length > 0) {
      setProductId(searchProductId);
    }
  }, [searchProductId]);

  const ratingStars = useMemo(() => {
    const rating = widget?.product?.rating || 3.5;
    const totalStars = Math.floor(rating);
    const arrayStars = [...Array(totalStars)];
    const halfStar = rating - totalStars >= 0.5;
    return (
      <>
        {arrayStars.map((n) => (
          <WidgetStar key={`star_${n}`} />
        ))}
        {halfStar && <WidgetStar half />}
      </>
    );
  }, [widget]);

  return (
    <Container>
      {(initialProductId.length === 0 || !widget) && (
        <>
          <InputHolder>
            <InputLabel>Product ID</InputLabel>
            <InputText type="text" value={searchProductId} onChange={handleChangeProductID} />
          </InputHolder>
          <InputHolder>
            <InputLabel>Categories</InputLabel>
            <InputText type="text" placeholder="phones" />
          </InputHolder>
          <InputButton onClick={handleSubmit} disabled={loading}>
            load widget
          </InputButton>
        </>
      )}
      {widget && (
        <Widget>
          <WidgetLink href="#" target="_blank">
            <WidgetImage preview={false} src={widget.product.image.url} />
            <WidgetRating>
              <WidgetRatingTitle>Our Score</WidgetRatingTitle>
              <WidgetRatingStar>{ratingStars}</WidgetRatingStar>
            </WidgetRating>
            <WidgetContent>
              <WidgetHeadlineText>{widget.product.headline}</WidgetHeadlineText>
              <WidgetTitleText>{widget.product.name}</WidgetTitleText>
              <WidgetDescriptionText>{widget.product.description}</WidgetDescriptionText>
              <WidgetButtons>
                {widget.buttons.map((b) => (
                  <WidgetButton key={b.url} target="_blank" href={b.url}>
                    ${b.price}.00 from {b.merchant.name}
                  </WidgetButton>
                ))}
              </WidgetButtons>
            </WidgetContent>
          </WidgetLink>
        </Widget>
      )}
    </Container>
  );
};

export default WidgetPage;
