import React, { useState } from 'react';

import { Form, Input, Tooltip } from 'antd';

import { DeleteFilled } from '@ant-design/icons';

import { Container, Content, ButtonRemove } from './styles';
import MerchantField from '../../Fields/MerchantField';

interface ProductMerchantFormProps {
  name: number;
  fieldKey: number;
  onRemove(): void;
}

const ProductMerchantForm: React.FC<ProductMerchantFormProps> = ({ name, fieldKey, onRemove }) => {
  const [deleteHover, setDeleteHover] = useState(false);

  return (
    <Container isHover={deleteHover}>
      <Content>
        <Form.Item
          label="Merchant"
          labelCol={{
            span: 8,
          }}
          name={[name, 'merchant']}
          style={{
            width: 360,
            marginBottom: 0,
          }}
          fieldKey={[fieldKey, 'merchant']}
        >
          <MerchantField />
        </Form.Item>
        <Form.Item
          label="SKU"
          name={[name, 'sku']}
          style={{
            marginLeft: 20,
            width: 280,
            marginBottom: 0,
          }}
          fieldKey={[fieldKey, 'sku']}
        >
          <Input placeholder="e.g. UGG-BB-PUR-06" maxLength={15} />
        </Form.Item>
      </Content>
      <Tooltip title="Remove mMerchant">
        <ButtonRemove
          onMouseOver={() => setDeleteHover(true)}
          onMouseOut={() => setDeleteHover(false)}
          type="link"
          shape="circle"
          onClick={onRemove}
        >
          <DeleteFilled />
        </ButtonRemove>
      </Tooltip>
    </Container>
  );
};

export default ProductMerchantForm;
