import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Popover, Table, Tooltip, Checkbox, TableProps, Card, Pagination } from 'antd';
import { SettingOutlined, MenuOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  Container,
  Content,
  ActionsContainer,
  ActionsPanel,
  ActionContent,
  TitlePopover,
  PaginationContent,
} from './styles';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = SortableElement((iProps: any) => <tr {...iProps} />);
const SortableContent = SortableContainer((iProps: any) => <tbody {...iProps} />);

export interface IColumnConfig {
  index: number;
  title: string;
  visible?: boolean;
  disabled?: boolean;
}

export interface ITypeHideable {
  hideByDefault?: boolean;
  title?: string;
  disabled?: boolean;
}

interface IExtraActionIcon {
  width?: any;
  height?: any;
}

export interface IExtraAction {
  icon: React.ComponentType<IExtraActionIcon>;
  title?: string;
  visible?: boolean;
  onClick?(): void;
  backgroundColor?: string;
}

export interface DynamicColumnType extends ColumnType<any> {
  hideable?: boolean | ITypeHideable;
}

export declare type DynamicColumnsType = DynamicColumnType[];

export interface IDynamicTableProps extends TableProps<any> {
  columns?: DynamicColumnsType;
  allowReorderColumns?: boolean;
  onChangeColumnsConfig?(columnsConfig?: IColumnConfig[]): void;
  initialConfig?: IColumnConfig[];
  extraActions?: IExtraAction[];
}

const DynamicTable: React.FC<IDynamicTableProps> = ({
  allowReorderColumns = true,
  columns,
  onChangeColumnsConfig,
  initialConfig,
  extraActions,
  pagination,
  size = 'small',
  ...props
}) => {
  const [showConfig, setShowConfig] = useState(false);
  const [columnsConfig, setColumnsConfig] = useState<IColumnConfig[]>([]);

  useEffect(() => {
    if (initialConfig) {
      setColumnsConfig(initialConfig);
    } else if (columns) {
      const ic = columns.map((c, index) => {
        if (!c.hideable || typeof c.hideable === 'boolean') {
          return {
            index,
            title: c.title as string,
            visible: c.hideable || true,
          };
        }
        return {
          index,
          title: c.hideable.title || (c.title as string),
          visible: !c.hideable.hideByDefault,
          disabled: c.hideable.disabled,
        };
      });
      setColumnsConfig(ic);
    }
  }, [columns, initialConfig]);

  const handleSaveColumnConfig = useCallback(
    (newConfig: IColumnConfig[]) => {
      const configWithindex = newConfig.map((a, index) => ({ ...a, index }));
      setColumnsConfig(configWithindex);
      if (onChangeColumnsConfig) {
        onChangeColumnsConfig(configWithindex);
      }
    },
    [onChangeColumnsConfig],
  );

  const handleChangeColumnVisibility = useCallback(
    (item, checked) => {
      const columnIndex = columnsConfig.findIndex((o) => o.title === item.title);
      if (columnIndex > -1) {
        const newOrder = [...columnsConfig];
        newOrder[columnIndex].visible = checked;
        setColumnsConfig(newOrder);
        handleSaveColumnConfig(newOrder);
      }
    },
    [columnsConfig, handleSaveColumnConfig],
  );

  const sortColumns = useMemo(() => {
    const result: ColumnType<any>[] = [];
    if (allowReorderColumns) {
      result.push({
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      });
    }
    result.push({
      dataIndex: 'title',
      className: 'drag-visible',
      render: (title: string, item: any) => {
        if (!item.hideable || typeof item.hideable === 'boolean') {
          return title;
        }
        return item.hideable.title || title;
      },
    });
    result.push({
      dataIndex: 'visible',
      width: 50,
      render: (visible: boolean, item: any) => (
        <Checkbox
          disabled={item.disabled}
          defaultChecked={visible}
          onChange={(val) => handleChangeColumnVisibility(item, val.target.checked)}
        />
      ),
    });
    return result;
  }, [allowReorderColumns, handleChangeColumnVisibility]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMove(columnsConfig, oldIndex, newIndex).filter((el) => !!el);
        handleSaveColumnConfig(newData);
      }
    },
    [columnsConfig, handleSaveColumnConfig],
  );

  const draggableBodyRow = useCallback(
    ({ className: _, style: __, ...restProps }) => {
      const index = columnsConfig.findIndex((x) => x.index === restProps['data-row-key']);
      return <SortableItem index={index} {...restProps} />;
    },
    [columnsConfig],
  );

  const draggableContainer = useCallback(
    (iProps: any) => {
      return (
        <SortableContent
          lockAxis="y"
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...iProps}
        />
      );
    },
    [onSortEnd],
  );

  const fixedColumns = useMemo(() => {
    const ordered = columnsConfig.reduce((found, o) => {
      if (!o.visible) {
        return found;
      }
      const next = (columns || []).find((c) => {
        if (!c.hideable || typeof c.hideable === 'boolean') {
          return o.title === c.title;
        }
        return o.title === (c.hideable.title || c.title);
      });
      if (next) {
        found.push(next);
      }
      return found;
    }, [] as DynamicColumnType[]);
    return ordered;
  }, [columns, columnsConfig]);

  return (
    <Container>
      <Content>
        <Card
          bodyStyle={{
            padding: 0,
          }}
        >
          <Table columns={fixedColumns} pagination={false} size={size} {...props} />
        </Card>
        <ActionsContainer>
          <ActionsPanel>
            <Popover
              placement="left"
              onVisibleChange={() => setShowConfig(!showConfig)}
              overlayStyle={{
                width: 400,
              }}
              overlayInnerStyle={{
                top: 40,
              }}
              content={
                <Table
                  size="small"
                  scroll={{ y: 540 }}
                  showHeader={false}
                  pagination={false}
                  rowKey="index"
                  columns={sortColumns}
                  dataSource={columnsConfig}
                  components={
                    allowReorderColumns
                      ? {
                          body: {
                            wrapper: draggableContainer,
                            row: draggableBodyRow,
                          },
                        }
                      : undefined
                  }
                />
              }
              title={
                <TitlePopover>
                  <span>Columns config</span>
                </TitlePopover>
              }
              trigger="click"
              visible={showConfig}
            >
              <ActionContent visible={!props.loading}>
                <Tooltip title="Columns Config">
                  <SettingOutlined width={100} height={100} />
                </Tooltip>
              </ActionContent>
            </Popover>

            {extraActions?.map(({ icon: Icon, visible, onClick, title, backgroundColor }, idx) => (
              <ActionContent
                color={backgroundColor}
                key={`icon_${idx}`}
                visible={!props.loading && visible}
                onClick={onClick}
              >
                <Tooltip title={title}>
                  <Icon width={100} height={100} />
                </Tooltip>
              </ActionContent>
            ))}
          </ActionsPanel>
        </ActionsContainer>
      </Content>
      {pagination && (
        <PaginationContent>
          <Pagination
            disabled={props.loading === true}
            current={pagination.current}
            onChange={pagination.onChange}
            pageSize={pagination.pageSize}
            showSizeChanger={pagination.showSizeChanger}
            pageSizeOptions={['20', '50', '100']}
            total={pagination.total}
          />
        </PaginationContent>
      )}
    </Container>
  );
};

export default DynamicTable;
