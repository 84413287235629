import styled from 'styled-components';
import { Card, Typography } from 'antd';

const { Text } = Typography;

interface CenterColumnProps {
  width?: number;
}

interface SmallTextProps {
  maxWidth?: number;
}

export const ImageContainer = styled.div`
  width: 54px;
  display: flex;
  align-items: center;
`;

export const ProductSummary = styled(Card)`
  font-size: 12px;
  background: none;
  margin: auto 0;

  .ant-card-meta-title {
    font-size: 13px;
    margin-bottom: 0 !important;
  }
  .ant-card-meta {
    display: flex;
  }
  .ant-card-meta-avatar {
    display: flex;
    float: none;
  }
  .ant-card-body {
    padding: 0px;
  }
  .ant-card-meta-detail {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 1px !important;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-size: 12px !important;
  }
`;

export const CenteredColumn = styled.div<CenterColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
`;

export const SmallText = styled(Text)<SmallTextProps>`
  font-size: 12px;
  max-width: ${(props) => props.maxWidth}px !important;
  text-align: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
