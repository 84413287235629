import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { IProductDTO, IInputProduct } from '../../dtos/ProductDTO';

import {
  useFetchProductInfoMutation,
  useFetchLandingPageDataQuery,
  useSearchProductsQuery,
} from '../../graphql/Hooks';
import ImageUploadField from '../Fields/ImageUploadField';
import BrandsField from '../Fields/BrandsField';
import CategoryField from '../Fields/CategoryField';
import MerchantField from '../Fields/MerchantField';
import { IMAGE_TYPES } from '../../utils/constants';
import Mutation from '../../graphql/Mutation';
import { asyncUseMutation } from '../../services/GraphQLService';
import PlacementField from "../Fields/PlacementField";
import ProductSearchField from "../Fields/ProductSearchField";
import {ProductItem, ProductItemContent} from "../../pages/CreateDeeplinkPage/styles";
import Query from "../../graphql/Query";
import { useQueryStringParam } from '../../hooks/url';

interface CreateProductFormProps {
  debug?: boolean;
}

interface ISelect {
  value: string;
  label: any;
}

interface IPlacementId {
  key: string;
  value: string;
}

interface CreateProductFormState {
  asin: string;
  brand: ISelect;
  category: ISelect;
  image: any;
  merchant: ISelect;
  model: string;
  name: string;
  price: string;
  sku: string;
  upc: string;
  affiliateLink: string | undefined;
  url: string | undefined;
  buttonText: string;
  placementType: string;
  placement: IPlacementId | undefined;
  offersNumber: string | undefined;
  widgetVersion: string | undefined;
  overrideTitle: string | undefined;
  overrideDescription: string | undefined;
}

const PLACEMENT_TYPES = [
  {
    key: 'textlink',
    value: 'Text Link',
  },
  {
    key: 'button',
    value: 'Button',
  },
  {
    key: 'widget',
    value: 'Widget',
  },
];

const ArhFieldset: React.FC<any> = ({ children, title, style }) =>
  <fieldset className="arh-fieldset" style={{ fontWeight: 700, }}>
    <legend>{title}</legend>
    {children}
  </fieldset>

const CreateProductPageV2: React.FC<CreateProductFormProps> = ({ debug }) => {
  const [form] = Form.useForm();
  const [showOfferUrlSection, setShowOfferUrlSection] = useState(true);
  const [initialFormError, setInitialFormError] = useState<string>();
  const [uploading, setUploading] = useState(false);
  const [placementType, setPlacementType] = useState<string>('textlink');
  const [widgetVersion, setWidgetVersion] = useState<string>('1');
  const [selectedProduct, setSelectedProduct] = useState<IProductDTO>();
  const [create, { loading }] = useMutation(Mutation.getMutation('createProduct'));
  const [upload, { loading: imageLoading }] = useMutation(Mutation.uploadImage.mutation);
  const [imageId, setImageId] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [fetchProductInfoData, productInfoLoading] = useFetchProductInfoMutation();
  const [fetchLandingPageData, productDataLoading] = useFetchLandingPageDataQuery();
  const [fetchProducts, productsLoading] = useSearchProductsQuery();
  const [asinSearchWarning, setAsinSearchWarning] = useState<string>();
  const [upcSearchWarning, setUpcSearchWarning] = useState<string>();
  const [loadProduct, { data: singleProductData, loading: singleProductLoading }] = useLazyQuery(Query.product.query)
  const [productIdParameter, setProductId] = useQueryStringParam('productId', '');
  const [placementTypeParameter, setPlacementTypeParameter] = useQueryStringParam('placementType', '');
  const [placement, setPlacement] = useQueryStringParam('placementId', '');
  const [numberOfOffers, setNumberOfOffers] = useQueryStringParam('numberOfOffers', '');
  const [widgetVersionParameter, setWidgetVersionParameter] = useQueryStringParam('widgetVersion', '');
  const [overrideTitle, setOverrideTitle] = useQueryStringParam('overrideTitle', '');
  const [overrideDescription, setOverrideDescription] = useQueryStringParam('overrideDescription', '');

  // CCP-554 TODO: add product search capability
  // const { searchResults, doSearch, searchResultsLoading } = useProductSearch();

  const handleUpperSubmit = useCallback(
    (event) => {
      const { action } = event.data;
      if (action === 'createProductFormSubmit') {
        if (!showOfferUrlSection) {
          form.submit();
        } else {
          setInitialFormError('No Product selected');
          setTimeout(() => {
            setInitialFormError('');
          }, 5000);
        }
      }
    },
    [form, showOfferUrlSection],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!debug) {
      window.addEventListener('message', handleUpperSubmit);
      return () => {
        window.removeEventListener('message', handleUpperSubmit);
      };
    }
  }, [debug, handleUpperSubmit]);

  useEffect(() => {
    if (productIdParameter) {
      setShowOfferUrlSection(false);
      setSelectedProduct({ id: productIdParameter, offerTotals: 0 });
      loadProduct({
        variables: {
          id: productIdParameter,
        }
      });

      form.setFieldsValue({
        numberOfOffers,
        widgetVersion: widgetVersionParameter,
        overrideTitle,
        overrideDescription,
      })

      if (placementTypeParameter) {
        form.setFieldsValue({
          placementType: placementTypeParameter,
        });
        setPlacementType(placementTypeParameter);
      }

      if (placement) {
        form.setFieldsValue({
          placement: { value: placement }
        })
      }
    }
  },[productIdParameter]);

  useEffect(() => {
    if (!singleProductData) {
      return;
    }

    setSelectedProduct(singleProductData?.product);
    form.setFieldsValue({
      productName: singleProductData?.product?.name,
    });
  }, [singleProductData]);

  const asyncUpload = useCallback(
    async (fieldName: string, { file, url }) => {
      const variables = {
        operation: 'uploadImage',
        field: fieldName,
        [fieldName]: file,
        url,
      };
      setUploading(true);
      try {
        const uploaded = await asyncUseMutation(upload, { variables });
        return uploaded.object;
      } finally {
        setUploading(false);
      }
    },
    [upload],
  );

  const validateUniqueASIN = useCallback(async (): Promise<void> => {
    setUpcSearchWarning(undefined);
    setAsinSearchWarning(undefined);
    if (selectedProduct || showOfferUrlSection) {
      return Promise.resolve();
    }

    const asin = form.getFieldValue('asin')?.trim();
    const upc = form.getFieldValue('upc')?.trim();
    const sku = form.getFieldValue('sku')?.trim();
    if (!asin && !upc && !sku) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('UPC/ASIN/SKU one of these fields should be entered');
    }
    return Promise.resolve();
  }, [form]);

  const uploadImage = useCallback(
    async (file: File) => {
      const saved = await asyncUpload('image', { file });
      setImageId(saved.id);
    },
    [asyncUpload],
  );

  const uploadImageByUrl = useCallback(
    async (url: string) => {
      const saved = await asyncUpload('image', { url });
      setImageId(saved.id);
    },
    [asyncUpload],
  );

  const onBackClick = useCallback(() => {
    form.resetFields();
    setImageId('');
    setImageUrl('');
    setShowOfferUrlSection(true);
  }, [form]);

  const uploadImageToCloudCity = useCallback(
    async (url: string) => {
      try {
        await uploadImageByUrl(url);
        setImageUrl(url);
        return url;
      } catch (_) {
        return undefined;
      }
    },
    [uploadImageByUrl],
  );

  const setProductFormGivenOfferUrl = useCallback(
    async (offerUrl: string) => {
      const res = await fetchLandingPageData({ url: offerUrl });
      const landingPageData = res.data.object || {};

      const foundId =
        (landingPageData.asin && landingPageData.asin?.length > 0) || (landingPageData.upc && landingPageData.upc?.length > 0);

      const fetchProductInfoResponse = foundId
        ? await fetchProductInfoData({
            asin: landingPageData.asin || undefined,
            upc: landingPageData.upc || undefined,
          })
        : {};

      const product = fetchProductInfoResponse.data?.fetchProductInfo?.product;

      let image = null;
      if (product?.image?.id) {
        setImageId(product.image?.id);
        setImageUrl(product.image?.url);
        image = product.image.url;
      } else if (landingPageData.imageUrl) {
        image = await uploadImageToCloudCity(landingPageData.imageUrl);
      }

      form.setFieldsValue({
        image,
        name: landingPageData.name || product?.name,
        model: landingPageData.model || product?.model,
        brand: product?.brand
          ? {
              value: product.brand.id,
              label: product.brand.name,
            }
          : undefined,
        category: product?.category
          ? {
              value: product.category.id,
              label: product.category.name,
            }
          : undefined,
        sku: landingPageData.sku,
        upc: landingPageData.upc || product?.upc,
        asin: landingPageData.asin || product?.asin,
        price: landingPageData.price,
        merchant: landingPageData.merchant
          ? {
              value: landingPageData.merchant.merchantId,
              label: landingPageData.merchant.name,
            }
          : undefined,
      });
    },
    [fetchLandingPageData, fetchProductInfoData, form, uploadImageToCloudCity],
  );

  const setProductFormGivenProductId = useCallback(
    async (asin: string | undefined, upc: string | undefined) => {
      const fetchProductInfoResponse =
        asin || upc
          ? await fetchProductInfoData({
              asin,
              upc,
            })
          : {};

      const product = fetchProductInfoResponse.data?.fetchProductInfo?.product;

      if (product?.image) {
        setImageId(product.image?.id);
        setImageUrl(product.image?.url);
      }

      form.setFieldsValue({
        image: product?.image?.url,
        name: product?.name,
        model: product?.model,
        brand: product?.brand
          ? {
              value: product.brand.id,
              label: product.brand.name,
            }
          : undefined,
        category: product?.category
          ? {
              value: product.category.id,
              label: product.category.name,
            }
          : undefined,
        upc: product?.upc,
        asin: product?.asin,
        price: product?.offersInfo?.[0]?.price || product?.offersInfo?.[0]?.originalPrice,
        affiliateLink: product?.offersInfo?.[0]?.url,
        merchant: product?.offersInfo?.[0]
          ? {
              value: product.offersInfo?.[0]?.merchant?.id,
              label: product.offersInfo?.[0]?.merchant?.name,
            }
          : undefined,
      });
    },
    [fetchProductInfoData, form],
  );

  const searchProducts = useCallback(async (query: string) => {
    const products = await fetchProducts({
      offset: 0,
      limit: 10,
      keywords: query
    });

    return products?.data?.object;
  },
  []);

  const searchProductByAsinUpc = useCallback(async (asin: string | undefined, upc: string | undefined) => {
    if (!asin && !upc) {
      return false;
    }

    const searchByASIN = !!asin;
    const products = await searchProducts((searchByASIN ? asin : upc) as string);

    if (products?.pageInfo.total > 0) {
      if (products?.pageInfo.total === 1) {
        setSelectedProduct(products?.edges[0].node as IProductDTO);
        setShowOfferUrlSection(false);
        return true;
      }

      if (searchByASIN) {
        setAsinSearchWarning('We found couple of products in DB for this ASIN please use product search field select them')
      } else {
        setUpcSearchWarning('We found couple of products in DB for this UPC please use product search field to select them');
      }

      return true;
    } else if (products?.pageInfo.total === 0 && asin && upc) {
      const productsByUpc = await searchProducts(upc);

      if (productsByUpc?.pageInfo.total > 0) {
        if (productsByUpc?.pageInfo.total === 1) {
          setSelectedProduct(productsByUpc?.edges[0].node as IProductDTO);
          setShowOfferUrlSection(false);
          return true;
        }

        setUpcSearchWarning('We found couple of products in DB for this UPC please use product search field to select them');

        return true;
      }
    }

    return false;
  },
  []);

  const onNextClick = useCallback(async () => {
    const upc = form.getFieldValue('upc');
    const asin = form.getFieldValue('asin');
    const offerUrl = form.getFieldValue('url');
    setAsinSearchWarning(undefined);
    setUpcSearchWarning(undefined);

    if (!offerUrl && !upc && !asin) {
      setInitialFormError('No Offer URL, UPC or ASIN provided');
      setTimeout(() => {
        setInitialFormError('');
      }, 5000);

      return;
    }

    const foundProductByAsinUpc = await searchProductByAsinUpc(asin, upc);
    if (foundProductByAsinUpc) {
      return;
    }

    try {
      if (offerUrl) {
        await setProductFormGivenOfferUrl(offerUrl);
      } else if (upc || asin) {
        await setProductFormGivenProductId(asin, upc);
      }
    } finally {
      setShowOfferUrlSection(false);
    }
  }, [form, setProductFormGivenOfferUrl, setProductFormGivenProductId]);

  const handleCreateProduct = useCallback(async (val: CreateProductFormState) => {
    const data: IInputProduct = {
      name: val.name?.trim(),
      model: val.model?.trim(),
      upc: val.upc?.trim() || null,
      asin: val.asin?.trim() || null,
      imageId,
      brandId: val.brand?.value,
      categoryId: val.category?.value,
      landingPagesInfo:
        val.url && val.url?.length > 0
          ? [
            {
              price: parseFloat(val.price),
              url: val.url,
              status: 'UNKNOWN' as const,
              createdDate: Date.now(),
            },
          ]
          : [],
      offersInfo: [
        {
          merchantId: val.merchant?.value,
          price: parseFloat(val.price),
          originalPrice: parseFloat(val.price),
          url: val.affiliateLink || val.url || null,
          buttonText: val.buttonText,
          createdDate: Date.now(),
          startDate: Date.now(),
          endDate: moment().add('1', 'M').valueOf(),
          manual: false,
          outOfStock: false,
        },
      ],
      merchantsInfo:
        !!val.merchant?.value && val.sku?.trim()?.length > 0
          ? [
            {
              merchantId: val.merchant.value,
              sku: val.sku.trim(),
            },
          ]
          : [],
      headline: null,
      description: null,
      dtProductId: null,
      make: null,
      gtin: null,
      rating: null,
    };

    const created = await create({
      variables: { data },
    });

    const productInfo = {
      placementType: val.placementType,
      placementId: val.placement?.value,
      offersNumber: val.offersNumber,
      widgetVersion: val.widgetVersion,
      overrideTitle: val.overrideTitle,
      overrideDescription: val.overrideDescription,
    };

    if (!debug) {
      const productId = created.data.createProduct.id || null;
      window.parent.postMessage(
        {
          action: 'createProduct',
          productId,
          productInfo,
        },
        '*',
      );
    } else {
      message.info('The product has been saved');
    }

  }, []);

  const handleInsertToContent = useCallback(async (val: CreateProductFormState) => {
    const productInfo = {
      placementType: val.placementType,
      placementId: val.placement?.value,
      offersNumber: val.offersNumber,
      widgetVersion: val.widgetVersion,
      overrideTitle: val.overrideTitle,
      overrideDescription: val.overrideDescription,
    };

    if (!debug) {
      const productId = selectedProduct?.id || null;
      window.parent.postMessage(
        {
          action: 'createProduct',
          productId,
          productInfo,
        },
        '*',
      );
    } else {
      message.info('Product was inserted');
    }
  }, [selectedProduct]);

  const handleSubmit = useCallback(
    async (val: CreateProductFormState) => {
      if (selectedProduct) {
        await handleInsertToContent(val);
      } else {
        await handleCreateProduct(val);
      }
    },
    [create, debug, imageId, selectedProduct],
  );

  return (
    <>
      <Form onFinish={handleSubmit} className="thin-form" form={form} labelAlign="left" labelCol={{ span: 5 }}>
        {showOfferUrlSection && (<div>
          {initialFormError && <div style={{ color: 'red', marginLeft: '164px' }}>{initialFormError}</div>}
          <ArhFieldset title='Search for an existing product by Product name/Cloud City ID/ASIN/UPC'>
            <Form.Item label="Product" name="product">
              <ProductSearchField
                placeholder='Product name/ID/ASIN/UPC'
                width={433}
                dropdownStyle={{
                  maxWidth: 433,
                  minWidth: 402,
                }}
                notFoundContentText='No Results'
                filterOption={() => { return true; }}
                onChange={(productId, productData) => { setSelectedProduct(productData); setShowOfferUrlSection(false); }}
                renderItem={(product: IProductDTO) => (
                  <ProductItem>
                    <strong title={product.name}>{product.name}</strong>
                    <span>{product.brand?.name}</span>
                    {product.offersInfo && product.offersInfo.length > 0 ? (
                      <ProductItemContent>
                        <aside>
                          {product.offersInfo.length} Offer{product.offersInfo.length > 1 ? 's' : ''}:
                        </aside>
                        {product.offersInfo.map((offerInfo, idx) => (
                          <span key={idx}>
                              {offerInfo.merchant?.name}: ${offerInfo.price}
                            {product.offersInfo && idx + 1 < product.offersInfo.length && ','}
                            </span>
                        ))}
                      </ProductItemContent>
                    ) : (
                      <aside>No Offers</aside>
                    )}
                  </ProductItem>
                )}
              />
            </Form.Item>
          </ArhFieldset>
          <ArhFieldset title='Create a new product by filling in one of the below fields'>
            <Form.Item name="url" label="Offer Url" rules={[{ type: 'url', message: 'Invalid URL' }]}>
              <Input placeholder="https://www.amazon.com/dp/ASINASIN" />
            </Form.Item>
            <Form.Item
              name="upc"
              label="UPC"
              rules={[{ max: 12, min: 1, validator: validateUniqueASIN }]}
              hasFeedback={!!upcSearchWarning}
              help={upcSearchWarning}
              validateStatus={upcSearchWarning ? 'warning' : undefined}
            >
              <Input placeholder="e.g. UPC-A 042100005264" maxLength={12} />
            </Form.Item>
            <Form.Item
              name="asin"
              label="ASIN"
              rules={[{ len: 10, validator: validateUniqueASIN }]}
              hasFeedback={!!asinSearchWarning}
              help={asinSearchWarning}
              validateStatus={asinSearchWarning ? 'warning' : undefined}
            >
              <Input placeholder="e.g. B00005N5PF" maxLength={10} />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '15px',
                marginBottom: '15px',
              }}
            >
              <Button type="primary" onClick={onNextClick} loading={productDataLoading || productInfoLoading || productsLoading || imageLoading}>
                Next
              </Button>
            </div>
          </ArhFieldset>
        </div>)}
        <div style={{ display: showOfferUrlSection ? 'none' : 'block' }}>
          {selectedProduct && (
            <Form.Item
              name='productName'
              label='Name'
              initialValue={selectedProduct?.name || ''}
              labelCol={{span: 6}}
            >
              <Input value={selectedProduct?.name || ''} readOnly />
            </Form.Item>
          )}
          {!showOfferUrlSection && (<Row>
            <Col span={24}>
              <Form.Item
                name='placementType'
                label='Placement Type'
                initialValue='textlink'
                rules={[{ required: true, message: 'Placement Type is required' }]}
                labelCol={{span: 6}}
              >
                <Select
                  placeholder='Placement Type'
                  onSelect={(iPlacement: string) => setPlacementType(iPlacement)}
                  style={{
                    maxWidth: 433,
                    height: 32,
                  }}
                >
                  {PLACEMENT_TYPES.map(({ key, value }) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='placement'
                label='Placement ID'
                labelCol={{span: 6}}
              >
                <PlacementField />
              </Form.Item>
              {placementType !== 'textlink' && (
                <Form.Item
                  name='offersNumber'
                  label='Number of Offers'
                  initialValue='2'
                  labelCol={{span: 6}}
                >
                  <Input placeholder="2" defaultValue='2' />
                </Form.Item>
              )}
              {placementType === 'widget' && (
                <Form.Item
                  name='widgetVersion'
                  label='Widget Version'
                  initialValue={widgetVersion}
                  labelCol={{span: 6}}
                >
                  <Select
                    placeholder='Widget Version'
                    onSelect={(iWidgetVersion: string) => setWidgetVersion(iWidgetVersion)}
                    style={{
                      width: '100%',
                      height: 32,
                    }}
                  >
                    {['1', '2'].map((value) => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {placementType === 'widget' && (
                <Form.Item
                  name='overrideTitle'
                  label='Override title'
                  labelCol={{span: 6}}
                >
                  <Input />
                </Form.Item>
              )}
              {placementType === 'widget' && (
                <Form.Item
                  name='overrideDescription'
                  label='Override description'
                  labelCol={{span: 6}}
                >
                  <Input />
                </Form.Item>
              )}
            </Col>
          </Row>)}
          {!selectedProduct && !singleProductLoading && (<div>
            <Row>
            <Col span={6}>
              <Form.Item name="image" rules={[{ required: true, message: 'Image is required' }]}>
                <ImageUploadField
                  loading={uploading}
                  onChange={uploadImage}
                  accept={IMAGE_TYPES}
                  max={5}
                  imageUrl={imageUrl}
                  disabled={false}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item name="brand" label="Brand">
                <BrandsField showSearch />
              </Form.Item>
              <Form.Item name="model" label="Model">
                <Input placeholder="e.g. SX-800 H" />
              </Form.Item>
              <Form.Item name="name" label="Display Name" rules={[{ required: true, message: 'Name is required' }]}>
                <Input autoComplete="false" placeholder="e.g. Samsung SX-800 H" />
              </Form.Item>
              <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Category is required' }]}>
                <CategoryField />
              </Form.Item>
            </Col>
            </Row>
            <Divider />
            <Row>
            <Col span={18} offset={6}>
              <Form.Item name="upc" label="UPC" rules={[{ max: 12, min: 1, validator: validateUniqueASIN }]}>
                <Input placeholder="e.g. UPC-A 042100005264" maxLength={12} />
              </Form.Item>
              <Form.Item name="asin" label="ASIN" rules={[{ len: 10, validator: validateUniqueASIN }]}>
                <Input placeholder="e.g. B00005N5PF" maxLength={10} />
              </Form.Item>
              <Form.Item name="sku" label="SKU" rules={[{ max: 100, min: 1, validator: validateUniqueASIN }]}>
                <Input placeholder="e.g. UGG-BB-PUR-06" />
              </Form.Item>
              {form.getFieldValue('affiliateLink')?.length > 0 ? (
                <Form.Item
                  name="affiliateLink"
                  label="Affiliate Link"
                  rules={[
                    { required: true, message: 'Affiliate Link is required' },
                    { type: 'url', message: 'Invalid URL' },
                  ]}
                >
                  <Input placeholder="Please Enter an affiliate link for the offer URL" />
                </Form.Item>
              ) : (
                <Form.Item
                  name="url"
                  label="Offer Url"
                  rules={[
                    { required: true, message: 'Offer URL is required' },
                    { type: 'url', message: 'Invalid URL' },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item name="merchant" label="Merchant" rules={[{ required: true, message: 'Merchant is required' }]}>
                <MerchantField placeholder="Select Merchant" />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true, message: 'Price is required' },
                  {
                    pattern: /^\d{1,6}(\.\d{1,2})?$/,
                    message: 'Price should be a number. E.g. 1.23',
                  },
                ]}
              >
                <Input placeholder="0.00" />
              </Form.Item>
                <Form.Item label="CTA" name="buttonText">
                  <Input placeholder="Custom call to action" />
                </Form.Item>
              </Col>
            </Row>
          </div> )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '15px',
            }}
          >
            <Button
              style={{
                display: debug ? 'block' : 'none',
                marginRight: '20px'
              }}
              type="primary"
              onClick={onBackClick}
            >
              Back
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading || uploading}
              style={{ display: debug ? 'block' : 'none' }}
            >
              Create
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateProductPageV2;
