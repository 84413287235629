/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import React, { memo, useRef, useCallback, useEffect, useMemo, useState } from 'react';
import { pluralize, camelize, humanize, underscore } from 'inflected';
import _ from 'lodash';

import { Form, FormInstance, Button, Card, Pagination, Table, Modal, Tabs } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';

import queryStringObject from 'query-string';
import { isEqual } from 'lodash';
import {
  Container,
  Content,
  FiltersContainer,
  Header,
  HeaderContent,
  PaginationContent,
  ToggleFilters,
} from './styles';
import Search from '../Search';
import { IPageInfo, IQueryPagination } from '../../graphql/Interface';
import Mutation from '../../graphql/Mutation';
import Query from '../../graphql/Query';

import {
  CRUDInterface,
  CRUDType,
  ICRUDFilter,
  ICrudFilterComponent,
  IEntityForm,
  IFormAction,
  IFormSchema,
} from '../../dtos/FormDTO';
import FormModal, { IModalSize } from './modal';
import { isArray } from '../../services/utils';
import { asyncUseMutation } from '../../services/GraphQLService';
import { useCustomEvent } from '../../hooks/customEvent';

type TableSizeType = 'short' | 'large';

interface ISelectedFilter {
  [key: string]: undefined | boolean | string | string[] | number;
}

interface IWithConfirmProps {
  title: string;
  action(): void;
}

interface IHandleQuery {
  name?: string;
  variables?: any;
  extraVariables?: any;
  perPage?: any;
  callback?(data: any): any;
}

interface ITab {
  id: string;
  title: React.ReactNode;
  condition(record: any): boolean;
}

interface ListLayoutProps {
  name: CRUDType | CRUDInterface;
  paginated?: boolean | string[];
  showImportButton?: boolean;
  tableClass?: string;
  modalSize?: IModalSize;
  schema?: IFormSchema;
  handleIndexQuery?: IHandleQuery;
  entityForm?: React.ComponentType<IEntityForm>;
  selected?: any;
  indexQuery?: string;
  columns: ColumnType<any>[];
  tabs?: ITab[];
  onChangeTab?(tab: string): void;
  clickable?: boolean;
  filters?: ICRUDFilter[];
  actions?: IFormAction[];
  onUnselect?(): void;
  hideDelete?: boolean;
  onUpdate?(form: FormInstance<any>): Promise<boolean>;
  onBeforeUpdate?(entity: any, selected: any): any;
  onCreate?(form: FormInstance<any>): Promise<boolean>;
  onBeforeCreate?(entity: any): any;
  onBeforeRemove?(selected: string[]): any;
  tableSize?: TableSizeType;
}

const removeEmptyOrNull = (obj: any) => {
  return _.omitBy(obj, (v) => {
    return !_.isBoolean(v) && (_.isEmpty(v) || _.isUndefined(v) || _.isNull(v) || v === '');
  });
};

const ListLayout: React.FC<ListLayoutProps> = ({
  name,
  paginated = true,
  tableClass,
  modalSize,
  selected: mySelected,
  onUnselect,
  schema,
  handleIndexQuery,
  entityForm: EntityForm,
  columns,
  tabs = [],
  filters = [],
  actions = [],
  clickable = true,
  showImportButton,
  children,
  onChangeTab,
  hideDelete,
  onUpdate,
  onBeforeUpdate,
  onCreate,
  onBeforeCreate,
  onBeforeRemove,
  tableSize,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [started, setStarted] = useState(false);
  const [selected, setSelected] = useState<any>();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const [page, setPage] = useState(1);

  const [queryString, setQueryString] = useState('');
  const [queryStringApplied, setQueryStringApplied] = useState('');

  const [selectedFilter, setSelectedFilter] = useState<ISelectedFilter>({
    query: queryStringApplied,
  });

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [direction, setDirection] = useState<'ASC' | 'DESC'>();
  const [order, setOrder] = useState<string>();
  const [showFilters, setShowFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [entityFormModalLoading, setEntityFormModalLoading] = useState(false);

  const [entityName, crudTitle] = useMemo(
    () => (typeof name === 'string' ? [name, name as string] : [name.entityName, name.name]),
    [name],
  );

  const [create, { loading: creating, data: created }] = useMutation(
    Mutation.getMutation(`create${entityName}`),
  );

  const [update, { loading: updating, data: updated }] = useMutation(
    Mutation.getMutation(`update${entityName}`),
  );

  const [remove, { loading: removing, data: removed }] = useMutation(
    Mutation.getMutation(`delete${pluralize(entityName as string)}`),
  );

  const [upload] = useMutation(Mutation.uploadFile.mutation);
  const [uploading, setUploading] = useState(false);

  const asyncUpload = useCallback(
    async (fieldName: string, file: File) => {
      const variables = {
        operation: 'uploadFile',
        field: fieldName,
        [fieldName]: file,
      };
      setUploading(true);
      try {
        const uploaded = await asyncUseMutation(upload, { variables });
        return uploaded.uploadFile;
      } finally {
        setUploading(false);
      }
    },
    [upload],
  );

  const perPage = useMemo(() => {
    return handleIndexQuery?.perPage || 20;
  }, [handleIndexQuery?.perPage]);

  const iVariables = useMemo(() => {
    const offset = (page - 1) * perPage;
    if (handleIndexQuery && handleIndexQuery.variables) {
      return handleIndexQuery.variables;
    }

    const extraVariables = handleIndexQuery?.extraVariables || {};
    const myFilters = JSON.parse(JSON.stringify(selectedFilter));
    const objVariables = {
      offset,
      order,
      direction,
      limit: perPage,
      filters: { ...myFilters, query: queryStringApplied },
    };
    return { ...objVariables, ...extraVariables };
  }, [direction, handleIndexQuery, order, page, perPage, queryStringApplied, selectedFilter]);

  const queryName = useMemo(() => {
    if (handleIndexQuery && handleIndexQuery.name) {
      return handleIndexQuery.name;
    }
    return camelize(pluralize(entityName as string), false);
  }, [handleIndexQuery, entityName]);

  const [loadData, { loading: queryLoading, data }] = useLazyQuery<IQueryPagination<any>>(
    Query.getQuery(queryName),
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (started) {
      let builtSearch = {};
      for (const [key, value] of Array.from(params.entries())) {
        if (key !== 'query' && key !== 'id' && key !== 'page') {
          builtSearch = { ...builtSearch, [key]: value?.length > 0 ? value : undefined };
        }
      }
      const objUrl = {
        query: queryStringApplied.length > 0 ? queryStringApplied : undefined,
        page: page === 1 ? undefined : page,
        id: selected ? selected.id : undefined,
        ...builtSearch,
      };
      const cleanedParams = JSON.parse(JSON.stringify({ ...objUrl }));
      const search = new URLSearchParams(cleanedParams);
      history.push({ search: search.toString() });
    }
  }, [history, page, queryStringApplied, selected, started, params]);

  useEffect(() => {
    if (started) {
      loadData({
        variables: iVariables,
      });
    }
  }, [iVariables, loadData, started]);

  const toggleFilters = useCallback(() => {
    setShowFilters((previous) => !previous);
  }, []);

  const debouncedQueryString = useRef(
    _.debounce((q: string) => {
      setQueryStringApplied(q);
      setPage(1);
    }, 200),
  );

  useEffect(() => debouncedQueryString.current(queryString), [queryString]);

  const handleSearch = useCallback((iValue) => {
    setQueryString(iValue);
  }, []);

  const handleSelectChange = useCallback((selectedRowKeys: string[]) => {
    setSelectedKeys(selectedRowKeys);
  }, []);

  const handlePaginationChange = useCallback((e) => {
    setPage(e);
  }, []);

  const withConfirm = useCallback(({ title, action }: IWithConfirmProps) => {
    Modal.confirm({
      title,
      onOk: action,
    });
  }, []);

  const deleteSelectedEntities = useCallback(() => {
    const iVars = onBeforeRemove ? onBeforeRemove(selectedKeys) : { ids: selectedKeys };
    remove({
      variables: iVars,
    });
  }, [onBeforeRemove, remove, selectedKeys]);

  const hasSelected = useMemo(() => selectedKeys.length > 0, [selectedKeys]);

  useEffect(() => {
    if (data) {
      if (handleIndexQuery?.callback) {
        setDataSource(handleIndexQuery.callback(data));
        return;
      }
      if (paginated === true) {
        const { edges, pageInfo: iPageInfo } = data.object;
        setDataSource(edges.map((ed) => ed.node));
        setPageInfo(iPageInfo);
        window.scrollTo(0, 0);
        return;
      }
      setDataSource(data.object as unknown as any[]);
    }
  }, [data, handleIndexQuery, paginated]);

  useEffect(() => {
    if (selected) {
      setShowModal(true);
    }
  }, [selected]);

  const handleRowClick = useCallback(
    (entity: any) => {
      if (clickable) {
        setSelected(entity);
      }
    },
    [clickable, setSelected],
  );

  const tableColumns = useMemo(() => {
    const tc = columns.map((item) => ({
      ...item,
      dataIndex: item.dataIndex || item.key,
    }));
    return tc;
  }, [columns]);

  const handleColumnChange = useCallback((__, filter, { order: iOrder, columnKey }) => {
    if (!iOrder || !columnKey) {
      setOrder(undefined);
      setDirection(undefined);
      return;
    }
    setDirection(iOrder === 'ascend' ? 'ASC' : 'DESC');
    setOrder(columnKey);
  }, []);

  const filteredDataSource = useMemo(() => {
    if (paginated === true) {
      return dataSource;
    }
    if (paginated !== false) {
      if (queryStringApplied.trim().length === 0) {
        return dataSource;
      }
      const filtered = dataSource.filter((a) => {
        const values = paginated.map((c) => a[c]);
        const find = values.findIndex((v) => {
          const fixedQuery = queryStringApplied.replace(/\W/g, '');
          const regex = new RegExp(fixedQuery, 'i');
          const aa = v.match(regex);
          return !!aa;
        });
        return find > -1;
      });
      return filtered;
    }
    return dataSource;
  }, [dataSource, paginated, queryStringApplied]);

  const idName = useMemo(() => {
    if (schema && schema.id) {
      return schema.id;
    }
    return 'id';
  }, [schema]);

  const renderTable = useCallback(
    (iTab?: ITab) => (
      <>
        <Card
          bodyStyle={{
            padding: 0,
          }}
        >
          <Table
            className={clickable ? 'clickable' : ''}
            rowClassName={tableClass}
            rowSelection={{
              onChange: (val) => handleSelectChange(val as string[]),
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            pagination={false}
            size="small"
            columns={tableColumns}
            rowKey={(record) => record.rowKey || record.id || record[idName]}
            dataSource={iTab ? filteredDataSource.filter((ds) => iTab.condition(ds)) : filteredDataSource}
            loading={queryLoading}
            onChange={handleColumnChange}
          />
        </Card>
      </>
    ),
    [
      clickable,
      filteredDataSource,
      handleColumnChange,
      handleRowClick,
      handleSelectChange,
      idName,
      queryLoading,
      tableClass,
      tableColumns,
    ],
  );

  const loadFields = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (exception) {
      return null;
    }
    const originalEntity = form.getFieldValue('original');

    if (schema && schema.parse) {
      return schema.parse(form.getFieldsValue(), originalEntity);
    }

    const formKeys = Object.keys(form.getFieldsValue());
    const objFilter = formKeys.reduce((filter: any, key) => {
      const row = form.getFieldsValue()[key];
      if (row instanceof File || key === 'entity') {
        return filter;
      }
      if (selected && key === idName) {
        return filter;
      }
      let iKey = key;
      let iValue = row;
      if (row && row.id) {
        iKey = `${key}Id`;
        iValue = row.id;
      }
      Object.assign(filter, {
        [iKey]: iValue,
      });
      return filter;
    }, {});
    const values = JSON.parse(JSON.stringify(objFilter));
    return removeEmptyOrNull(values);
  }, [form, idName, schema, selected]);

  const saveWithImage = useCallback(
    async (values: any, callback: any) => {
      const formKeys = Object.keys(form.getFieldsValue());
      const fileKey = formKeys.find((fk) => form.getFieldsValue()[fk] instanceof File);
      if (fileKey) {
        const newImage = await asyncUpload(fileKey, form.getFieldsValue()[fileKey]);
        callback({
          ...values,
          [`${fileKey}Id`]: newImage.id,
        });
        return;
      }
      callback(values);
    },
    [asyncUpload, form],
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelected(undefined);
    setEntityFormModalLoading(false);
    form.resetFields();
    if (onUnselect) {
      onUnselect();
    }
  }, [form, onUnselect]);

  const handleCreate = useCallback(async () => {
    if (onCreate) {
      // let the controlling component handle the update instead
      await onCreate(form);
    } else {
      const values = await loadFields();
      if (values) {
        saveWithImage(values, (iValues: any) => {
          const variables = onBeforeCreate
            ? onBeforeCreate(iValues)
            : {
                data: iValues,
              };

          create({
            variables,
          });
        });
      }
    }
  }, [create, form, loadFields, onBeforeCreate, onCreate, saveWithImage]);

  const handleUpdate = useCallback(async () => {
    if (onUpdate) {
      // let the controlling component handle the update instead
      setEntityFormModalLoading(await onUpdate(form));
    } else {
      const values = await loadFields();
      if (values) {
        saveWithImage(values, (iValues: any) => {
          const { id } = selected;
          const variables = onBeforeUpdate
            ? onBeforeUpdate(iValues, selected)
            : {
                id,
                data: iValues,
              };

          update({
            variables,
          });
        });
      }
    }
  }, [onUpdate, form, loadFields, saveWithImage, selected, onBeforeUpdate, update]);

  const getFilterValue = useCallback((filterValue?: any) => {
    if (!filterValue) {
      return undefined;
    }
    if (isArray(filterValue)) {
      const iValue = filterValue.map((f: any) => f.id) as string[];
      return iValue.length === 0 ? undefined : iValue;
    }
    if (filterValue.id) {
      return filterValue.id;
    }
    return filterValue;
  }, []);

  const handleFilter = useCallback(
    (fName: string, fValue: any, updateUrlParams = false) => {
      if (fName === 'query' || fName === 'id') {
        return;
      }
      const iValue = getFilterValue(fValue);
      const iSelected = selectedFilter[fName];
      const isEquals = isEqual(iValue, iSelected);
      if (!isEquals) {
        setSelectedFilter((previous) => ({ ...previous, [fName]: iValue }));
        setPage(1);
      }
      if (updateUrlParams && !isEquals) {
        const queryParams = queryStringObject.parse(location.search);
        const cleanedParams = JSON.parse(
          JSON.stringify({
            ...queryParams,
            [fName]: iValue,
          }),
        );
        const newSearch = new URLSearchParams(cleanedParams);
        history.push({ search: newSearch.toString() });
      }
    },
    [getFilterValue, history, location.search, selectedFilter],
  );

  useEffect(() => {
    if (filters.length > 0) {
      const filteredValues = filters.reduce((obj: any, f) => {
        Object.assign(obj, {
          [f.name]: f.value,
        });
        return obj;
      }, {});
      setSelectedFilter((previous) => ({ ...previous, ...filteredValues }));
    }
  }, [filters]);

  const filterComponents = useMemo(() => filters.filter((f) => !!f.component), [filters]);

  const humanizedTitle = useMemo(() => humanize(underscore(crudTitle)), [crudTitle]);

  useEffect(() => {
    if (page) {
      setSelectedKeys([]);
    }
  }, [page]);

  useEffect(() => {
    if (mySelected && mySelected.id) {
      setShowModal(true);
      setSelected(mySelected);
    }
  }, [mySelected]);

  useEffect(() => {
    if (!started) {
      setStarted(true);

      const startedParams = new URLSearchParams(location.search);
      if (startedParams.get('id')) {
        setSelected({ id: startedParams.get('id') });
      }
      const pageParam = startedParams.get('page') ? Number(startedParams.get('page')) : 1;
      const pageQuery = startedParams.get('query') || '';

      const filterWithDefaultValue = filters.reduce((obj: any, fc) => {
        if (fc.defaultValue) {
          Object.assign(obj, {
            [fc.name]: fc.defaultValue,
          });
        }
        return obj;
      }, {});
      setSelectedFilter((previous) => ({ ...previous, ...filterWithDefaultValue }));
      setPage(pageParam);
      setQueryString(pageQuery);
    }
  }, [filters, location.search, mySelected, started]);

  const handleActionClick = useCallback(
    async (action: IFormAction) => {
      if (action.confirm) {
        Modal.confirm({
          title: action.confirm,
          onOk: async () => {
            await action.onClick(
              {
                ...iVariables,
                query: queryStringApplied,
              },
              selectedKeys,
              filteredDataSource.filter((a) => selectedKeys.includes(a.id)),
            );
            loadData({
              variables: iVariables,
            });
          },
        });
      } else {
        await action.onClick(
          {
            ...iVariables,
            query: queryStringApplied,
          },
          selectedKeys,
          filteredDataSource.filter((a) => selectedKeys.includes(a.id)),
        );
        loadData({
          variables: iVariables,
        });
      }
    },
    [filteredDataSource, iVariables, loadData, queryStringApplied, selectedKeys],
  );

  useEffect(() => {
    if (showModal) {
      form.resetFields();
    }
  }, [form, showModal]);

  const importClickEvent = useCustomEvent<void>('importButton:click');
  const modalFooter = useMemo(() => {
    const buttons = [];
    buttons.push(
      <Button key="close" onClick={handleCloseModal}>
        Close
      </Button>,
    );
    if (showImportButton) {
      buttons.push(
        <Button key="import" onClick={() => importClickEvent.trigger()}>
          Import
        </Button>,
      );
    }
    buttons.push(
      <Button
        key="exec"
        type="primary"
        // eslint-disable-next-line prettier/prettier
        loading={creating !== updating !== uploading !== entityFormModalLoading}
        onClick={selected ? handleUpdate : handleCreate}
      >
        {selected ? 'Update' : 'Create'}
      </Button>,
    );
    return buttons;
  }, [
    entityFormModalLoading,
    showImportButton,
    creating,
    handleCloseModal,
    handleCreate,
    handleUpdate,
    importClickEvent,
    selected,
    updating,
    uploading,
  ]);

  useEffect(() => {
    if (updated || created || removed) {
      loadData({
        variables: iVariables,
      });
      handleCloseModal();
    }
  }, [created, handleCloseModal, iVariables, loadData, removed, updated]);

  const handleOnKeyUp = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        selected ? handleUpdate() : handleCreate();
      }
    },
    [handleCreate, handleUpdate, selected],
  );

  return (
    <>
      {EntityForm && (
        <FormModal
          size={modalSize}
          visible={showModal}
          onCancel={handleCloseModal}
          title={`${selected ? 'Edit' : 'Create'} ${humanizedTitle}`}
          footer={modalFooter}
        >
          <EntityForm
            key={`entityform-${selected?.id}`}
            onKeyUp={handleOnKeyUp}
            form={form}
            entity={selected}
            {...(showImportButton ? { useOnImportClick: importClickEvent.useSubscribe } : {})}
            onSuccess={async () => {
              // refetch list data and close the modal
              loadData({
                variables: iVariables,
              });
              handleCloseModal();
            }}
          />
        </FormModal>
      )}

      <Container>
        <Header>
          <HeaderContent>
            <div className="left">
              <Search value={queryString} onChange={handleSearch} />
              {filterComponents.length > 0 && (
                <ToggleFilters onClick={toggleFilters}>
                  {showFilters ? 'hide filters' : 'show filters'}
                </ToggleFilters>
              )}
            </div>
            <div className="controls">
              <Button type="primary" onClick={() => setShowModal(true)}>
                Create {humanizedTitle}
              </Button>
              {!hideDelete && (
                <Button
                  danger
                  ghost
                  loading={removing}
                  onClick={() =>
                    withConfirm({
                      action: deleteSelectedEntities,
                      title: 'Do you want to delete selected items?',
                    })
                  }
                  disabled={!hasSelected}
                >
                  Delete Selected
                </Button>
              )}
              {actions.length > 0 && (
                <div style={{ marginLeft: 15 }}>
                  {actions.map((action) => (
                    <Button
                      disabled={action.disableWithNoSelections && selectedKeys.length === 0}
                      key={action.title}
                      type={action.type || 'ghost'}
                      onClick={() => handleActionClick(action)}
                      loading={action.loading}
                    >
                      {action.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </HeaderContent>

          <FiltersContainer visible={showFilters}>
            {filterComponents.map((filter, idx) => {
              if (filter.defaultValue?.length > 0 && !showFilters) {
                setShowFilters(true);
              }
              const Filter = filter.component as React.ComponentType<ICrudFilterComponent>;
              return (
                <div key={`filter_${idx}`}>
                  <Filter
                    width={190}
                    name={filter.label}
                    multiple={filter.multiple}
                    defaultValue={filter.defaultValue}
                    value={filter.value}
                    onFilterChange={(val) => handleFilter(filter.name, val, filter.updateUrlParams)}
                  />
                </div>
              );
            })}
          </FiltersContainer>
        </Header>
        {children}
        <Content size={tableSize}>
          <>
            {tabs.length === 0 ? (
              renderTable()
            ) : (
              <Tabs
                type="card"
                defaultActiveKey={tabs[0].id}
                onChange={(val) => onChangeTab && onChangeTab(val)}
              >
                {tabs.map((t) => (
                  <Tabs.TabPane tab={t.title} key={t.id}>
                    {renderTable(t)}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            )}
            {paginated && (
              <PaginationContent>
                <Pagination
                  current={page}
                  disabled={queryLoading}
                  onChange={handlePaginationChange}
                  showSizeChanger={false}
                  defaultPageSize={20}
                  pageSize={perPage}
                  total={pageInfo?.total}
                />
              </PaginationContent>
            )}
          </>
        </Content>
      </Container>
    </>
  );
};

export default memo(ListLayout);
