import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import LoginPage from '../pages/LoginPage';
import Dashboard from '../pages/DashboardPage';
import ProductsPage from '../pages/ProductsPage';
import UsersPage from '../pages/UsersPage';
import PublishersPage from '../pages/PublishersPage';
import CategoriesPage from '../pages/CategoriesPage';
import AffiliateNetworksPage from '../pages/AffiliateNetworksPage';
import BrandsPage from '../pages/BrandsPage';
import ImportPage from '../pages/ImportPage';
import WebsitesPage from '../pages/WebsitesPage';
import MerchantsPage from '../pages/MerchantsPage';
import CampaignsPage from '../pages/CampaignsPage';
import CreateProductPage from '../pages/CreateProductPage';
import RegisterProductPage from '../pages/RegisterProductPage';
import WidgetPage from '../pages/WidgetPage';
import InventoryPage from '../pages/InventoryPage';
import LinkGeneratorPage from '../pages/LinkGeneratorPage';
import CreateDeeplinkPage from '../pages/CreateDeeplinkPage';
import CreateDealDeeplinkPage from '../pages/CreateDealDeeplinkPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import { ROUTES } from '../utils/constants';

const Routes: React.FC = () => (
  <Switch>
    <Route path={ROUTES.login} component={LoginPage} />
    <Route exact path={ROUTES.base} component={Dashboard} permit="base" />
    <Route path={ROUTES.widget} component={WidgetPage} noLayout permit="base" />
    <Route path={ROUTES.createProduct} component={CreateProductPage} noLayout permit="createProduct" />
    <Route path={ROUTES.createDeeplink} component={CreateDeeplinkPage} noLayout permit="createDeeplink" />
    <Route path={ROUTES.registerProduct} component={RegisterProductPage} noLayout permit="registerProduct" />
    <Route path={ROUTES.import} component={ImportPage} permit="import" />
    <Route path={ROUTES.users} component={UsersPage} permit="users" />
    <Route path={ROUTES.products} component={ProductsPage} permit="products" />
    <Route path={ROUTES.publishers} component={PublishersPage} permit="publishers" />
    <Route path={ROUTES.productCategories} component={CategoriesPage} permit="categories" />
    <Route path={ROUTES.affiliateNetworks} component={AffiliateNetworksPage} permit="affiliateNetworks" />
    <Route path={ROUTES.brands} component={BrandsPage} permit="brands" />
    <Route path={ROUTES.websites} component={WebsitesPage} permit="websites" />
    <Route path={ROUTES.merchants} component={MerchantsPage} permit="merchants" />
    <Route path={ROUTES.campaigns} component={CampaignsPage} permit="campaigns" />
    <Route path={ROUTES.inventory} component={InventoryPage} permit="inventory" />
    <Route
      path={ROUTES.createDealDeeplink}
      component={CreateDealDeeplinkPage}
      noLayout
      permit="createDealDeeplink"
    />
    <Route path={ROUTES.linkGenerator} component={LinkGeneratorPage} permit="linkGenerator" />
    <Route path={ROUTES.forgotPassword} component={ForgotPasswordPage} />
    <Route path={`${ROUTES.resetPassword}/:token`} component={ResetPasswordPage} />
  </Switch>
);

export default Routes;
