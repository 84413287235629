import styled from 'styled-components';

interface AffiliateRowLabelProps {
  width: number;
}

interface SummaryResultProps {
  empty?: boolean;
}

export const Header = styled.div`
  margin: 0 100px 20px 100px;
  padding: 23px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

export const LinksContent = styled.div`
  margin: 0 100px;
`;

export const SummaryPanel = styled.div`
  padding: 12px 21px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #d9d9d9;
  margin-bottom: 21px;
`;

export const SummaryResult = styled.div<SummaryResultProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  * {
    color: ${(props) => (props.empty ? 'red' : '#1890ff')};
  }
  svg {
    font-size: 21px;
    margin-right: 6px;
  }
`;

export const AffiliateRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;
`;

export const AffiliateRowLabel = styled.strong<AffiliateRowLabelProps>`
  width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
`;
