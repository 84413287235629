/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const closestParent = (target: any, selector: string) => {
  while (target) {
    if (target.matches && target.matches(selector)) return target;
    target = target.parentNode;
  }
  return null;
};

export const isString = (x: any): boolean => Object.prototype.toString.call(x) === '[object String]';

export const isObject = (x: any): boolean => Object.prototype.toString.call(x) === '[object Object]';

export const isArray = (x: any) => Object.prototype.toString.call(x) === '[object Array]';

export const decodeAccessToken = (token: string) => {
  return JSON.parse(atob(token));
};

export const sleep = async (timeInMilis: number) => {
  await new Promise((resolve) => setTimeout(resolve, timeInMilis));
};

export const copyToClipboard = (text: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};
