import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { IQueryPagination } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';
import { IPlacementDTO } from '../../../dtos/PlacementDTO';

const PlacementField: React.FC<IGenericSelectFieldProps<IPlacementDTO>> = (params) => {
  const [value, setValue] = useState('');
  const [loadBrands, { loading, data }] = useLazyQuery<IQueryPagination<IPlacementDTO>>(
    Query.placements.query,
  );

  const placements = useMemo(() => {
    if (data) {
      return data.object.edges.map((o) => ({
        value: o.node.placementId,
        label: o.node.name,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    loadBrands({
      variables: {
        filters: {
          query: value,
        },
      },
    });
  }, [loadBrands, value]);

  return (
    <SelectField
      name="Placement"
      onSearch={(val) => setValue(val)}
      loading={loading}
      data={placements}
      {...params}
    />
  );
};

export default PlacementField;
