import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import React from "react";

const viewConfig = {
  menu: true,
  md: true,
  html: false,
};

const canViewConfig = {
  menu: true,
  md: true,
  html: true,
  fullScreen: false,
  hideMenu: true,
  both: true,
};

const plugins = [
  'font-bold',
  'font-italic',
  'list-unordered',
  'table',
];

interface CustomMdEditorWrapperProps {
  value?: string;
  onChange?: (value: string) => void;
}

const CustomMdEditorWrapper: React.FC<CustomMdEditorWrapperProps> = ({
  value = '',
  onChange,
}) => {
  // Handle the change event from MdEditor and pass the text value up
  const handleEditorChange = ({ text, html }: { text: string,  html: string }) => {
    if (onChange) {
      onChange(text);
    }
  };

  return (
    <MdEditor
      value={value}
      view={viewConfig}
      canView={canViewConfig}
      plugins={plugins}
      markdownClass="dt-md-editor"
      renderHTML={(text: string): string => text}
      onChange={handleEditorChange}
    />
  );
};


export default CustomMdEditorWrapper;
