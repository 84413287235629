import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { IQueryPagination } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import { ICategoryDTO } from '../../../dtos/ProductDTO';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

const CategoryField: React.FC<IGenericSelectFieldProps<ICategoryDTO>> = (params) => {
  const [value, setValue] = useState('');
  const { loading, data } = useQuery<IQueryPagination<ICategoryDTO>>(Query.categoriesCollection.query, {
    variables: {
      offset: 0,
      limit: 20,
      filters: {
        type: 'PRODUCT',
        query: value,
      },
    },
  });

  const categories = useMemo(() => {
    if (data) {
      return data.object.edges.map((o) => ({
        value: o.node.id,
        label: o.node.name,
      }));
    }
    return [];
  }, [data]);

  return (
    <SelectField
      name="Category"
      onSearch={(val) => setValue(val)}
      loading={loading}
      data={categories}
      {...params}
    />
  );
};

export default CategoryField;
