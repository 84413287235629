import { useCallback, useState } from 'react';

import {
  useQuery,
  useLazyQuery,
  QueryResult,
  QueryTuple,
  useApolloClient,
  DocumentNode,
  ApolloQueryResult,
  FetchResult,
  ApolloError,
} from '@apollo/client';

import Query from './Query';

import { IInputMerchantProductInfo, IProductDTO, IRefreshOffersDTO } from '../dtos/ProductDTO';
import Mutation from './Mutation';
import { IProductSearch, IQueryPagination } from "./Interface";

export function useSynchronousQuery<Data, Variables>(
  query: DocumentNode,
): [(args: Variables) => Promise<ApolloQueryResult<Data>>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();

  const cb = useCallback(
    async (variables: Variables) => {
      setLoading(true);
      const res = await client.query<Data, Variables>({
        query,
        variables,
      });
      setLoading(false);
      return res;
    },
    [client, query],
  );

  return [cb, loading];
}

interface IProductQueryResult {
  product?: IProductDTO;
}

interface IProductQueryVars {
  id: string;
}

interface ProductQueryParams {
  onCompleted?(args: IProductQueryResult): void;
  onError?(error: ApolloError): void;
}

export function useProductQuery(variables: IProductQueryVars): QueryResult<IProductQueryResult> {
  return useQuery<IProductQueryResult, IProductQueryVars>(Query.product.query, { variables });
}

export function useLazyProductQuery(
  params?: ProductQueryParams,
): QueryTuple<IProductQueryResult, IProductQueryVars> {
  return useLazyQuery<IProductQueryResult, IProductQueryVars>(Query.product.query, params);
}

interface IRefreshOffersVars {
  productId: string;
  asin?: string;
  upc?: string;
  merchantsInfo?: IInputMerchantProductInfo[];
}

export function useRefreshOffersQuery(): [
  (args: IRefreshOffersVars) => Promise<ApolloQueryResult<{ productOffers: IRefreshOffersDTO | null }>>,
  boolean,
] {
  return useSynchronousQuery<{ productOffers: IRefreshOffersDTO }, IRefreshOffersVars>(
    Query.productOffers.query,
  );
}

export interface ISearchProductInNetworkCatalogsVars {
  filter: ISearchProductInNetworkCatalogsFilter;
  limit?: number;
}

export interface ISearchProductInNetworkCatalogsFilter {
  keyword?: string;
  asin?: string;
  upc?: string;
}

export interface IProductNetworkSearchResultDTO {
  name: string;
  imageUrl?: string | null;
  upc?: string | null;
  gtin?: string | null;
  brand?: string | null;
  description?: string | null;
  networkName: string;
}

export interface IProductResultEdgeDTO {
  node: IProductNetworkSearchResultDTO;
  cursor?: string;
}

export interface IProductNetworkSearchResultCollectionDTO {
  edges: IProductResultEdgeDTO[];
}

export function useSearchProductInNetworkCatalogsQuery(): [
  (
    args: ISearchProductInNetworkCatalogsVars,
  ) => Promise<ApolloQueryResult<{ object: IProductNetworkSearchResultCollectionDTO }>>,
  boolean,
] {
  return useSynchronousQuery<{ object: IProductNetworkSearchResultCollectionDTO }, ISearchProductInNetworkCatalogsVars>(
    Query.searchProductInNetworkCatalogs.query,
  );
}

export interface IFetchLandingPageDataVars {
  url: string;
}

interface IFetchLandingPageDataDTO {
  asin?: string | null;
  imageUrl?: string | null;
  merchant?: {
    merchantId: string;
    name: string;
  } | null;
  model?: string | null;
  name?: string | null;
  brand?: string | null;
  price?: string | null;
  sku?: string | null;
  upc?: string | null;
  url?: string | null;
}

export function useFetchLandingPageDataQuery(): [
  (args: IFetchLandingPageDataVars) => Promise<ApolloQueryResult<{ object: IFetchLandingPageDataDTO }>>,
  boolean,
] {
  return useSynchronousQuery<{ object: IFetchLandingPageDataDTO }, IFetchLandingPageDataVars>(Query.landingPage.query);
}

export function useSynchronousMutation<Data, Variables>(
  mutation: DocumentNode,
): [(args: Variables) => Promise<FetchResult<Data>>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();

  const cb = useCallback(
    async (variables: Variables) => {
      setLoading(true);
      const res = await client.mutate<Data, Variables>({
        mutation,
        variables,
      });
      setLoading(false);
      return res;
    },
    [client, mutation],
  );

  return [cb, loading];
}

export interface IFetchProductInfoVars {
  id?: string;
  asin?: string;
  amazonUrl?: string;
  upc?: string;
}

interface IErrorDTO {
  namespace: string | null;
  name: string | null;
  message: string | null;
}

interface IFetchedProductInfoDTO {
  product?: IProductDTO;
  error?: IErrorDTO;
}

export function useFetchProductInfoMutation(): [
  (args: IFetchProductInfoVars) => Promise<FetchResult<{ fetchProductInfo: IFetchedProductInfoDTO | null }>>,
  boolean,
] {
  return useSynchronousMutation<{ fetchProductInfo: IFetchedProductInfoDTO }, IFetchProductInfoVars>(
    Mutation.fetchProductInfo.mutation,
  );
}

export function useSearchProductsQuery(): [
  (args: IProductSearch) => Promise<ApolloQueryResult<IQueryPagination<IProductDTO>>>,
  boolean,
] {
  return useSynchronousQuery<IQueryPagination<IProductDTO>, IProductSearch>(Query.searchProducts.query);
}
