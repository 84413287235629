import React, { useEffect, useMemo } from 'react';

import { Form, Input } from 'antd';

import { useLazyQuery } from '@apollo/client';

import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import { ICategoryDTO } from '../../../dtos/ProductDTO';

const CategoryForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const [loadCategory, { data }] = useLazyQuery(Query.category.query);

  const category = useMemo(() => {
    if (!entity || !data) {
      return undefined;
    }
    return data.category as ICategoryDTO;
  }, [entity, data]);

  useEffect(() => {
    if (!category || !form) {
      return;
    }
    form.setFieldsValue(category);
  }, [form, category]);

  useEffect(() => {
    if (entity) {
      loadCategory({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadCategory]);

  return (
    <Form form={form} labelAlign="right" labelCol={{ span: 5 }} {...props}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="e.g. Computers" autoComplete="off" />
      </Form.Item>

      <Form.Item name="aliases" label="Aliases">
        <Input.TextArea rows={4} placeholder="e.g. Laptops, Notebooks" autoComplete="off" />
      </Form.Item>
    </Form>
  );
};

export default CategoryForm;
