import React, { useCallback, useMemo, useState } from 'react';

import { Button, Tag } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { currencyFormat } from '../../../utils/constants';
import { IInventoryFilters } from '../../../graphql/Interface';
import { IInventoryDTO } from '../../../dtos/ArticleInventoryDTO';
import { SmallText } from './styles';
import { IMerchantDTO } from '../../../dtos/MerchantDTO';
import InventoryModal from '../InventoryModal';
import InfoPopover from '../../InfoPopover';
import { LinkButtonContainer, NumberColumn } from '../../../pages/InventoryPage/styles';
import TransactionsModal from '../TransactionsModal';

export type ProductInventoryModalView = 'article' | 'link';

interface MerchantModalProps {
  visible?: boolean;
  groupingName?: string;
  totalCount: number;
  filters: IInventoryFilters;
  onCancel(): void;
  maxLocalPerPage: number;
}

const MerchantModal: React.FC<MerchantModalProps> = ({ totalCount, maxLocalPerPage, ...props }) => {
  const getMerchantName = useCallback((merchant?: IMerchantDTO) => {
    return merchant?.displayName || merchant?.name || 'N/A';
  }, []);

  const [transactionsCount, setTransactionsCount] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState<IInventoryDTO>();

  const handleSelectArticles = useCallback((inventory: IInventoryDTO, counter: number) => {
    setTransactionsCount(counter);
    setSelectedArticle(inventory);
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          title: 'Merchant',
          dataIndex: 'merchant',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => (a.merchant?.name || '').localeCompare(b.merchant?.name || ''),
                  multiple: 1,
                },
          render: (merchant: IMerchantDTO) => (
            <InfoPopover
              image={merchant?.logo?.url}
              showImageNotFound
              width={150}
              items={[
                {
                  value: getMerchantName(merchant),
                },
                {
                  value: merchant?.id,
                },
                {
                  value: merchant?.affiliateNetwork?.name || undefined,
                },
                {
                  value: merchant?.website || undefined,
                  url: merchant?.website || undefined,
                },
                {
                  render: merchant?.disabled === true ? <Tag color="volcano">Disabled</Tag> : undefined,
                },
              ]}
            >
              {getMerchantName(merchant)}
            </InfoPopover>
          ),
        },

        {
          title: 'Clicks',
          dataIndex: 'clicks',
          width: 100,
          showSorterTooltip: false,
          defaultSortOrder: 'descend',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.clicks - b.clicks,
                  multiple: 1,
                },
          render: (clicks: number) => (
            <NumberColumn>
              <SmallText type="secondary">{clicks}</SmallText>
            </NumberColumn>
          ),
        },
        {
          title: 'Transactions',
          key: 'TRANSACTIONS',
          dataIndex: 'quantity',
          width: 120,
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.quantity - b.quantity,
                },
          defaultSortOrder: 'descend',
          render: (transactions: number, inventory: IInventoryDTO) => (
            <LinkButtonContainer>
              <Button
                type="ghost"
                shape="round"
                size="small"
                title={`${transactions === 0 ? 'No' : transactions} transaction${transactions !== 1 && 's'}`}
                danger={transactions === 0}
                disabled={transactions === 0}
                onClick={() => handleSelectArticles(inventory, transactions)}
                style={{
                  width: 80,
                  fontSize: 12,
                }}
              >
                {transactions}
              </Button>
            </LinkButtonContainer>
          ),
        },
        {
          title: 'Commissions',
          key: 'COMMISSIONS',
          dataIndex: 'commissions',
          width: 130,
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.commissions - b.commissions,
                },
          defaultSortOrder: 'descend',
          render: (commissions: number) => (
            <NumberColumn>
              <SmallText type="secondary">{currencyFormat(commissions)}</SmallText>
            </NumberColumn>
          ),
        },
      ] as ColumnsType<IInventoryDTO>,
    [getMerchantName, handleSelectArticles, maxLocalPerPage, totalCount],
  );

  return (
    <>
      <TransactionsModal
        totalCount={transactionsCount}
        onCancel={() => setSelectedArticle(undefined)}
        filters={{
          ...props.filters,
          merchantId: selectedArticle?.merchant.id,
        }}
        maxLocalPerPage={maxLocalPerPage}
        visible={!!selectedArticle}
        groupingName="Merchant"
      />
      <InventoryModal
        totalCount={totalCount}
        dbColumns={['MERCHANT']}
        columns={columns}
        name="Merchants"
        maxLocalPerPage={maxLocalPerPage}
        {...props}
        localFilter={(iInventory) => iInventory.merchant.name}
      />
    </>
  );
};

export default MerchantModal;
