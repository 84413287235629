import React from 'react';

import { Select } from 'antd';

interface TagsFieldProps {
  tags?: string[];
  readOnly?: boolean;
  placeholder?: string;
  value?: string[];
  onChange?(v: any): void;
}

const TagsField: React.FC<TagsFieldProps> = ({ tags = [], readOnly, placeholder, value, onChange }) => {
  return (
    <Select
      mode="tags"
      className={readOnly ? 'App-Select-Readonly' : undefined}
      tokenSeparators={[' ']}
      size="middle"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      defaultValue={tags}
      dropdownStyle={{ display: 'none' }}
      style={{ width: '100%' }}
    />
  );
};

export default TagsField;
