import styled from 'styled-components';
import { Card } from 'antd';
import DatePicker from '../../DatePicker';

interface ContainerProps {
  index: number;
}
export const Container = styled(Card)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${(props) => (props.index > 0 ? 12 : 0)}px;
`;

export const URLContent = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 45px;
`;

export const DateField = styled(DatePicker)`
  width: 160px;
`;
