import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Input, Button, Divider, Typography, notification } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import Query from '../../graphql/Query';
import { MIN_PASSWORD_LENGTH, ROUTES } from '../../utils/constants';
import { IUserDTO } from '../../dtos/UserDTO';
import Mutation from '../../graphql/Mutation';

const { Text } = Typography;

interface IResetPasswordParams {
  password: string;
  confirmation: string;
}

const ResetPasswordPage: React.FC = () => {
  const [foundUser, setData] = useState<IUserDTO>();
  const { handleError } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const token = location.pathname.split('/').pop();
  const [form] = Form.useForm();
  const { loading } = useQuery(Query.recoverUserFromPasswordResetToken.query, {
    variables: { token },
    onCompleted: (data) => {
      setData(data.recoverUserFromPasswordResetToken);
    },
    onError: () => {
      history.push(ROUTES.login);
    },
  });
  const [resetPasswordMutation] = useMutation(Mutation.resetPassword.mutation);

  const validateNewPasswordConfirmation = useCallback(
    async (rule, value): Promise<void> => {
      const newPasswordValue = form.getFieldValue('password');
      if (newPasswordValue === value) {
        await Promise.resolve();
      } else {
        await Promise.reject(new Error());
      }
    },
    [form],
  );

  const handleContinue = useCallback(
    async ({ password }: IResetPasswordParams): Promise<void> => {
      try {
        await resetPasswordMutation({
          variables: {
            data: {
              token,
              password,
            },
          },
        });
        notification.success({
          message: 'Resetting password',
          description: 'Password reset successfully, please login!',
        });
        history.push(ROUTES.login);
      } catch (err) {
        handleError(err as Error, 'Resetting password error');
      }
    },
    [token, history, resetPasswordMutation, handleError],
  );

  return (
    <>
      <Text type="secondary">
        Welcome, <strong>{foundUser?.firstName}</strong>!
      </Text>
      <br />
      <Text type="secondary">Please create a new password:</Text>
      <Form
        form={form}
        style={{ marginTop: 15 }}
        labelCol={{ span: 7 }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleContinue}
      >
        <Form.Item name="password" rules={[{ required: true, min: MIN_PASSWORD_LENGTH }]}>
          <Input.Password placeholder="Your password" data-recording-sensitive />
        </Form.Item>

        <Form.Item
          name="passwordConfirmation"
          rules={[
            { required: true, min: MIN_PASSWORD_LENGTH },
            { validator: validateNewPasswordConfirmation, message: 'Must be equals password' },
          ]}
        >
          <Input.Password placeholder="Repeat your password" data-recording-sensitive />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{
              width: '100%',
            }}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Link style={{ marginLeft: 4 }} to={ROUTES.login} href={ROUTES.login}>
          Back to Login
        </Link>
      </div>
    </>
  );
};

export default ResetPasswordPage;
