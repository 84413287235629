import styled from 'styled-components';
import { Row, Typography } from 'antd';

const { Link } = Typography;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ImageContainer = styled.div`
  min-width: 70px;
  width: 70px;
  margin-right: 12px;
  div: {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ClicksTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-left: 8px;
  }
`;

export const SlotIdContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SmallLink = styled(Link)`
  width: 100%;
  cursor: pointer;
  color: gray;
  text-decoration: none;
`;

export const PaginationContent = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FilterContent = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
