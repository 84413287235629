import styled from 'styled-components';

import { Layout as AntLayout, PageHeaderProps } from 'antd';

export const Layout = styled(AntLayout)`
  background: #ffffff;
`;

export const LayoutHeader: React.FC<PageHeaderProps> = styled(AntLayout.Header)`
  background: #ffffff;
  margin-left: 0;
`;

export const LayoutContent = styled(AntLayout.Content)`
  background: #ffffff;
`;

export const LayoutFooter = styled(AntLayout.Footer)`
  background: #ffffff;
`;
