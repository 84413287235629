import config from '../config';

const prefix = `@${config.storagePrefix || 'CC'}`;

export default {
  getItem: (key: string): string | undefined => localStorage.getItem(prefix + key) || undefined,

  setItem: (key: string, value: string): void => localStorage.setItem(prefix + key, value),

  removeItem: (key: string): void => localStorage.removeItem(prefix + key),

  setJsonItem: (key: string, value: any): void => localStorage.setItem(prefix + key, JSON.stringify(value)),

  getJsonItem: (key: string): any => {
    const data = localStorage.getItem(prefix + key);
    return data ? JSON.parse(data) : {};
  },
};
