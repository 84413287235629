/* eslint-disable prefer-promise-reject-errors */
import React from 'react';
import { useLocation } from 'react-router';
import { Container } from './styles';
import CreateProductForm from '../../components/CreateProductForm';
import CreateProductFormV2 from '../../components/CreateProductFormV2';

const CreateProductPage: React.FC = () => {
  const location = useLocation();
  const debug = new URLSearchParams(location.search).get('debug') === 'true';
  const formVersion = new URLSearchParams(location.search).get('form-version');
  const wpUserName = new URLSearchParams(location.search).get('wpUserName');

  return (
    <Container>
      {formVersion === '2' && <CreateProductFormV2 debug={debug} />}
      {formVersion !== '2' && <CreateProductForm debug={debug} wpUserName={wpUserName} />}
    </Container>
  );
};

export default CreateProductPage;
