/* eslint-disable no-console */
class Logger {
  info = (pre: string, object: any): void => {
    console.log(`%c ${pre} `, 'background: rgba(74,144,226, .3);', object);
  };

  success = (pre: string, object: any): void => {
    console.log(`%c ${pre} `, 'background: rgba(74,226, 144, .3);', object);
  };

  error = (pre: string, object: any): void => {
    console.log(`%c ${pre} `, 'background: rgba(255, 40, 40, .3);', object);
  };
}
export default new Logger();
