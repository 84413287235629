import React, { useEffect, useMemo } from 'react';

import { Form, Input } from 'antd';
import { useLazyQuery } from '@apollo/client';
import Query from '../../../graphql/Query';
import { IEntityForm } from '../../../dtos/FormDTO';
import { IBrandDTO } from '../../../dtos/ProductDTO';

const BrandsForm: React.FC<IEntityForm> = ({ form, entity, onSuccess: _onSuccess, ...props }) => {
  const [loadBrand, { data }] = useLazyQuery(Query.brand.query);

  const brand = useMemo(() => {
    if (!entity || !data) {
      return undefined;
    }
    return data.brand as IBrandDTO;
  }, [entity, data]);

  useEffect(() => {
    if (!brand || !form) {
      return;
    }
    form.setFieldsValue(brand);
  }, [form, brand]);

  useEffect(() => {
    if (entity) {
      loadBrand({
        variables: {
          id: entity.id,
        },
      });
    }
  }, [entity, loadBrand]);

  return (
    <Form form={form} labelAlign="right" labelCol={{ span: 5 }} {...props}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="e.g. Apple" autoComplete="off" />
      </Form.Item>
    </Form>
  );
};

export default BrandsForm;
