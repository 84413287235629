import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { IQueryObject } from '../../../graphql/Interface';
import Query from '../../../graphql/Query';
import { IPriorityDTO } from '../../../dtos/CampaignDTO';
import SelectField, { IGenericSelectFieldProps } from '../SelectField';

const PriorityField: React.FC<IGenericSelectFieldProps> = (params) => {
  const [iValue, setIvalue] = useState('');
  const [loadPriorities, { loading, data }] = useLazyQuery<IQueryObject<IPriorityDTO>>(
    Query.campaignRulePriorities.query,
  );

  const iPriorities = useMemo(() => {
    if (data) {
      // return data.object.map(o => ({
      //   label: o.label,
      //   value: o.value,
      // }));
      return data.object;
    }
    return [];
  }, [data]);

  useEffect(() => {
    loadPriorities({
      variables: {
        filters: {
          query: iValue,
        },
      },
    });
  }, [loadPriorities, iValue]);

  return (
    <SelectField
      name="Priority"
      onSearch={(val) => setIvalue(val || '')}
      loading={loading}
      data={iPriorities}
      {...params}
    />
  );
};

export default PriorityField;
