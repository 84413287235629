import React, { useCallback, useMemo, useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { Button } from 'antd';
import { IInventoryFilters } from '../../../graphql/Interface';
import { IInventoryDTO } from '../../../dtos/ArticleInventoryDTO';
import { SmallText } from './styles';
import { LinkButtonContainer, LinkContainer, NumberColumn } from '../../../pages/InventoryPage/styles';
import InventoryModal from '../InventoryModal';
import { IProductDTO } from '../../../dtos/ProductDTO';
import InfoPopover from '../../InfoPopover';
import { currencyFormat } from '../../../utils/constants';
import TransactionsModal from '../TransactionsModal';

export type ProductInventoryModalView = 'article' | 'link';

interface ProductModalProps {
  visible?: boolean;
  groupingName?: string;
  totalCount: number;
  filters: IInventoryFilters;
  onCancel(): void;
  maxLocalPerPage: number;
}

const ProductModal: React.FC<ProductModalProps> = ({ totalCount, maxLocalPerPage, ...props }) => {
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState<IInventoryDTO>();

  const handleSelectArticles = useCallback((inventory: IInventoryDTO, counter: number) => {
    setTransactionsCount(counter);
    setSelectedArticle(inventory);
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          title: 'Product',
          dataIndex: 'product',
          key: 'PRODUCT_NAME',
          width: 100,
          ellipsis: true,
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => (a.product.name || '').localeCompare(b.product.name || ''),
                  multiple: 1,
                },
          render: (product: IProductDTO) => (
            <LinkContainer>
              {product.name ? (
                <InfoPopover
                  key={product.id}
                  showImageNotFound
                  image={product?.image?.url}
                  items={[
                    {
                      value: product.name,
                    },
                    {
                      value: product.id,
                    },
                    {
                      value: product.categoryName,
                    },
                  ]}
                >
                  {product.name}
                </InfoPopover>
              ) : (
                <>
                  <SmallText>N/A</SmallText>
                  <SmallText
                    type="secondary"
                    italic
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {product.id}
                  </SmallText>
                </>
              )}
            </LinkContainer>
          ),
        },
        {
          title: 'Category',
          dataIndex: 'product',
          key: 'PRODUCT_CATEGORY',
          width: 120,
          showSorterTooltip: false,
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) =>
                    (a.product.category?.name || '').localeCompare(b.product.category?.name || ''),
                },
          render: (product: IProductDTO) => (
            <SmallText title={product.category?.name} type="secondary" ellipsis>
              {product.category?.name || 'N/A'}
            </SmallText>
          ),
        },
        {
          title: 'Clicks',
          dataIndex: 'clicks',
          key: 'CLICKS',
          width: 80,
          showSorterTooltip: false,
          defaultSortOrder: 'descend',
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.clicks - b.clicks,
                  multiple: 1,
                },
          render: (clicks: number) => (
            <NumberColumn>
              <SmallText type="secondary">{clicks}</SmallText>
            </NumberColumn>
          ),
        },
        {
          title: 'Transactions',
          key: 'TRANSACTIONS',
          dataIndex: 'quantity',
          width: 85,
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.quantity - b.quantity,
                },
          defaultSortOrder: 'descend',
          render: (transactions: number, inventory: IInventoryDTO) => (
            <LinkButtonContainer>
              <Button
                type="ghost"
                shape="round"
                size="small"
                title={`${transactions === 0 ? 'No' : transactions} transaction${transactions !== 1 && 's'}`}
                danger={transactions === 0}
                disabled={transactions === 0}
                onClick={() => handleSelectArticles(inventory, transactions)}
                style={{
                  width: 80,
                  fontSize: 12,
                }}
              >
                {transactions}
              </Button>
            </LinkButtonContainer>
          ),
        },
        {
          title: 'Commissions',
          key: 'COMMISSIONS',
          dataIndex: 'commissions',
          width: 85,
          sorter:
            totalCount > maxLocalPerPage
              ? true
              : {
                  compare: (a, b) => a.commissions - b.commissions,
                },
          defaultSortOrder: 'descend',
          render: (commissions: number) => (
            <NumberColumn>
              <SmallText type="secondary">{currencyFormat(commissions)}</SmallText>
            </NumberColumn>
          ),
        },
      ] as ColumnsType<IInventoryDTO>,
    [handleSelectArticles, maxLocalPerPage, totalCount],
  );

  return (
    <>
      <TransactionsModal
        totalCount={transactionsCount}
        onCancel={() => setSelectedArticle(undefined)}
        filters={{
          ...props.filters,
          productIds: selectedArticle?.product.id ? [selectedArticle.product.id] : [],
        }}
        maxLocalPerPage={maxLocalPerPage}
        visible={!!selectedArticle}
        groupingName="Product"
      />
      <InventoryModal
        dbColumns={['PRODUCT']}
        totalCount={totalCount}
        columns={columns}
        maxLocalPerPage={maxLocalPerPage}
        name="Products"
        {...props}
        localFilter={(iInventory) => iInventory.product.name}
      />
    </>
  );
};

export default ProductModal;
